import { IVisitsService } from "./VisitsService";
import { IBookingPolicyService } from "./BookingPolicyService";
import { IBookingService } from "./BookingService";
import { IService as ICateringMenuItemService } from "./CateringMenuItems/Service";
import { IService as IRoleService } from "./Roles/Service";
import { ISpaceService } from "./SpaceService";
import { ISpaceUtilisationSummaryService } from "./SpaceUtilisationSummaryService";
import { IUserPreferencesService } from "./UserPreferencesService";

export class Services implements IServices
{
    public bookingPolicies: IBookingPolicyService;
    public bookings: IBookingService;
    public visits: IVisitsService;
    public spaces: ISpaceService;
    public spaceUtilisationSummaries: ISpaceUtilisationSummaryService;
    public userPreferences: IUserPreferencesService;
    public cateringMenuItems: ICateringMenuItemService;
    public roles: IRoleService;

    constructor(
        bookingPolicies: IBookingPolicyService,
        bookings: IBookingService,
        visits: IVisitsService,
        spaces: ISpaceService,
        spaceUtilisationSummaries: ISpaceUtilisationSummaryService,
        userPreferences: IUserPreferencesService,
        cateringMenuItems: ICateringMenuItemService,
        roles: IRoleService,
    )
    {
        this.bookingPolicies = bookingPolicies;
        this.bookings = bookings;
        this.visits = visits;
        this.spaces = spaces;
        this.spaceUtilisationSummaries = spaceUtilisationSummaries;
        this.userPreferences = userPreferences;
        this.cateringMenuItems = cateringMenuItems;
        this.roles = roles;
    }
}

export interface IServices
{
    bookingPolicies: IBookingPolicyService;
    bookings: IBookingService;
    visits: IVisitsService;
    spaces: ISpaceService;
    spaceUtilisationSummaries: ISpaceUtilisationSummaryService;
    userPreferences: IUserPreferencesService;
    cateringMenuItems: ICateringMenuItemService;
    roles: IRoleService;
}
