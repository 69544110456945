import { Component } from 'react';
import { appContext } from '../../AppContext';
import SpaceArrangementsDialog, { ISpaceArrangement } from './SpaceArrangementsDialog';
import { Button } from '@mui/material';
import IbssSvgIcon from '../uicomponents/IbssSvgIcon';
import { Icons } from '../../Common/AllsvgIcons';

class SpaceArrangementPicker extends Component<IProps, IState>
{
    private labels = appContext().labels;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            showLayoutSeatingModal: false,
        };
    }



    public updateLayoutSeating(seating: ISpaceArrangement, spaceId: string, spaceName: string): void
    {
        this.props.onSubmit(seating, spaceId, spaceName);
        this.setState({showLayoutSeatingModal: false});
    }

    public render(): JSX.Element
    {
        return (
            <>
                {this.props.showSpaceLayout && (
                    <>
                        <Button
                            fullWidth  
                            sx={{justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }}
                            onClick={() => this.setState({ showLayoutSeatingModal: true })}
                            disabled= {this.props.bookingStatus === "Completed" || this.props.bookingStatus === "Cancelled"}
                            
                        >
                            <div className="icon-text-inline pl-0">
                                <div style={{ fontSize: '26px', marginTop: '0px', display: 'flex'}}>
                                    <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }}>
                                        {Icons.SpaceLayoutIcon}
                                    </IbssSvgIcon>
                                </div>
                                <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelLinkedSpaces}</span>
                            </div>
                            <div className="d-flex">
                                <span className="space-text-item mr-3">{this.props.selectedLayoutName}</span>
                                <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                    {Icons.RightArrowIcon}
                                </IbssSvgIcon>
                            </div>
                        </Button>
                        <hr />
                    </>
                )}

                {this.props.showSpaceArrangement && (
                    <>
                        <Button
                            fullWidth 
                            sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }} 
                            onClick={() => this.setState({ showLayoutSeatingModal: true })}
                        >
                            <div className="icon-text-inline pl-0">
                                <div style={{ fontSize: '26px', marginTop: '0px', display: 'flex'}}>
                                    <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }}>
                                        {Icons.SeatingArrangementIcon}
                                    </IbssSvgIcon>
                                </div>
                                <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelLayouts}</span>
                            </div>
                            <div className="d-flex">
                                <span className="space-text-item mr-3">{this.props.selectedLayoutStyle}</span>
                                <IbssSvgIcon fontSize='medium' className="" sx={{ color: (theme) => theme.palette.text.primary }}>
                                    {Icons.RightArrowIcon}
                                </IbssSvgIcon>
                            </div>
                        </Button>
                        <hr />
                    </>
                )}

                <SpaceArrangementsDialog 
                    modalOpen={this.state.showLayoutSeatingModal}
                    onClose={()=> this.setState({ showLayoutSeatingModal: false })}
                    onSave={(seating: ISpaceArrangement, spaceId: string, spaceName: string) => this.updateLayoutSeating(seating, spaceId, spaceName)}
                    spaceSetup={this.props.spaceSetup}
                    spaceLayout={this.props.spaceLayout}

                    spaceId={this.props.spaceId}
                    selectedLayoutStyle={this.props.selectedLayoutStyle}
                />
            </>
        )
    }
}

export default SpaceArrangementPicker;

export interface IProps
{
    bookingStatus: string;
    onSubmit: (seating: ISpaceArrangement, spaceId: string, spaceName: string) => void;

    spaceId: string; 
    selectedLayoutName: string;
    selectedLayoutStyle: string;

    showSpaceLayout: boolean;
    showSpaceArrangement: boolean;
    spaceSetup: number,
    spaceLayout? : string,
}

export interface IState
{
    showLayoutSeatingModal: boolean;
}