import { Component } from "react";
import "../../../styles/css/table.scss";
import "../../../App.css";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import Spinner from "../../../Components/Spinner";
import CheckboxIndeterminateInput from "../../../Components/uicomponents/CheckboxIndeterminateInput";
import Submenu from "../../../Components/SubMenu";
import Helper, { multiSort } from "../../../Common/Helper";
import { IPartialAppState, appContext } from "../../../AppContext";
import IbssDialog from "../../../Components/uicomponents/IbssDialog";
import FacilitySpacesActivePage from "../../../Components/SpacesTable";
import DonutChartCard from "../../../Components/DonutChart";
import PieChartCard from "../../../Components/IbssPieChart";
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import { DateHelper } from "../../../Common/DateHelper";
import { ODataQuery } from "../../../Providers.Api/ODataQuery";
import { BookingFilter } from "../../../Providers.Api/Bookings/BookingRepository";
import { Visit2, VisitFilter } from "../../../Providers.Api/Visits/VisitsRepository";

interface IStatusSpaceData{
  Space_State_Name:string;
  Space_State_Id:string;
  isChecked:boolean;
}

class FacilitySpaces extends Component {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;
  private get bookingService() { return appContext().bookingService; };
  private get apiClient() { return appContext().apiClient; };

  state = {
    isLoading: false,
    activePage: 0,
    isFilter: false,
    show: false,
    isDelete: false,
    Space_IsEnabledModalValue: 1,
    occupiedSpaceData: [],
    freeSpaceData: [],
    directedCleaningSpaceData: [],
    filteredSpaceData: [],
    checked: false,
    statusSpaceData: [],
    statusModalValues: [],
    checkBoxValue: false,
    availableCheckboxModalValue: false,

    dataPie1: [],
    dataPie1Value: 0,
    COLORS1: ['#FF7913', '#FFC400', '#00C853', '#2962FF'],

    emptyPieData : [{ name: "No Data", value: 100, count: 0 }],
    COLORSEMPTY: ['#393D44'],

    todayBooking: 0,
    spacesNotCheckedIn: [],
    spaceswithnoBookings: 0,
    checkedInBookings: 0,
    unoccupiedSpaceswithBookings: 0,
    noShowBookings: 0,
    todaysVisitors: 0,
    visitorInBuilding: 0,
    visitorsCheckout: 0,
    filterMode: false,
    selectedBuildingId: this.appState.buildingId.toString(),
  };


  public async componentDidMount() {

    const { history, match }: any = this.props;
    this.setState({ checkBoxValue: true, availableCheckboxModalValue: true })
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingIdParam = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingIdParam }, this);
    if (match.params.tab) {
      const tabNumber = parseInt(match.params.tab)
      if (!isNaN(match.params.tab) && tabNumber) {
        this.setState({ activePage: tabNumber })
      }
    }

      if (this.session.getBuildingId() && match.params.filter) {
        history.push("/operational-services-spaces/" + this.state.selectedBuildingId + "/" + match.params.tab + "/" + match.params.filter)
      } else if (this.session.getBuildingId()) {
        history.push("/operational-services-spaces/" + this.state.selectedBuildingId)
      } else {
        history.push("/operational-services-buildings/0")
      }
    
    this.getDetails();
    
  }
  
  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void
  {
    if(prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.getDetails();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
      if (state.buildingId != undefined)
      {
          this.setState({ selectedBuildingId: state.buildingId.toString() });
          history.push(match.path.replace(":buildingid", state.buildingId));
      }
  }

  getDetails = async () => {
    this.getSpacePieChart();
    this.getTodayBookings();
    this.getNoCheckin();
    this.getNoBookings();
    this.getCheckedInBookings();
    this.getUnoccupiedBookings();
    this.getNoShowBookings();
    this.getTodaysSpaceVisitors();
    this.getVisitorInBuilding();
    this.getVisitorsCheckout();
    this.getOccupiedSpaceData()
    this.getfreeSpaceData()
    this.getDirectedCleaningSpaceData()
    this.getStatusValuesForFiltering();
    //this.handleComponentMountGetFilterSpaces();
    this.handleOkClickButton();
  }

  getSpacePieChart = async () => {
    try {
      this.setState({ isLoading: true })
      const response = await apis.getSpacePieChart(this.session.getBuildingId())
      const responseData = response.data
      let nodeArray: any = [];
      let graphdata: any = [];
      let totalSpaceCount = 0;
      responseData.forEach((item: any) => {
        let index = (nodeArray.indexOf(item.Node_Id) > -1) ? nodeArray.indexOf(item.Node_Id) : -1;
        totalSpaceCount = totalSpaceCount + item.Space_Occupancy
        if (index === -1) {
          nodeArray.push(item.Node_Id)
          graphdata.push({ nodeId: item.Node_Id, name: "", value: item.Space_Occupancy, count: item.Space_Occupancy })
        } else {
          graphdata[index].value = graphdata[index].value + item.Space_Occupancy;
          graphdata[index].count = graphdata[index].count + item.Space_Occupancy;
        }
      });

      let totalCount = 0;
      graphdata.forEach(async (item: any) => {
        apis.getSpaceFloorName(item.nodeId).then((res) => {
          this.setState({ isLoading: false })
          totalCount = totalCount + 1;
          let index = nodeArray.indexOf(item.nodeId);
          if (res.data.length > 0) {
            graphdata[index].name = res.data[0].name;
          }
          graphdata[index].value = Math.round((graphdata[index].value / totalSpaceCount) * 100)
          if (totalCount === graphdata.length) {
            this.setState({
              dataPie1: multiSort(graphdata, { value: 'desc' }),
              dataPie1Value: totalSpaceCount
            })
          }
        }).catch((error: any) => {
          this.setState({ isLoading: false })
        });
      });

    } catch (error: any) {
      this.setState({ isLoading: false })
    }
  }

  getTodayBookings = async () => 
  {
    try {
      const query = new ODataQuery({ nodeId: this.session.getBuildingId() });
      const startDate = DateHelper.today();
      const endDate = DateHelper.today().plus({ days: 1 }).startOf('day');
      const response = await this.bookingService.getBookings(query, startDate, endDate);
      this.setState({ todayBooking: response.value.length });
    } 
    catch (error: any) 
    {
    }
  }

  getNoCheckin = async () => {
    try {
      const response = await apis.getSpacesNotCheckedIn(this.session.getBuildingId());
      this.setState({ spacesNotCheckedIn: response.data })
    } catch (error: any) {
    }
  }

  getNoBookings = async () => {
    try {
      const response = await apis.getSpaceswithnoBookings(this.session.getBuildingId());
      this.setState({ spaceswithnoBookings: response.data.length })
    } catch (error: any) {
    }
  }

  getCheckedInBookings = async () => {
    try {
      const response = await apis.getCheckedInBookings(this.session.getBuildingId());
      this.setState({ checkedInBookings: response.data.length })
    } catch (error: any) {
    }
  }

  getUnoccupiedBookings = async () => {
    try {
      const response = await apis.getUnoccupiedSpaceswithBookings(this.session.getBuildingId());
      this.setState({ unoccupiedSpaceswithBookings: response.data.length })
    } catch (error: any) {
    }
  }

  getNoShowBookings = async () => {
    try {
      const query = new ODataQuery({ 
        nodeId: this.session.getBuildingId(),
        filter: new BookingFilter({
            bookingStatus: 'No Show',
        }), 
      });
      const startDate = DateHelper.today();
      const endDate = DateHelper.today().plus({ days: 1 }).startOf('day');
      const response = await this.bookingService.getBookings(query, startDate, endDate);
      this.setState({ noShowBookings: response.value.length });
    } catch (error: any) {
    }
  }

  getTodaysSpaceVisitors = async () => {
    try {
      const buildingId = this.session.getBuildingId();

      const query = new ODataQuery({
        top: 201,
        nodeId: buildingId,
        select: Visit2,
        filter: new VisitFilter({
            minStartDate: DateHelper.today().date().setZoneByNode(buildingId),
            maxEndDate: DateHelper.today().plus({ days: 1 }).startOf('day').date().plus({ days: 1 }).setZoneByNode(buildingId),
        }),
      });
      const response = await this.apiClient.visits.getMany(query);
      this.setState({ todaysVisitors: response.length });
    } catch (error: any) {
    }
  }

  getVisitorInBuilding = async () => {
    try {
      const buildingId = this.session.getBuildingId();
      const query = new ODataQuery({
        top: 201,
        nodeId: buildingId,
        select: Visit2,
        filter: new VisitFilter({
            minStartDate: DateHelper.today().date().setZoneByNode(buildingId),
            maxEndDate: DateHelper.today().plus({ days: 1 }).startOf('day').date().plus({ days: 1 }).setZoneByNode(buildingId),
            status: 'Checked In'
        }),
      });
      const response = await this.apiClient.visits.getMany(query);
      this.setState({ visitorInBuilding: response.length });
    } catch (error: any) {
    }
  }

  getVisitorsCheckout = async () => {
    try {
      const response = await apis.getVisitorsCheckout(this.session.getBuildingId());
      this.setState({ visitorsCheckout: response.data.length })
    } catch (error: any) {
    }
  }


  getOccupiedSpaceData = async () => {
    try {
      const response = await apis.getOccupiedSpace(this.session.getBuildingId())
      const responseData = response.data
      let arr: any = []
      responseData.forEach((eachData: any) => {
        let occ: any = "";
        if (eachData.Meta_Occ_Type === 0) {
          occ = "(unmonitored)";
        } else if (eachData.Meta_Occ_Type === 1) {
          occ = eachData.Space_Ext_Occ_Count;
        }
        const innerRes: any = { ...eachData, occupancy: occ }
        arr.push(innerRes);
      });
      this.setState({ occupiedSpaceData: arr })
    } catch (error: any) {
    }
  }


  getfreeSpaceData = async () => {
    try {
      const response = await apis.getFreeSpace(this.session.getBuildingId())
      const responseData = response.data
      let arr: any = []
      responseData.forEach((eachData: any) => {
        let free: any = "";
        if (eachData.Meta_Occ_Type === 0) {
          free = "(unmonitored)";
        } else if (eachData.Meta_Occ_Type === 1) {
          free = eachData.Space_Ext_Occ_Count;
        }
        const innerRes: any = { ...eachData, freeField: free }
        arr.push(innerRes);
      });
      this.setState({ freeSpaceData: arr })

    } catch (error: any) {
    }
  }

  getDirectedCleaningSpaceData = async () => {
    try {
      const response = await apis.getDirectedCleaningSpace(this.session.getBuildingId())
      const responseData = response.data
      let arr: any = []
      responseData.forEach((eachData: any) => {
        let dcCleaning: any = "";
        if (eachData.Meta_Occ_Type === 0) {
          dcCleaning = "(unmonitored)";
        } else if (eachData.Meta_Occ_Type === 1) {
          dcCleaning = eachData.Space_Ext_Occ_Count;
        }
        const innerRes: any = { ...eachData, directedCleaningFiled: dcCleaning }
        arr.push(innerRes);
      });
      this.setState({ directedCleaningSpaceData: arr })
    } catch (error: any) {
    }
  }


  getFilteredSpaceData = async (FilteredData: any) => {
    const { match }: any = this.props;
    try {
      let response;
      if (match.params.filter && match.params.filter === "notcheckedin" && !this.state.filterMode) {
        response = await apis.getSpacesNotCheckedIn(this.session.getBuildingId())
      } else if (match.params.filter && match.params.filter === "withnobookings" && !this.state.filterMode) {
        response = await apis.getSpaceswithnoBookings(this.session.getBuildingId())
      } else if (match.params.filter && match.params.filter === "unoccupiedspaceswithbookings" && !this.state.filterMode) {
        response = await apis.getUnoccupiedSpaceswithBookings(this.session.getBuildingId())
      } else if (match.params.filter && match.params.filter === "occupancy" && !this.state.filterMode) {
        response = await apis.getSpacebyBuildingIDForBooking(this.session.getBuildingId())
      } else {
        response = await apis.getFilteredSpaceData(FilteredData);
      }
      const responseData = response.data;
      let arr: any = []
      responseData.forEach((eachData: any) => {
        let filterData: any = "(unmonitored)";
        if (eachData.Meta_Occ_Type === 0) {
          filterData = "(unmonitored)";
        } else if (eachData.Meta_Occ_Type === 1) {
          filterData = eachData.Space_Ext_Occ_Count;
        }
        const innerRes: any = { ...eachData, filterdFiled: filterData }
        arr.push(innerRes);
      });
      this.setState({ filteredSpaceData: (arr && arr[0]) ? arr : [] })

    } catch (error: any) {
    }
  }

  filterButtonClicked = () => {
    this.setState({
      isFilter: !this.state.isFilter,
      filterMode: true
    })
  }

  getStatusValuesForFiltering = async () => {
    try {
      const response = await apis.getStatusValuesForSpaces(this.session.getBuildingId())
      const responseData = response.data
      const newResponseData = responseData && responseData[0] && responseData.map((item: any) => {
        let newItem = { ...item }
        newItem.isChecked = false
        return newItem;
      })
      this.setState({ statusSpaceData: newResponseData })
    }
    catch {

    }
  }

  excelExportButtonStyle = (table: any) => {
    let styleClass = "button-primary-disable mr-3 e-control e-btn e-lib"
    if (table && table.rows && table.rows[0]) {
      styleClass = "button-primary e-control mr-3 e-btn e-lib"
    }
    return styleClass
  }


  handleDownloadExcelOccupiedSpace = async () => {
    try {
      await apis.downloadExcelOccupiedSpace(this.session.getBuildingId())
    } catch (error: any) {
    }
  }

  cancelFilterModal = () => {
    this.setState({
      isFilter: false,
    })
  }

  handleClickCheckBox = (e: any, checkItem: any) => {
    const newStatusSpaceData = this.state.statusSpaceData.map((item: any) => {
      if (checkItem.Space_State_Id === item.Space_State_Id) {
        let newItem = { ...item }
        newItem.isChecked = !item.isChecked
        return newItem
      }
      return item
    })
    this.setState({ statusSpaceData: newStatusSpaceData })
  }

  handleOkClickButton = async () => {
    let Space_IsEnabled;
    let Space_IsAvailable;

    let SpaceListQuery = ''
    this.state.statusSpaceData.forEach((item: any) => {
      if (item.isChecked === true) {
        if (SpaceListQuery === "") {
          SpaceListQuery = `Space_Status eq '${item.Space_State_Id}'`
        }
        else if (SpaceListQuery) {
          SpaceListQuery = `${SpaceListQuery} or Space_Status eq '${item.Space_State_Id}'`
        }
      }
    })


    if (this.state.checkBoxValue === true) {
      Space_IsEnabled = "1";
    }
    else if (this.state.checkBoxValue === false) {
      Space_IsEnabled = "0";
    }

    if (this.state.availableCheckboxModalValue === true) {
      Space_IsAvailable = "1";
    }
    else if (this.state.availableCheckboxModalValue === false) {
      Space_IsAvailable = "0";
    }

    const FilteredData = {
      nodeId: this.session.getBuildingId(),
      Space_IsEnabled: Space_IsEnabled,
      Space_IsAvailable: Space_IsAvailable,
      SpaceListQuery: SpaceListQuery,
    }

    await this.getFilteredSpaceData(FilteredData)
    this.cancelFilterModal();
    return
  }

  handleComponentMountGetFilterSpaces = async () => {
    const filteredData = {
      nodeId: this.session.getBuildingId(),
      Space_IsEnabled: "1",
      Space_IsAvailable: "1",
      SpaceListQuery: "",
    }

    await this.getFilteredSpaceData(filteredData)
    return
  }


  handleDownloadExcelFreeSpace = async () => {
    try {
      await apis.downloadExcelFreeSpace(this.session.getBuildingId())
    } catch (error: any) {
    };
  }


  handleDownloadExcelDirectedCleaningSpace = async () => {
    try {
      await apis.downloadExcelDirectedCleaningSpace(this.session.getBuildingId())
    } catch (error: any) {
    };
  }

  handleCheckChange = (checkValue: any) => {
    this.setState({ checkBoxValue: checkValue })

  }

  handleCheckAvailableChange = (checkValue: any) => {
    this.setState({ availableCheckboxModalValue: checkValue })

  }

  todaysBookingsFilter = () => {
    const { history }: any = this.props;
    history.push({pathname: `/operational-services-bookings/${this.appState.buildingId.toString()}/0`, search: '?Status=Any'});
  }

  checkedInBookingsFilter = () => {
    const { history }: any = this.props;
    history.push({pathname: `/operational-services-bookings/${this.appState.buildingId.toString()}/0`, search: '?Status=In Progress'});
  }

  noShowBookingFilter = () => {
    const { history }: any = this.props;
    history.push({pathname: `/operational-services-bookings/${this.appState.buildingId.toString()}/0`, search: '?Status=No Show'});
  }

  spacesFilter = (param: any) => {
    const { history }: any = this.props;
    history.push("/operational-services-spaces/" + this.session.getBuildingId() + "/4/" + param)
  }

  visitorFilter = (param: any) => {
    const { history }: any = this.props;
    history.push({pathname: `/operational-services-visitors/${this.appState.buildingId.toString()}/0`});
  }

  private visitorInbuilding(): void
{
    const { history }: any = this.props;
    history.push({pathname: `/operational-services-visitors/${this.appState.buildingId.toString()}/0`, search: '?Status=Checked In'});
  }

  private visitorsCheckout(): void
  {
    const { history }: any = this.props;
    history.push({pathname: `/operational-services-visitors/${this.appState.buildingId.toString()}/0`, search: '?Status=Checked Out'});
  }

  render() {
    const { match, history }: any = this.props;
    const { checkBoxValue, availableCheckboxModalValue }: any = this.state

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabOverview,
          tabNo: 0,
          active: true,
        },
        {
          title: this.labels.HubTabOpservSpacesOccupied,
          tabNo: 1,
          active: true,
        },
        {
          title: this.labels.HubTabOpservSpacesFree,
          tabNo: 2,
          active: false,
        },
        {
          title: this.labels.HubTabOpservDirectedCleaning,
          tabNo: 3,
          active: false,
        },
        {
          title: this.labels.HubTabCustomFilter,
          tabNo: 4,
          active: false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        if (match.params.filter && i !== 4) {
          history.push("/operational-services-spaces/" + this.session.getBuildingId() + "/" + i)
          this.getDetails();
        } else {
          this.setState({ activePage: i });
        }
      },
    };

    const tableColumnInfo = [
      {
         label: this.labels.HubLabelSpaceName,
         field: "Space_Name",
         sort: "",
         width: 50,
       },
       {
         label: this.labels.HubLabelSpaceClass,
         field: "Space_Class",
         sort: "",
         width: 50,
       },
       {
         label: this.labels.HubLabelSpaceType,
         field: "Space_Type",
         sort: "",
         width: 50,
       },
       {
         label: this.labels.HubLabelSpaceId,
         field: "Space_Id",
         sort: "",
         width: 50,
       },
       {
         label: this.labels.HubLabelStatus,
         field: "Space_Status_Text",
         sort: "",
         width: 50,
       },
   ]


    const occupiedSpaceTableData = {
      columns:[...tableColumnInfo,       
      {
        label: this.labels.HubLabelOccupancyLabel,
        field: "occupancy",
        sort: "",
        width: 50,
      }],
      rows: this.state.occupiedSpaceData ? this.state.occupiedSpaceData : []
    };


    const freeSpaceTableData = {
      columns:[...tableColumnInfo,       
        {
          label: this.labels.HubLabelOccupancyLabel,
          field: "freeField",
          sort: "",
          width: 50
        }],
      rows: this.state.freeSpaceData ? this.state.freeSpaceData : []
    };



    const directedCleaningSpaceTableData = {
      columns:[...tableColumnInfo,       
        {
          label: this.labels.HubLabelOccupancyLabel,
          field: "directedCleaningFiled",
          sort: "",
          width: 50
        }],
      rows: this.state.directedCleaningSpaceData ? this.state.directedCleaningSpaceData : []
    };


    const filteredSpaceTableData = {
      columns:[...tableColumnInfo,       
        {
          label: this.labels.HubLabelOccupancyLabel,
          field: "filterdFiled",
          sort: "",
          width: 50
        }],
      rows: this.state.filteredSpaceData ? this.state.filteredSpaceData : []
    };


    return (
      <>
        <div>
          <IbssDialog 
            onClose={this.cancelFilterModal}
            open={this.state.isFilter}
            header={this.labels.HubLabelFilter} 
            content=
            {
              <>
                <CheckboxIndeterminateInput
                  label={this.labels.HubLabelEnabled}
                  checkBoxValue={checkBoxValue}
                  onChange={this.handleCheckChange}
                />

                <CheckboxIndeterminateInput
                  label={this.labels.HubLabelAvailable}
                  checkBoxValue={availableCheckboxModalValue}
                  onChange={this.handleCheckAvailableChange}
                />

                <div className="row mt-3">
                  <label className="upload-file-modal-label col-4 pl-0 pr-0">
                    {this.labels.HubLabelStatus}
                  </label>
                </div>

                {this.state.statusSpaceData &&
                  this.state.statusSpaceData.map((item: IStatusSpaceData) => {
                    return (
                      <div className="row modal-div-status-checkbox mt-3">
                        <input
                          className="modal-status-checkbox"
                          type="checkbox"
                          name={item.Space_State_Id}
                          id={item.Space_State_Id}
                          checked={item.isChecked}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleClickCheckBox(e, item);
                          }}
                        ></input>
                        <label className="upload-file-modal-label col-4 pl-0 pr-0">
                          {item.Space_State_Name}
                        </label>
                      </div>
                    );
                  })}
              </>
            }
            footer={ 
              <>
              <IbssButtonRedo
                   onClick={() => this.cancelFilterModal()}
                   color="secondary"
                   variant="outlined"
                   className="mr-2"
                >
                    {this.labels.HubButtonCancel}
                </IbssButtonRedo>
                <IbssButtonRedo
                    color="primary"
                    variant="contained"
                    size="medium"
                    className="ml-2"
                    onClick={() => this.handleOkClickButton()}
                >
                    {this.labels.HubLabelOk}
                </IbssButtonRedo>
              </>
            }>
          </IbssDialog>
          <div>
            <Submenu {...props} />
            {this.state.activePage === 0 &&
              <div className="facility-mang-chart-layout my-0">
                {this.state.isLoading  && <Spinner />}
                  <div className="chart-panel-section2 my-0">
                    <div className="donutchartSpace spacePanel" onClick={() => this.spacesFilter("occupancy")}>
                      <h3 className="donutcharttitlecenter">{this.labels.HubLabelOccupancyOverviewLabel}</h3>
                      {this.state.dataPie1.length !== 0 &&
                      this.state.dataPie1Value !== 0 ? (
                        <PieChartCard 
                          data={this.state.dataPie1}
                          colors={this.state.COLORS1}
                          value={this.state.dataPie1Value}
                          emptyData={this.state.dataPie1}

                        />
                      ) : (
                        <PieChartCard 
                          data={this.state.dataPie1}
                          colors={this.state.COLORSEMPTY}
                          value={0}
                          emptyData={this.state.emptyPieData}
                        />
                      )}
                    </div>
                    <div className="donutchartSpace donutchartmargin">
                    <DonutChartCard
                        cardClicked={this.todaysBookingsFilter}
                        title={this.labels.HubLabelTodaysBookings}
                        subTitle={this.state.todayBooking}
                    />
                    <DonutChartCard
                        cardClicked={() => this.spacesFilter("notcheckedin")}
                        title={this.labels.HubLabelSpacesNotCheckedIn}
                        subTitle={this.state.spacesNotCheckedIn.length}
                    />
                    <DonutChartCard
                        cardClicked={() => this.spacesFilter("withnobookings")}
                        title={this.labels.HubLabelSpacesWithNoBookings}
                        subTitle={this.state.spaceswithnoBookings}
                    />
                    </div>

                    <div className="donutchartSpace donutchartmargin">
                      <DonutChartCard
                        cardClicked={this.checkedInBookingsFilter}
                        title={this.labels.HubLabelCheckedInBookings}
                        subTitle={this.state.checkedInBookings}
                      />
                      <DonutChartCard
                        cardClicked={() =>
                          this.spacesFilter("unoccupiedspaceswithbookings")
                        }
                        title={
                          this.labels.HubLabelUnOccupiedSpaceswithBookings
                        }
                        subTitle={this.state.unoccupiedSpaceswithBookings}
                      />
                      <DonutChartCard
                        cardClicked={this.noShowBookingFilter}
                        title={this.labels.HubLabelNoShowBookings}
                        subTitle={this.state.noShowBookings}
                      />
                    </div>

                    <div className="donutchartSpace donutchartmargin">
                      <DonutChartCard
                        cardClicked={() => this.visitorFilter("todaysvisitors")}
                        title={this.labels.HubLabelTodaysVisitors}
                        subTitle={this.state.todaysVisitors}
                      />
                      <DonutChartCard
                        cardClicked={() =>
                          this.visitorInbuilding()
                        }
                        title={this.labels.HubLabelVisitorsInBuilding}
                        subTitle={this.state.visitorInBuilding}
                      />
                      <DonutChartCard
                        cardClicked={() =>
                          this.visitorsCheckout()
                        }
                        title={this.labels.HubLabelVisitorsCheckedOut}
                        subTitle={this.state.visitorsCheckout}
                      />
                    </div>
                  </div>
              </div>
            }
            {this.state.activePage === 1 &&
                <FacilitySpacesActivePage
                  title={this.labels.HubTabOpservSpacesOccupied}
                  downloadButtonClassName={this.excelExportButtonStyle(
                    occupiedSpaceTableData
                  )}
                  isDownloadButtonDisabled={occupiedSpaceTableData.rows[0] ? false : true}
                  downloadButtonClicked={this.handleDownloadExcelOccupiedSpace}
                  isLoading={this.state.isLoading}
                  tableData={occupiedSpaceTableData}
                  showDownloadButton ={true}
                />
            }
            {this.state.activePage === 2 &&
                <FacilitySpacesActivePage
                  title={this.labels.HubTabOpservSpacesFree}
                  downloadButtonClassName={this.excelExportButtonStyle(
                    freeSpaceTableData
                  )}
                  isDownloadButtonDisabled={freeSpaceTableData.rows[0] ? false : true}
                  downloadButtonClicked={this.handleDownloadExcelFreeSpace}
                  isLoading={this.state.isLoading}
                  tableData={freeSpaceTableData}
                  showDownloadButton ={true}
                />
            }
            {this.state.activePage === 3 &&
                <FacilitySpacesActivePage
                  title={this.labels.HubTabOpservDirectedCleaning}
                  downloadButtonClassName={this.excelExportButtonStyle(
                    directedCleaningSpaceTableData
                  )}
                  isDownloadButtonDisabled={
                    directedCleaningSpaceTableData.rows[0] ? false : true
                  }
                  downloadButtonClicked={this.handleDownloadExcelDirectedCleaningSpace}
                  isLoading={this.state.isLoading}
                  tableData={directedCleaningSpaceTableData}
                  showDownloadButton ={true}
                />
            }
            {this.state.activePage === 4 &&
                <FacilitySpacesActivePage
                  title={this.labels.HubLabelOpservSpacesFiltered}
                  downloadButtonClicked={this.filterButtonClicked}
                  isLoading={this.state.isLoading}
                  tableData={filteredSpaceTableData}
                  showDownloadButton ={false}
                  showFilterButton ={true}
                  filterButtonClicked={this.filterButtonClicked}
                />
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    // mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilitySpaces);