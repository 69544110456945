import fileDownload from "js-file-download";
import FileSaver from 'file-saver'
import axios from "axios";
import momentBusiness, { min, utc } from 'moment-business-days';
import Helper, { getWorkingDayArray, buildingTimeConverted, convertApiResponseObjectToLower, getBuildingTimeZoneByNodeId, dateConverter } from "../Common/Helper";
import 'moment-timezone';
import { DateTime } from 'luxon';
import { DateHelper } from "../../src/Common/DateHelper";
import { appContext } from "../AppContext";
import { ApiHelper } from "./ApiHelper";

// const userApi = "https://userapi.ibss.dev.iconics.cloud/api/user/v1/Users/";
// const dataEntrySecurityApi = "https://dataentryapi.ibss.dev.iconics.cloud/api/webentry/v1/Security/";
// const dataEntrySpacesApi = "https://dataentryapi.ibss.dev.iconics.cloud/api/webentry/v1/Spaces";
// const allApi = "https://api.ibss.dev.iconics.cloud/v1/"

//Set env
const current_env = window.location.href;
/* this will fetch URL value
  Either it will be
  Local  - http://localhost:3000/
  ICONICS Cloud Environments - [https://adminportal.ibss.[ENV].iconics.cloud/]https://adminportal.ibss.[ENV].iconics.cloud/
  Customer Environments - [https://adminportal.ibss.[CUSTOMER]https://adminportal.ibss.[CUSTOMER ENV]/
*/

let userApiBaseUrl, dataApiBaseUrl, dataEntryApiBaseUrl;
let userApi, dataEntrySecurityApi, dataEntrySpacesApi, dataEntryWebApi, reportingApiV1, reportingApiV2, allApi, allApiv2, dataEntryApiV2;
if (current_env.includes('localhost')) {

  let host = Helper.getCurrentHostEnvironment();
  // Redirection for Localhost to DEV
  userApiBaseUrl = "https://userapi.ibss.dev.iconics.cloud/";
  dataEntryApiBaseUrl = "https://dataentryapi.ibss.dev.iconics.cloud/"
  dataApiBaseUrl = "https://dataapi.ibss.dev.iconics.cloud/";
  userApi = "https://userapi.ibss.dev.iconics.cloud/api/user/v1/Users/";
  dataEntrySecurityApi = "https://dataentryapi.ibss.dev.iconics.cloud/api/webentry/v1/Security/";
  dataEntrySpacesApi = "https://dataentryapi.ibss.dev.iconics.cloud/api/webentry/v1/Spaces";
  dataEntryWebApi = "https://dataentryapi.ibss.dev.iconics.cloud/api/webentry/v1/";
  reportingApiV1 = "https://reportingapi.ibss.dev.iconics.cloud/v1/";
  reportingApiV2 = "https://reportingapi.ibss.dev.iconics.cloud/v2/";
  allApi = "https://api.ibss.dev.iconics.cloud/v1/";
  allApiv2 = "https://api.ibss.dev.iconics.cloud/v2/";
  dataEntryApiV2 = "https://dataentryapi.ibss.dev.iconics.cloud/v2/";

  //userApiBaseUrl = "https://userapi.ibss.test.iconics.cloud/";
  //dataEntryApiBaseUrl = "https://dataentryapi.ibss.test.iconics.cloud/"
  //dataApiBaseUrl = "https://dataapi.ibss.test.iconics.cloud/";
  //userApi = "https://userapi.ibss.test.iconics.cloud/api/user/v1/Users/";
  //dataEntrySecurityApi = "https://dataentryapi.ibss.test.iconics.cloud/api/webentry/v1/Security/";
  //dataEntrySpacesApi = "https://dataentryapi.ibss.test.iconics.cloud/api/webentry/v1/Spaces";
  //dataEntryWebApi = "https://dataentryapi.ibss.test.iconics.cloud/api/webentry/v1/";
  //allApi = "https://api.ibss.test.iconics.cloud/v1/";
  //allApiv2 = "https://api.ibss.test.iconics.cloud/v2/";
  //dataEntryApiV2 = "https://dataentryapi.ibss.test.iconics.cloud/v2/";

  // userApiBaseUrl = "https://userapi.ibss.sales.iconics.cloud/";
  // dataEntryApiBaseUrl = "https://dataentryapi.ibss.sales.iconics.cloud/"
  // dataApiBaseUrl = "https://dataapi.ibss.sales.iconics.cloud/";
  // userApi = "https://userapi.ibss.sales.iconics.cloud/api/user/v1/Users/";
  // dataEntrySecurityApi = "https://dataentryapi.ibss.sales.iconics.cloud/api/webentry/v1/Security/";
  // dataEntrySpacesApi = "https://dataentryapi.ibss.sales.iconics.cloud/api/webentry/v1/Spaces";
  // dataEntryWebApi = "https://dataentryapi.ibss.sales.iconics.cloud/api/webentry/v1/";
  // allApi = "https://api.ibss.sales.iconics.cloud/v1/";
}
else
{
  //For all other environments use the environment itself
  // let host = window.location.hostname.replace('adminportal.', '');

  // let activeurl = window.location.href;
  // let breakUrl = activeurl.split("/")
  // let re = breakUrl[2];
  // breakUrl = re.split(".")
  // re = breakUrl[0];

  let host = Helper.getCurrentHostEnvironment();
  userApiBaseUrl = `https://userapi${host}/`;
  dataEntryApiBaseUrl = `https://dataentryapi${host}/`
  dataApiBaseUrl = `https://dataapi${host}/`;
  userApi = 'https://userapi' + host + '/api/user/v1/Users/';
  dataEntrySecurityApi = 'https://dataentryapi' + host + '/api/webentry/v1/Security/';
  dataEntrySpacesApi = 'https://dataentryapi' + host + '/api/webentry/v1/Spaces';
  dataEntryWebApi = 'https://dataentryapi' + host + '/api/webentry/v1/';
  reportingApiV1 = 'https://reportingapi' + host + '/v1/';
  reportingApiV2 = 'https://reportingapi' + host + '/v2/';
  allApi = 'https://api' + host + '/v1/';
  allApiv2 = 'https://api' + host + '/v2/';
  dataEntryApiV2 = 'https://dataentryapi' + host + '/v2/';
}
const moment = require('moment');

const usersIdentityProviderAPI = (payload) => {
  axios.defaults.headers["Content-Type"] = "application/json"
  const body = `${payload}`
  return axios.post(`${allApi}auth/identity-providers2`, JSON.stringify(body))
};

const loginWithEmailAndPassword = axios.create({
  baseURL: userApi,
  headers: {
    'App-Version': `IBSS Hub/${process.env.REACT_APP_VERSION}`,
  }
});

const getIBSSAPIVersion = () => {
  return axios.get(`${allApi}Service/version`);
}

const getIBSSUserAPIVersion = () => {
  return axios.get(`${userApiBaseUrl}v1/Service/version`);
}

const getIBSSDataEntryAPIVersion = () => {
  return axios.get(`${dataEntryApiBaseUrl}v1/Service/version`);
}

const getIBSSDataAPIVersion = () => {
  return axios.get(`${dataApiBaseUrl}v1/Service/version`);
}


const getUserDetails = () => {

  return axios.get(userApi + "userdetails");
};
const getIdentityProviders = () => {

  return axios.get(dataEntrySecurityApi + "IdentityProviders");
};
const getIdentityProvidersDetailsById = (id) => {

  return axios.get(dataEntrySecurityApi + "IdentityProviders/" + id);
};
const getIdentityProvidersRoles = () => {


  return axios.get(dataEntrySecurityApi + "Roles");
};
const getRolesById = (id) => {

  return axios.get(dataEntrySecurityApi + "Roles/" + id);
};
const getIdentityProvidersRolesDetailsById = (id) => {

  return axios.get(dataEntrySecurityApi + "Roles/" + id);
};
const getRoleDataSecurityById = (id) => {

  return axios.get(dataEntrySecurityApi + "RoleDataSecurity/" + id);
};
const getUserRoleByRoleId = (id) => {

  return axios.get(dataEntrySecurityApi + "Roles/" + id + "/users");
};
const updateRoleDataSecurityById = (id, payload) => {

  return axios.put(dataEntrySecurityApi + "Roles/" + id, payload);
};
const getIdentityProvidersUsers = () => {

  return axios.get(dataEntrySecurityApi + "Users");
};
const getIdentityProvidersUsersById = (id) => {

  return axios.get(dataEntrySecurityApi + "Users/" + id);
};
const getIdentityProvidersUserRolesById = (id) => {

  return axios.get(dataEntrySecurityApi + "Users/" + id + "/roles");
};
const getIdentityProvidersUserAvailableRolesById = (id) => {

  return axios.get(dataEntrySecurityApi + "Users/" + id + "/available-roles");
};//
const assignRoleToUser = (payload) => {

  return axios.post(dataEntrySecurityApi + "UserRoles", payload);
};
const patchProvider  = (id, payload) => {

  return axios.patch(dataEntrySecurityApi + "IdentityProviders/" + id, payload);
};
const createProvider = (payload) => {

  return axios.post(dataEntrySecurityApi + "IdentityProviders", payload);
};
const createRole = (payload) => {

  return axios.post(dataEntrySecurityApi + "Roles", payload);
};
const updateRoleData = (id, payload) => {

  return axios.put(dataEntrySecurityApi + "RoleDataSecurity/" + id, payload);
}
const createUser = (payload) => {

  return axios.post(dataEntrySecurityApi + "Users", payload);
};

const updateUserDetails = (id, payload) => {

  return axios.put(dataEntrySecurityApi + "Users/" + id, payload);
}
const deleteById = (id) => {

  return axios.delete(dataEntrySecurityApi + "IdentityProviders/" + id);
};
const deleteRoleById = (id) => {

  return axios.delete(dataEntrySecurityApi + "Roles/" + id);
};
const deleteUserById = (id) => {

  return axios.delete(dataEntrySecurityApi + "Users/" + id);
};
const deleteAssignedRoleToUserById = (userId, roleId) => {

  return axios.delete(dataEntrySecurityApi + "UserRoles/" + userId + "/" + roleId);
};

const getSpacesByFilter = (filterValue) => {

  return axios.get(dataEntrySpacesApi + `?%24filter=type eq '${filterValue}'`)
}

const getSpacesByFilterEnabledBuilding = (filderType, node_IsEnabled) => {

  return axios.get(dataEntrySpacesApi + `?%24filter=type eq '${filderType}' and Node_IsEnabled eq ${node_IsEnabled}`)
}

const getSpacesById = (id, filter) => {

  if (filter === "Zone") {
    let type = sessionStorage.getItem("zoneType")
    return axios.get(dataEntrySpacesApi + `/${id}?%24filter=type%20eq%20%27Zone%27%20and%20spaceType%20eq%20%27` + type + `%27`)
  } else {
    return axios.get(dataEntrySpacesApi + `/${id}?%24filter=type eq '` + filter + `'`)
  }

}

const getSpacesByIdIsPartOf = (id, filter) => {

  if (filter === "Zone") {
    let type = sessionStorage.getItem("zoneType")
    return axios.get(dataEntrySpacesApi + `/${id}/isPartOf?%24filter=type%20eq%20%27Zone%27%20and%20spaceType%20eq%20%27` + type + `%27`)
  } else {
    return axios.get(dataEntrySpacesApi + `/${id}/isPartOf?%24filter=type eq '` + filter + `'`)
  }
}

const getSpacesByIdHasPart = (id, filter) => {

  if (filter === "Zone") {
    let type = sessionStorage.getItem("zoneType")
    return axios.get(dataEntrySpacesApi + `/${id}/hasPart?%24filter=type%20eq%20%27Zone%27%20and%20spaceType%20eq%20%27` + type + `%27`)
  } else {
    return axios.get(dataEntrySpacesApi + `/${id}/hasPart?%24filter=type eq '` + filter + `'`)
  }
}

const getSpacesByParameters = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/parameters`)
}

const deleteSpacesById = (id) => {

  return axios.delete(dataEntrySpacesApi + `/${id}`)
}


const updateSpacesById = (id, payload) => {

  return axios.put(dataEntrySpacesApi + `/${id}`, payload)
}

const getTaskCategories = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/taskcategories`)
}


const getTaskCategoriesById = (nodeid, id) => {

  return axios.get(allApi + `${nodeid}/TaskCategories/` + id)
}

const getTaskTypes = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/tasktypes`)
}

const getTaskTypesById = (nodeid, id, filter) => {

  let link = allApi + nodeid + "/" + filter + "/" + id;
  return axios.get(link)
}

const getSpaceStatesById = (nodeid, id, filter) => {

  return axios.get(allApi + `${nodeid}/` + filter + `/` + id + `/states`)
}

const updateSpaceStatesDetails = (nodeid, id, filter, payload) => {

  return axios.put(allApi + `${nodeid}/` + filter + `/` + id, payload);
}

const getDevices = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/devices`)
}

const getDevicesById = (id) => {

  return axios.get(allApi + `Devices/` + id)
}

const getEnvironmentalZones = (nodeid) => {

  return axios.get(allApi + `${nodeid}/EnvironmentalZones`)
}

const getSpaceStates = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/spacestates`)
}

const getTaskStates = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/taskstates`)
}

const getEnvironmentalZonesById = (nodeid, id) => {

  return axios.get(allApi + `${nodeid}/EnvironmentalZones/` + id)
}

const createSpace = (payload) => {

  return axios.post(dataEntrySpacesApi + '/', payload);
};

const createTaskStates = (payload, nodeid) => {

  return axios.post(allApi + nodeid + `/TaskStates`, payload);
};

const createSpaceStates = (payload, nodeid) => {

  return axios.post(allApi + nodeid + `/SpaceStates`, payload);
};

const getibssspacetypesList = () => {

  return axios.get(dataEntrySpacesApi + `/ibssspacetypes`)
}

const updateTaskCategoriesSpacesById = (nodeid, id, payload) => {

  return axios.put(allApi + `${nodeid}/TaskCategories/` + id, payload);
}

const createTaskCategories = (nodeid, payload) => {

  return axios.post(allApi + nodeid + `/TaskCategories`, payload);
};

const createDevice = (payload, nodeid) => {

  return axios.post(allApi + 'Devices?nodeid=' + nodeid, payload);
};

const getDeviceById = (id) => {

  return axios.get(allApi + 'Devices/' + id)
}

const updateDeviceById = (id, payload) => {

  return axios.put(allApi + 'Devices/' + id, payload);
}

const deleteDeviceById = (id) => {

  return axios.delete(allApi + `Devices/${id}`)
}

const createParameter = (nodeid, payload) => {

  return axios.post(allApi + nodeid + `/Parameters`, payload);
};


const updateParameterById = (nodeid, id, payload) => {

  return axios.put(allApi + nodeid + '/Parameters/' + id, payload);
}

const getParameterById = (nodeid, id) => {

  return axios.get(allApi + nodeid + '/Parameters/' + id)
}

const deleteParameterById = (nodeid, id) => {

  return axios.delete(allApi + nodeid + '/Parameters/' + id)
}

const getKPIs = (nodeid) => {

  return axios.get(allApi + nodeid + '/BuildingKPIs')
}

const getBuildingKPIById = (nodeid, id) => {

  return axios.get(allApi + nodeid + '/BuildingKPIs/' + id)
}

const createBuildingKPI = (nodeid, payload) => {

  return axios.post(allApi + nodeid + `/BuildingKPIs`, payload);
};

const updateBuildingKPIById = (nodeid, id, payload) => {

  return axios.put(allApi + nodeid + '/BuildingKPIs/' + id, payload);
}

const deleteBuildingKPIById = (nodeid, id) => {

  return axios.delete(allApi + nodeid + '/BuildingKPIs/' + id)
}

const getSignages = (nodeid) => {

  return axios.get(allApi + nodeid + '/Signage')
}
const getSignagesById = (nodeid, id) => {

  return axios.get(allApi + nodeid + '/Signage/' + id)
}

const createSignage = (nodeid, payload) => {

  return axios.post(allApi + nodeid + `/Signage`, payload);
};

const updateSignageById = (nodeid, id, payload) => {

  return axios.put(allApi + nodeid + '/Signage/' + id, payload);
}

const deleteSignageById = (nodeid, id) => {

  return axios.delete(allApi + nodeid + '/Signage/' + id)
}

const resetPasswordUser = (id) => {

  return axios.put(`${dataEntrySecurityApi}Users/${id}/resetpassword`)
}

const resetNativePassword = (payload) => {

  return axios.post(userApi + `resetpassword`, payload);
}

const getTabList = () => {

  return axios.get(userApi + 'userapplicationactions2')
}

const getSpacetypeList = () => {

  return axios.get(dataEntrySpacesApi + '/ibssspacetypes')
}

const getSpaceWorkTypeList = async () => {


  const response = await axios.get(dataEntrySpacesApi + '/ibssspaceworktypes')
  return response
}

const getSpaceClassTypeList = () => {

  return axios.get(dataEntrySpacesApi + '/ibssspaceclasses')
}

const getVisitorsInactive = (nodeid) => {

  return axios.get(allApi + nodeid + '/Visits/inactive')
}

const getVisitorsOnSite = (nodeid) => {

  return axios.get(allApi + nodeid + '/Visits/checked-in')
}

const getsyncaad = () => {

  return axios.get(userApi + "syncaad")
}

const createTaskTypes = (payload, nodeid) => {

  return axios.post(allApi + nodeid + `/TaskTypes`, payload);
};

const updateTaskTypes = (nodeid, id, payload) => {

  return axios.put(allApi + `${nodeid}/TaskTypes/` + id, payload);
}

const getAwaitingApprovalVisitors = (buildingId) => {

  // return axios.get(allApi + `${buildingId}/Visits/awaiting-approval`);
  return axios.get(allApi + `${buildingId}/Visits?%24top=500&$filter=(Visit_IsApproved eq 0) and (Visit_IsCancelled eq 0) and  (Visit_IsCheckedIn eq 0) and ( Visit_IsDenied eq 0) and (Visit_IsNoShow eq 0)`);
}

const getTodaysVisitors = (buildingId, startDate, endDate, select) => {
  axios.defaults.headers.common = {
    Description: 'todays visitors',
  };

  const query =
    `$top=201&` +
    `$filter=Visit_Start_Date ge datetime'${startDate}' and Visit_End_Date lt datetime'${endDate}'&` +
    `$select=${select == null ? "" : ApiHelper.toODataSelect(select)}&` +
    `showDebugStats=false&` +
    `schema=false`

  let visit = allApi + `${buildingId}/Visits?${query}`;
  return axios.get(visit);
}

const getFilteredVisitors = async (model) => {
  axios.defaults.headers.common = {
    Description: 'filtered visitors',
  };
  const { buildingId, startDate, endDate, statusValue, host } = model

  let filterStatusValue = "";
  let filterHostValue = "";
  if (statusValue) {
    filterStatusValue = `and Visit_Status eq '${statusValue}'`
  }
  if (host) {
    filterHostValue = `and Visit_Host_Email eq '${host}'`
  }

  const response = await axios.get(
    `${allApi}${buildingId}/Visits?%24top=500&$filter=Visit_Start_Date ge datetime'${startDate}' and Visit_End_Date lt datetime'${endDate}' ${filterStatusValue} ${filterHostValue} &showDebugStats=false&schema=false`
  );

  return response
}

const getNextWorkingDayVisitors = (buildingId) => {


  const Occ_Wkng_Days_Stt = localStorage.getItem("Occ_Wkng_Days_Stt")
  const Occ_Wkng_Days_Stp = localStorage.getItem("Occ_Wkng_Days_Stp")

  const workingDayArray = getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))

  momentBusiness.updateLocale('us', {
    workingWeekdays: workingDayArray
  });

  const todayDate = DateHelper.fromIsoToDefaultFormat(DateHelper.now().toISO(), 'yyyy-MM-dd')
  // const todayDate = moment().format('YYYY-MM-DD')
  const nextWorkingDay = momentBusiness(todayDate, 'YYYY-MM-DD').nextBusinessDay().toDate().toISOString()

  const startDate = nextWorkingDay
  const endDate = DateTime.fromISO(nextWorkingDay).plus({ days: 1 }).toUTC().toISO()
  // const endDate = moment(nextWorkingDay).endOf('day').toISOString()

  return axios.get(`${allApi}/${buildingId}/Visits?%24top=500&%24filter=Visit_Start_Date ge datetime'${startDate}' and Visit_End_Date lt datetime'${endDate}' &showDebugStats=false&schema=false`);
}

const getCheckedInVisitors = (buildingId) => {

  return axios.get(allApi + `${buildingId}/Visits?%24top=500&$filter=(Visit_IsApproved eq 1) and (Visit_IsCheckedIn eq 1) and  (Visit_IsCheckedOut eq 0)`);
}

const getFutureVisitors = (buildingId) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  let today = DateHelper.today().toUTC().toISO()
  // let today = moment.utc(moment().startOf('day')).toISOString();
  return axios.get(allApi + `${buildingId}/Visits?%24top=500&%24filter=Visit_Start_Date%20gt%20datetime'${today}' &showDebugStats=false&schema=false`);
}

const downloadExcelFilterVisitors = async (model) =>
{
    const { buildingId, startDate, endDate, statusValue, host, type, email } = model;
    const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
    axios.defaults.headers.common = { Authorization: `bearer ${sessionStorage.getItem("token")}` };

    let filterValue = "";
    if (statusValue)
    {
        filterValue += `and Visit_Status eq '${statusValue}'`;
    }
    if (host)
    {
        filterValue += `and Visit_Host_Email eq '${host}'`;
    }

    if (type === "flex")
    {
        const response = await axios.get(
            `${reportingApiV1}${buildingId}/Visits/download?$filter=Visit_Host_Email eq '${email}' and Visit_Start_Date ge datetime'${startDate}' and Visit_End_Date lt datetime'${endDate}' &showDebugStats=false&schema=false`,
            { responseType: 'blob' }
        );

        if (response.status === 200) 
        {
            fileDownload(response.data, outputFilename);
            return { status: 'success' }
        }
        else 
        {
            return { status: 'failed' }
        }
    }
    else 
    {
        const response = await axios.get(
            `${reportingApiV1}${buildingId}/Visits/download?$filter=Visit_Start_Date ge datetime'${startDate}' and Visit_End_Date lt datetime'${endDate}' ${filterValue} &showDebugStats=false&schema=false`,
            { responseType: 'blob', }
        );

        if (response.status === 200) 
        {
            fileDownload(response.data, outputFilename);
            return { status: 'success' };
        }
        else 
        {
            return { status: 'failed' };
        }
    }
}

const getFacilityNotification = async (nodeId) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  const startDateTime = DateHelper.now();
  const startDate = DateTime.fromISO(startDateTime).date().toUTC().toISO()
  const endDate = DateTime.now().plus({ days: 7 }).endOf('day').toUTC().toISO();
  return axios.get(`${allApi}${nodeId}/Notifications?%24top=1000&startDate=${startDate}&endDate=${endDate}&schema=false&showDebugStats=false`);
}

const createFacilityNotification = async (nodeId, payload) => {


  return axios.post(`${allApi}${nodeId}/Notifications`, payload);
}

const updateFacilityNotification = async (nodeId, Notification_Id, payload) => {


  return axios.put(`${allApi}${nodeId}/Notifications/${Notification_Id}`, payload);
}

const getSpecificFacilityNotification = async (nodeId, Notification_Id) => {


  return axios.get(`${allApi}${nodeId}/Notifications/${Notification_Id}`);
}

const getWorkSpaceType = async (nodeId, Space_Type_Label, Space, classType, periodType, floorNodeId, Period_Start_Date, periodEndDate) => {

  const buildingTz = getBuildingTimeZoneByNodeId(nodeId);
  const buildingStartTime = DateTime.fromISO(Period_Start_Date).startOfLocalDayAsUtc(buildingTz).toISO();
  const buildingEndTime = DateTime.fromISO(periodEndDate).endOfLocalDayAsUtc(buildingTz).toISO();
  let queryString = "";
  if (floorNodeId === "Any" || floorNodeId === "") {
    queryString = `${Space_Type_Label}&$filter=Space_Class eq '${classType}' and Space_Type_Label ne 'Total' and Period_Type eq ${periodType} and Period_Start_Date ge datetime'${buildingStartTime}' and Period_End_Date le datetime'${buildingEndTime}' and Node_Level_Name eq '${Space}'`
  } else {
    queryString = `${Space_Type_Label}&$filter=Space_Class eq '${classType}' and Space_Type_Label ne 'Total' and Period_Type eq ${periodType} and Period_Start_Date ge datetime'${buildingStartTime}' and Period_End_Date le datetime'${buildingEndTime}' and Node_Level_Name eq '${Space}' and Node_Id eq ${floorNodeId}`;
  }
  const response = await axios.get(`${allApi}${typeof nodeId === 'string' ? +nodeId : nodeId}/SpaceUtilisation/SpaceUtilisationData?recursive=true&$select=${queryString}`);
  return response;
}

const getOverviewWorkSpaceClassification = async (nodeId, spaceTypeLabel, periodType, periodStartDate, periodEndDate, nodeLevelName) => {
  const buildingTz = getBuildingTimeZoneByNodeId(nodeId);
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  let buildingStartTime = "";
  let buildingEndTime = "";

  // if building option type is all logic starts
  if (nodeId === 1) {
    buildingStartTime = DateTime.fromISO(periodStartDate).date().minus({ days: 1 }).toISO().slice(0, 16);
    buildingStartTime = `${buildingStartTime}:01Z`;
    buildingEndTime = DateTime.fromISO(periodEndDate).plus({ days: 1 }).toISO().slice(0, 16);
    buildingEndTime = `${buildingEndTime}:59Z`;
  } else {
    buildingStartTime = DateTime.fromISO(periodStartDate).startOfLocalDayAsUtc(buildingTz).toISO();
    buildingEndTime = DateTime.fromISO(periodEndDate).endOfLocalDayAsUtc(buildingTz).toISO();
  }
  // if building option type is all logic ends

  let queryString = "";
  queryString = `Space_Id,Space_Type,Space_Class,Node_Id,Node_Level_Name,Space_Name,Space_Type_Label,Period_Current_Space_Value,Period_Change_Space_Value,Period_Current_Capacity_Value,Period_Change_Capacity_Value,Period_Current_Max_Occ_Value,Period_Change_Max_Occ_Value,Period_Current_Peak_Value&$filter=Space_Type_Label eq '${spaceTypeLabel}' and Period_Type eq ${periodType} and Period_Start_Date ge datetime'${periodStartDate.toUTC().toISO()}' and Period_Start_Date lt datetime'${periodEndDate.toUTC().toISO()}' and Node_Level_Name eq '${nodeLevelName}'`;
  const response = await axios.get(`${allApi}${typeof nodeId === "string" ? +nodeId : nodeId}/SpaceUtilisation/SpaceUtilisationData?recursive=true&$select=${queryString}`);
  return response.data;
}

const getSpaceAnalyticsHeader = async (nodeId, spaceClass, spaceTypeLabel, periodType, Period_Start_Date, periodEndDate, nodeLevelName, floorNodeId, utcTimeDiff) => {
  const buildingTz = getBuildingTimeZoneByNodeId(nodeId);

  let buildingStartTime = "";
  let buildingEndTime = "";

  // if building option type is all logic starts
  if (nodeId === 1) {
    buildingStartTime = DateTime.fromISO(Period_Start_Date).date().minus({ days: 1 }).toISO().slice(0, 16);
    buildingStartTime = `${buildingStartTime}:01Z`;
    buildingEndTime = DateTime.fromISO(periodEndDate).plus({ days: 1 }).toISO().slice(0, 16);
    buildingEndTime = `${buildingEndTime}:59Z`;
  } else {
    buildingStartTime = DateTime.fromISO(Period_Start_Date).startOfLocalDayAsUtc(buildingTz).toISO();
    buildingEndTime = DateTime.fromISO(periodEndDate).endOfLocalDayAsUtc(buildingTz).toISO();
  }
  // if building option type is all logic ends

  let queryString = "";
  if (floorNodeId === "Any" || floorNodeId === "") {
    queryString = `Space_Id,Space_Type,Space_Class,Node_Id,Node_Level_Name,Space_Name,Space_Type_Label,Period_Current_Space_Value,Period_Change_Space_Value,Period_Current_Capacity_Value,Period_Change_Capacity_Value,Period_Current_Max_Occ_Value,Period_Change_Max_Occ_Value&$filter=Space_Class eq '${spaceClass}' and Space_Type_Label eq '${spaceTypeLabel === "Any" ? "Total" : spaceTypeLabel
      }' and Period_Type eq ${periodType} and Period_Start_Date ge datetime'${Period_Start_Date.toUTC().toISO()}' and Period_Start_Date lt datetime'${periodEndDate.toUTC().toISO()}' and Node_Level_Name eq 'Building'&top=1`;
  } else {
    queryString = `Space_Id,Space_Type,Space_Class,Node_Id,Node_Level_Name,Space_Name,Space_Type_Label,Period_Current_Space_Value,Period_Change_Space_Value,Period_Current_Capacity_Value,Period_Change_Capacity_Value,Period_Current_Max_Occ_Value,Period_Change_Max_Occ_Value&$filter=Space_Class eq '${spaceClass}' and Space_Type_Label eq '${spaceTypeLabel === "Any" ? "Total" : spaceTypeLabel
      }' and Period_Type eq ${periodType} and Period_Start_Date ge datetime'${Period_Start_Date.toUTC().toISO()}' and Period_Start_Date lt datetime'${periodEndDate.toUTC().toISO()}' and Node_Level_Name eq 'Floor' and Node_Id eq ${floorNodeId}&top=1`;
  }
  const response = await axios.get(`${allApi}${typeof nodeId === "string" ? +nodeId : nodeId}/SpaceUtilisation/SpaceUtilisationData?recursive=true&$select=${queryString}`);
  return response;
};

const deleteSpecificFacilityNotification = async (nodeId, Notification_Id) => {


  return axios.delete(`${allApi}${nodeId}/Notifications/${Notification_Id}`);
}

const getWorkingStartEndDateForSpecificBuilding = async(nodeId) => 
{
  const getBuildingWorkingDays = async() =>
  {
    // to get both Occ_Wkng_Days_Stt & Occ_Wkng_Days_Stp from the Parameters endpoint, had to remove $top=1 from query.
    const response = await axios.get(`${allApi}${nodeId}/Parameters?schema=false&recursive=true&%24select=Parameter_Name%2CParameter_Value&%24filter=(Parameter_Name eq 'Occ_Wkng_Days_Stp') OR (Parameter_Name eq 'Occ_Wkng_Days_Stt')`);
    return response.data;
  }

  const response = await getBuildingWorkingDays();
  const weekStartDay = response.find(i => i.Parameter_Name === 'Occ_Wkng_Days_Stt');
  const weekStopDay = response.find(i => i.Parameter_Name === 'Occ_Wkng_Days_Stp');

  if(weekStartDay)
  {
    localStorage.setItem("Occ_Wkng_Days_Stt", weekStartDay.Parameter_Value);
  }
  if(weekStopDay)
  {
    localStorage.setItem("Occ_Wkng_Days_Stp", weekStopDay.Parameter_Value);
  }
}

const getOccupiedSpace = async (nodeId) => {


  const response = await axios.get(
    `${allApi}${nodeId}/Spaces?$select=Space_Class,Space_Type,Meta_Occ_Type,Space_Name,Space_Id,Space_Status_Text,Space_Ext_Occ_Count&$filter= Space_IsEnabled eq 1 and Space_IsAvailable eq '0'`);

  return response
}

const getFreeSpace = async (nodeId) => {


  const response = await axios.get(
    `${allApi}${nodeId}/Spaces?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text, Meta_Occ_Type,Space_Ext_Occ_Count &$filter= Space_IsEnabled eq 1 and Space_IsAvailable eq '1'`);

  return response
}

const getFilteredSpaceData = async (model) => {
  const { nodeId, Space_IsEnabled, Space_IsAvailable, SpaceListQuery } = model;



  let filterObj = {
    Space_IsEnabled: Space_IsEnabled,
    Space_IsAvailable: Space_IsAvailable

  }

  let filterValue = ""
  Object.entries(filterObj).map(([objKey, objeValue]) => {
    if (objeValue) {
      if (!filterValue) {
        filterValue = "&$filter="
      } else {
        filterValue = `${filterValue} and`
      }
      if (objKey === "Space_IsEnabled") {
        filterValue = `${filterValue} ${objKey} eq ${objeValue}`
      } else {
        filterValue = `${filterValue} ${objKey} eq '${objeValue}'`
      }
    }
  })

  if (SpaceListQuery) {
    if (filterValue === "") {
      filterValue = `&$filter= ${SpaceListQuery}`
    } else {
      filterValue = `${filterValue} and (${SpaceListQuery})`
    }
  }

  const response = await axios.get(
    `${allApi}${nodeId}/Spaces?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text,Meta_Occ_Type,Space_Ext_Occ_Count${filterValue}`);

  return response
}

const getStatusValuesForSpaces = async (nodeId) => {

  const response = await axios.get(
    `${allApi}/${nodeId}/SpaceStates`);
  return response

}


const getDirectedCleaningSpace = async (nodeId) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  const startDateTime = DateHelper.today().toUTC().toISO()

  const response = await axios.get(
    `${allApi}${nodeId}/Spaces?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text,Meta_Occ_Type,Space_Ext_Occ_Count
    &$filter= Space_Last_Occupied gt datetime'${startDateTime}'`);
  return response
}

const getFilteredSpace = async (nodeId) => {


  const response = await axios.get(
    `${allApi}${nodeId}/Spaces?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text,Meta_Occ_Type,Space_Ext_Occ_Count
    &$filter = Space_IsEnabled eq 1`);
  return response
}




const getSpecificSpaceCateringMenu = async (nodeId, spacecateringId) => {

  return axios.get(`${allApi}${nodeId}/SpaceCateringMenu/${spacecateringId}`);
}

const getRoamerPageData = async (path) => {

  return axios.get(`${dataEntryWebApi}/file/${path}`);
}

const deleteFilePath = async (path) => {

  return axios.delete(`${dataEntryWebApi}/file/${path}`);
}

const downloadImageFile = async (path) => {
  let fileName = path.split("/").pop();

  const response = await axios.get(`${dataEntryWebApi}/file/Download/${path}`, { responseType: "blob" });
  let blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
  FileSaver.saveAs(blob, fileName);
}

const getFile = async (path) => {
  let response = await axios.get(`${dataEntryWebApi}/file/Download/${path}`, { responseType: "blob" });
  let blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
  return blob;
}

const uploadFile = async (model) => {
  const { fileData, modalPathValue, processAsMapvalue } = model


  return axios.post(`${dataEntryWebApi}/File/${modalPathValue}?ProcessAsMapFile=${processAsMapvalue}`, fileData);
}

const getNotificationImageURIData = async (nodeId, notificationId) => {


  const path = "c/Notifications"
  return axios.get(`${dataEntryWebApi}file/${path}`);
}


const getSpecificNotificationData = async (nodeId, notificationId) => {
  const id = notificationId.replaceAll("'", "")
  return axios.get(`${allApi}${nodeId}/Notifications/${id}`);
}

const deleteSpecificSpaceCateringMenu = async (nodeId, spacecateringId) => {

  return axios.delete(`${allApi}${nodeId}/SpaceCateringMenu/${spacecateringId}`);
}

const deleteTaskTypesById = (nodeid, id) => {

  return axios.delete(allApi + nodeid + '/TaskTypes/' + id)
}

const deleteTaskStatesById = (nodeid, id) => {

  return axios.delete(allApi + nodeid + '/TaskStates/' + id)
}

const getAssigned = (buildingId) => {

  return axios.get(allApi + `${buildingId}/Tasks?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner%2CTask_Status&%24filter=Task_IsActive%20eq%201%20and%20Task_IsOwned%20eq%201`);
}

const getUnassigned = (buildingId) => {

  return axios.get(allApi + `${buildingId}/Tasks?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner%2CTask_Status&%24filter=Task_IsActive%20eq%201%20and%20Task_IsOwned%20eq%200`);
}

const downloadExcelOccupiedSpace = async (buildingId) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };

  const response = await axios.get(
    `${reportingApiV1}${buildingId}/Spaces/download?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text&$filter= Space_IsEnabled eq 1 and Space_IsAvailable eq 0`, { responseType: 'blob', });

  fileDownload(response.data, outputFilename)
  return;

}

const downloadExcelFreeSpace = async (buildingId) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  // const outputFilename = `${(new Date()).toISOString()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };

  const response = await axios.get(
    `${reportingApiV1}${buildingId}/Spaces/download?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text&$filter= Space_IsEnabled eq 1 and Space_IsAvailable eq 1`, { responseType: 'blob' });

  fileDownload(response.data, outputFilename)
  return;

}

const downloadExcelDirectedCleaningSpace = async (buildingId) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };

  const startDateTime = DateHelper.today().toUTC().toISO()

  const response = await axios.get(
    `${reportingApiV1}${buildingId}/Spaces/download?$select=Space_Class,Space_Type,Space_Name,Space_Id,Space_Status_Text
    &$filter= Space_Last_Occupied gt datetime'${startDateTime}'`, { responseType: 'blob' });

  fileDownload(response.data, outputFilename)
  return;

}

const getResolved = (buildingId) => {

  // let today = new Date();
  // let endDate= moment(today).format("yyyy-MM-DD");
  // let startdate = moment(today.setDate(today.getDate()-7)).format("yyyy-MM-DD");
  // return axios.get(allApi + `${buildingId}/Tasks/history?startDate=${startdate}&endDate=${endDate}`);
  const startDate = moment().subtract(7, 'days').startOf('day').toDate().toISOString();
  return axios.get(allApi + `${buildingId}/Tasks?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CCreatedAt%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner&%24filter=Task_IsResolved%20eq%201%20and%20Task_Event_Time ge%20datetime%27${startDate}%27`);
}


const getCustomFilter = async (model) =>
{
  const { buildingId, startDate, endDate, statusValue } = model
  return axios.get(allApi + `${buildingId}/Tasks?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CCreatedAt%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner%2CTask_Status%2CTask_IsActive%2CTask_IsOwned&$filter=${statusValue ? `Task_Status eq %27${statusValue}%27 and` : ""} CreatedAt%20ge%20datetime%27${startDate}%27 and CreatedAt%20le%20datetime%27${endDate}%27`);
}

const createVisit = (buildingid, payload) => {

  return axios.post(allApi + buildingid + `/Visits`, payload);
};

const getDetailsByVisitId = (nodeId, visitId) => {

  return axios.get(allApi + nodeId + "/Visits/" + visitId);
}

const updateVisitorByVisitId = (nodeId, visitId, payload) => {

  return axios.put(allApi + nodeId + "/Visits/" + visitId, payload);
}

const updateApproveVisitByVisitId = (nodeId, visitId) => {
  let payload = {
    "Visit_IsApproved": 1
  }


  let version = appContext().localStorageProvider.getVersion();
  if (version > 11)
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/action", payload);
  else
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/approve",);
}

const updateCancelledVisitByVisitId = (nodeId, visitId) => {
  let payload = {
    "Visit_IsCancelled": 1,
  }


  let version = appContext().localStorageProvider.getVersion();
  if (version > 11)
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/action", payload);
  else
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/cancel",);
}

const updateCheckedInVisitByVisitId = (nodeId, visitId) => {
  let payload = {
    "Visit_IsCheckedIn": 1
  }


  let version = appContext().localStorageProvider.getVersion();
  if (version > 11)
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/action", payload);
  else
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/checkin",);
}

const updateCheckedOutVisitByVisitId = (nodeId, visitId) => {
  let payload = {
    "Visit_IsCheckedOut": 1
  }


  let version = appContext().localStorageProvider.getVersion();
  if (version > 11)
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/action", payload);
  else
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/checkout",);
}

const updateDenyVisitByVisitId = (nodeId, visitId, denyComment) => {
  let payload = {
    "Visit_IsDenied": 1,
    "Visit_Approval_Comments": denyComment
  }

  let version = appContext().localStorageProvider.getVersion();
  if (version > 11)
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/action", payload);
  else
    return axios.put(allApi + nodeId + "/Visits/" + visitId + "/deny", JSON.stringify(denyComment), { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
}

const getTaskCategoriesByNodeid = (nodeId) => {

  return axios.get(dataEntrySpacesApi + "/" + nodeId + "/taskcategories?recursive=true");
}

const getNext7DaysBookingsFromSelectedDate = async (buildingid, startDateTime) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  if (!startDateTime) {
    startDateTime = DateHelper.now();
  } else {
    startDateTime = DateTime.fromISO(startDateTime.toISOString());
  }

  let startDate = DateTime.fromISO(startDateTime).date().toUTC().toISO();
  let endDate = DateTime.now().plus({ days: 7 }).endOf('day').toUTC().toISO();

  const bookingApiFunc = async (buildingid, startDate, endDate) => {
    return axios.get(allApi + buildingid + "/Bookings?&%24top=5000&%24select=Booking_IsActive%2C_CreatedBy%2C_CreatedAt%2C%20booking_Id%2Cbooking_Owner_Name%2Cbooking_Owner_Email%2Cbooking_Start%2Cbooking_End%2Cbooking_Name%2Cspace_Id%2Cbooking_Status&bookingStart=" + startDate + "&bookingEnd=" + endDate);
  }

  const apiArrayFunc = [bookingApiFunc]
  const apiResponse = await Promise.all(apiArrayFunc.map(async (itemFunc) => {
    const response = await itemFunc(buildingid, startDate, endDate)
    return response
  }))

  const [bookingApiRes] = apiResponse

  const spaceApiRes = await appContext().apiCache.getSpacesByBuilding(parseInt(buildingid));

  const bookingApiResData = bookingApiRes.data.map((item) => {
    let newItem = { ...item }

    const spaceIdArray = item.Space_Id.split(";")

    const spaceNameArrary = spaceIdArray.map((spaceId) => {
      const spaceObj = spaceApiRes.find(obj => {
        return obj.Space_Id === spaceId;
      })

      if (spaceObj) {
        return spaceObj.Space_Name
      } else {
        return ""
      }
    })

    newItem.Space_Name = spaceNameArrary.join(";")
    return newItem
  })

  const newResponse = {
    data: bookingApiResData
  }

  return newResponse
}

const getTodayBookings = async (buildingid,startDate,endDate) => {
  axios.defaults.headers.common = {
    Description: 'today bookings',
  };
  const bookingApiFunc = async () => {
    let query = `&%24top=5000&%24select=Node_Id,Booking_IsActive,_CreatedBy,_CreatedAt,Booking_Id,Booking_Owner_Name,Booking_Owner_Email,Booking_Start,Booking_End,Booking_Name,Space_Id,Booking_Status,Booking_IsApproved&%24filter=Booking_Start ge datetime'${startDate}' and Booking_End lt datetime'${endDate}'`
    return axios.get(allApi + buildingid + `/Bookings?${query}`);
  }

  const apiArrayFunc = [bookingApiFunc]
  const apiResponse = await Promise.all(apiArrayFunc.map(async (itemFunc) => {
    const response = await itemFunc(buildingid, startDate, endDate)
    return response
  }))

  const [bookingApiRes] = apiResponse;

  const spaceApiRes = await appContext().apiCache.getSpacesByBuilding(parseInt(buildingid));

  const bookingApiResData = bookingApiRes.data.map((item) => {
    let newItem = { ...item }

    const spaceIdArray = item.Space_Id.split(";")

    const spaceNameArrary = spaceIdArray.map((spaceId) => {
      const spaceObj = spaceApiRes.find(obj => {
        return obj.Space_Id === spaceId;
      })
      if (spaceObj) {
        return spaceObj.Space_Name
      } else {
        return ""
      }
    })

    newItem.Space_Name = spaceNameArrary.join(";")
    return newItem
  })

  const newResponse = {
    data: bookingApiResData
  }

  return newResponse
}


const getNextBookings = async (buildingid, id) => {


  const Occ_Wkng_Days_Stt = localStorage.getItem("Occ_Wkng_Days_Stt")
  const Occ_Wkng_Days_Stp = localStorage.getItem("Occ_Wkng_Days_Stp")

  const workingDayArray = getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))

  momentBusiness.updateLocale('us', {
    workingWeekdays: workingDayArray
  });

  const todayDate = DateHelper.fromIsoToDefaultFormat(DateHelper.now().toISO(), 'yyyy-MM-dd');
  const nextWorkingDay = momentBusiness(todayDate, 'YYYY-MM-DD').nextBusinessDay().toDate().toISOString()


  const startDate = nextWorkingDay
  const endDate = DateTime.fromISO(nextWorkingDay).plus({ days: 1 }).toUTC().toISO();


  const bookingApiFunc = async (buildingid, startDate, endDate) => {
    return axios.get(`${allApi}${buildingid}/Bookings?&%24top=5000&%24select=Booking_IsActive%2C_CreatedBy%2C_CreatedAt%2C%20booking_Id%2Cbooking_Owner_Name%2Cbooking_Owner_Email%2Cbooking_Start%2Cbooking_End%2Cbooking_Name%2Cspace_Id%2Cbooking_Status&bookingStart=${startDate}&bookingEnd=${endDate}`);
  }

  const apiArrayFunc = [bookingApiFunc]
  const apiResponse = await Promise.all(apiArrayFunc.map(async (itemFunc) => {
    const response = await itemFunc(buildingid, startDate, endDate)
    return response
  }))

  const [bookingApiRes] = apiResponse

  const spaceApiRes = await appContext().apiCache.getSpacesByBuilding(parseInt(buildingid));

  const bookingApiResData = bookingApiRes.data.map((item) => {
    let newItem = { ...item }

    const spaceIdArray = item.Space_Id.split(";")

    const spaceNameArrary = spaceIdArray.map((spaceId) => {
      const spaceObj = spaceApiRes.find(obj => {
        return obj.Space_Id === spaceId;
      })
      if (spaceObj) {
        return spaceObj.Space_Name
      } else {
        return ""
      }
    })

    newItem.Space_Name = spaceNameArrary.join(";")
    return newItem
  })

  const newResponse = {
    data: bookingApiResData
  }

  return newResponse
}


const getYesterdayBookings = async (buildingid, id) => {


  const Occ_Wkng_Days_Stt = localStorage.getItem("Occ_Wkng_Days_Stt")
  const Occ_Wkng_Days_Stp = localStorage.getItem("Occ_Wkng_Days_Stp")

  const workingDayArray = getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))
  momentBusiness.updateLocale('us', {
    workingWeekdays: workingDayArray
  });

  const todayDate = DateHelper.fromIsoToDefaultFormat(DateHelper.now().toISO(), 'yyyy-MM-dd');
  const nextWorkingDay = momentBusiness(todayDate, 'YYYY-MM-DD').prevBusinessDay().toDate().toISOString()

  const startDate = nextWorkingDay
  const endDate = DateTime.fromISO(nextWorkingDay).plus({ days: 1 }).toUTC().toISO();
  const bookingApiFunc = async (buildingid, startDate, endDate) => {
    return axios.get(allApi + buildingid + "/Bookings?&%24top=5000&%24select=Booking_IsActive%2C_CreatedBy%2C_CreatedAt%2C%20booking_Id%2Cbooking_Owner_Name%2Cbooking_Owner_Email%2Cbooking_Start%2Cbooking_End%2Cbooking_Name%2Cspace_Id%2Cbooking_Status&bookingStart=" + startDate + "&bookingEnd=" + endDate);
  }

  const apiArrayFunc = [bookingApiFunc]
  const apiResponse = await Promise.all(apiArrayFunc.map(async (itemFunc) => {
    const response = await itemFunc(buildingid, startDate, endDate)
    return response
  }))

  const [bookingApiRes] = apiResponse

  const spaceApiRes = await appContext().apiCache.getSpacesByBuilding(parseInt(buildingid));

  const bookingApiResData = bookingApiRes.data.map((item) => {
    let newItem = { ...item }

    const spaceIdArray = item.Space_Id.split(";")

    const spaceNameArrary = spaceIdArray.map((spaceId) => {
      const spaceObj = spaceApiRes.find(obj => {
        return obj.Space_Id === spaceId;
      })
      if (spaceObj) {
        return spaceObj.Space_Name
      } else {
        return ""
      }
    })

    newItem.Space_Name = spaceNameArrary.join(";")
    return newItem
  })

  const newResponse = {
    data: bookingApiResData
  }

  return newResponse
}

const getTaskTypesfacility = (id) => {

  return axios.get(dataEntrySpacesApi + `/${id}/tasktypes?recursive=true`)
}

const getBefore10OverviewBookings = (nodeId) => {


  let today = new Date();
  today = moment(today.setDate(today.getDate() - 10)).format("yyyy-MM-DD");
  let enddate = new Date();
  enddate = moment(enddate.setDate(enddate.getDate() - 1)).format("yyyy-MM-DD");
  return axios.get(allApi + nodeId + "/BookingsDailySummary/" + today + "/" + enddate + "/BookingsSummaryData?recursive=false&schema=false")
}

const getAvailableRights = () => {

  return axios.get(dataEntrySecurityApi + "/Roles/AvailableRights");

}

const getTaskTypesByCategoryIdfacility = (nodeId, categoryId) => {

  return axios.get(`${allApi}/${nodeId}/TaskTypes?$filter=Task_Category_Id eq ${categoryId} and Task_Type_IsEnabled eq 1`);

}

const getSpaceByFloorId = (nodeId,floorId) => {
  return axios.get(`${allApi}/${nodeId}/Spaces/?schema=false&showDebugStats=false&%24top=2000&%24select=Space_Id%2CNode_Id%2CSpace_Type&%24filter=Node_Id%20eq%20 + ${floorId}`)
}


const getRightList = () => {

  return axios.get(userApi + 'userapplicationactions2?application=ADMINPORTAL')
}

const getRightsList = () => {

  return axios.get(userApi + 'userapplicationactions2')
}

const getTabListAAD = (token) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ` + token,
  };
  return axios.get(userApi + 'userapplicationactions2')
}

const getOverviewTasks = (nodeId) => {
  axios.defaults.headers.common = {
    Description: 'overviewTasks',
  };

  let startDate = DateHelper.now().minus({ days: 10 }).toUTC().toISO();
  let endDate = DateTime.fromISO(DateHelper.now().toString()).plus({ day: 4 }).startOf('day').toUTC().toISO();
  let query = `schema=false&showDebugStats=false&%24top=2000&%24select=Task_Due_Date%2CTask_IsActive%2C Task_IsCancelled%2CTask_IsResolved%2CTask_IsInProgress%2CTask_IsOwned%2CTask_Pass%2CTask_Fail&%24filter=Task_Due_Date ge datetime'${startDate}' and Task_Due_Date lt datetime'${endDate}'`
  return axios.get(allApi + nodeId + `/Tasks?${query}`)
}

const getZonesData = async (floorNodeId, singleZone) => {


  let response = ""

  if (singleZone) {
    const zoneRes = await axios.get(allApi + floorNodeId + `/SpaceZones?%24select=%20Meta_Loc_Zone%2CNode_Id%2CNode_Name%2CSpace_Zone_Id&filter=Meta_Loc_Zone eq ${singleZone}`);
    response = zoneRes.data.filter((item) => item.Meta_Loc_Zone == singleZone)[0].Meta_Loc_Zone
  } else {
    response = axios.get(allApi + floorNodeId + "/SpaceZones?%24select=%20Meta_Loc_Zone%2CNode_Id%2CNode_Name%2CSpace_Zone_Id");
  }


  return response;

}


const updateTasksAssignByTaskId = (nodeId, taskId, modeType, payload) => {

  return axios.put(allApi + nodeId + "/Tasks/" + taskId + "/" + modeType, payload);
}

const updateBookingParties = (nodeId, payload) => {

  return axios.post(allApi + nodeId + "/BookingParties", payload);
}

const updateEquipment = (nodeId, payload) => {
    const utcPayload = {
        ...payload,
        StartDate: DateTime.fromISO(payload.StartDate).toUTC().toISO(),
        EndDate: DateTime.fromISO(payload.EndDate).toUTC().toISO(),
    };
    return axios.post(allApi + nodeId + "/Equipment/available", utcPayload);
}

const getBookingParties = (nodeId, Booking_Id) => {

  return axios.get(allApi + nodeId + `/BookingParties?schema=false&%24filter=Booking_Id%20eq%20%27${Booking_Id}%27%20and%20Booking_Participant_Type%20eq%203`);

}

const getEquipmentDetails = (nodeId, Booking_Resource_Id) => {

  return axios.get(allApi + nodeId + `/Equipment/${Booking_Resource_Id}?schema=false`);

}

const updateTaskForMyTaskToResolveButton = (nodeId, taskId, payload) => {

  return axios.put(`${allApi}${nodeId}/Tasks/${taskId}/resolve`, payload);
}

const updateTasksUnassignByTaskId = (nodeId, taskId, payload) => {

  return axios.put(allApi + nodeId + "/Tasks/" + taskId + "/unassign", payload);
}

const updateTasksCancelByTaskId = (nodeId, taskId, payload) => {

  return axios.put(allApi + nodeId + "/Tasks/" + taskId + "/cancel", payload);
}

const updateTasksReallocateByTaskId = (nodeId, taskId, payload) => {

  return axios.put(allApi + nodeId + "/Tasks/" + taskId + "/re-allocate", payload);
}

const updateTasksSetInProgressByTaskId = (nodeId, taskId, modeType, payload) => {

  return axios.put(allApi + nodeId + "/Tasks/" + taskId + "/" + modeType, payload);
}

const updateTasksPriorityByTaskId = (nodeId, taskId, payload) => {

  return axios.put(allApi + nodeId + "/Tasks/" + taskId + "/change-priority", payload);
}

const getTaskEmail = (partialEmail) => {

  return axios.get(userApi + partialEmail + '/getUsers')
}

const getBookingID = (nodeId, bookingId) => {

  return axios.get(allApi + nodeId + '/Bookings/' + bookingId)
}

const updateBookingByBookingId = (nodeId, bookingId, payload) => {

  return axios.put(allApi + nodeId + `/Bookings/` + bookingId, payload);
}

const getNotification = () => {

  return axios.get(dataEntryWebApi + 'Notification')
}

const getSpacebyBuildingID = (nodeId,) => {

  return axios.get(allApi + nodeId + '/Spaces?schema=false&showDebugStats=false&%24top=500&%24filter=Space_Capacity%20gt%200%20and%20Meta_Bookable%20eq%201%20or%20Meta_Bookable%20eq%203%20or%20Meta_Bookable%20eq%204')
}

const getSpacebyBuildingIDForSpaceName = (nodeId) => {

  return axios.get(allApi + nodeId + '/Spaces?schema=false&showDebugStats=false')
}

const createNotification = (payload) => {

  return axios.post(dataEntryWebApi + `Notification`, payload);
}

const createCatering = (nodeId, payload) => {

  // return axios.post(allApi + `Notification`, payload);
  // return axios.post(allApi + nodeId +"/SpaceCateringMenu/" +payload);
  return axios.post(allApi + nodeId + "/SpaceCateringMenu", payload);


}

const updateCatering = (nodeId, spaceCateringId, payload) => {

  return axios.put(`${allApi}${nodeId}/SpaceCateringMenu/${spaceCateringId}`, payload)
}

const getNotificationById = (id) => {

  return axios.get(dataEntryWebApi + `Notification/` + id)
}

const updateNotificationById = (id, payload) => {

  return axios.put(dataEntryWebApi + `Notification/` + id, payload);
}

const deleteNotificationById = (id) => {

  return axios.delete(dataEntryWebApi + `Notification/` + id);
};

const deleteCateringById = (nodeId, spaceCateringId) => {

  // return axios.delete(dataEntryWebApi + `Notification/` + id);
  return axios.delete(`${allApi}${nodeId}/SpaceCateringMenu/${spaceCateringId}`);
};

const updateBookingCheckinById = (nodeid, spaceid, email) => {

  return axios.put(allApi + nodeid + '/Spaces/' + spaceid + '/checkin?bookingOwnerEmail=' + email);
}

const updateBookingCheckoutById = (nodeid, spaceid, email) => {

  return axios.put(allApi + nodeid + '/Spaces/' + spaceid + '/checkout?bookingOwnerEmail=' + email);
}

const getAPIVersion = () => {

  return axios.get(allApi + "Service/version");
};

const getHostName = (name) => {

  return axios.get(allApi + "Users/" + name);
};


const getSpacebyBuildingIDandDate = (nodeId, startDate, endDate, isUpdate) => {

  let dateUpdate = startDate.split(":")
  startDate = dateUpdate.join("%3A")
  dateUpdate = endDate.split(":")
  endDate = dateUpdate.join("%3A")

  const versions = appContext().localStorageProvider.getVersion();
  if (versions > 11) {
    if (isUpdate === true) {
      return axios.get(allApi + nodeId + '/Spaces/search?schema=false&showDebugStats=false&%24top=500&%24')
    } else {
      return axios.get(allApi + nodeId + '/Spaces/search?schema=false&showDebugStats=false&%24top=500&%24select=Space_Id,Space_Name,Node_Id,Space_Capacity,Space_Layout,Space_Setup,Space_Class&%24filter=Space_Capacity%20gt%200%20and%20%28Meta_Bookable%20eq%201%20or%20Meta_Bookable%20eq%203%20or%20Meta_Bookable%20eq%204%29%20and%20AvailableBetween%28datetime%27' + startDate + '%27%2Cdatetime%27' + endDate + '%27%29')
    }
  }
  else {
    return axios.get(allApi + nodeId + '/Spaces?schema=false&showDebugStats=false&%24select=Space_Id,Space_Name,Node_Id,Space_Capacity,Space_Layout,Space_Setup')
  }

}

const getSpacebyBuildingIDForBooking = (nodeId) => {
  const versions = appContext().localStorageProvider.getVersion();
  if (versions > 11) {
    return axios.get(allApi + nodeId + '/Spaces/search?schema=false&showDebugStats=false')
  } else {
    return axios.get(allApi + nodeId + '/Spaces?schema=false&showDebugStats=false')
  }
}


const getDirectedCleaning = (nodeId) => {
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  let today = DateHelper.now().toISO();
  today = DateHelper.fromIsoToDefaultFormat(today, 'yyyy-MM-dd');
  return axios.get(allApi + nodeId + "/Spaces?schema=false&showDebugStats=false&%24select=Space_Class%2CSpace_Last_Occupied%2CSpace_Type%2CSpace_Name%2CSpace_Id&%24filter=Space_Last_Occupied%20gt%20datetime%27" + today + "T00%3A00%3A00Z%27");
}

const getMyBookings = (nodeId, email) => {

  const now = moment()
  const startDate = moment.utc(now.startOf('day')).toISOString();
  // const startDate = "2022-06-01T18:30:00.000Z"
  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + "/Bookings?%24select=_CreatedBy%2C_CreatedAt%2C%20Booking_Id%2CBooking_Owner_Name%2CBooking_Owner_Email%2CBooking_Start%2CBooking_End%2CBooking_Name%2CSpace_Id%2CBooking_Status&%24filter=Booking_Owner_Email%20eq%20%27" + email + "%27%20and%20Booking_Start%20gt%20datetime'" + startDate + "'");
}

const getMyVisitors = (nodeId, email,startDate,endDate) =>
{
  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + "/Visits?%24filter=Visit_Host_Email%20eq%20%27" + email + "%27%20and%20Visit_Start_Date%20gt%20datetime%27" + startDate.toUTC().toISO() + "%27%20and%20Visit_End_Date%20lt%20datetime%27" + endDate.toUTC().toISO() +"%27" );
}

const getMyActiveTasks = (nodeId, email) => {

  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + `/Tasks?schema=false&showDebugStats=false&%24filter=CreatedBy%20eq%20%27` + email + `%27%20and%20Task_Status%20ne%20%27Cancelled%27%20and%20Task_Status%20ne%20%27Resolved%27`);
}

const getMyResolvedTasks = (nodeId, email) => {

  // const today =moment().startOf('day').toDate().toISOString()
  const today = moment().subtract(7, 'days').startOf('day').toDate().toISOString();
  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + "/Tasks?schema=false&showDebugStats=false&%24filter=CreatedBy%20eq%20%27" + email + "%27%20and%20Task_Status%20eq%20%27Resolved%27%20and%20Task_Event_Time%20gt%20datetime%27" + today + "%27");
}

const getMyOverdueTasks = (nodeId, email) => {

  let today = new Date();
  today = moment(today).format("yyyy-MM-DD");
  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + "/Tasks?schema=false&showDebugStats=false&%24filter=Task_Owner%20eq%20%27" + email + "%27%20and%20Task_Status%20ne%20%27Resolved%27%20and%20Task_Status%20ne%20%27Cancelled%27%20and%20Task_Due_Date%20lt%20datetime%27" + today + "T17%3A00%3A00Z%27");
}

const getMyAssignedTasks = (nodeId, email) => {

  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + "/Tasks?schema=false&showDebugStats=false&%24filter=Task_Owner%20eq%20%27" + email + "%27%20and%20Task_Status%20ne%20%27Resolved%27%20and%20Task_Status%20ne%20%27Cancelled%27%20and%20Task_Status%20eq%20%27Assigned%27%20or%20Task_Status%20eq%20%27In Progress%27%20");
}

const getMyUnassignedTasks = (nodeId, email) => {

  email = email.toLowerCase();
  email = email.replace("@", "%40")
  return axios.get(allApi + nodeId + "/Tasks?schema=false&showDebugStats=false&%24filter=Task_Status%20ne%20%27Resolved%27%20and%20Task_Status%20ne%20%27Cancelled%27%20and%20Task_IsActive%20eq%201%20and%20Task_IsOwned%20eq%200");
}

const getSpacePieChart = (buildingid) => {


  return axios.get(allApi + buildingid + "/Spaces?schema=false&showDebugStats=false&%24top=1000&%24select=Node_Id%2C%20Space_Id%2C%20Space_Capacity%2C%20Space_Occupancy");
}

const getSpaceFloorName = (buildingid) => {


  return axios.get(dataEntrySpacesApi + "?%24filter=type%20eq%20%20%27Floor%27&nodeId=" + buildingid);
}

const getSpacesNotCheckedIn = (buildingid) => {


  return axios.get(allApi + buildingid + "/Spaces?schema=false&showDebugStats=false&%24top=10000&%24filter=Space_Status%20eq%20%27S36%27%20or%20Space_Status%20eq%20%27S37%27%20or%20Space_Status%20eq%20%27S38%27%20or%20Space_Status%20eq%20%27S39%27");
}

const getSpaceswithnoBookings = (buildingid) => {


  return axios.get(allApi + buildingid + "/Spaces?schema=false&showDebugStats=false&%24top=10000&%24filter=Space_Status%20eq%20%27S26%27%20or%20Space_Status%20eq%20%27S27%27");
}

const getCheckedInBookings = async (buildingid) => {


  let startDate = DateHelper.today().toUTC().toISO();
  let endDate = DateHelper.today().endOf('day').toUTC().toISO();

  const bookingApiFunc = async (buildingid, startDate, endDate) => {

    return axios.get(allApi + buildingid + "/Bookings?&%24top=2000&%24filter=%28Booking_Start%20ge%20datetime'" + startDate + "'%20and%20Booking_End%20lt%20datetime'" + endDate + "'%29%20and%20Booking_IsCheckedIn%20eq%201");
  }

  const apiArrayFunc = [bookingApiFunc]
  const apiResponse = await Promise.all(apiArrayFunc.map(async (itemFunc) => {
    const response = await itemFunc(buildingid, startDate, endDate)
    return response
  }))

  const [bookingApiRes] = apiResponse;

  const spaceApiRes = await appContext().apiCache.getSpacesByBuilding(parseInt(buildingid));

  const bookingApiResData = bookingApiRes.data.map((item) => {
    let newItem = { ...item }

    const spaceIdArray = item.Space_Id.split(";")

    const spaceNameArrary = spaceIdArray.map((spaceId) => {
      const spaceObj = spaceApiRes.find(obj => {
        return obj.Space_Id === spaceId;
      })
      if (spaceObj) {
        return spaceObj.Space_Name
      } else {
        return ""
      }
    })

    newItem.Space_Name = spaceNameArrary.join(";")
    return newItem
  })

  const newResponse = {
    data: bookingApiResData
  }

  return newResponse
}

const getUnoccupiedSpaceswithBookings = (buildingid) => {


  return axios.get(allApi + buildingid + "/Spaces?schema=false&showDebugStats=false&%24top=10000&%24filter=Space_Status%20eq%20%27S31%27");
}

const getNoShowBookings = async (buildingid) => {


  let startDate = DateHelper.today().toUTC().toISO();
  let endDate = DateHelper.today().endOf('day').toUTC().toISO();

  const bookingApiFunc = async (buildingid, startDate, endDate) => {

    return axios.get(allApi + buildingid + `/Bookings?schema=false&showDebugStats=false&%24top=5000&%24filter=Booking_Start ge datetime'${startDate}' and Booking_End lt datetime'${endDate}' and Booking_Status%20eq%20%27No%20Show%27`);
  }

  const apiArrayFunc = [bookingApiFunc]
  const apiResponse = await Promise.all(apiArrayFunc.map(async (itemFunc) => {
    const response = await itemFunc(buildingid, startDate, endDate)
    return response
  }))

  const [bookingApiRes] = apiResponse

  const spaceApiRes = await appContext().apiCache.getSpacesByBuilding(parseInt(buildingid));

  const bookingApiResData = bookingApiRes.data.map((item) => {
    let newItem = { ...item }

    const spaceIdArray = item.Space_Id.split(";")

    const spaceNameArrary = spaceIdArray.map((spaceId) => {
      const spaceObj = spaceApiRes.find(obj => {
        return obj.Space_Id === spaceId;
      })
      if (spaceObj) {
        return spaceObj.Space_Name
      } else {
        return ""
      }
    })

    newItem.Space_Name = spaceNameArrary.join(";")
    return newItem
  })

  const newResponse = {
    data: bookingApiResData
  }

  return newResponse
}

const getTodaysSpaceVisitors = (buildingid) => {


  let startDate = DateHelper.today().toUTC().toISO();
  let endDate = DateHelper.today().endOf('day').toUTC().toISO();

  return axios.get(allApi + buildingid + "/Visits?%24filter=Visit_Start_Date%20ge%20datetime'" + startDate + "'%20and%20Visit_End_Date%20lt%20datetime'" + endDate + "'%20and%20Visit_IsApproved%20eq%201&%24top=500&showDebugStats=false&schema=false");
}

const getVisitorInBuilding = (buildingid) => {
  axios.defaults.headers.common = {
    Description: 'visitors in building',
  };


  let startDate = DateHelper.today().toUTC().toISO();
  let endDate = DateHelper.today().endOf('day').toUTC().toISO();

  return axios.get(allApi + buildingid + "/Visits?%24filter=Visit_Start_Date%20ge%20datetime'" + startDate + "'%20and%20Visit_End_Date%20lt%20datetime'" + endDate + "'%20and%20Visit_Status%20eq%20%27Checked%20In%27&%24top=500&showDebugStats=false&schema=false");
}

const getVisitorsCheckout = (buildingid) => {
  axios.defaults.headers.common = {
    Description: 'visitors checkedout',
  };

  // const todayDate = moment().format('YYYY-MM-DD')
  // const nextWorkingDay = momentBusiness(todayDate, 'YYYY-MM-DD').nextBusinessDay().toDate().toISOString()

  // const startDate = nextWorkingDay
  // const endDate = moment(nextWorkingDay).endOf('day').toISOString()


  let startDate = DateHelper.today().toUTC().toISO();
  let endDate = DateHelper.today().endOf('day').toUTC().toISO();

  return axios.get(allApi + buildingid + "/Visits?%24filter=Visit_Start_Date%20ge%20datetime'" + startDate + "'%20and%20Visit_End_Date%20lt%20datetime'" + endDate + "'%20and%20Visit_Status%20eq%20%27Checked%20Out%27&%24top=500&showDebugStats=false&schema=false");
}

const getParametersByName = (nodeId) => {

  return axios.get(allApi + nodeId + "/Parameters/byname/Vis_Arvl_Loc");
}

const getHeaderKpiTargetSpaceUtilization = (nodeId, Occ_Target) => {


  return axios.get(allApi + 1 + `/Parameters?schema=false&recursive=false&%24top=1&%24filter=Node_Id%20eq%20${typeof nodeId === 'string' ? +nodeId : nodeId}%20and%20Parameter_Name%20eq%20%27${Occ_Target}%27`);
}

const getHeaderKpiTargetCapacityUtilization = (nodeId, Occ_Target) => {

  return axios.get(allApi + 1 + `/Parameters?schema=false&recursive=false&%24top=1&%24filter=Node_Id%20eq%20${typeof nodeId === 'string' ? +nodeId : nodeId}%20and%20Parameter_Name%20eq%20%27${Occ_Target}%27`);
}

const getSpaceName = (nodeId, spaceId) => {

  return axios.get(allApi + nodeId + "/Spaces/" + spaceId);
}
const getFacilityZonesData = async (nodeId) => {

  return axios.get(`${allApi}${nodeId}/EnvironmentalZoneData`);
}
const getFacilitySensorsData = async (nodeId) => {

  return axios.get(`${allApi}${nodeId}/EnvironmentalSensors`);
};
const getFullSensorsData = async (nodeId, sensorId) => {

  return axios.get(`${allApi}${nodeId}/EnvironmentalSensors/${sensorId}`);
};

const visitorImportFile = (nodeId, formData) => {

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(allApi + nodeId + "/Visits/import", formData, config);
}

const updateBookingCheckoutByallId = (nodeid, spaceid, payload) => {

  const versions = appContext().localStorageProvider.getVersion();

  if (versions > 11) {
    return axios.put(allApi + nodeid + '/Spaces/' + spaceid + '/action', payload);
  } else {
    return axios.put(allApi + nodeid + '/Spaces/' + spaceid + '/checkout');
  }

}
const getFullZoneData = (nodeId, zoneId) => {

  return axios.get(`${allApi}${nodeId}/EnvironmentalZoneData/${zoneId}`);
}
const getHistoricalZoneData = (nodeId, zoneId, startDate, endDate) => {

  return axios.get(`${allApi}${nodeId}/EnvironmentalZoneDailySummary?startDate=${startDate}&endDate=${endDate}&%24filter=Env_Zone_Id%20eq%20${zoneId}%20and%20Summary_Day_Type%20eq%20%27WorkingHours%27&%24select=Record_Id%2CSummary_Day_Type%2CIAQ_Status%2CEnv_Temperature%2CEnv_Humidity%2CEnv_Sound_Level%2CEnv_Zone_LastUpdated&isUTC=false`);
}

const getFloorData = (nodeId) => {

  return axios.get(`${allApi}${nodeId}/Nodes`);
}

const getAllFloors = (nodeId) => {
  return axios.get(`${allApi}${nodeId}/Floors?$top=500`);
}

const getData = (params) => {
  return axios.get(allApi + params.endPoint);
}

const getUserPreferences = (nodeId) => {

  return axios.get(`${allApi}${nodeId}/UserPreferences`);
}
const getAllNodeData = (nodeId) => {

  return axios.get(`${allApi}${nodeId}/Nodes`);
}
const putData = (requiredData) => {

  return axios.put(`${allApi}${requiredData.endPoint}`, requiredData.payload);
}

const requestNewPassword = (email) => {
  return axios.put(dataEntryWebApi + 'Security/Users/' + email + '/resetpassword');
}

const downloadExcelAssignedTasks = async (buildingid) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  const response = await axios.get(
    reportingApiV1 + buildingid + "/Tasks/download?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner%2CTask_Status&%24filter=Task_IsActive%20eq%201%20and%20Task_IsOwned%20eq%201",
    { responseType: 'blob', }
  );

  fileDownload(response.data, outputFilename)
  return;
}

const downloadExcelUnAssignedTasks = async (buildingid) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  const response = await axios.get(
    reportingApiV1 + buildingid + "/Tasks/download?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner%2CTask_Status&%24filter=Task_IsActive%20eq%201%20and%20Task_IsOwned%20eq%200",
    { responseType: 'blob', }
  );

  fileDownload(response.data, outputFilename)
  return;
}

const downloadExcelResolvedTasks = async (buildingid) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };
  const startDateTime = DateHelper.now().minus({ days: 7 }).toUTC().toISO();
  const response = await axios.get(
    reportingApiV1 + buildingid + "/Tasks/download?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner&%24filter=Task_IsResolved%20eq%201%20and%20Task_Event_Time ge%20datetime%27" + `${startDateTime}` + "%27",
    { responseType: 'blob', }
  );

  fileDownload(response.data, outputFilename)
  return;
}

const downloadExcelCustomFilterTasks = async (object) => {
  const outputFilename = `${DateHelper.now().toUTC().toISO()}.xlsx`;
  axios.defaults.headers.common = {
    Authorization: `bearer ${sessionStorage.getItem("token")}`,
  };

  const { buildingId, startDate, endDate, statusValue } = object

  const response = await axios.get(reportingApiV1 + `${buildingId}/Tasks/download?schema=false&showDebugStats=false&%24top=2000&%24select=Task_Id%2CTask_Type_Name%2CCreatedAt%2CTask_Category_Name%2CSpace_Id%2CTask_Priority%2CTask_Due_Date%2CTask_Owner
     &$filter=Task_Status eq %27${statusValue}%27 and CreatedAt%20ge%20datetime%27${startDate}%27 and CreatedAt%20le%20datetime%27${endDate}%27`, { responseType: 'blob', });

  fileDownload(response.data, outputFilename)
  return;
}

const apis =
{
  // urls
  userApiBaseUrl,
  dataApiBaseUrl,
  dataEntryApiBaseUrl,
  userApi,
  dataEntrySecurityApi,
  dataEntrySpacesApi,
  dataEntryWebApi,
  reportingApiV1,
  reportingApiV2,
  allApi,
  allApiv2,
  dataEntryApiV2,

  // endpoints
  getData,
  putData,
  getAllNodeData,
  getUserPreferences,
  getHistoricalZoneData,
  getFullZoneData,
  updateBookingCheckoutByallId,
  visitorImportFile,
  getSpacebyBuildingIDForBooking,
  getVisitorsCheckout,
  getVisitorInBuilding,
  getTodaysSpaceVisitors,
  getNoShowBookings,
  getUnoccupiedSpaceswithBookings,
  getCheckedInBookings,
  getSpaceswithnoBookings,
  getSpacesNotCheckedIn,
  getSpaceFloorName,
  getSpacePieChart,
  getMyUnassignedTasks,
  getMyAssignedTasks,
  getMyOverdueTasks,
  getMyResolvedTasks,
  getMyActiveTasks,
  //getMyBookingsOther,
  getMyVisitors,
  getMyBookings,
  getDirectedCleaning,
  getSpacebyBuildingIDandDate,
  getSpaceName,
  getParametersByName,
  getHeaderKpiTargetSpaceUtilization,
  getHeaderKpiTargetCapacityUtilization,
  getIBSSAPIVersion,
  getIBSSUserAPIVersion,
  getIBSSDataEntryAPIVersion,
  getIBSSDataAPIVersion,
  getHostName,
  getAPIVersion,
  updateBookingCheckoutById,
  updateBookingCheckinById,
  updateDenyVisitByVisitId,
  updateCheckedOutVisitByVisitId,
  updateCheckedInVisitByVisitId,
  updateCancelledVisitByVisitId,
  deleteNotificationById,
  updateNotificationById,
  getNotificationById,
  createNotification,
  createCatering,
  updateCatering,
  getSpacebyBuildingID,
  getSpacebyBuildingIDForSpaceName,
  getNotification,
  updateBookingByBookingId,
  getBookingID,
  getTaskEmail,
  updateTasksPriorityByTaskId,
  updateTasksSetInProgressByTaskId,
  updateTasksReallocateByTaskId,
  updateTasksCancelByTaskId,
  updateTasksUnassignByTaskId,
  updateTasksAssignByTaskId,
  updateEquipment,
  updateBookingParties,
  updateTaskForMyTaskToResolveButton,
  getOverviewTasks,
  getTabListAAD,
  getRightsList,
  getRightList,
  getTaskTypesByCategoryIdfacility,
  getSpaceByFloorId,
  getAvailableRights,
  getBefore10OverviewBookings,
  getTaskTypesfacility,
  getYesterdayBookings,
  getNextBookings,
  getTodayBookings,
  getNext7DaysBookingsFromSelectedDate,
  getTaskCategoriesByNodeid,
  updateApproveVisitByVisitId,
  updateVisitorByVisitId,
  getDetailsByVisitId,
  createVisit,
  getResolved,
  getCustomFilter,
  getUnassigned,
  downloadExcelOccupiedSpace,
  downloadExcelFreeSpace,
  downloadExcelDirectedCleaningSpace,
  getAssigned,
  deleteTaskStatesById,
  deleteTaskTypesById,
  updateTaskTypes,
  createTaskTypes,
  getsyncaad,
  getVisitorsOnSite,
  getVisitorsInactive,
  getSpaceClassTypeList,
  getSpaceWorkTypeList,
  getSpacetypeList,
  getTabList,
  resetNativePassword,
  resetPasswordUser,
  deleteSignageById,
  updateSignageById,
  createSignage,
  getSignagesById,
  getSignages,
  deleteBuildingKPIById,
  deleteCateringById,
  updateBuildingKPIById,
  createBuildingKPI,
  getBuildingKPIById,
  getKPIs,
  deleteParameterById,
  getParameterById,
  updateParameterById,
  createParameter,
  deleteDeviceById,
  updateDeviceById,
  getDeviceById,
  createDevice,
  createTaskCategories,
  updateTaskCategoriesSpacesById,
  getibssspacetypesList,
  createSpaceStates,
  createTaskStates,
  createSpace,
  getTaskStates,
  getSpaceStates,
  identityProviders: usersIdentityProviderAPI,
  getUserDetails,
  getIdentityProviders,
  getIdentityProvidersRoles,
  getRolesById,
  getIdentityProvidersUsers,
  getIdentityProvidersDetailsById,
  getIdentityProvidersRolesDetailsById,
  getRoleDataSecurityById,
  updateRoleDataSecurityById,
  getUserRoleByRoleId,
  updateRoleData,
  getIdentityProvidersUsersById,
  getIdentityProvidersUserRolesById,
  getIdentityProvidersUserAvailableRolesById,
  updateUserDetails,
  assignRoleToUser,
  patchProvider,
  createProvider,
  createRole,
  createUser,
  deleteById,
  deleteRoleById,
  deleteUserById,
  deleteAssignedRoleToUserById,
  getSpacesByFilter,
  getSpacesById,
  getSpacesByIdIsPartOf,
  getSpacesByIdHasPart,
  getSpacesByParameters,
  getTaskCategories,
  getTaskCategoriesById,
  getTaskTypes,
  getTaskTypesById,
  getDevices,
  getDevicesById,
  getEnvironmentalZones,
  getEnvironmentalZonesById,
  deleteSpacesById,
  updateSpacesById,
  getSpaceStatesById,
  updateSpaceStatesDetails,
  getAwaitingApprovalVisitors,
  getTodaysVisitors,
  getFilteredVisitors,
  getNextWorkingDayVisitors,
  getCheckedInVisitors,
  getFutureVisitors,
  downloadExcelFilterVisitors,
  getSpacesByFilterEnabledBuilding,
  getFacilityNotification,
  createFacilityNotification,
  updateFacilityNotification,
  getSpecificFacilityNotification,
  getWorkSpaceType,
  getOverviewWorkSpaceClassification,
  deleteSpecificFacilityNotification,
  getWorkingStartEndDateForSpecificBuilding,
  getOccupiedSpace,
  getFreeSpace,
  getSpaceAnalyticsHeader,
  getFilteredSpaceData,
  getDirectedCleaningSpace,
  getStatusValuesForSpaces,
  getFilteredSpace,
  getSpecificSpaceCateringMenu,
  getRoamerPageData,
  deleteFilePath,
  downloadImageFile,
  getFile,
  uploadFile,
  getNotificationImageURIData,
  getSpecificNotificationData,
  deleteSpecificSpaceCateringMenu,
  requestNewPassword,
  getFacilityZonesData,
  getFacilitySensorsData,
  getFullSensorsData,
  getFloorData,
  getAllFloors,
  getZonesData,
  downloadExcelAssignedTasks,
  downloadExcelUnAssignedTasks,
  downloadExcelResolvedTasks,
  downloadExcelCustomFilterTasks,
  loginWithCredentials: loginWithEmailAndPassword,
  getBookingParties,
  getEquipmentDetails,
};
export default apis;