import React, { Component } from "react";
import { appContext } from "../AppContext";
import { DateTime } from "luxon";
import { Typography } from "@mui/material";

export default class TimeZoneOffset extends Component<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            lightModeTheme: this.appState.lightModeTheme,
            isLocalTime: false,
            offsetInHours: 0,
        };
    }

    public componentDidMount(): void
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));
        const browserTime = DateTime.now();
        const buildingTime = browserTime.setZoneByNode(this.props.nodeId, true);
        const offsetInHours = buildingTime.offset / 60;
        this.setState({ isLocalTime: (buildingTime.offset == browserTime.offset) , offsetInHours: offsetInHours });
    }

    public render(): JSX.Element 
    {
        return (
            <Typography className="icon-text-inline" sx={{fontFamily: 'Source Sans Pro', fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                <span className="space-icon-item"><img style={{ height: '18px', width: '18px', paddingRight: '5px' }} src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Timezone (Fill).svg`} alt="icon" /></span>
                {this.state.isLocalTime ? this.labels.HubLabelLocalTime : `UTC ${this.state.offsetInHours > 0 ? "+" : ""}${this.state.offsetInHours}`}
            </Typography>
        )
    }
}

interface IProps
{
    nodeId: number;
}

interface IState
{
    lightModeTheme: boolean;
    isLocalTime: boolean;
    offsetInHours: number;
}
