import { DateTime, MinuteNumbers, WeekdayNumbers, Duration, DurationObjectUnits } from "luxon";
import { DateHelper } from "./DateHelper";

declare module 'luxon/src/datetime'
{
    export interface DateTime
    {
        isNull(): boolean;
        clone(): DateTime;
        snapToMinute(allowedMinutes: MinuteNumbers[], options?: { preserveDate?: boolean, allowStartOfNextDay?: boolean, direction?: number }): DateTime;
        snapToDay(firstAllowedDay: WeekdayNumbers, lastAllowedDay: WeekdayNumbers): DateTime;
        isMinuteIn(allowedMinutes: MinuteNumbers[]): boolean;
        isDayBetween(firstAllowedDay: WeekdayNumbers, lastAllowedDay: WeekdayNumbers): boolean;
        date(): DateTime;
        setZoneOrDefault(timeZone?: string): DateTime;
        toFormatOrDefault(format?: string): string;
        startOfLocalDayAsUtc(timeZone: string): DateTime;
        endOfLocalDayAsUtc(timeZone: string): DateTime;
        getLocale(): string;
        toLocaleDateString(): string;
        toLocaleTimeString(): string;
        toLocaleDateTimeString(): string;
        toHourMinuteSeconds(): string;
        setZoneByNode(nodeId: number, keepTime?: boolean): DateTime;
        offsetTimeByNode(nodeId: number, keepZone?: boolean): DateTime;
        plusPeriods(periodType: number, numOfPeriods?: number): DateTime;
    }
}

declare module 'luxon/src/duration'
{
    export interface Duration
    {
        durationToString(): string
    }
}


DateTime.prototype.isNull = function(): boolean
{
    return (!this.isValid && this.invalidReason == "null");
}

DateTime.prototype.clone = function(): DateTime
{
    return this.plus({ second: 0 });
}

DateTime.prototype.snapToMinute = function(allowedMinutes: MinuteNumbers[], options?: { preserveDate?: boolean, allowStartOfNextDay?: boolean, direction?: number }): DateTime
{
    if (!this.isValid)
    {
        return DateTime.invalid(this.invalidReason as string, this.invalidExplanation ?? undefined);
    }

    let startOfDay = this.startOf("day");
    let startOfNextDay = this.plus({ day: 1 }).startOf("day");
    let direction = ((options?.direction ?? 1) < 0 ? -1 : 1);
    let preserveDate = (options?.preserveDate ?? false);
    let allowStartOfNextDay = (options?.allowStartOfNextDay ?? false);
    let orderedMinutes = [...allowedMinutes].sort((a, b) => (a < b ? -direction : direction));
    let validMinute = orderedMinutes.filter(i => (direction > 0 ? i >= this.minute : i <= this.minute))[0];
    
    let retVal = this;
    if (validMinute == null)
    {
        validMinute = orderedMinutes[0];
        retVal = retVal.plus({ hour: direction });
    }
    retVal = retVal.set({ minute: validMinute }).startOf("minute");

    if (preserveDate && !(allowStartOfNextDay && retVal.equals(startOfNextDay)))
    {
        retVal = retVal.set({ year: startOfDay.year, month: startOfDay.month, day: startOfDay.day });
    }
    return retVal;
}

DateTime.prototype.snapToDay = function(firstAllowedDay: WeekdayNumbers, lastAllowedDay: WeekdayNumbers): DateTime
{
    if (!this.isValid)
    {
        return DateTime.invalid(this.invalidReason as string, this.invalidExplanation ?? undefined);
    }

    let retVal = this.plus({  day: 0} );
    for (let i = 0; i < 7; i++)
    {
        if (retVal.isDayBetween(firstAllowedDay, lastAllowedDay))
        {
            return retVal;
        }
        retVal = retVal.plus({ day: 1 });
    }
    return DateTime.invalid("Could not snap the day.");
}

DateTime.prototype.isMinuteIn = function(allowedMinutes: MinuteNumbers[]): boolean
{
    if (!this.isValid)
    {
        return false;
    }
    return allowedMinutes.contains(this.minute);
}

DateTime.prototype.isDayBetween = function(firstAllowedDay: WeekdayNumbers, lastAllowedDay: WeekdayNumbers): boolean
{
    if (!this.isValid)
    {
        return false;
    }
    else if (firstAllowedDay <= lastAllowedDay)
    {
        return (this.weekday >= firstAllowedDay && this.weekday <= lastAllowedDay);
    }
    else
    {
        return (this.weekday >= firstAllowedDay || this.weekday <= lastAllowedDay);
    }
}

DateTime.prototype.date = function(): DateTime
{
    return this.startOf("day");
}

DateTime.prototype.setZoneOrDefault = function(timeZone?: string): DateTime
{
    return this.setZone(timeZone ?? DateHelper.defaultZone);
}

DateTime.prototype.toFormatOrDefault = function(format?: string): string
{
    return this.toFormat(format ?? DateHelper.defaultFormat);
}

DateTime.prototype.startOfLocalDayAsUtc = function(timeZone: string): DateTime
{
    return this.setZone(timeZone).date().toUTC();
}

DateTime.prototype.endOfLocalDayAsUtc = function(timeZone: string): DateTime
{
    return this.setZone(timeZone).endOf('day').toUTC();
}

DateTime.prototype.getLocale = function(): string
{
    return (navigator.languages && navigator.languages.length ? navigator.languages[0] : 'en');
}

DateTime.prototype.toLocaleDateString = function(): string
{
    return this.toLocaleString(DateTime.DATE_SHORT, { locale: this.getLocale()});
}

DateTime.prototype.toLocaleTimeString = function(): string
{
    return this.toLocaleString(DateTime.TIME_SIMPLE, { locale: this.getLocale()});
}

DateTime.prototype.toLocaleDateTimeString = function(): string
{
    return this.toLocaleString(DateTime.DATETIME_SHORT, { locale: this.getLocale()});
}

DateTime.prototype.toHourMinuteSeconds = function(): string
{
    return this.toFormat("HH:mm:ss");
}

DateTime.prototype.setZoneByNode = function(nodeId: number, keepTime?: boolean): DateTime
{
    keepTime = keepTime ?? true;
    const buildingZone = DateHelper.getZoneByNode(nodeId);
    const dateTime = this.setZone(buildingZone, { keepLocalTime: keepTime });
    return dateTime; 
}

DateTime.prototype.offsetTimeByNode = function(nodeId: number, keepZone?: boolean): DateTime
{
    keepZone = keepZone ?? true;
    const buildingZone = DateHelper.getZoneByNode(nodeId);
    const originalZone = this.zone;
    let dateTime = this.setZone(buildingZone, { keepLocalTime: false });

    if (keepZone)
    {
        dateTime = dateTime.setZone(originalZone, { keepLocalTime: true });
    }
    return dateTime;
}

DateTime.prototype.plusPeriods = function(periodType: number, numOfPeriods?: number): DateTime
{
    numOfPeriods = numOfPeriods ?? 1;
    switch (periodType) 
    {
        case 3: // daily
            return this.plus({ days: numOfPeriods });
        case 0: // weekly
            return this.plus({ days: 7 * numOfPeriods });
        case 1: // monthly
            return this.plus({ months: numOfPeriods });
        case 2: // yearly
            return this.plus({ years: numOfPeriods });
        default:
            throw new Error(`The 'period type' ${periodType} is not supported.`);
    }
}

Duration.prototype.durationToString = function (): string
{
    const hourDiff = this.hours;
    const minuteDiff = this.minutes !== undefined && this.minutes % 60
    return `${hourDiff} hr ${minuteDiff === 0 ? "" : minuteDiff} ${minuteDiff === 0 ? "" : "min"}`
}
