import axios, { AxiosResponse } from "axios";
import { ApiError } from "./ApiError";
import apis from "./apis";

export class UserPreferenceRepository implements IUserPreferenceRepository
{
    public async updateUserPreferences(value: IUserPreferences): Promise<IUserPreferences>
    {
        try
        {
            const response: AxiosResponse<IUserPreferences> = await axios.put(`${apis.allApi}1/UserPreferences`, value);
            if (response.status !== 200)
            {
                throw response;
            }
            return response.data;

        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUserPreferenceRepository
{
    updateUserPreferences(value: IUserPreferences): Promise<IUserPreferences>;
}

export interface IUserPreferences
{
    SecurityPrefs: ISecurityPrefs;
    NotificationPrefs: INotificationPrefs;
    PrivacyPrefs: IPrivacyPrefs;
    CalendarPrefs: ICalendarPrefs;
    EnvironmentPrefs: IEnvironmentPrefs;
    WorkingHoursPrefs: IWorkingHoursPrefs;
    SearchPrefs: ISearchPrefs;
    DiagnosticsPrefs: IDiagnosticsPrefs;
    FavouriteColleagues: Array<IFavouriteColleague>;
    Nodes: Array<IUserPreferencesNode>;
}

export interface ISecurityPrefs
{
    Pin: string;
}

export interface INotificationPrefs
{
    Visitors: (number | null);
    Tasks: (number | null);
    Bookings: (number | null);
    BuildingsPrefs: Array<IBuildingsPrefs>;
}

export interface IBuildingsPrefs
{
    NodeId: (number | null);
    Value: (number | null);
}

export interface IPrivacyPrefs
{
    ShareLocation: (number | null);
}

export interface ICalendarPrefs
{
    SyncCalendar: number;
}

export interface IEnvironmentPrefs
{
    Temperature: (number | null);
    SoundLevel: (number | null);
}

export interface IWorkingHoursPrefs
{
    UserStartTime: string;
    UserEndTime: string;
}

export interface ISearchPrefs
{
    DefaultSearchResults: string;
    DefaultBuilding: (number | null);
}

export interface IDiagnosticsPrefs
{
    Logging: number;
}

export interface IFavouriteColleague
{
    Email: string;
    FirstName: string;
    LastName: string;
}

export interface IUserPreferencesNode
{
    NodeId: number | null;
    DefaultFloor: number | null;
    DefaultZone: number | null;
    FavouriteSpaces?: Array<string>;
    FavouriteAmenities?: Array<string>;
    FavouriteEquipment?: Array<string>;
}
