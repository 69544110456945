import React, { Component } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, InputLabel } from '@mui/material';
import IbssDatePicker from './Datepicker/IbssDatePicker';
import { appContext } from "../../AppContext";
import { DateTime } from 'luxon';
import { DateHelper } from '../../Common/DateHelper';

class IbssPageHeader extends Component<IPageHeaderProps>
{
    private get labels() { return appContext().labels; }

    private startDateChanged(date: Date | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : DateTime.fromJSDate(new Date(date as Date)));
        this.props.DEPRECATED_onDateChange?.(date as Date, StartOrEnd.Start);
        this.props.onStartDateChange?.(dateTime);
    }

    private endDateChanged(date: Date | null): void
    {
        const dateTime = (date == null ? DateHelper.null() : DateTime.fromJSDate(new Date(date as Date)));
        this.props.DEPRECATED_onDateChange?.(date as Date, StartOrEnd.End);
        this.props.onEndDateChange?.(dateTime);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                <Grid item lg={4} sx={{pt:0}} >
                    <Box className="table-panel-header" component="div" sx={{ ml: '0',textOverflow: 'ellipsis',whiteSpace:'nowrap',overflow:'hidden',width:"100%" }}>{this.props.pageTitle}</Box>
                </Grid>
                            <Grid item lg={8} sx={{ display: 'flex', justifyContent: 'right',pt:0 }}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { ml: 1,my:1, width: '20ch' },
                                    }}
                                >
                                    <FormControl>
                                        {
                                            this.props.daysFilterLabel &&
                                                <InputLabel id="demo-simple-select-label">{this.props.daysFilterLabel}</InputLabel>
                                        }
                                        <Select
                                            id="demo-simple-select"
                                            value={this.props.daysFilter}
                                            size="small"
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={(e: SelectChangeEvent<string>) => this.props.todayChanged(e)}
                                            label={this.props.daysFilterLabel}
                                            sx={this.props.daysFilterLabel ? null : { '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        >
                                            {
                                                (this.props.daysFilterExcludeToday == undefined || this.props.daysFilterExcludeToday == false) &&
                                                    <MenuItem value={this.labels.HubLabelToday}>{this.labels.HubLabelToday}</MenuItem>
                                            }
                                            {
                                                this.props.additionalDateFilterOptions && this.props.additionalDateFilterOptions?.length > 0 &&
                                                this.props.additionalDateFilterOptions.map(label => { return (<MenuItem value={label}>{label}</MenuItem>) })
                                            }
                                            <MenuItem value={this.labels.HubLabelCustom}>{this.labels.HubLabelCustom}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <IbssDatePicker
                                        value={this.props.startDate}
                                        onChange={event => this.startDateChanged(event)}
                                        label={this.labels.HubLabelFrom}
                                        renderInput={(params) =>
                                            {
                                            const { sx, ...paramsMinusSx } = params;
                                            return (
                                                <TextField
                                                    size="small"
                                                    {...paramsMinusSx}
                                                    sx={{ width: "auto" }}
                                                    error={false}
                                                />
                                            );
                                        }}
                                        disabled={this.props.dateDisabled}
                                    />
                                    <IbssDatePicker
                                        value={this.props.endDate}
                                        onChange={event => this.endDateChanged(event)}
                                        label={this.labels.HubLabelTo}
                                        renderInput={(params) =>
                                            {
                                            const { sx, ...paramsMinusSx } = params;
                                            return (
                                                <TextField
                                                    size="small"
                                                    {...paramsMinusSx}
                                                    sx={{ width: "auto" }}
                                                    error={new Date(this.props.endDate) < new Date(this.props.startDate)}
                                                />
                                            );
                                        }}
                                        disabled={this.props.dateDisabled}
                                        minDate={this.props.startDate}
                                    />
                                </Box>
                            </Grid>
                            {
                                new Date(this.props.endDate) < new Date(this.props.startDate) &&
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right',pt:0 }}>
                                    <Box className='text-danger' style={{ margin: 0 }} >{this.labels.HubLabelStartDateMustBeBeforeTheEndDate}</Box>
                                </Grid>
                            }
                </Grid>
            </>
        )
    }
};

export interface IPageHeaderProps
{
    pageTitle: string;
    daysFilter: string;
    todayChanged: (e: SelectChangeEvent<string>) => void;
    startDate: Date;
    endDate: Date;
    onStartDateChange?: (e: DateTime) => void;
    onEndDateChange?: (e: DateTime) => void;
    dateDisabled: boolean,
    additionalDateFilterOptions?: string[],
    daysFilterLabel?: string;
    daysFilterExcludeToday?: boolean

    /** @deprecated */
    DEPRECATED_onDateChange?: (e: Date, value: StartOrEnd) => void;
}

export enum StartOrEnd
{
    Start,
    End,
}

export default IbssPageHeader;
