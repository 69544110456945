import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class GetPrivacyPolicyEndpoint implements IGetPrivacyPolicyEndpoint
{
    public async execute(): Promise<string>
    {
        try
        {
            const response = await axios.get<string>(`${apis.allApi}Service/privacy-policy`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetPrivacyPolicyEndpoint
{
    execute(): Promise<string>;
}