import Chip from '@mui/material/Chip';
import { IBreadcrumbModel } from './models/IBreadcrumbModal';
import { Key } from 'react';


export default function IbssbreadcrumbLeft(props: { value: IBreadcrumbModel[]; }) {
  return (
  <>
  <div className='ibss-breadcrumb'>
      {props.value.map((data: IBreadcrumbModel, index: Key | null | undefined) => {
        let icon = <img alt={data.value.toUpperCase()} src={data.src} height='20px' />;
        return (
            <Chip
              icon={icon}
              label={data.value.toUpperCase()}
              sx={{marginRight: 3}}
            />
        );
      })}
    </div>
  </>
  );
}
