import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Component } from "react";
import IbssTextField from './IbssTextField';
import { InputAdornment, Typography } from '@mui/material';
import InfiniteScroller, { InfiniteScrollerResult } from '../InfiniteScroller/InfiniteScroller';

export default class IbssTransferList extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            checked: [],
            left: this.props.left,
            right: this.props.right,
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState): void
    {
        if (prevProps !== this.props)
        {
            // check if the contents of the array passed in via props.left has changed. if so, copy props.left to state, 
            if (JSON.stringify(prevProps.left) !== JSON.stringify(this.props.left))
            {
                this.setState({
                    left: this.notB(this.props.left.slice(), this.state.right),
                    checked: [],
                })
            }
            // check if the contents of the array passed in via props.right has changed. if so, copy props.right to state, 
            if (JSON.stringify(prevProps.right) !== JSON.stringify(this.props.right))
            {
                this.setState({
                    right: this.notB(this.props.right.slice(), this.state.left),
                    checked: [],
                })
            }
        }

        if (prevState.left !== this.state.left)
        {
            this.props.onLeftListChanged && this.props.onLeftListChanged(this.state.left);
        }
        if (prevState.right !== this.state.right)
        {
            this.props.onRightListChanged && this.props.onRightListChanged(this.state.right);
        }
    }

    private notB(a: IListItem[], b: IListItem[]): IListItem[]
    {
        // comparison of itemA and itemB are done without comparing percent property.
        return a.filter((itemA) => b.filter(itemB => JSON.stringify({ ...itemB, percent: '' }) === JSON.stringify({ ...itemA, percent: '' })).length === 0);
    }

    private intersection(a: IListItem[], b: IListItem[]): IListItem[]
    {
        // comparison of itemA and itemB are done without comparing percent property.
        return a.filter((itemA) => b.filter(itemB => JSON.stringify({ ...itemB, percent: '' }) === JSON.stringify({ ...itemA, percent: '' })).length > 0);
    }

    private itemClicked(value: IListItem): void
    {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) 
        {
            newChecked.push(value);
        }
        else
        {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checked: newChecked });
    }

    private allRightClicked(): void 
    {
        const { left, right } = this.state;
        const enabledLeftListItems = left.filter(item => item.disabled != true);
        const disabledLeftListItems = left.filter(item => item.disabled == true);
        this.setState({
            right: right.concat(enabledLeftListItems),
            left: disabledLeftListItems,
        });
    }

    private checkedRightClicked(): void 
    {
        // move checked items left to right
        const { left, right, checked } = this.state;
        const leftChecked = this.intersection(this.state.checked, this.state.left);

        this.setState({
            right: right.concat(leftChecked),
            left: this.notB(left, leftChecked),
            checked: this.notB(checked, leftChecked),
        });
    }

    private checkedLeftClicked(): void
    {
        // move checked items right to left
        const { left, right, checked } = this.state;
        const rightChecked = this.intersection(this.state.checked, this.state.right);

        this.setState({
            left: left.concat(rightChecked),
            right: this.notB(right, rightChecked),
            checked: this.notB(checked, rightChecked),
        });
    }

    private allLeftClicked(): void
    {
        const { left, right } = this.state;
        const enabledRightListItems = right.filter(item => item.disabled != true);
        const disabledRightListItems = right.filter(item => item.disabled == true);
        this.setState({
            left: left.concat(enabledRightListItems),
            right: disabledRightListItems,
        });
    }

    private percentageChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: IListItem): void
    {
        const input = event.target.value;
        const expression = /^[0-9\b]+$/ // only accept strings which are digits

        if ((input === "" || expression.test(input)) && input.length <= 3) 
        {
            this.setState(prevState => (
            {
                right: prevState.right.map(i =>
                {
                    if (i.id === item.id)
                    {
                        i.percent = event.target.value;
                    }
                    return i;
                })
            }))
        }
    }

    private async getLeft(skipToken: string): Promise<string>
    {
        if (this.props.getLeft == null)
        {
            return "";
        }
        const left = await this.props.getLeft(skipToken);
        const totalLeft = [ ...this.state.left, ...left.items ];
        this.setState({ left: totalLeft });
        return left.skipToken;
    }

    private async getRight(skipToken: string): Promise<string>
    {
        if (this.props.getRight == null)
        {
            return "";
        }
        const right = await this.props.getRight(skipToken);
        const totalRight = [ ...this.state.right, ...right.items ];
        this.setState({ right: totalRight });
        return right.skipToken;
    }

    public render(): JSX.Element
    {
        const { listWidth, listHeight, flexHorizontalPlacement } = this.props;
        return (
            <Grid container spacing={2} justifyContent={flexHorizontalPlacement ?? "center"} alignItems="top">
                <Grid md={5} sx={{pr:2,pt:2}}>
                    <CustomList
                        items={this.state.left}
                        checked={this.state.checked}
                        itemClicked={i => this.itemClicked(i)}
                        listWidth={listWidth}
                        listHeight={listHeight}
                        showInfiniteScroller={this.props.showInfiniteScroller}
                        getData={i => this.getLeft(i)}
                    />
                </Grid>
                <Grid md={2}  sx={{pl:2,pt:2}}>
                    <Grid container direction="column" alignItems="top">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={() => this.allRightClicked()}
                            disabled={this.state.left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={() => this.checkedRightClicked()}
                            disabled={this.intersection(this.state.checked, this.state.left).length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={() => this.checkedLeftClicked()}
                            disabled={this.intersection(this.state.checked, this.state.right).length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={() => this.allLeftClicked()}
                            disabled={this.state.right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid  md={5}  sx={{paddingLeft:2,paddingTop:2}}>
                    {<CustomList
                        showInput={this.props.showInputs}
                        items={this.state.right}
                        checked={this.state.checked}
                        itemClicked={i => this.itemClicked(i)}
                        onPercentageChanged={(event, item) => this.percentageChanged(event, item)}
                        listWidth={listWidth}
                        listHeight={listHeight}
                        showInfiniteScroller={this.props.showInfiniteScroller}
                        getData={i => this.getRight(i)}
                    />}
                </Grid>
            </Grid>
        );
    }
}

class CustomList extends Component<ICustomListProps, Record<string, never>>
{
    private infiniteScrollerContainer = React.createRef<HTMLDivElement>();

    private isItemChecked(item: IListItem, checked: IListItem[], usePrimaryTextAsKey: boolean)
    {
        const key = (usePrimaryTextAsKey ? item.primaryText : item.id);
        return checked.findIndex(x => (usePrimaryTextAsKey ? x.primaryText : x.id) === key) !== -1 && !item.disabled;
    }

    public render(): JSX.Element
    {
        const { checked, items, itemClicked, listWidth, listHeight } = this.props;
        const usePrimaryTextAsKey = (items.some(i => i.id == null) || checked.some(i => i.id == null));

        return (
            <Paper sx={{ width: listWidth ?? "100%", height: listHeight ?? 230, overflow: 'auto' }} ref={this.infiniteScrollerContainer}>
                <List dense component="div" role="list">
                    {items.map((value: IListItem) =>
                    {
                        const labelId = `transfer-list-item-${value.primaryText}-label`;

                        return (
                            <ListItem
                                key={`${value.primaryText}-${value?.id ?? ''}`}
                                role="listitem"
                                onClick={() => value.disabled ? {} : itemClicked(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        disabled={value.disabled}
                                        checked={this.isItemChecked(value, checked, usePrimaryTextAsKey)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText 
                                    id={labelId} 
                                    primary={`${value.primaryText}`} 
                                    secondary={value.secondaryText} 
                                    primaryTypographyProps={{ style: { whiteSpace: "normal", overflowWrap: "break-word" } }}
                                />
                                {(this.props.showInput ?? false) &&
                                <IbssTextField
                                    id={`transfer-list-item-${value.primaryText}-percent-input`}
                                    inputProps={{ min: 0, max: 100, inputMode: 'numeric', pattern: '[0-9]*', style: { fontSize: 12 }, 'aria-labelledby': labelId, }}
                                    size={'small'}
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '65px', minWidth: '65px', marginLeft: '5px' }}
                                    value={value?.percent ?? '0'}
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={(event) => this.props.onPercentageChanged && this.props.onPercentageChanged(event, value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'><Typography sx={{ fontSize: '12px' }}>%</Typography></InputAdornment>
                                        ),
                                    }}
                                    error={value.percent === ''}
                                />}
                            </ListItem>
                        );
                    })}
                </List>
                { this.props.showInfiniteScroller && <InfiniteScroller container={this.infiniteScrollerContainer} getData={i => this.props.getData(i)} /> }
            </Paper>
        );
    }
}

interface ICustomListProps
{
    items: IListItem[];
    itemClicked: (value: IListItem) => void;
    checked: IListItem[];
    listWidth?: string | null;
    listHeight?: string | null;
    showInput?: boolean;
    onPercentageChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: IListItem) => void;
    showInfiniteScroller?: boolean;
    getData: (skipToken: string) => Promise<string>;
}

interface IProps
{
    left: IListItem[];
    right: IListItem[];
    listWidth?: string | null;
    listHeight?: string | null;
    flexHorizontalPlacement?: string | null; // values for justifyContent property of a flex container.
    onLeftListChanged?: (list: IListItem[]) => void;
    onRightListChanged?: (list: IListItem[]) => void;
    showInputs?: boolean;
    showInfiniteScroller?: boolean;
    getLeft?: (skipToken: string) => Promise<InfiniteScrollerResult<IListItem>>;
    getRight?: (skipToken: string) => Promise<InfiniteScrollerResult<IListItem>>;
}

interface IState
{
    checked: IListItem[];
    left: IListItem[];
    right: IListItem[];
}

export interface IListItem
{
    primaryText: string;
    secondaryText: string;
    percent?: string;
    id?: string;
    disabled?: boolean;
}
