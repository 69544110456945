import { Component } from "react";
import { IPartialAppState, appContext } from "../../../AppContext";
import "../../../styles/css/table.scss";
import "../../../App.css";
import Spinner from "../../../Components/Spinner";
import ChartPanelInfo from "../../../Components/ChartPanelInfo";
import { DateHelper } from "../../../Common/DateHelper";
import { DateTime } from "luxon";
import PieChartCard, { IPie } from "../../../Components/PieChartCard";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { Visit, VisitFilter } from "../../../Providers.Api/Visits/VisitsRepository";
import { ODataQuery } from "../../../Providers.Api/ODataQuery";

class Trends extends Component
{

  private labels = appContext().labels;
  private appState = appContext().state;
  private get apiClient() { return appContext().apiClient; }

  state = {
    isLoading: false,
    selectedDate: "",
    buildingid: this.appState.buildingId.toString(),
    dataGraph: [],
    donutChartData: [],

    dataPie1: [],
    dataPie1Value: 0,
    COLORS1: ['#9B59B6', '#F44336', '#FF7913', '#2962FF'],

    dataPie2: [],
    dataPie2Value: 0,
    COLORS2: ['#F44336', '#FFC400', '#00C853', '#2962FF'],

    dataPie3: [],
    dataPie3Value: 0,
    COLORS3: ['#FFC400', '#2962FF', '#00C853'],

    widthchart: 0,
    widthdonut: 0,
  };

  public async componentDidMount()
  {
    const { match, history }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    this.calculateWidth();

    if (this.state.buildingid)
    {
      this.setState({
        buildingid: this.state.buildingid
      })
      if (this.state.buildingid !== match.params.buildingid)
      {
        let temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
        history.push(temp + "/" + this.state.buildingid)
      }
    }

    if (this.state.buildingid && match.params.buildingid !== null && this.state.buildingid !== match.params.buildingid)
    {
      history.push("/operational-services-visitors-trends/" + this.state.buildingid)
    } else
    {
      if (this.state.buildingid && match.params.filter)
      {
        history.push("/operational-services-visitors-trends/" + this.state.buildingid + "/" + match.params.tab + "/" + match.params.filter)
      } else if (this.state.buildingid)
      {
        history.push("/operational-services-visitors-trends/" + this.state.buildingid)
      } else
      {
        history.push("/operational-services-visitors-trends/0")
      }
    }
    this.onBarClick({ activeLabel: DateTime.now().toLocaleString(DateTime.DATE_SHORT) });
    this.getDetails();
    window.addEventListener('resize', this.calculateWidth);
    window.addEventListener('load', this.calculateWidth);
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any): void
  {
    if (prevState.buildingid != this.state.buildingid)
    {
      this.getDetails();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
    if (state.buildingId != undefined)
    {
      this.setState({ buildingid: state.buildingId });
      history.push(match.path.replace(":buildingid", state.buildingId));
    }
  }

  private async calculateWidth(): Promise<void>
  {
    let chartwidth = window.innerWidth - 390;
    if (chartwidth > 2200)
    {
      this.setState({
        widthchart: 2200
      })
    } 
    else
    {
      if (chartwidth < 960)
      {
        this.setState({
          widthchart: 960
        })
      } 
      else
      {
        this.setState({
          widthchart: chartwidth
        })
      }
    }
  }

  private async getDetails(): Promise<void>
  {
    this.setState({ isLoading: true, selectedDate: "" })
    let tempGraph: any = [];
    let tempPie1: Array<IPie> = [];
    let tempPie2: Array<IPie> = [];
    let tempPie3: Array<IPie> = [];
    let todayDate = DateHelper.now().toJSDate();
    let dateFormatting = DateTime.fromISO(todayDate.toISOString()).toLocaleString(DateTime.DATE_SHORT);
    tempGraph.push({ name: dateFormatting, NoShow: 0, Cancelled: 0, AutoCancelled: 0, Completed: 0, Active: 0 });
    for (let i = 1; i < 14; i++)
    {
      dateFormatting = DateTime.fromISO(todayDate.toISOString()).plus({ day: i }).toLocaleString(DateTime.DATE_SHORT);
      tempGraph.push({ name: dateFormatting, Denied: 0, NeverApproved: 0, AwaitingApproval: 0, Approved: 0 });
    }

    tempPie1.push({ name: this.labels.HubLabelDeniedLabel, value: 0, count: 0 })
    tempPie1.push({ name: this.labels.HubLabelNeverApproved, value: 0, count: 0 })
    tempPie1.push({ name: this.labels.HubTabAwaitingApproval, value: 0, count: 0 })
    tempPie1.push({ name: this.labels.HubLabelApproved, value: 0, count: 0 })

    tempPie2.push({ name: this.labels.HubLabelNoShow, value: 0, count: 0 })
    tempPie2.push({ name: this.labels.HubLabelCancelled, value: 0, count: 0 })
    tempPie2.push({ name: this.labels.HubLabelCompleted, value: 0, count: 0 })
    tempPie2.push({ name: this.labels.HubLabelActive, value: 0, count: 0 })

    tempPie3.push({ name: this.labels.HubLabelNotCheckedIn, value: 0, count: 0 })
    tempPie3.push({ name: this.labels.HubLabelCheckedIn, value: 0, count: 0 })
    tempPie3.push({ name: this.labels.HubLabelCheckedOut, value: 0, count: 0 })

    this.setState({
      dataGraph: tempGraph,
      dataPie1: tempPie1,
      dataPie2: tempPie2,
      dataPie3: tempPie3
    });

    try
    {
      let today = DateHelper.now().minus({ days: 7 });
      let endDate = DateTime.fromISO(DateHelper.now().toISO()).plus({ day: 6 }).endOf('day');

      const query = new ODataQuery({
            top: 500,
            nodeId: parseInt(this.state.buildingid),
            select: Visit,
            filter: new VisitFilter({
                minStartDate: today,
                maxEndDate: endDate,
            }),
      });
      const response = await this.apiClient.visits.getMany(query);
      
      todayDate = DateHelper.now().toJSDate();
      dateFormatting = DateTime.fromISO(todayDate.toString()).set({ day: DateTime.local().day }).toLocaleString(DateTime.DATE_SHORT);
      for (let i = 0; i < tempGraph.length; i++)
      {
        let Denied = 0;
        let NeverApproved = 0;
        let AwaitingApproval = 0;
        let Approved = 0;

        for (let j = 0; j < response.length; j++)
        {
          dateFormatting = response[j].Visit_Start_Date.toLocaleString(DateTime.DATE_SHORT);
          if (tempGraph[i].name === dateFormatting)
          {
            if (response[j].Visit_IsApproved === 0 && response[j].Visit_IsDenied === 1)
            {
              Denied = Denied + 1;
            }
            if (response[j].Visit_IsApproved === 0 && (response[j].Visit_IsNoShow === 1 || response[j].Visit_IsCancelled === 1) && response[j].Visit_IsDenied === 0)
            {
              NeverApproved = NeverApproved + 1;
            }
            if (response[j].Visit_IsApproved === 0 && response[j].Visit_IsNoShow === 0 && response[j].Visit_IsDenied === 0 && response[j].Visit_IsCancelled === 0)
            {
              AwaitingApproval = AwaitingApproval + 1;
            }
            if (response[j].Visit_IsApproved === 1)
            {
              Approved = Approved + 1;
            }
          }
        }

        tempGraph[i].Denied = Denied
        tempGraph[i].NeverApproved = NeverApproved
        tempGraph[i].AwaitingApproval = AwaitingApproval
        tempGraph[i].Approved = Approved
      }
      this.setState({
        dataGraph: tempGraph
      });

    }
    catch
    {
    
    }
  
    try
    {
      let today = DateHelper.now().startOf('day');
      let endDate = DateTime.fromISO(DateHelper.now().toISO()).plus({ day: 14 }).endOf('day');

      const query = new ODataQuery({
            top: 500,
            nodeId: parseInt(this.state.buildingid),
            select: Visit,
            filter: new VisitFilter({
                minStartDate: today,
                maxEndDate: endDate,
            }),
      });
      const response = await this.apiClient.visits.getMany(query);
      this.setState({ donutChartData: response });

      tempPie1 = [];
      tempPie2 = [];
      tempPie3 = [];
      let Denied = 0;
      let NeverApproved = 0;
      let AwaitingApproval = 0;
      let Approved = 0;
      let NoShow = 0;
      let Cancelled = 0;
      let Completed = 0;
      let Active = 0;
      let NotCheckedIn = 0;
      let CheckedIn = 0;
      let CheckedOut = 0;

      const visitsToday = response.filter((visit) => 
      visit.Visit_Start_Date.toLocaleString(DateTime.DATE_SHORT) == DateTime.now().toLocaleString(DateTime.DATE_SHORT));

      for (let i = 0; i < visitsToday.length; i++)
      {
        if (visitsToday[i].Visit_IsApproved === 0 && visitsToday[i].Visit_IsDenied === 1)
        {
          Denied = Denied + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 0 && (visitsToday[i].Visit_IsNoShow === 1 || visitsToday[i].Visit_IsCancelled === 1) && visitsToday[i].Visit_IsDenied === 0)
        {
          NeverApproved = NeverApproved + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 0 && visitsToday[i].Visit_IsNoShow === 0 && visitsToday[i].Visit_IsDenied === 0 && visitsToday[i].Visit_IsCancelled === 0)
        {
          AwaitingApproval = AwaitingApproval + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1)
        {
          Approved = Approved + 1;
        }

        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsNoShow === 1)
        {
          NoShow = NoShow + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 0 && visitsToday[i].Visit_IsCancelled === 1)
        {
          Cancelled = Cancelled + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCheckedOut === 1)
        {
          Completed = Completed + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCancelled === 0 && visitsToday[i].Visit_IsNoShow === 0 && visitsToday[i].Visit_IsCheckedOut === 0)
        {
          Active = Active + 1;
        }


        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsNoShow === 0 && visitsToday[i].Visit_IsCheckedIn === 0 && visitsToday[i].Visit_IsCheckedOut === 0)
        {
          NotCheckedIn = NotCheckedIn + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCheckedOut === 0 && visitsToday[i].Visit_IsCheckedIn === 1)
        {
          CheckedIn = CheckedIn + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCheckedOut === 1 && visitsToday[i].Visit_IsCheckedIn === 0)
        {
          CheckedOut = CheckedOut + 1;
        }
      }

      let totalDonut1 = Denied + NeverApproved + AwaitingApproval + Approved;
      let totalDonut2 = NoShow + Cancelled + Completed + Active;
      let totalDonut3 = NotCheckedIn + CheckedIn + CheckedOut;

      tempPie1.push({ name: this.labels.HubLabelDeniedLabel, value: Math.round((Denied / totalDonut1) * 100), count: Denied })
      tempPie1.push({ name: this.labels.HubLabelNeverApproved, value: Math.round((NeverApproved / totalDonut1) * 100), count: NeverApproved })
      tempPie1.push({ name: this.labels.HubTabAwaitingApproval, value: Math.round((AwaitingApproval / totalDonut1) * 100), count: AwaitingApproval })
      tempPie1.push({ name: this.labels.HubLabelApproved, value: Math.round((Approved / totalDonut1) * 100), count: Approved })

      tempPie2.push({ name: this.labels.HubLabelNoShow, value: Math.round((NoShow / totalDonut2) * 100), count: NoShow })
      tempPie2.push({ name: this.labels.HubLabelCancelled, value: Math.round((Cancelled / totalDonut2) * 100), count: Cancelled })
      tempPie2.push({ name: this.labels.HubLabelCompleted, value: Math.round((Completed / totalDonut2) * 100), count: Completed })
      tempPie2.push({ name: this.labels.HubLabelActive, value: Math.round((Active / totalDonut2) * 100), count: Active })

      tempPie3.push({ name: this.labels.HubLabelNotCheckedIn, value: Math.round((NotCheckedIn / totalDonut3) * 100), count: NotCheckedIn })
      tempPie3.push({ name: this.labels.HubLabelCheckedIn, value: Math.round((CheckedIn / totalDonut3) * 100), count: CheckedIn })
      tempPie3.push({ name: this.labels.HubLabelCheckedOut, value: Math.round((CheckedOut / totalDonut3) * 100), count: CheckedOut })

      this.setState({
        dataPie1: tempPie1,
        dataPie1Value: totalDonut1,
        dataPie2: tempPie2,
        dataPie2Value: totalDonut2,
        dataPie3: tempPie3,
        dataPie3Value: totalDonut3
      })
    }
    catch
    {

    }
    this.setState({ isLoading: false });
  }

  onBarClick = (e: any) =>
  {
    if (e !== null)
    {
      let bardate = " - " + e.activeLabel;
      if (this.state.selectedDate === bardate)
      {
        this.setState({
          selectedDate: ""
        })
      } 
      else
      {
        let piedata = this.state.donutChartData;
        let tempPie1: Array<IPie> = [];
        let tempPie2: Array<IPie> = [];
        let tempPie3: Array<IPie> = [];
        let Denied = 0;
        let NeverApproved = 0;
        let AwaitingApproval = 0;
        let Approved = 0;
        let NoShow = 0;
        let Cancelled = 0;
        let Completed = 0;
        let Active = 0;
        let NotCheckedIn = 0;
        let CheckedIn = 0;
        let CheckedOut = 0;

        for (let i = 0; i < piedata.length; i++)
       {
         let data: Visit = piedata[i];
         let dateFormatting = data.Visit_Start_Date.toLocaleString(DateTime.DATE_SHORT)
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && data.Visit_IsDenied === 1)
         {
           Denied = Denied + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && (data.Visit_IsNoShow === 1 || data.Visit_IsCancelled === 1) && data.Visit_IsDenied === 0)
         {
           NeverApproved = NeverApproved + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && data.Visit_IsNoShow === 0 && data.Visit_IsDenied === 0 && data.Visit_IsCancelled === 0)
         {
           AwaitingApproval = AwaitingApproval + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1)
         {
           Approved = Approved + 1;
         }


         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsNoShow === 1)
         {
           NoShow = NoShow + 1;
         }
         if (e.activeLabel === dateFormatting && e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && data.Visit_IsCancelled === 1)
         {
           Cancelled = Cancelled + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCheckedOut === 1)
         {
           Completed = Completed + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCancelled === 0 && data.Visit_IsNoShow === 0 && data.Visit_IsCheckedOut === 0)
         {
           Active = Active + 1;
         }


         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsNoShow === 0 && data.Visit_IsCheckedIn === 0 && data.Visit_IsCheckedOut === 0)
         {
           NotCheckedIn = NotCheckedIn + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCheckedOut === 0 && data.Visit_IsCheckedIn === 1)
         {
           CheckedIn = CheckedIn + 1;
         }
         if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCheckedOut === 1 && data.Visit_IsCheckedIn === 0)
         {
           CheckedOut = CheckedOut + 1;
         }
       }
 
       let totalDonut1 = Denied + NeverApproved + AwaitingApproval + Approved;
       let totalDonut2 = NoShow + Cancelled + Completed + Active;
       let totalDonut3 = NotCheckedIn + CheckedIn + CheckedOut;
       tempPie1.push({ name: this.labels.HubLabelDeniedLabel, value: Math.round((Denied / totalDonut1) * 100), count: Denied })
       tempPie1.push({ name: this.labels.HubLabelNeverApproved, value: Math.round((NeverApproved / totalDonut1) * 100), count: NeverApproved })
       tempPie1.push({ name: this.labels.HubTabAwaitingApproval, value: Math.round((AwaitingApproval / totalDonut1) * 100), count: AwaitingApproval })
       tempPie1.push({ name: this.labels.HubLabelApproved, value: Math.round((Approved / totalDonut1) * 100), count: Approved })

       tempPie2.push({ name: this.labels.HubLabelNoShow, value: Math.round((NoShow / totalDonut2) * 100), count: NoShow })
       tempPie2.push({ name: this.labels.HubLabelCancelled, value: Math.round((Cancelled / totalDonut2) * 100), count: Cancelled })
       tempPie2.push({ name: this.labels.HubLabelCompleted, value: Math.round((Completed / totalDonut2) * 100), count: Completed })
       tempPie2.push({ name: this.labels.HubLabelActive, value: Math.round((Active / totalDonut2) * 100), count: Active })

       tempPie3.push({ name: this.labels.HubLabelNotCheckedIn, value: Math.round((NotCheckedIn / totalDonut3) * 100), count: NotCheckedIn })
       tempPie3.push({ name: this.labels.HubLabelCheckedIn, value: Math.round((CheckedIn / totalDonut3) * 100), count: CheckedIn })
       tempPie3.push({ name: this.labels.HubLabelCheckedOut, value: Math.round((CheckedOut / totalDonut3) * 100), count: CheckedOut })

        this.setState({
          dataPie1: [],
          dataPie1Value: 0,
          dataPie2: [],
          dataPie2Value: 0,
          dataPie3: [],
          dataPie3Value: 0,
        }, () =>
        {
          this.setState({
            dataPie1: tempPie1,
            dataPie1Value: totalDonut1,
            dataPie2: tempPie2,
            dataPie2Value: totalDonut2,
            dataPie3: tempPie3,
            dataPie3Value: totalDonut3,
            selectedDate: bardate
          })
        })
        }
      }
    
  }

  render()
  {

    return (
      <>
          {(this.state.isLoading) && <Spinner />}
          {(
            <div className="page-height-exct-header">
            <div className="rightPanel-main-content">
                <div className="chart-panel-section1">
                  <ChartPanelInfo
                    chartHeading={this.labels.HubLabelVisitorsOverview}
                    iconSrc="/images/Sidebar_Icons/Light_theme/Visitor Management.svg"
                  />
                  <div className="topchart">
                    {this.state.dataGraph.length !== 0 &&
                      <BarChart data={this.state.dataGraph} onClick={this.onBarClick} height={400} width={this.state.widthchart} >
                        <CartesianGrid vertical={false} />
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" label={{ value: this.labels.HubLabelVisitorLevel, angle: -90, position: 'insideLeft', fill: 'var(--ui-text)' }} />
                        <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                        <Legend />
                        <Bar dataKey="Denied" name={this.labels.HubLabelDeniedLabel} stackId="a" fill="#9B59B6" />
                        <Bar dataKey="NeverApproved" name={this.labels.HubLabelNeverApproved} stackId="a" fill="#F44336" />
                        <Bar dataKey="AwaitingApproval" name={this.labels.HubTabAwaitingApproval} stackId="a" fill="#FF7913" />
                        <Bar dataKey="Approved" name={this.labels.HubLabelApproved} stackId="a" fill="#2962FF" />
                      </BarChart>
                    }
                  </div>
                </div>

                <div className="chart-panel-section2 justify-content-between">
                    <PieChartCard dataPie={this.state.dataPie1} dataPieValue={this.state.dataPie1Value} colorArray={this.state.COLORS1} label={this.labels.HubLabelVisitApprovalStatus} selectedDate={this.state.selectedDate}  />
                    <PieChartCard dataPie={this.state.dataPie2} dataPieValue={this.state.dataPie2Value} colorArray={this.state.COLORS2} label={this.labels.HubLabelApprovedVisits} selectedDate={this.state.selectedDate} />
                    <PieChartCard dataPie={this.state.dataPie3} dataPieValue={this.state.dataPie3Value} colorArray={this.state.COLORS3} label={this.labels.HubLabelCheckinCheckoutStatusLabel} selectedDate={this.state.selectedDate}  />
                </div>
            </div>
            </div>
          )}
      </>
    );
  }
}

export default Trends;
