import { Grid } from '@mui/material';
import { Component } from 'react';
import VisitorsTodayChartWidget from './VisitorsTodayChartWidget';
import NewVisitorWidget from './NewVisitorWidget';
import VisitorListWidget from './VisitorListWidget';
import { DateTime } from 'luxon';
import { appContext } from '../../../../AppContext';
import { VisitFilter, VisitId, VisitShort } from '../../../../Providers.Api/Visits/VisitsRepository';
import { RouteComponentProps } from 'react-router';
import ErrorIcon from '@mui/icons-material/Error';
import { Icons } from '../../../../Common/AllsvgIcons';
import IbssSvgIcon from '../../../../Components/uicomponents/IbssSvgIcon';
import { ODataQuery } from '../../../../Providers.Api/ODataQuery';

class VisitorDashboard extends Component<RouteComponentProps, IState>
{
    private get visitsService() { return appContext().visitsService; }
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    constructor(props: RouteComponentProps)
    {
        super(props);
        this.state =
        {
            visits: [],
            buildingId: this.appState.buildingId,
            fetchingVisitData: false,
            visitorsAwaitingApproval: 0
        };
    };

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme, buildingId: i.buildingId }));
        this.getVisitData();
    }

    public componentDidUpdate(prevProps: RouteComponentProps, prevState: IState): void
    {
        if (prevState.buildingId !== this.state.buildingId)
        {
            this.getVisitData();
            this.props.history.push(this.props.match.path.replace(":buildingid", this.state.buildingId.toString()));
        }
    }

    private async getVisitData(): Promise<void>
    {
        this.setState({ fetchingVisitData: true });

        const query = new ODataQuery({
            nodeId: this.state.buildingId,
            select: VisitShort,
            filter: new VisitFilter({
                minStartDate: DateTime.now().startOf('day').setZoneByNode(this.state.buildingId),
                maxStartDate: DateTime.now().plus({ days: 1 }).startOf('day').setZoneByNode(this.state.buildingId),
            }),
        });

        const visits = await this.visitsService.getMany(query);
        visits.value.sort((a, b) => DateTime.fromISO(a.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true) > DateTime.fromISO(b.Visit_Start_Date).offsetTimeByNode(this.state.buildingId, true) ? 1 : -1)
        this.setState({ visits: visits.value, fetchingVisitData: false })
        this.getVisitorsAwaitingApproval();
    }

    private async getVisitorsAwaitingApproval(): Promise<void>
    {
        const query = new ODataQuery({
            nodeId: this.state.buildingId,
            select: VisitId,
            filter: new VisitFilter({
                minStartDate: DateTime.now().startOf('day').setZoneByNode(this.state.buildingId),
                maxStartDate: DateTime.now().plus({ days: 7 }).startOf('day').setZoneByNode(this.state.buildingId),
                status: 'Awaiting Approval',
            }),
        });

        const visits = await this.visitsService.getMany(query);
        this.setState({ visitorsAwaitingApproval: visits.value.length })
    }

    public render(): JSX.Element
    {
        return (
            <>
                {/* <div className="m-3" style={{ overflow: 'auto' }}> */}
                <div className="page-height-exct-header">
                    <div className='rightPanel-main-content '>
                        <Grid container spacing={2}>
                            <Grid item xl={4} xs={5} spacing={2}>
                                <VisitorsTodayChartWidget visits={this.state.visits} />
                                <div className='mt-1'>
                                    <NewVisitorWidget fetchVisits={() => this.getVisitData()} />
                                </div>
                                {
                                    this.state.visitorsAwaitingApproval > 0 &&
                                    <div className='d-flex' onClick={() => this.props.history.push("/operational-services-visitors/" + this.state.buildingId.toString())} style={{ cursor: 'pointer', alignItems: 'center', backgroundColor: '#FFBBBF', borderRadius: '8px', marginTop: '15px', padding: '2px 10px', justifyContent: 'space-between' }}>
                                        <div style={{ alignItems: 'center' }} className='d-flex'>
                                            <div style={{ color: '#dd362b', marginTop: '-3px' }}>
                                                <ErrorIcon color='inherit' sx={{ marginTop: '10px', marginRight: '8px' }} />
                                            </div>
                                            <div>
                                                {this.state.visitorsAwaitingApproval} {this.labels.HubLabelVisitorsAwaitingApproval}
                                            </div>
                                        </div>
                                        <IbssSvgIcon fontSize='small' className="mr-2">
                                            {Icons.RightArrowIcon}
                                        </IbssSvgIcon>
                                    </div>
                                }
                            </Grid>
                            <Grid item xl={8} xs={7}>
                                <VisitorListWidget fetchVisits={() => this.getVisitData()} visits={this.state.visits} loading={this.state.fetchingVisitData} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

export default VisitorDashboard;

export interface IState
{
    visits: VisitShort[];
    buildingId: number;
    fetchingVisitData: boolean
    visitorsAwaitingApproval: number;
}