import { Component } from 'react';
import IbssDialog from '../uicomponents/IbssDialog';
import { appContext } from '../../AppContext';
import EquipmentList from './EquipmentList';
import EquipmentSummary from './EquipmentSummary';
import { Box, Divider } from '@mui/material';
import { getFloorNameUsingFloorAndBuildingId } from '../../Common/Helper';
import { IUpdateEquipmentRequest, IUpdateEquipmentResponse, IEquipmentDetailsResponse } from '../../Providers.Api/Models';
import { AxiosResponse } from 'axios';
import apis from '../../Providers.Api/apis';
import { Constants } from '../../Common/Constants';
import { DateTime } from 'luxon';

class EquipmentsDialog extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get buildingId(): number { return parseInt(this.props.buildingId) }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            bookedEquipment: [], // equipment already booked by user
            equipments: [], // all equipment
            selectedEquipmentIds: [], // clicked equipment list items.
            isLoading: false,
            hearingAidsBooked: 0,
            presentationAidsBooked: 0,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        if(this.props.floorId && this.props.bookingId)
        {
            this.getBookedParties(this.props.floorId, this.props.bookingId);
            await this.getEquipment();
        }
    }

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if(prevProps.bookingId !== this.props.bookingId && this.props.bookingId)
        {
            this.getBookedParties(this.props.floorId, this.props.bookingId);
            await this.getEquipment();
        }
        
        if(prevProps.show !== this.props.show && this.props.bookingId)
        {
            this.getBookedParties(this.props.floorId, this.props.bookingId);
            await this.getEquipment();
        }

        if(prevState.hearingAidsBooked !== this.state.hearingAidsBooked || prevState.presentationAidsBooked !== this.state.presentationAidsBooked)
        {
            if(this.props.bookedEquipmentChanged)
            {
                this.props.bookedEquipmentChanged(this.state.hearingAidsBooked, this.state.presentationAidsBooked);
            }
        }
    }

    private async getEquipment(): Promise<void>
    {
        if (this.props.equipmentType == "" ||
            this.props.bookingStart == "" ||
            this.props.bookingEnd == "" ||
            this.props.buildingId == "")
        {
            return;
        }

        const updatedEquipment: IUpdateEquipmentRequest =
        {
            EquipmentType: this.props.equipmentType,
            StartDate: DateTime.fromISO(this.props.bookingStart).setZoneByNode(this.buildingId).toISO(),
            EndDate: DateTime.fromISO(this.props.bookingEnd).setZoneByNode(this.buildingId).toISO(),
        };
        
        const buildingId = this.props.buildingId;

        try
        {
            this.setState({isLoading: true});
            // get available equipment
            const updateResponse: AxiosResponse<IUpdateEquipmentResponse[]> = await apis.updateEquipment(buildingId, updatedEquipment);
            const equipments = updateResponse.data.filter(i=> i.Equip_Type===this.props.equipmentType).map(i => Equipment.fromUpdateEquipmentResponse(i, buildingId));

            // get booked equipment
            const bookedEquipmentList: Equipment[] = [];

            for (let i = 0; i < this.state.bookedEquipment.length; i++)
            {
                const bookedEquipment: AxiosResponse<IEquipmentDetailsResponse> = await apis.getEquipmentDetails(1, this.state.bookedEquipment[i].Booking_Resource_Id);
                const bookedEquipmentView = Equipment.fromEquipmentDetailsResponse(bookedEquipment.data, buildingId.toString());
                if(bookedEquipmentView.equipmentType === this.props.equipmentType)
                {
                    // find index of equipment with same equipmentId as bookedEquipmentView so as to replace it with bookedEquipmentView in the equipments array.
                    const duplicateEquipmentIndex = equipments.findIndex(i => i.equipmentId === bookedEquipmentView.equipmentId)
                    if(duplicateEquipmentIndex !== -1)
                    {                       
                        // if duplicate equipment Id, replace it
                        equipments[duplicateEquipmentIndex] = bookedEquipmentView;
                    }
                    else
                    {
                        // else if not duplicate equipment Id, add it.
                        equipments.push(bookedEquipmentView)
                    }
                    bookedEquipmentList.push(bookedEquipmentView);
                }
            }

            // calculate the number of booked hearing aids & presentation aids.
            const hearingAids = bookedEquipmentList.filter(i => i.equipmentType === "HearingAid");
            const presentationAids = bookedEquipmentList.filter(i => i.equipmentType === "PresentationAid");
           
            // set state with new data;
            this.setState({
                equipments: equipments,
                bookedEquipment: this.state.bookedEquipment.filter(i => bookedEquipmentList.find(j => j.equipmentId === i.Booking_Resource_Id)), // filter bookedEquipment to be consistent with entries in BookedEquipmentList 
                selectedEquipmentIds: this.state.selectedEquipmentIds.filter(i => equipments.find(j => j.equipmentId === i)), // limit selectedEquipmentIds to the options in state.equipments.
                hearingAidsBooked: hearingAids.length,
                presentationAidsBooked: presentationAids.length,
            });
        }
        catch
        {
            return;
        }
        finally
        {
            this.setState({isLoading: false});
        }
    }


    private handleSelectEquipment(equipmentId: string): void
    {
        // if equipmentId already selected, remove it from state.selectedEquipmentIds, else, add new equipmentId to state.selectedEquipmentIds.
        if(this.state.selectedEquipmentIds.includes(equipmentId))
        {
            this.setState((prevState)=>({selectedEquipmentIds: prevState.selectedEquipmentIds.filter(id => id !== equipmentId)}));
        }
        else
        {
            this.setState((prevState)=>({selectedEquipmentIds: [...prevState.selectedEquipmentIds, equipmentId]}));
        }
    }

    private getBookedParties(nodeId: number, bookingId: string): void
    {
        const bookedEquipment: IBookedEquipment[] = [];
        this.apiClient.bookingParties.getByBookingId(nodeId, bookingId).then((res) =>
        {    
            for (let i = 0; i < res.length; i++)
            {
                const floorName = getFloorNameUsingFloorAndBuildingId(this.props.buildingId, res[i].Node_Id);
                bookedEquipment.push({...res[i], 'Floor_Name': floorName});
            }

            this.setState({ 
                bookedEquipment: bookedEquipment, 
                selectedEquipmentIds: res.map((x: { Booking_Resource_Id: string; }) => x.Booking_Resource_Id),
            });
        });
    }

    public render(): JSX.Element
    {
        return (
            <IbssDialog
                aria-modal="true"
                aria-label="cost code modal"
                fullWidth
                maxWidth={'md'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelAddYourEquipment}</label>
                    </>
                }
                content=
                {
                    <Box>
                    <EquipmentList
                        bookingId={this.props.bookingId}
                        bookingStart={this.props.bookingStart}
                        bookingEnd={this.props.bookingEnd}
                        buildingId={this.props.buildingId}
                        equipmentType={this.props.equipmentType}

                        bookedEquipment={this.state.bookedEquipment}
                        equipments={this.state.equipments}
                        selectedEquipmentIds={this.state.selectedEquipmentIds}
                        selectEquipment={(equipmentId: string) => this.handleSelectEquipment(equipmentId)}
                        isLoading={this.state.isLoading}
                    />
                    <Divider sx={{mt: 2, mb: 2}}/>
                    <EquipmentSummary
                        bookedEquipment={this.state.bookedEquipment}
                        bookingName={this.props.bookingName}
                        bookingStart={this.props.bookingStart}
                        bookingEnd={this.props.bookingEnd}
                        bookingId={this.props.bookingId}
                        buildingId={this.props.buildingId}
                        disabled={this.props?.disabled}
                        equipments={this.state.equipments}
                        equipmentType={this.props.equipmentType}
                        floorId={this.props.floorId}
                        onClose={this.props.onClose}
                        selectedEquipmentIds={this.state.selectedEquipmentIds}
                        spaceId={this.props.spaceId}
                        spaceName={this.props.spaceName}
                    />
                    </Box>
                }
            />
        )
    }
}

export default EquipmentsDialog;

export interface IProps
{
    show: boolean,
    onClose: () => void,
    bookingId: string,
    bookingStart: string,
    bookingEnd: string,
    bookingName: string,
    buildingId: string,
    disabled?: boolean,
    equipmentType: string,
    floorId: number,
    spaceId: string,
    spaceName: string,
    bookedEquipmentChanged?: (hearingAids: number, presentationAids: number) => void,
}

export interface IState
{
    bookedEquipment: IBookedEquipment[],
    equipments: Equipment[],
    selectedEquipmentIds: string[],
    isLoading: boolean,
    hearingAidsBooked: number,
    presentationAidsBooked: number,
}

export interface IBookedEquipment
{
    Booking_End: string;
    Booking_Id: string;
    Booking_Participant_CheckedIn: number;
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Participant_Type: number;
    Booking_Resource_Id: string;
    Booking_Start: string;
    Booking_Visitor: number;
    Node_Id: number;
    Record_Id: string;
    Floor_Name: number;
}

export class Equipment
{
    public static fromUpdateEquipmentResponse(response: IUpdateEquipmentResponse, buildingId: string): Equipment
    {
        let equipment = new Equipment();
        equipment.nodeId = response.Node_Id;
        equipment.equipmentId = response.Equip_Id;
        equipment.equipmentName = response.Equip_Name;
        equipment.equipmentType = response.Equip_Type;
        equipment.equipmentClass = response.Equip_Class;
        equipment.imageUrl = ((response.ImageURI || Constants.imagePaths.get(response.Equip_Class)) ?? "");
        equipment.bookingResourceId = response.Booking_Resource_Id;
        equipment.metaLocZone = response.Meta_Loc_Zone;
        equipment.floorName = getFloorNameUsingFloorAndBuildingId(buildingId, response.Node_Id);
        equipment.backgroundColor = "";
        return equipment;
    }

    public static fromEquipmentDetailsResponse(response: IEquipmentDetailsResponse, buildingId: string): Equipment
    {
        let equipment = new Equipment();
        equipment.nodeId = response.Node_Id;
        equipment.equipmentId = response.Equip_Id;
        equipment.equipmentName = response.Equip_Name;
        equipment.equipmentType = response.Equip_Type;
        equipment.equipmentClass = response.Equipment_Class;
        equipment.imageUrl = ((response.ImageURI || Constants.imagePaths.get(response.Equipment_Class)) ?? "");
        equipment.bookingResourceId = response.Booking_Resource_Id;
        equipment.metaLocZone = response.Meta_Loc_Zone;
        equipment.floorName = getFloorNameUsingFloorAndBuildingId(buildingId, response.Node_Id);
        equipment.backgroundColor = "lightGrey";
        return equipment;
    }

    public nodeId = 0;
    public equipmentId = "";
    public equipmentName = "";
    public equipmentType = "";
    public equipmentClass = "";
    public imageUrl = "";
    public bookingResourceId = "";
    public metaLocZone = "";
    public floorName = "";
    public backgroundColor = "";
}