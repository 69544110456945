import React, { Component } from 'react'
import { connect } from 'react-redux';
import LoadingOverlay from '../../../../Components/LoadingOverlay'
import BuildingSpecificNotification from './BuildingSpecific';
import VisitorNotification from './VisitorNotification';
import { Grid } from '@mui/material';
import IbssHorizontalTabs, { TabsParameters } from '../../../../Components/uicomponents/IbssTabs';
import TaskNotification from './TaskNotification';
import BookingNotification from './BookingNotification';
import BuildingIcon from '../../../../Components/uicomponents/customMaterialIcons/BuildingIcon';
import NotificationIcon from '../../../../Components/uicomponents/customMaterialIcons/NotificationIcon';
import TaskIcon from '../../../../Components/uicomponents/customMaterialIcons/TaskIcon';
import CalendarIcon from '../../../../Components/uicomponents/customMaterialIcons/CalendarIcon';
import RightArrowIcon from '../../../../Components/uicomponents/customMaterialIcons/RightArrowIcon';
import { appContext } from '../../../../AppContext';
import IbssSvgIcon from '../../../../Components/uicomponents/IbssSvgIcon';
import { Icons } from '../../../../Common/AllsvgIcons';
import { UPDATE_MAIN_PAGE_TITLE } from '../../../../app/constants';


class Notification extends Component<any, any> {
    private labels = appContext().labels;

    state = {
        isLoading: false,
    }

    render() {
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelUserPrefNotifications });

        const listTabs: TabsParameters[] = [
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.VisitorIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelVisitorNotification}
                        </div>
                    </div>
                ),
                components: <VisitorNotification />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.taskIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelTaskNotification}
                        </div>
                    </div>
                ),
                components: <TaskNotification />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.calenderIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelBookingNotification}
                        </div>
                    </div>
                ),
                components: <BookingNotification />,
            },
            {
                label: (
                    <div className='jc-ai-center filter-modal-label'>
                        <div className="mr-8px">
                            <IbssSvgIcon>
                                {Icons.building}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-8px">
                            {this.labels.HubLabelBuildingSpecific}
                        </div>
                    </div>
                ),
                components: <BuildingSpecificNotification />,
            },
        ];
        return (
            <>
                {this.state.isLoading && <LoadingOverlay />}
                <div className="page-height-exct-header">
                        <div className='rightPanel-main-content'>
                            <Grid container gap={15} columns={16}>
                                <IbssHorizontalTabs
                                    className='ibss-vertical-tab'
                                    orientation='vertical'
                                    tabs={listTabs}
                                    boxwidth="344px"
                                    sx={{
                                        fontSize: "18px", marginTop: "25px", fontFamily: "Source Sans Pro", backgroundColor: `background.paper`, display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                />
                            </Grid>
                        </div>
                </div>
            </>
        )
    }
};

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
    };
};

export default connect(mapStateToProps)(Notification);
