import React from "react";
import { Component } from "react";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssOnBoardingPopup from "../../../Components/uicomponents/IbssOnboardingPopup";
import OnBoardingSetUp from "./OnBoardingSetUp";
import OnBoardingBuildingPreferences from "./OnBoardingBuidingPreferences";
import OnBoardingFloorPreferences from "./OnBoardingFloorPreferences";
import OnBoardingWorkingDay from "./OnBoardingWorkingDay";
import OnBoardingSetUpaPIN from "./OnBoardingSetUpaPIN";
import OnBoardingColleagueFinding from "./OnBoardingColleagueFinding";
import OnBoardingNotified from "./OnBoardingNotified";
import { InputOption } from "../../../Components/uicomponents/InputSelectBox";
import Helper from "../../../Common/Helper";
import LoadingOverlay from "../../../Components/LoadingOverlay";
import { getUserPrefrenceJosn } from "../../../Common/UserPreferenceJSON";
import { IUserPreferences } from "../../../Providers.Api/UserPreferenceRepository";
import { IbssComponent } from "../../../Components/IbssComponent";

export default class OnBoarding extends IbssComponent<IOnBoarding, State>
{

    private get labels() { return appContext().labels; }
    private get alert() { return appContext().alert; }
    private get local() { return appContext().localStorageProvider; }
    private get userPreferences() { return appContext().userPreferencesService; }
    private maxSteps = 7;

    constructor(props: IOnBoarding) {
        super(props);

        this.state = new State();
    }

    public async componentDidMount(): Promise<void>
    {
        this.setState({ isOpen: this.props.open });

        // building and floor option
        const buildingOption = Helper.makeDropdownOptions(Helper.getAllBuildingsData(), "Name", "Node_Id");

        // get user preferences
        const userPreferences = this.local.getUserPreferences();
        const buildingFirstOption = parseInt(buildingOption[0].value) as number | null
        const buildingId = userPreferences?.SearchPrefs?.DefaultBuilding == null || userPreferences?.SearchPrefs?.DefaultBuilding == 1 ? buildingFirstOption : userPreferences?.SearchPrefs?.DefaultBuilding;
        const floorOptions = Helper.makeDropdownOptions(Helper.getFloorsFromSelectedBuilding(buildingId), "Node_Name", "Node_Id")

        await this.setStateAsync({
            PIN: userPreferences?.SecurityPrefs?.Pin,
            dataPrivacy: userPreferences?.PrivacyPrefs?.ShareLocation === 0 ? false : true,
            userPrefrences: userPreferences,
        });

        this.setState((prevState)=> (
            {
                // configure buildings input
                buildings: 
                {
                    ...prevState.buildings,
                    onChange: (value) => this.buildingChanged(value), 
                    options: buildingOption,
                    value: buildingId,

                },
                // configure floors input
                floors: 
                {
                    ...prevState.floors,
                    options: floorOptions,
                    onChange: (value) => this.floorChanged(value),
                    value: this.getDefaultFloor(buildingId) ?? parseInt(floorOptions[0].value),
                }
            }
        ));
    }

    private getDefaultFloor(buildingId: number | null): number | null
    {
        return this.state.userPrefrences.Nodes.filter(i => i.NodeId == buildingId ?? -1)[0]?.DefaultFloor;
    }

    private buildingChanged(option: any): void
    {
        const buildingId = option.target.value as number;
        this.state.buildings.value = buildingId;
        this.state.floors.options = Helper.makeDropdownOptions(Helper.getFloorsFromSelectedBuilding(buildingId), "Node_Name", "Node_Id");
        this.state.floors.value = this.getDefaultFloor(buildingId) ?? parseInt(this.state.floors.options[0].value);
        this.setState({ buildings: this.state.buildings, floors: this.state.floors });
    }

    private floorChanged(option: any): void {
        this.state.floors.value = option.target.value;
        this.setState({ floors: this.state.floors });
    }

    public incrementActiveStep(): void 
    {
        this.setState(prevState => 
        {
            return { activeStep: prevState.activeStep + 1 }
        });
    }

    public decrementActiveStep(): void
    {
        this.setState(prevState => {
            return { activeStep: prevState.activeStep - 1 }
        });
    }

    handleSubmitBuilding = async () => {
        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            SearchPrefs:
            {
                DefaultBuilding: this.state.buildings.value,
                DefaultSearchResults: this.state.userPrefrences.SearchPrefs.DefaultSearchResults,
            },
        };

        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }


    handlesubmitFloor = async () => {
        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            Nodes:
                [
                    {
                        NodeId: this.state.buildings.value,
                        DefaultFloor: this.state.floors.value,
                        DefaultZone: null
                    }
                ]
        };

        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    public PinChange = (value: string) => {
        this.setState({ PIN: value })
    }

    handleSubmitSetPIN = async () => {
        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            SecurityPrefs: {
                Pin: this.state.PIN,
            }
        }
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    public closingPopup(): void {
        this.props.closePopup();
    }

    public change = (e: any) => {
        this.setState({
            dataPrivacy: e.target.checked
        });
    };

    public submitDataPrivacy = async () => {
        const payload: IUserPreferences = {
            ...this.state.userPrefrences,
            PrivacyPrefs:
            {
                ShareLocation: this.state.dataPrivacy === false ? 0 : 1,
            },

        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    public render(): JSX.Element {
        const popups = [
            {
                image: "onb-1 1",
                content: <OnBoardingSetUp incrementActiveStep={this.incrementActiveStep.bind(this)} />,
                header: <></>

            },
            {
                image: "building selector",
                content: (
                    <OnBoardingBuildingPreferences
                        incrementActiveStep={this.incrementActiveStep.bind(this)}
                        buildings={this.state?.buildings}
                        userPrefrences={this.state.userPrefrences}
                    />
                ),
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div>
                    {/* <div className="modal-headerIco-lg" onClick={() => this.handleSubmitBuilding()} >&times;</div> */}
                </div>
                </>
            },
            {
                image: "floor preference",
                content: (
                    <OnBoardingFloorPreferences
                        incrementActiveStep={this.incrementActiveStep.bind(this)}
                        floors={this.state.floors}
                        selectedBuilding={this.state.buildings.value}
                        userPrefrences={this.state.userPrefrences}
                    />
                ),
                header: (
                    <>
                        <div className="d-flex justify-content-between">
                            <div
                                onClick={() => this.decrementActiveStep()}
                                className="fa fa-angle-left modal-headerIco-lg"
                            ></div>
                            {/* <div
                                className="modal-headerIco-lg"
                                onClick={() => this.handlesubmitFloor()}
                            >
                                &times;
                            </div> */}
                        </div>
                    </>
                )

            },
            {
                image: "your working day",
                content: (<OnBoardingWorkingDay
                    buildingId={this.state.buildings.value}
                    incrementActiveStep={this.incrementActiveStep.bind(this)}
                />),
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div> 
                    {/* <div className="modal-headerIco-lg" onClick={(e) => this.handleSubmitWorkingDay()} >&times;</div> */}
                </div>
                </>
            },
            {
                image: "pin setup",
                content: <OnBoardingSetUpaPIN
                    incrementActiveStep={this.incrementActiveStep.bind(this)}
                    PIN={this.state.PIN}
                    PinChange={this.PinChange.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />,
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div> 
                    {/* <div className="modal-headerIco-lg" onClick={() => this.handleSubmitSetPIN()}>&times;</div> */}
                </div></>
            },
            {
                image: "location services",
                content: <OnBoardingColleagueFinding
                    incrementActiveStep={this.incrementActiveStep.bind(this)}
                    dataPrivacy={this.state.dataPrivacy}
                    change={this.change.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />,
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div> 
                    {/* <div className="modal-headerIco-lg" onClick={() => this.submitDataPrivacy()} >&times;</div> */}
                </div></>
            },
            {
                image: "notification",
                content: <OnBoardingNotified
                    closePopup={this.closingPopup.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />,
                header: <><div className="d-flex justify-content-between">  <span onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></span>
                </div></>
            }
        ]

        return (<>

            {(this.state.isLoading || this.state.isLoadingToSave) && <LoadingOverlay />}
            <IbssOnBoardingPopup
                isOpen={this.props.open}
                header={popups[this.state.activeStep].header}
                maxSteps={this.maxSteps}
                activeStep={this.state.activeStep}
                image={popups[this.state.activeStep].image}
                content={popups[this.state.activeStep].content}
            />
        </>);

    }
}

class State {
    private get labels() { return appContext().labels; }
    public isLoadingToSave = false;
    public isLoading = false;
    public isOpen = false;
    public activeStep = 0;
    public PIN: string = "";
    public dataPrivacy = false;
    public buildings =
        {
            name: "DefaultBuilding",
            id: "DefaultBuilding",
            inputLabel: this.labels.dataUserPrefWorkplaceDefBuilding_L,
            options: new Array<InputOption>(),
            value: 0 as number | null,
            onChange: (value: string) => { }
        };
    public floors =
        {
            name: "DefaultFloor",
            id: "DefaultFloor",
            inputLabel: this.labels.dataUserPrefWorkplaceDefFloor_L,
            options: new Array<InputOption>(),
            value: 0 as number | null,
            onChange: (value: string) => { }
        };

    public userPrefrences = getUserPrefrenceJosn();
}

export interface IOnBoarding {
    open: boolean;
    closePopup: () => void;
}

