import { Component } from "react";
import IbssButton from "../uicomponents/IbssButton";

export default class MapButton extends Component<any, any>
{
    public render(): JSX.Element
    {
        let className = "btn-image" + (this.props.active ? " btn-active" : "");
        let style = { borderRadius: "4px", border: "solid var(--ui-text) 1px", ...this.props.style };

        return (
            <IbssButton className={className} style={style} onClick={this.props.onClick}>
            <svg width="auto" height="38" viewBox="0 0 58 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="56" height="36" rx="4" fill="none" />
                <path fillRule="evenodd" clipRule="evenodd" d="M26.4206 12.17C26.5358 12.1041 26.6764 12.1002 26.7951 12.1596L31.8992 14.7116L36.347 12.17C36.4699 12.0997 36.6209 12.1002 36.7433 12.1713C36.8657 12.2423 36.9411 12.3732 36.9411 12.5147V23.1029C36.9411 23.2454 36.8647 23.377 36.741 23.4477L32.1087 26.0947C31.9934 26.1606 31.8528 26.1645 31.7341 26.1051L26.63 23.5531L22.1822 26.0947C22.0593 26.165 21.9083 26.1645 21.7859 26.0934C21.6635 26.0224 21.5881 25.8915 21.5881 25.75V15.1618C21.5881 15.0193 21.6645 14.8877 21.7882 14.817L26.4206 12.17ZM26.63 12.9649L22.3823 15.3922V25.0658L26.4206 22.7582C26.5358 22.6923 26.6764 22.6884 26.7951 22.7478L31.8992 25.2998L36.147 22.8725V13.1989L32.1087 15.5065C31.9934 15.5724 31.8528 15.5763 31.7341 15.5169L26.63 12.9649Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M26.6178 12.1176C26.8371 12.1176 27.0148 12.2954 27.0148 12.5147V23.1029C27.0148 23.3222 26.8371 23.5 26.6178 23.5C26.3985 23.5 26.2207 23.3222 26.2207 23.1029V12.5147C26.2207 12.2954 26.3985 12.1176 26.6178 12.1176Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.9117 14.7647C32.131 14.7647 32.3088 14.9425 32.3088 15.1618V25.75C32.3088 25.9693 32.131 26.1471 31.9117 26.1471C31.6924 26.1471 31.5146 25.9693 31.5146 25.75V15.1618C31.5146 14.9425 31.6924 14.7647 31.9117 14.7647Z" style={{ fill: "var(--ui-text)" }} />
                <rect x="1" y="1" width="56" height="36" rx="4" strokeWidth="0" style={{ stroke: "var(--ui-text)" }} />
            </svg>
            </IbssButton>
        );
    }
}