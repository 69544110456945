import * as React from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import  Paper from '@mui/material/Paper';
import  { PaperProps }  from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { appContext } from '../../AppContext';
import defaultCustomerLogo from '../../Pages/Public/Login/CustomerLogo.png';
import { DateTime } from 'luxon';

class StyledPaper extends React.Component<PaperProps>{
    render(): JSX.Element
    {
        return <Paper
            square 
            elevation={0}
            sx={(theme)=>({
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: 'row',
                alignItems: 'center',

                background: theme.palette.mode === 'light' ? theme.palette.background.default : theme.palette.background.paper,
                opacity: '0.75',

                [theme.breakpoints.up(1052)]: { // media query for screen sizes 1052 pixels and above
                    width:'1052px',
                    maxWidth: '1052px', 
                    height: '80px',
                    padding: '10px 20px',
                    justifyContent: 'space-between',
                },

                [theme.breakpoints.down(1052)]: { // media query for screen sizes below 1052 pixels
                    width:'660px',
                    maxWidth: '660px', 
                    height: '80px',
                    padding: '10px 20px',
                    justifyContent: 'center', // change to having, on this Paper, just justifyContent: 'space-beween' once Privacy Policy link is provided.
                },        
            })}>
            {this.props.children}
        </Paper>
    }
}

class StyledItem extends React.Component<BoxProps>{
    // to contain the two logos on either side of the footer. Both has to disappear at a specified window width.
    render(): JSX.Element 
    {
        return <Box sx={(theme)=> ({
            padding: '0px 45px', 
            alignItems: 'center',
            [theme.breakpoints.down(1052)]: {
                display: 'none'
            },
        })}>{this.props.children}</Box>
    }
}

const spanStyle = {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
}

type footerProps = {
    customerLogoSrc?: string;
    customerLogAltText?: string;
}
class IbssFooter extends React.Component<footerProps & PaperProps> {
    private labels = appContext().labels;
    private appState = appContext().state;

    render(): JSX.Element{
        return (
            <StyledPaper>
                <StyledItem>
                    <img src={this.props?.customerLogoSrc ?? defaultCustomerLogo } alt={this.props?.customerLogAltText ?? "partner company logo"}/>
                </StyledItem>
                
                {/* <Box sx={{padding: '20px 44px' }}>
                    <Link href="#" underline="hover" display="inline" component="span" sx={{...spanStyle, color:"primary.main"}}>
                        {this.labels.HubLabelPrivacyPolicy}
                    </Link>
                </Box>*/}
                <Box sx={{padding: '20px 44px' }}>
                    <Typography display="inline" component="span" sx={{...spanStyle, color: (theme) => theme.palette.text.secondary}}>
                        {`${this.labels.HubLabelCopyRight} ${DateTime.now().year}`}
                    </Typography>
                </Box>
                <StyledItem>
                    <img src={this.appState.lightModeTheme? `/images/Logos/Iconics_logo_light.png` : `/images/Logos/Iconics_logo_dark.svg`} alt="Iconics company logo light version"/>
                </StyledItem>
            </StyledPaper>
        )
    }
}

export default IbssFooter;