import { Component } from "react";
import IbssButton from "../uicomponents/IbssButton";

export default class ListButton extends Component<any, any>
{
    public render(): JSX.Element
    {
        let className = "btn-image" + (this.props.active ? " btn-active" : "");
        let style = { borderRadius: "4px", border: "solid var(--ui-text) 1px", ...this.props.style };

        return (
            <IbssButton className={className} style={style} onClick={this.props.onClick} >
            <svg width="auto" height="38" viewBox="0 0 58 38" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <rect x="1" y="1" width="56" height="36" rx="4" fill="none" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.2354 11.9853C24.2354 11.766 24.4131 11.5882 24.6324 11.5882H36.8089C37.0282 11.5882 37.2059 11.766 37.2059 11.9853C37.2059 12.2046 37.0282 12.3823 36.8089 12.3823H24.6324C24.4131 12.3823 24.2354 12.2046 24.2354 11.9853Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5293 11.9853C20.5293 11.766 20.7071 11.5882 20.9264 11.5882H21.9852C22.2045 11.5882 22.3822 11.766 22.3822 11.9853C22.3822 12.2046 22.2045 12.3823 21.9852 12.3823H20.9264C20.7071 12.3823 20.5293 12.2046 20.5293 11.9853Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.2354 16.75C24.2354 16.5307 24.4131 16.3529 24.6324 16.3529H36.8089C37.0282 16.3529 37.2059 16.5307 37.2059 16.75C37.2059 16.9693 37.0282 17.1471 36.8089 17.1471H24.6324C24.4131 17.1471 24.2354 16.9693 24.2354 16.75Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5293 16.75C20.5293 16.5307 20.7071 16.3529 20.9264 16.3529H21.9852C22.2045 16.3529 22.3822 16.5307 22.3822 16.75C22.3822 16.9693 22.2045 17.1471 21.9852 17.1471H20.9264C20.7071 17.1471 20.5293 16.9693 20.5293 16.75Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.2354 21.5147C24.2354 21.2954 24.4131 21.1176 24.6324 21.1176H36.8089C37.0282 21.1176 37.2059 21.2954 37.2059 21.5147C37.2059 21.734 37.0282 21.9118 36.8089 21.9118H24.6324C24.4131 21.9118 24.2354 21.734 24.2354 21.5147Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5293 21.5147C20.5293 21.2954 20.7071 21.1176 20.9264 21.1176H21.9852C22.2045 21.1176 22.3822 21.2954 22.3822 21.5147C22.3822 21.734 22.2045 21.9118 21.9852 21.9118H20.9264C20.7071 21.9118 20.5293 21.734 20.5293 21.5147Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.2354 26.2794C24.2354 26.0601 24.4131 25.8824 24.6324 25.8824H36.8089C37.0282 25.8824 37.2059 26.0601 37.2059 26.2794C37.2059 26.4987 37.0282 26.6765 36.8089 26.6765H24.6324C24.4131 26.6765 24.2354 26.4987 24.2354 26.2794Z" style={{ fill: "var(--ui-text)" }} />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.5293 26.2794C20.5293 26.0601 20.7071 25.8824 20.9264 25.8824H21.9852C22.2045 25.8824 22.3822 26.0601 22.3822 26.2794C22.3822 26.4987 22.2045 26.6765 21.9852 26.6765H20.9264C20.7071 26.6765 20.5293 26.4987 20.5293 26.2794Z" style={{ fill: "var(--ui-text)" }} />
                <rect x="1" y="1" width="56" height="36" rx="4" strokeWidth="0" style={{ stroke: "var(--ui-text)" }} />
            </svg>
            </IbssButton>
        );
    }
}