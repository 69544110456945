import { Component } from "react";
import { appContext } from "../../AppContext";
import IbssSvgIcon from "../uicomponents/IbssSvgIcon";
import { Icons } from "../../Common/AllsvgIcons";
import IbssDialog from "../uicomponents/IbssDialog";
import IbssAutocomplete from "../uicomponents/IbssAutocomplete";
import { TextField, Box } from "@mui/material";
import IbssTextField from "../uicomponents/IbssTextField";
import IbssButtonRedo from "../uicomponents/IbssButton";
import { Button } from '@mui/material';
import { isEqual } from "lodash";
import UserPicker, * as UserPickerModule from "../Inputs/UserPicker/UserPicker";

export default class EmployeeOrVisitorPicker extends Component<IProps, IState>
{
    private labels = appContext().labels;

    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            showModal: false,
            isVisitor: false,
            noOnBehalfOfSelectedError: '',
            onBehalfOfLabel: '',
            nonVisitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "", displayName: "" },
            alreadyPresentError: '',
            visitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "" },
            isDisabled: false,
            errors: '',
            bookingAutoCheckin: 0,
            onBehalfOf: '',
            onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false }
        }
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        // if props.onBehalfOfData is defined and has changed.
        if(this.props.onBehalfOfData && !isEqual(prevProps?.onBehalfOfData, this.props?.onBehalfOfData))
        {
            this.setState({
                onBehalfOfData: this.props.onBehalfOfData,
                onBehalfOfLabel: this.props.onBehalfOfData.firstName,
            });
        }
    }

    private handleBookingForEmployee(): void
    {
        this.setState({
            isVisitor: false
        })
    }

    private handleBookingForVisitor(): void
    {
        this.setState({
            isVisitor: true,
        })
    }

    private async employeeChanged(user: UserPickerModule.IUser | null): Promise<void>
    {
        if (user)
        {
            this.setState({
                nonVisitorOnBehalfOfData: {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    company: user.company,
                    displayName: user.displayName
                },
                noOnBehalfOfSelectedError: ""
            })
        }
        else
        {
            this.setState({
                nonVisitorOnBehalfOfData: {
                    email: "",
                    firstName: "",
                    lastName: "",
                    company: "",
                    displayName: ""
                }
            })
        }
    }

    private async employeeTextChanged(text: string): Promise<void>
    {
        this.setState({ onBehalfOfLabel: text });
    }

    private bookingOnBehalfOfEmailChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        let errors: string = '';
        const pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!pattern.test(e.target.value))
        {
            // update error massage in errors.email  object
            errors = this.labels.HubLabelInvalidEmailAddress;;
        }
        // updated state for email errors
        if (errors === "")
        {
            this.setState({
                isDisabled: false
            })
        } else
        {
            this.setState({
                isDisabled: true
            })
        }
        this.setState({
            errors: errors,
        });

        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                email: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private bookingOnBehalfOfFirstNameChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                firstName: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private bookingOnBehalfOfLastNameChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                lastName: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private bookingOnBehalfOfCompanyChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                company: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    }

    private setChecked(e: React.ChangeEvent<HTMLInputElement>): void
    {
        if (e.target.name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 1)
        {
            this.setState({ bookingAutoCheckin: 0 });
        }
        if (e.target.name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 0)
        {
            this.setState({ bookingAutoCheckin: 1 });
        }
    };

    private async handleAddBookingFor(): Promise<void>
    {
        if ((this.state.nonVisitorOnBehalfOfData.email !== "" || this.state.visitorOnBehalfOfData.email !== "") && this.state.errors === "")
        {
            if (this.state.nonVisitorOnBehalfOfData.email !== "")
            {
                await this.setState({
                    onBehalfOf: this.state.nonVisitorOnBehalfOfData.email,
                    onBehalfOfLabel: this.state.nonVisitorOnBehalfOfData.firstName,
                    onBehalfOfData:
                    {
                        email: this.state.nonVisitorOnBehalfOfData.email,
                        firstName: this.state.nonVisitorOnBehalfOfData.firstName,
                        lastName: this.state.nonVisitorOnBehalfOfData.lastName,
                        company: this.state.nonVisitorOnBehalfOfData.company,
                        isVisitor: false,
                    }
                })
            }
            else
            {
                await this.setState({
                    onBehalfOf: this.state.visitorOnBehalfOfData.email,
                    onBehalfOfLabel: this.state.visitorOnBehalfOfData.firstName,
                    onBehalfOfData:
                    {
                        email: this.state.visitorOnBehalfOfData.email,
                        firstName: this.state.visitorOnBehalfOfData.firstName,
                        lastName: this.state.visitorOnBehalfOfData.lastName,
                        company: this.state.visitorOnBehalfOfData.company,
                        isVisitor: true,
                    }
                })
            }
            this.handleShowHideBookingForModal();
        }
        else
        {
            this.setState({ noOnBehalfOfSelectedError: this.labels.HubLabelNoOnBehalfSelcted });
        }

        this.props.onSubmit(this.state.onBehalfOf, this.state.onBehalfOfData, this.state.bookingAutoCheckin);
    }

    private async handleShowHideBookingForModal(): Promise<void>
    {
        await this.setState({
            showModal: !this.state.showModal,
        })
        this.setState(
            {
                isVisitor: false,
                nonVisitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "", displayName: "" },
                visitorOnBehalfOfData: { email: "", firstName: "", lastName: "", company: "", },
                noOnBehalfOfSelectedError: "",
                onBehalfOfLabel: this.state.showModal === false ? this.state.onBehalfOfLabel : "",
                onBehalfOf: this.state.showModal === false ? this.state.onBehalfOf : "",
            })
    };

    public render(): JSX.Element
    {
        return (
            <>
                <Button
                    fullWidth
                    sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }}
                    onClick={() => this.setState({ showModal: true })}
                    className="d-flex"
                    style={{ cursor: 'pointer' }}
                >
                    <div className="icon-text-inline pl-0" style={{ width: '80%' }}>
                        <div style={{ fontSize: '13px' }}>
                            <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{color: (theme) => theme.palette.text.primary }}>
                                {Icons.UserIcon}
                            </IbssSvgIcon>
                        </div>
                        <span className="space-text-item col-text">{this.labels.HubLabelBookForSomeone}</span>
                    </div>
                    <div className="d-flex" style={{ width: '20%', marginTop: '3px', justifyContent: 'right' }}>
                        <div style={{ marginTop: '3px' }}>
                            {this.state.onBehalfOfLabel && <span className="space-text-item mr-3">{this.state.onBehalfOfLabel}</span>}
                        </div>
                        <IbssSvgIcon fontSize='medium' className="" sx={{color: (theme) => theme.palette.text.primary }}>
                            {Icons.RightArrowIcon}
                        </IbssSvgIcon>
                    </div>
                    <div className="d-flex">

                    </div>
                </Button>
                <hr />
                {
                    this.state.showModal &&
                    <IbssDialog
                        aria-modal="true"
                        aria-label="book on behalf of modal"
                        open={this.state.showModal}
                        fullWidth
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelBookOnBehalfHeading}</label>
                            </>
                        }
                        content=
                        {
                            <div>

                                <div className="d-flex justify-content-between">
                                    <label className="lable-modal">{this.labels.HubLabelChooseBookingSpaceFor}</label>
                                    {/* <input name="isPrivate" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.isVisitor} onChange={() => this.handleChangeVisitor()} /> */}
                                </div>

                                <div className="mb-3">
                                    <button type="button" className={`${this.state.isVisitor ? 'btn-popup-tab' : 'btn-popup-tab-active'} mr-2`} onClick={() => this.handleBookingForEmployee()}>{this.labels.HubLabelEmployee}</button>
                                    <button type="button" className={`${!this.state.isVisitor ? 'btn-popup-tab' : 'btn-popup-tab-active'}`} onClick={() => this.handleBookingForVisitor()}>{this.labels.HubLabelvisitor}</button>
                                </div>
                                <div className="text-danger">{this.state.noOnBehalfOfSelectedError && this.state.noOnBehalfOfSelectedError}</div>

                                <hr className="m-0 mx-2" />

                                {!this.state.isVisitor &&
                                    <div className="mt-2">
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelEnterNameOrEmailOfEmployee}</label>
                                            <UserPicker
                                                searchText={ this.state.onBehalfOfLabel }
                                                onChange={ user => this.employeeChanged(user) }
                                                onSearchTextChange={ text => this.employeeTextChanged(text) }
                                            />
                                            <div className="text-danger">{this.state.alreadyPresentError}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.isVisitor &&
                                    <>
                                        <div className="mt-1">
                                            <label className="lable-modal">{this.labels.HubLabelNotMemberOfStaff}</label>
                                        </div>
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelEmailAddress}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="email"
                                                    name="email"
                                                    value={this.state.visitorOnBehalfOfData.email}
                                                    onChange={(e) => { this.bookingOnBehalfOfEmailChanged(e) }}
                                                    placeholder={this.labels.HubLabelemailPlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                            <div className="text-danger">
                                                {this.state.errors}
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelFirstName}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="firstName"
                                                    value={this.state.visitorOnBehalfOfData.firstName}
                                                    onChange={(e) => { this.bookingOnBehalfOfFirstNameChanged(e) }}
                                                    placeholder={this.labels.HubLabelFirstNamePlaceholderLabel}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelLastName}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="lastName"
                                                    value={this.state.visitorOnBehalfOfData.lastName}
                                                    onChange={(e) => { this.bookingOnBehalfOfLastNameChanged(e) }}
                                                    placeholder={this.labels.HubLabelLastNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelCompany}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="company"
                                                    value={this.state.visitorOnBehalfOfData.company}
                                                    onChange={(e) => { this.bookingOnBehalfOfCompanyChanged(e) }}
                                                    placeholder={this.labels.HubLabelCompanyNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
                                    <label className="lable-modal w-90 text-wrap">{this.state.isVisitor ? this.labels.HubLabelVisitorAutoCheckInRoamerAccess : this.labels.HubLabelEmployeeAutoCheckInRoamerAccess}</label>
                                    <input name="bookingAutoCheckin" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.bookingAutoCheckin === 1} onChange={(e) => this.setChecked(e)} />
                                </Box>
                            </div>
                        }
                        footer=
                        {
                            <div className="px-58 w-100 d-flex justify-content-end">
                                <IbssButtonRedo
                                    variant='contained'
                                    onClick={() => this.handleAddBookingFor()}
                                >
                                    {this.labels.HubButtonSave}
                                </IbssButtonRedo>
                            </div>
                        }
                        onClose={() => this.handleShowHideBookingForModal()}
                    />
                }
            </>
        );
    }
}

export interface IState
{
    showModal: boolean;
    isVisitor: boolean;
    noOnBehalfOfSelectedError: string;
    onBehalfOfLabel: string;
    nonVisitorOnBehalfOfData: INonVisitorOnBehalfOfData;
    alreadyPresentError: string;
    visitorOnBehalfOfData: IVisitorOnBehalfOfData;
    isDisabled: boolean;
    errors: string;
    bookingAutoCheckin: number;
    onBehalfOf: string;
    onBehalfOfData: IOnBehalfOf;
}

export interface IProps
{
    onSubmit: Function;
    onBehalfOfData?: IOnBehalfOf;
}

export interface INonVisitorOnBehalfOfData
{
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    displayName: string;
}

export interface IVisitorOnBehalfOfData
{
    email: string;
    firstName: string;
    lastName: string;
    company: string;
}

export interface IOnBehalfOf
{
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    isVisitor: boolean;
}
