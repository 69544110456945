import { Component } from 'react';
import { appContext } from '../../AppContext';
import { Button, CircularProgress } from '@mui/material';
import EquipmentsDialog from './EquipmentsDialog';
import IbssSvgIcon from '../uicomponents/IbssSvgIcon';
import { Icons } from '../../Common/AllsvgIcons';
import { IbssComponent } from '../IbssComponent';

export default class EquipmentsPicker extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            showDialog: false,
            numOfHearingAidsBooked: 0,
            numOfPresentationAidsBooked: 0,
        };
    }

    private bookedEquipmentChanged(numOfHearingAids: number, numOfPresentationAids: number): void
    {
        this.setState({
            numOfHearingAidsBooked: numOfHearingAids,
            numOfPresentationAidsBooked: numOfPresentationAids,
        });
    }

    public render(): JSX.Element
    {
        const props = this.props;
        return (
            <>
                {props.bookingId && (props.hearingAidReqs === 1) && this.props.equipmentType === "HearingAid" &&
                    (
                        <Button
                            fullWidth
                            sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }}
                            className={"row attendes cp mt-16"}
                            onClick={() => this.setState({ showDialog: !this.state.showDialog })}
                        >
                            <div className="icon-text-inline pl-0">
                                <div style={{ fontSize: '26px', height: '19px', marginTop: '-11px', width: '33px' }}>
                                    <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {Icons.HearingAidIcon}
                                    </IbssSvgIcon>
                                </div>
                                <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelHearingAid}</span>
                            </div>

                            <div className="d-flex">
                                {this.state.isLoading &&
                                    <div style={{ cursor: "none" }}>
                                        <CircularProgress size={20} />
                                    </div>
                                }
                                {!this.state.isLoading &&
                                    <div className="d-flex">
                                        <span className="space-text-item mr-3 text-lowercase">{`${this.state.numOfHearingAidsBooked} ${this.labels.HubLabelItems}`}</span>
                                        <IbssSvgIcon fontSize='medium' sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {Icons.RightArrowIcon}
                                        </IbssSvgIcon>
                                    </div>
                                }
                            </div>
                        </Button>
                    )
                }

                {props.bookingId && (props.presentationAidReqs === 1) && this.props.equipmentType === "PresentationAid" &&
                    (
                        <Button
                            fullWidth
                            sx={{ justifyContent: 'space-between', display: 'flex', textTransform: 'capitalize' }}
                            className={"row attendes cp mt-16"}
                            onClick={() => this.setState({ showDialog: !this.state.showDialog })}
                        >
                            <div className="icon-text-inline pl-0">
                                <div style={{ fontSize: '26px', height: '19px', marginTop: '-11px', width: '33px' }}>
                                    <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ color: (theme) => theme.palette.text.primary }}>
                                        {Icons.PresentationAidIcon}
                                    </IbssSvgIcon>
                                </div>
                                <span style={{ marginLeft: '-3px' }} className="space-text-item col-text">{this.labels.HubLabelPresentationAid}</span>
                            </div>

                            <div className="d-flex">
                                {this.state.isLoading &&
                                    <div style={{ cursor: "none" }}>
                                        <CircularProgress size={20} />
                                    </div>
                                }
                                {!this.state.isLoading &&
                                    <div className="d-flex">
                                        <span className="space-text-item mr-3 text-lowercase">{`${this.state.numOfPresentationAidsBooked} ${this.labels.HubLabelItems}`}</span>
                                        <IbssSvgIcon fontSize='medium' sx={{ color: (theme) => theme.palette.text.primary }}>
                                            {Icons.RightArrowIcon}
                                        </IbssSvgIcon>
                                    </div>
                                }
                            </div>
                        </Button>
                    )
                }

                <EquipmentsDialog
                    show={this.state.showDialog}
                    onClose={() => this.setState({ showDialog: false })}
                    bookingId={props.bookingId}
                    bookingStart={props.bookingStart}
                    bookingEnd={props.bookingEnd}
                    bookingName={props.bookingName}
                    buildingId={props.buildingId.toString()}
                    disabled={this.props.disabled}
                    equipmentType={this.props.equipmentType}
                    floorId={props.floorId}
                    spaceId={props.spaceId}
                    spaceName={props.spaceName}
                    bookedEquipmentChanged={(numOfHearingAids, numOfPresentationAids) => this.bookedEquipmentChanged(numOfHearingAids, numOfPresentationAids)}
                />
            </>
        )
    }
}

export interface IProps extends IBookingAndSpace
{
    disabled: boolean;
    equipmentType: string;
}

export interface IBookingAndSpace
{
    buildingId: number;
    bookingId: string;
    bookingStart: string;
    bookingEnd: string;
    bookingName: string;
    hearingAidReqs: number;
    presentationAidReqs: number;
    floorId: number;
    spaceId: string;
    spaceName: string;
}

export interface IState
{
    isLoading: boolean;
    showDialog: boolean;
    numOfHearingAidsBooked: number;
    numOfPresentationAidsBooked: number;
}
