import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { IFilter } from "../ODataFilter";
import { ODataQuery } from "../ODataQuery";
import { ICreateVisitEndpoint } from "./CreateVisitEndpoint";
import { IGetManyEndpoint } from "./GetManyEndpoint";
import { IVisitActionEndpoint } from "./VisitActionEndpoint";
import { IGetManyV2Endpoint } from "./GetV2ManyVisits";
import { PagedResponse } from "../Models";
import { ICreateV2Visit, ICreateV2VisitEndpoint } from "./CreateV2VisitEndpoint";
import { IDenyVisitV2, IDenyVisitsV2Endpoint } from "./DenyVisitsV2Endpoint";
import { ICancelVisitsV2Endpoint } from "./CancelVisitsV2Endpoint";
import { ICheckinVisitsV2Endpoint } from "./CheckinVisitsV2Endpoint";
import { ICheckoutVisitsV2Endpoint } from "./CheckoutVisitsV2Endpoint";
import { IApproveVisitV2, IApproveVisitsV2Endpoint } from "./ApproveVisitsV2Endpoint";
import { IGetByIdEndpoint } from "./GetByIdEndpoint";
import { IGetByIdV2Endpoint } from "./GetByIdV2Endpoint";
import { IEditVisitEndpoint } from "./EditVisitEndpoint";
import { IEditV2Visit, IEditV2VisitEndpoint } from "./EditV2VisitEndpoint";

export class VisitsRepository implements IVisitsRepository
{
    private getByIdEndpoint: IGetByIdEndpoint;
    private getByIdV2Endpoint: IGetByIdV2Endpoint;
    private createVisitEndpoint: ICreateVisitEndpoint;
    private createV2VisitEndpoint: ICreateV2VisitEndpoint;
    private visitActionEndpoint: IVisitActionEndpoint;
    private getManyEndpoint: IGetManyEndpoint;
    private getManyV2Endpoint: IGetManyV2Endpoint;
    private denyV2Endpoint: IDenyVisitsV2Endpoint;
    private cancelV2Endpoint: ICancelVisitsV2Endpoint;
    private checkinV2Endpoint: ICheckinVisitsV2Endpoint;
    private checkoutV2Endpoint: ICheckoutVisitsV2Endpoint;
    private approveV2Endpoint: IApproveVisitsV2Endpoint;
    private editVisitEndpoint: IEditVisitEndpoint;
    private editV2VisitEndpoint: IEditV2VisitEndpoint;

    constructor(
        getByIdEndpoint: IGetByIdEndpoint,
        getByIdV2Endpoint: IGetByIdV2Endpoint,
        createVisitEndpoint: ICreateVisitEndpoint,
        createV2VisitEndpoint: ICreateV2VisitEndpoint,
        visitActionEndpoint: IVisitActionEndpoint,
        getManyEndpoint: IGetManyEndpoint,
        getManyV2Endpoint: IGetManyV2Endpoint,
        denyV2Endpoint: IDenyVisitsV2Endpoint,
        cancelV2Endpoint: ICancelVisitsV2Endpoint,
        checkinV2Endpoint: ICheckinVisitsV2Endpoint,
        checkoutV2Endpoint: ICheckoutVisitsV2Endpoint,
        approveV2Endpoint: IApproveVisitsV2Endpoint,
        editVisitEndpoint: IEditVisitEndpoint,
        editV2VisitEndpoint: IEditV2VisitEndpoint
    )
    {
        this.getByIdEndpoint = getByIdEndpoint;
        this.getByIdV2Endpoint = getByIdV2Endpoint;
        this.createVisitEndpoint = createVisitEndpoint;
        this.createV2VisitEndpoint = createV2VisitEndpoint;
        this.visitActionEndpoint = visitActionEndpoint;
        this.getManyEndpoint = getManyEndpoint;
        this.getManyV2Endpoint = getManyV2Endpoint;
        this.denyV2Endpoint = denyV2Endpoint;
        this.cancelV2Endpoint = cancelV2Endpoint;
        this.checkinV2Endpoint = checkinV2Endpoint;
        this.checkoutV2Endpoint = checkoutV2Endpoint;
        this.approveV2Endpoint = approveV2Endpoint;
        this.editVisitEndpoint = editVisitEndpoint;
        this.editV2VisitEndpoint = editV2VisitEndpoint;
    }

    public createVisit(nodeId: number, visit: IVisit): Promise<ICreateVisitResponse>
    {
        return this.createVisitEndpoint.execute(nodeId, visit);
    }

    public createV2Visit(nodeId: number, visit: ICreateV2Visit): Promise<ICreateVisitResponse>
    {
        return this.createV2VisitEndpoint.execute(nodeId, visit);
    }

    public editVisit(nodeId: number, visitId: string, visit: IVisit): Promise<IEditVisitResponse>
    {
        return this.editVisitEndpoint.execute(nodeId, visitId, visit);
    }

    public editV2Visit(nodeId: number, visitId: string, visit: IEditV2Visit): Promise<IEditVisitResponse>
    {
        return this.editV2VisitEndpoint.execute(nodeId, visitId, visit);
    }

    public visitAction(nodeId: number, visitId: string, payload: IVisitAction): Promise<void>
    {
        return this.visitActionEndpoint.execute(nodeId, visitId, payload);
    }

    public getMany<TVisit>(query: ODataQuery<TVisit>): Promise<TVisit[]>
    {
        return this.getManyEndpoint.execute(query);
    }

    public getManyV2<TVisit>(query: ODataQuery<TVisit>): Promise<PagedResponse<TVisit[]>>
    {
        return this.getManyV2Endpoint.execute(query);
    }

    public getById<TVisit>(type: new () => TVisit, nodeId: number, visitId: string): Promise<TVisit>
    {
        return this.getByIdEndpoint.execute(type, nodeId, visitId);
    }

    public getByIdV2<TVisit>(type: new () => TVisit, nodeId: number, visitId: string): Promise<TVisit>
    {
        return this.getByIdV2Endpoint.execute(type, nodeId, visitId);
    }

    public denyVisitsV2(nodeId: number, visits: IDenyVisitV2[]): Promise<void>
    {
        return this.denyV2Endpoint.execute(nodeId, visits);
    }

    public cancelVisitsV2(nodeId: number, visits: VisitId[]): Promise<void>
    {
        return this.cancelV2Endpoint.execute(nodeId, visits);
    }

    public checkinVisitsV2(nodeId: number, visits: VisitId[]): Promise<void>
    {
        return this.checkinV2Endpoint.execute(nodeId, visits);
    }

    public checkoutVisitsV2(nodeId: number, visits: VisitId[]): Promise<void>
    {
        return this.checkoutV2Endpoint.execute(nodeId, visits);
    }

    public approveVisitsV2(nodeId: number, visits: IApproveVisitV2[]): Promise<void>
    {
        return this.approveV2Endpoint.execute(nodeId, visits);
    }
}
export interface IVisitsRepository 
{
    createVisit(nodeId: number, visit: IVisit): Promise<ICreateVisitResponse>;
    createV2Visit(nodeId: number, visit: ICreateV2Visit): Promise<ICreateVisitResponse>
    visitAction(nodeId: number, visitId: string, payload: IVisitAction): Promise<void>
    getMany<TVisit>(query: ODataQuery<TVisit>): Promise<TVisit[]>;
    getManyV2<TVisit>(query: ODataQuery<TVisit>): Promise<PagedResponse<TVisit[]>>;
    denyVisitsV2(nodeId: number, visits: IDenyVisitV2[]): Promise<void>;
    cancelVisitsV2(nodeId: number, visits: VisitId[]): Promise<void>;
    checkinVisitsV2(nodeId: number, visits: VisitId[]): Promise<void>;
    checkoutVisitsV2(nodeId: number, visits: VisitId[]): Promise<void>;
    approveVisitsV2(nodeId: number, visits: IApproveVisitV2[]): Promise<void>;
    getById<TVisit>(type: new () => TVisit, nodeId: number, visitId: string): Promise<TVisit>;
    getByIdV2<TVisit>(type: new () => TVisit, nodeId: number, visitId: string): Promise<TVisit>;
    editVisit(nodeId: number, visitId: string, visit: IVisit): Promise<ICreateVisitResponse>
    editV2Visit(nodeId: number, visitId: string, visit: ICreateV2Visit): Promise<ICreateVisitResponse>
}

export type VisitStatus = "New" | "Amended" | "Late Checkin" | "Awaiting Approval" | "Approved" | "Checked In" | "Cancelled" | "Completed" | "No Show" | "Auto Cancelled";

export interface IVisit
{
    Space_Id: string,
    Space_Name: string,
    Visitor_First_Name: string,
    Visitor_Last_Name: string,
    Visitor_Email: string,
    Visitor_Company: string,
    Visit_Host_Name: string,
    Visit_Host_Email: string,
    Visit_Start_Date: string,
    Visit_End_Date: string,
    Visit_Approval_Comments: string,
    DisableExtUpdate: boolean,
    Visit_Pacs_Id: string,
    Visit_Status?: string,
    Visit_Id?: string
}

export interface IVisitAction
{
    Visit_IsApproved?: number,
    Visit_IsCancelled?: number,
    Visit_IsCheckedIn?: number,
    Visit_IsCheckedOut?: number,
    Visit_IsDenied?: number,
    Visit_Approval_Comments?: string
}

export interface ICreateVisitResponse
{
    Visit_Id: string;
    Visit_IsApproved: number;
}

export interface IEditVisitResponse
{
    Visit_Id: string;
    Visit_IsApproved: number;
}

export class VisitId
{
    Visit_Id = "";
}

export class VisitShort
{
    Visit_Id = "";
    Visitor_Email = "";
    Visitor_First_Name = "";
    Visitor_Last_Name = "";
    Visit_Start_Date = "";
    Visit_Host_Name = "";
    Visit_Status = "";
}

export class Visit
{
    public Visit_Start_Date = DateTime.now();
    public Visit_IsApproved = 0;
    public Visit_IsCancelled = 0;
    public Visit_IsCheckedIn = 0;
    public Visit_IsCheckedOut = 0;
    public Visit_IsDenied = 0;
    public Visit_IsNoShow = 0;

    public fixTypes(visit: Visit): void
    {
        visit.Visit_Start_Date = DateTime.fromISO(visit.Visit_Start_Date as unknown as string);
    }
}

export class Visit2
{
    public Node_Id = 0;
    public Space_Name = "";
    public Visit_Id = "";
    public Visit_Start_Date = "";
    public Visit_End_Date = "";
    public Visit_Host_Name = "";
    public Visit_Status: VisitStatus = "New";
    public Visitor_First_Name = "";
    public Visitor_Last_Name = "";
    public Visitor_Email = "";
    public Visitor_Company = "";
}

export class VisitFilter implements IFilter
{
    public minStartDate = DateHelper.null();
    public maxStartDate = DateHelper.null();
    public minEndDate = DateHelper.null();
    public maxEndDate = DateHelper.null();
    public status = "";
    public hostEmail = "";

    constructor(value: Partial<VisitFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string>();
        filterParts.push(this.minStartDate.isValid ? `Visit_Start_Date ge datetime'${this.minStartDate.toUTC().toISO()}'` : "");
        filterParts.push(this.maxStartDate.isValid ? `Visit_Start_Date lt datetime'${this.maxStartDate.toUTC().toISO()}'` : "");
        filterParts.push(this.minEndDate.isValid ? `Visit_End_Date ge datetime'${this.minEndDate.toUTC().toISO()}'` : "");
        filterParts.push(this.maxEndDate.isValid ? `Visit_End_Date lt datetime'${this.maxEndDate.toUTC().toISO()}'` : "");
        filterParts.push(this.status != "" ? `Visit_Status eq '${this.status}'` : "");
        filterParts.push(this.hostEmail != "" ? `Visit_Host_Email eq '${this.hostEmail.toLocaleLowerCase()}'` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }
}