import { Component } from 'react';
import { appContext } from '../../AppContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IbssButton from '../uicomponents/IbssButton';
import IbssDialog from '../uicomponents/IbssDialog';
import IbssSvgIcon from '../uicomponents/IbssSvgIcon';
import { Icons } from '../../Common/AllsvgIcons';
import { ICreateBookingResponse } from '../../Providers.Api/Bookings/CreateV1BookingEndpoint';
import { Box, Button, Divider } from '@mui/material';
import EquipmentsDialog from '../Equipments/EquipmentsDialog';

class NewBookingDialog extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private appState = appContext().state;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            lightModeTheme: this.appState.lightModeTheme,
            showAddEquipmentModal: false,
            equipmentType: '',
        };
    }

    public componentWillMount(): void
    {
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    public handleCateringRoute(): void
    {
        const { history } = this.props;
        const activeurl = window.location.href;

        if(activeurl.includes("schedule-view"))
        {
            history.push({
                pathname: `/create-catering-order/${this.props.buildingId}/${this.props.bookingId}/${this.props.spaceId}`, 
                state: { bookingId: this.props.bookingId, originRoute: `/buildings/${this.props.buildingId}/bookings/schedule-view`} // information to push via react router
            });
        }
        else
        {
            history.push( `/create-catering-order/${this.props.buildingId}/${this.props.bookingId}/${this.props.spaceId}`);
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                <IbssDialog
                    aria-modal="true"
                    aria-label="equipment modal"
                    open={this.props.showCateringAidsModal}
                    content=
                    {
                        <>
                            <div className="d-flex justify-content-center">
                                <div>
                                    <div style={{ paddingLeft: "33px" }}>
                                        <IbssSvgIcon className="font-18">{Icons.Success}</IbssSvgIcon>
                                    </div>
                                    <p style={{ paddingTop: "5px" }}>{this.labels.HubLabelAllBooked}</p>
                                </div>
                            </div>
                            <span >{this.labels.HubLabelAllBookedPra1} <br />
                                {this.labels.HubLabelAllBookedPra2}</span><br />
                            
                            <Box sx={{mt: 2, mb: 3}}>
                                <Divider />
                                {this.props.presentationAidReqs === 1 && (
                                    <>
                                        <Button 
                                            fullWidth 
                                            sx={{ pb: 2, pt: 2}} 
                                            onClick={() => {this.setState({equipmentType: 'PresentationAid', showAddEquipmentModal: true})}}
                                        >
                                            <div className="icon-text-inline pl-0 d-flex justify-content-between w-100">
                                                <div className="d-flex pointer">
                                                    <span className="space-icon-item">
                                                        <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="Presentation Aids" />
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelPresentationAid}</span>
                                                </div>
                                                <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                            </div>
                                        </Button>
                                        <Divider />
                                    </>
                                )}
                                {this.props.hearingAidReqs === 1 && (
                                    <>
                                        <Button 
                                            fullWidth 
                                            sx={{ pb: 2, pt: 2}} 
                                            onClick={() => {this.setState({equipmentType: 'HearingAid', showAddEquipmentModal: true})}}
                                        >
                                            <div className="icon-text-inline pl-0 d-flex justify-content-between w-100">
                                                <div className="d-flex pointer">
                                                    <span className="space-icon-item">
                                                        <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="Hearing Aid"/>
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelHearingAid}</span>
                                                </div>
                                                <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                            </div>
                                        </Button>
                                        <Divider />
                                    </>
                                )}
                                {this.props.cateringReqs === 1 && (
                                    <>
                                        <Button fullWidth sx={{ pb: 2, pt: 2}} onClick={() => this.handleCateringRoute()}>
                                            <div className="icon-text-inline pl-0 d-flex justify-content-between w-100">
                                                <div className="d-flex pointer">
                                                    <span className="space-icon-item">
                                                        <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="Catering"/>
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelCateringTable}</span>
                                                </div>
                                                <div className="space-icon-item space-text-item pointer"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="Right Arrow" /></div>
                                            </div>
                                        </Button>
                                        <Divider sx={{mb: 3}}/>
                                    </>
                                )}
                            </Box>
                        </>
                    }
                    footer=
                    {
                        <IbssButton
                            variant="contained"
                            onClick={() => this.props.handleCateringAidsModal(false)}
                        >
                            {this.labels.HubButtondoThisLater}
                        </IbssButton>
                    }
                    fullWidth
                    onClose={() => this.props.handleCateringAidsModal(false)}
                />
                <EquipmentsDialog
                    show={this.state.showAddEquipmentModal}
                    onClose={()=> this.setState({showAddEquipmentModal: false})} 
                    bookingId={this.props.bookingId}
                    bookingName={this.props.bookingName}
                    bookingStart={this.props.bookingStart}
                    bookingEnd={this.props.bookingEnd}
                    buildingId={(this.props.buildingId).toString()}
                    equipmentType={this.state.equipmentType}
                    floorId={this.props.floorId}
                    spaceId={this.props.spaceId}
                    spaceName={this.props.spaceName}
                />
            </>
        )
    }
}

export default withRouter(NewBookingDialog);

export interface IProps extends RouteComponentProps
{
    bookingName: string,
    showCateringAidsModal: boolean,
    handleCateringAidsModal: (val: boolean) => void,
    cateringReqs: number,
    hearingAidReqs: number,
    presentationAidReqs: number,
    buildingId: number,
    spaceName: string,
    bookingId: string;
    spaceId: string;
    floorId: number;
    bookingStart: string,
    bookingEnd: string,
}

export interface IState
{
    lightModeTheme: boolean,
    showAddEquipmentModal: boolean,
    equipmentType: string,
}