import React from 'react';
import { appContext } from '../../../../AppContext';
import { DateTime, Interval } from 'luxon';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Day, Week, WorkWeek, Month, Agenda, ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject, TimelineViews,  GroupModel, EventRenderedArgs, PopupOpenEventArgs, SelectEventArgs } from '@syncfusion/ej2-react-schedule';
import "./ScheduleView.scss";
import { Box,Typography,Grid, TextField } from '@mui/material';
import IbssButton from '../../../../Components/uicomponents/IbssButton';
import { SpacesFilter } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { ComponentHelper } from '../../../../Common/ComponentHelper';
import ScheduleViewModal from './ScheduleViewModal';
import TablePagination from '@mui/material/TablePagination';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Helper from '../../../../Common/Helper';
import { IbssComponent } from '../../../../Components/IbssComponent';
import { createPortal } from 'react-dom';
import BookingCriteria from './BookingCriteria';
import "./ScheduleStyles.scss";
import { ODataQuery } from '../../../../Providers.Api/ODataQuery';
import { Booking } from '../../../../Providers.Api/Bookings/GetV2BookingsEndpoint';
import { Space } from '../../../../Providers.Api/Spaces/SpaceRepository';
import { ReactComponent as NoSpaceScheduleViewIcon } from './NoSpacesScheduleView.svg'
import { DateHelper } from '../../../../Common/DateHelper';
import { IListOption } from './ScheduleViewModal';
import { IUserPreferences } from '../../../../Providers.Api/UserPreferenceRepository';
import { IFloor } from '../../../../Providers.Api/Models';
class ScheduleView extends IbssComponent<RouteComponentProps<IQueryParams>, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get api() { return appContext().apiClient; }
    private get apiCache() { return appContext().apiCache; }
    private get bookingService() { return appContext().bookingService; }
    private helper =  new Helper()
    private component = new ComponentHelper(this);
    public setStateAsync = this.component.setStateAsync.bind(this.component);
    private scheduleRef: React.RefObject<ScheduleComponent>;
    private get local() { return appContext().localStorageProvider; }
    private userPreferences = {} as IUserPreferences;
    
    constructor(props: RouteComponentProps<IQueryParams>)
    {
        super(props);
        this.scheduleRef = React.createRef<ScheduleComponent>();
        this.state =
        {
            bookings: [],
            buildingId: 0,
            floor: 'Any', // Any or a string representation of floorId
            floorName: this.labels.HubLabelAny, 
            spaceType: 'Any',
            spaceWorkType: 'Any',
            capacity: '2',
            hasCatering: false,
            hasEquipment: false,
            zone: null,
            startTime: DateTime.now(),
            spaces: [],
            buildingStartHrs: '08:00',
            buildingEndHrs: '20:00',
            openFilterModal: false,
            // controls for pagination
            spacePageIndex: 0,
            
            // controls for button on scheduler
            showButton: false,
            selectedCell: null,

            // controls for create a booking filters
            showCreateBooking: false,
            selectedSlot: null,
            // selected booking data.
            selectedBooking: undefined,

            // state to go with async data calls.
            isLoading: false,

            sortSpacesOrder: ISortSpaces.Ascending,
            zoneOptions: [],
        };
    }

    public async componentWillMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        this.pageTitle = this.labels.HubLabelBookings;
    }

    public async componentDidMount(): Promise<void>
    {
        // load config
        this.userPreferences = this.local.getUserPreferences();

        // set initial SpaceType and floor 
        const initialFloor = this.getInitialFloor();
        const initialSpaceWorkType = this.getInitialSpaceWorkType(this.state.buildingId);

        this.setState({
            floor: initialFloor.value,
            floorName: initialFloor.label,
            spaceWorkType: initialSpaceWorkType,
        });

        await this.refreshSpacesAndBookings();
    }

    public async componentDidUpdate(prevProps: RouteComponentProps, prevState: IState): Promise<void>
    {
        if(prevState.buildingId !== this.state.buildingId)
        {
            // if user selects another building, navigate to new url containing updated buildingId,  load new rooms, bookings and building hours.
            const { history, match } = this.props;
            if(match.params !== null)
            {
                history.push(match.path.replace(":buildingid", this.state.buildingId.toString()));
            }
            // reset floor
            const initialFloor = this.getInitialFloor();
            this.setStateAsync(
                {
                    floor: initialFloor.value,
                    floorName: initialFloor.label,
                    spaceWorkType: this.getInitialSpaceWorkType(this.state.buildingId),
                }
            );
            await this.refreshSpacesAndBookings();
        }

        if(prevState.floor !== this.state.floor || prevState.capacity !== this.state.capacity || prevState.hasCatering !== this.state.hasCatering || prevState.hasEquipment !== this.state.hasEquipment || prevState.spaceType !== this.state.spaceType || prevState.spaceWorkType !== this.state.spaceWorkType)
        {
            await this.refreshSpacesAndBookings();
        }

        if(prevState.startTime !== this.state.startTime)
        {
            await this.refreshSpacesAndBookings();
        }
    }
    
    private async loadCachedSpaces(): Promise<void>
    {
        try
        {
            const response = await this.apiCache.getSpacesByBuilding(this.state.buildingId);
            const spaceView = response.map(i => SpaceView.fromSpace(i));
            const filteredSpaceView = this.filterByInputs(spaceView).sort((a,b) => this.compareSpaceNames(a, b)); // sort spaces by Ascending Order on loading cached spaces.
            await this.setStateAsync({
                spaces: filteredSpaceView,
                spacePageIndex: 0,
            });
        }
        catch
        {
            return;
        }
    }

    private filterByInputs(spaces: SpaceView[]): SpaceView[]
    {
        const { capacity, floor, hasCatering, hasEquipment, spaceType, spaceWorkType } = this.state;

        const filteredSpaces = spaces.filter(space => {
            
            return this.isEqualOrAny((space.nodeId).toString(), floor)
            && this.isEqualOrAny(space.spaceType, spaceType) 
            && this.isEqualOrAny(space.spaceWorkType, spaceWorkType) 
            && space.spaceCapacity >= parseInt(capacity)
            && (hasCatering? !!space.metaServReqsCatering : true) // if user specifies space needs catering, test metaServReqsCatering value,
            && (hasEquipment? (!!space.metaServReqsAV || !!space.metaServReqsHearing || !!space.metaServReqsPresentation): true)
            && this.metaBookableIs1or3or4or5(space.metaBookable)
        });

        return filteredSpaces
    }

    private isEqualOrAny(a: string, b: string)
    {
        // function to return true if both strings are equal or if any of them are "any" after text transform to lowercase.
        return ( a === b || (a.toLowerCase() === "any" || b.toLowerCase() === "any"))
    }

    private metaBookableIs1or3or4or5(spaceMetabookable: number)
    {
        return [1,3,4,5].includes(spaceMetabookable);
    }


    private compareSpaceNames(a: SpaceView, b: SpaceView): number
    {
        const spaceNameA = a.spaceName.toLocaleLowerCase();
        const spaceNameB = b.spaceName.toLocaleLowerCase();

        if(spaceNameA < spaceNameB)
        {
            return this.state.sortSpacesOrder === ISortSpaces.Ascending ? -1 : 1;
        }
        if(spaceNameA > spaceNameB)
        {
            return this.state.sortSpacesOrder === ISortSpaces.Ascending ? 1 : -1;
        }
        return 0;
    }

    private getInitialSpaceWorkType(buildingId: number): string
    {
        // returns the initial Work Space Type value, tries to avoid return 'Any'
        const workTypes = Helper.getWorkSpaceTypesByNodeId(buildingId);

        const options = workTypes
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }))
            .sort((a, b) => a.label.localeCompare(b.label)); //sort by name

        options.unshift({ label: this.labels.HubLabelAny, value: "Any" });

        const meetingOption = options.find(x => x.value === 'FormalMeeting')
        if (meetingOption)
        {
            return meetingOption.value;
        } 
        else
        {
            return options[1]?.value ?? options[0]?.value;
        }
    }

    private getInitialFloor(): IListOption<string>
    {
        // get user preferred floor or else the first floor option after 'Any', (or 'Any' if there are no floor options.)

        const buildingSpecificUserSearchPrefs = this.userPreferences.Nodes.find(building => building.NodeId === this.state.buildingId);
        const userPrefsFloor = buildingSpecificUserSearchPrefs?.DefaultFloor? buildingSpecificUserSearchPrefs?.DefaultFloor.toString() : '';

        const floorOptions = this.getFloors(this.state.buildingId)
        const backUpFloorOption = floorOptions[1] ?? floorOptions[0];

        return {
            label: floorOptions.find(option => option.value === userPrefsFloor)?.label || backUpFloorOption.label,
            value: userPrefsFloor || backUpFloorOption.value,
        };
    }

    private getFloors(selectedBuildingId: number): Array<IListOption<string>>
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);

        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id.toString() }))
            .sort((a, b) => a.label.localeCompare(b.label)); // sort by name

        options.unshift({ label: this.labels.HubLabelAny, value: 'Any' });
        return options;
    }

    public resetFilters(): void
    {
        const initialFloor = this.getInitialFloor();
        const initialSpaceWorkType = this.getInitialSpaceWorkType(this.state.buildingId)
        this.setState(
            {
                floor: initialFloor.value,
                floorName: initialFloor.label,
                spaceType: 'Any', 
                spaceWorkType: initialSpaceWorkType,
                capacity: '2',
                hasCatering: false,
                hasEquipment: false,
            }
        )
    }

    private async loadBookings(): Promise<void>
    {
        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').setZoneByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').setZoneByNode(this.state.buildingId);

        const query = new ODataQuery({ nodeId: this.state.buildingId });

        try
        {
            const spaceIds = [...this.state.spaces.slice(0, (this.state.spacePageIndex+1)*10).map(i => i.spaceId)];
            if(spaceIds.length > 0)
            {
                const bookings = await this.bookingService.getBookings(query, startOfTodayWithZone, endOfTodayWithZone, spaceIds);
                bookings.value.forEach(i =>
                {
                    i.Booking_Start = i.Booking_Start.offsetTimeByNode(query.nodeId);
                    i.Booking_End = i.Booking_End.offsetTimeByNode(query.nodeId);
                });
        
                const bookingsView = bookings.value.map(i => BookingView.fromBooking(i));
                this.setState({bookings: bookingsView});
            }
        }
        catch
        {
            return;
        }
    }

    private async loadMoreBookings(): Promise<void>
    {
        // load more bookings when more spaces are loaded.
        // keep startTime in local time, and when calculating the start and end of date for API interaction, convert to new building timezone, whilst keeping local time and dates. e.g. 15th of feb in the uk would return 15th of feb in Sydney
        const startOfTodayWithZone = this.state.startTime.startOf('day').setZoneByNode(this.state.buildingId);
        const endOfTodayWithZone = this.state.startTime.endOf('day').setZoneByNode(this.state.buildingId);
        
        // load the bookings for the next batch of spaceIds, append the returned bookings to existing bookings.
        const query = new ODataQuery({ nodeId: this.state.buildingId});

        try
        {
            const spaceIds = [...this.state.spaces.slice(this.state.spacePageIndex*10, (this.state.spacePageIndex+1)*10).map(i => i.spaceId)];
            if(spaceIds.length > 0)
            {
                const bookings = await this.bookingService.getBookings(query, startOfTodayWithZone, endOfTodayWithZone, spaceIds);
                bookings.value.forEach(i =>
                {
                    i.Booking_Start = i.Booking_Start.offsetTimeByNode(query.nodeId);
                    i.Booking_End = i.Booking_End.offsetTimeByNode(query.nodeId);
                });
        
                const bookingsView = bookings.value.map(i => BookingView.fromBooking(i));
                this.setState(prevState => ({bookings: [...prevState.bookings, ...bookingsView]}));
            }
        }
        catch
        {
            return;
        }
    }

    private async refreshSpacesAndBookings(): Promise<void>
    {
        // get spaces, bookings and building hours data
        this.setState({isLoading: true});
        await this.loadCachedSpaces();
        await this.loadBookings();
        await this.getBuildingHours();
        this.setState({isLoading: false});
    }

    private transformBookings(): IScheduleDate[]
    {
        const scheduleDates = this.state.bookings.flatMap(booking =>
            {
                const scheduleDatesForBooking = booking.spaceId.split(';').map(spaceId =>
                {
                    const scheduleDate: IScheduleDate =
                    {
                        // Id, Subject, StartTime & EndTime fields (default action event data field names) cannot be written as camelCase strings without first mapping camelCase field names to ScheduleComponent.eventSettings.fields
                        // e.g. fields: { subject: { title: 'subject', name: 'subject'}}, make sure value of name property matches the camelCase field name.
                        id: booking.bookingId, 
                        subject: booking.bookingName,
                        startTime: DateTime.fromISO(booking.bookingStart).toJSDate(),
                        endTime: DateTime.fromISO(booking.bookingEnd).toJSDate(),
                        bookingIsActive: booking.bookingIsActive,
                        bookingIsApproved: booking.bookingIsApproved,
                        bookingStatus: booking.bookingStatus,
                        spaceId: spaceId,
                        categoryColor: this.processBookingColour(booking),
                        cancelledBookingsInSameSpaceTimeSlot: this.cancelledBookingsInSpaceAndTimeSlot(booking.spaceId, booking.bookingStart, booking.bookingEnd), // when showing cancelled bookings alongside non-cancelled bookings.
                    }
                    return scheduleDate;
                });

                return scheduleDatesForBooking;
            });
            
        return scheduleDates;
    }

    private groupData: GroupModel = 
    {
        // group bookings by spaces - in ResourceDirective with name="Spaces", matching on SpaceId field of bookings transformed by transformBookings() and id field of ResourceDirective's datasource.
        resources: ['Spaces']
    }

    private changeDate(event: DateTime): void
    {
        this.setState({startTime: event});
    }

    private async getBuildingHours(): Promise<void> 
    {
        // get a building's start and stop hours.
        const rootNode = this.local.getNodeData();
        const building = this.helper.getBuildingById(rootNode, this.state.buildingId);

        this.setStateAsync({
            buildingStartHrs: building?.Occ_Office_hrs_stt.slice(0, -3) ?? '07:00',
            buildingEndHrs: building?.Occ_Office_hrs_stp.slice(0, -3) ?? '19:00',
        });
    }

    private async handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): Promise<void>
    {
        if(newPage > this.state.spacePageIndex)
        {
            // only load more bookings if new page is greater than current page.
            await this.setStateAsync({spacePageIndex: newPage});
            await this.loadMoreBookings();
        }
        else
        {
            await this.setStateAsync({spacePageIndex: newPage});
        }
    }

    private cancelledBookingsInSpaceAndTimeSlot(spaceId: string, startTime: string, endTime: string): number
    {
        // need to count cancelled bookings in space and time
        // startTime and endTime are from a specific BookingView object. They are string type.
        const bookingIntervalX = Interval.fromDateTimes(DateTime.fromISO(startTime), DateTime.fromISO(endTime));

        const countOfBookings = this.state.bookings.filter( i => {
            const bookingIntervalY = Interval.fromDateTimes(DateTime.fromISO(i.bookingStart), DateTime.fromISO(i.bookingEnd));
            return (i.bookingStatus === "Cancelled" || i.bookingStatus === "Auto Cancelled") && i.spaceId === spaceId && bookingIntervalX.overlaps(bookingIntervalY);
        }).length;

        return countOfBookings;
    }

    private onEventRendered(args: EventRenderedArgs): void
    {
        // before bookings are rendered on the scheduler, apply some styles to the bookings, but primarily to assign borderColor to the CategoryColor in the bookings data.
        
        // background color for Cancelled bookings is solid grey
        const backgroundColour = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled') ? '#DCE1E5' : 'transparent';
        // opacity for Cancelled bookings is less than 1.
        const opacity = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled')? '0.6' : '1';
        // maxWidth for all Cancelled bookings combined is 70% of overall cell width. the appointment is inside a table, the number of cells per row is equal to the number of bookings in slot, regardless of whether tye are cacelled or not
        const maxWidth = (args.data.bookingStatus === 'Cancelled' || args.data.bookingStatus === 'Auto Cancelled')? `${70/args.data.cancelledBookingsInSameSpaceTimeSlot}%` : '100%'; 

        (args.element as HTMLElement).style.color = '#263238'; // this is equivalent to var(ui--text), change to this var if syncfusion scheduler has dark mode.
        (args.element as HTMLElement).style.backgroundColor = backgroundColour;
        (args.element as HTMLElement).style.borderColor = args.data.categoryColor;
        (args.element as HTMLElement).style.borderRadius = '5px';
        (args.element as HTMLElement).style.borderLeftWidth = 'thick';
        (args.element as HTMLElement).style.opacity = opacity;
        (args.element as HTMLElement).style.maxWidth = maxWidth;
    }

    private processBookingColour(booking: BookingView): string
    {
        if (booking.bookingStatus === 'Cancelled' || booking.bookingStatus === 'Auto Cancelled')
        {
            return '#DCE1E5'; // this is equivalent to uiMidTone.,
        }
        else if(booking.bookingStatus === 'Completed')
        {
            return 'grey';
        }
        else if(booking.bookingStatus === 'No Show')
        {
            return 'blue';
        }
        else if(booking.bookingStatus === 'In Progress')
        {
            return 'yellow';
        }
        else if (booking.bookingIsApproved === 0)
        {
            // new booking pending approval has Booking_IsApproved value of 0.
            return 'red';
        }
        else if(booking.bookingIsApproved === 3 || booking.bookingIsApproved === 4)
        {
            return 'green';
        }
        else
        {
            return 'black';
        }
    }

    private onPopupOpen(args: PopupOpenEventArgs): void
    {
        // querySelector is onPopupOpen instead of onSelect because it reliably selects cells by className
        const element = document.querySelector(`.e-work-cells.e-work-hours.e-selected-cell`);
        this.setState({ selectedCell: element });
        if(args.data)
        {
            const {spaceId, startTime, endTime } = args.data;
            this.setState({
                selectedSlot: {
                    spaceId: spaceId,
                    startTime: startTime,
                    endTime: endTime,
                }
            });
        }

        // disables ALL popups
        args.cancel = true;
  
    }
    
    private onSelect(args: SelectEventArgs): void
    {
        if(args.requestType === "eventSelect")
        {
            if(args?.data && !(args.data instanceof Array))
            {
                this.setState({ 
                    showCreateBooking: !this.state.showCreateBooking, 
                    selectedBooking: this.state.bookings.find(i => 
                        {
                            const data = args.data as IScheduleDate;
                            return i.bookingId === data.id
                        }
                    )
                });
            }
        }
        else if(args.requestType === "mousemove")
        {
            return;
        }
        else
        {
            this.setState({ selectedBooking: undefined});
        }
    }

    public getFilterText(): string
    {
        const spaceWorkTypeLabel = Helper.getWorkSpaceTypesByNodeId(this.state.buildingId).find(i => i.Name === this.state.spaceWorkType)?.Label ?? this.labels.HubLabelAny;
        const spaceTypeLabel = Helper.getSpaceTypesByNodeId(this.state.buildingId).result.find(i => i.Name === this.state.spaceType)?.Label ?? this.labels.HubLabelAny;

        return `${this.labels.HubLabelworkType}: ${this.state.spaceWorkType === 'Any' ? this.labels.HubLabelAny : spaceWorkTypeLabel} | ` +
            `${this.labels.HubLabelSpaceType}: ${this.state.spaceType === 'Any' ? this.labels.HubLabelAny : spaceTypeLabel} | ` +
            `${this.labels.HubLabelFloor}: ${this.state.floor === 'Any' ? this.labels.HubLabelAny : this.state.floorName} | ` +
            `${this.labels.HubLabelCapacity}: ${this.state.capacity === '' ? this.labels.HubLabelAny : this.state.capacity} | ` +
            `${this.labels.HubLabelCateringTable}: ${this.state.hasCatering ? 'Has Catering' : this.labels.HubLabelAny} | ` +
            `${this.labels.funcScheduleViewResources_S}: ${this.state.hasEquipment ? 'Has Equipment' : this.labels.HubLabelAny}`;
    }

    public render(): JSX.Element
    {
        return (
            <>
            <div className="page-height-exct-header">
                <div className="rightPanel-main-content">
                    <div className="table-panel">
                        <>
                            {this.state.selectedCell && createPortal(
                                <IbssButton 
                                    variant="contained"
                                    style={{justifyContent: "flex-end"}} 
                                    fullWidth 
                                    onClick={()=>this.setState({showCreateBooking: !this.state.showCreateBooking})}
                                >
                                    {`+`}
                                </IbssButton>,
                                this.state.selectedCell
                            )}
                        </>
                        <BookingCriteria
                            {...this.props as RouteComponentProps<IQueryParams>}
                            open={this.state.showCreateBooking} 
                            setOpen={(isOpen: boolean) => this.setState({showCreateBooking: isOpen})}
                            buildingId={this.state.buildingId}
                            getBookings={()=> this.loadBookings()}
                            layoutStyle={'scheduleView'}
                            unsetSelectedCell={()=> this.setState({selectedCell: null})}
                            selectedBooking={this.state.selectedBooking}
                            clearSelectedBooking={()=> this.setState({selectedBooking: undefined })}
                            selectedSlot={this.state.selectedSlot}
                            selectedSpace={this.state.spaces.filter(space => space.spaceId === this.state?.selectedSlot?.spaceId ?? '').map(space => ({
                                spaceId: space.spaceId,
                                floorId: space.nodeId, 
                                spaceName: space.spaceName,
                                spaceType: space.spaceType,
                                spaceWorkType: space.spaceWorkType,
                                capacity: space.spaceCapacity.toString(),
                                zone: space.metaLocZone,
                                cateringReqs: space.metaServReqsCatering,
                                presentationAidReqs: space.metaServReqsPresentation,
                                hearingAidReqs: space.metaServReqsHearing,
                                requiresAV: space.metaServReqsAV,
                                spaceLayout: space.spaceLayout,
                                spaceSetup: space.spaceSetup,
                                spaceTypeLabel: space.spaceTypeLabel,
                                bookingPolicyId: space.bookingPolicyId,
                                meetingLinkAvailable: space.meetingLinkAvailable
                            }))}
                        />
                        <ScheduleViewModal 
                            openFilterModal={this.state.openFilterModal}
                            handleFilterModal={(status)=> this.setState({openFilterModal: status})}
                            buildingId={this.state.buildingId}
                            floor={this.state.floor}
                            spaceType={this.state.spaceType}
                            spaceWorkType={this.state.spaceWorkType}
                            capacity={this.state.capacity}
                            hasCatering={this.state.hasCatering}
                            hasEquipment={this.state.hasEquipment}
                            changeFloor={(floor, floorName) => this.setState({floor: floor, floorName: floorName})}
                            changeCapacity={(capacity)=> this.setState({capacity: capacity})}
                            changeSpaceType={(spaceType)=> this.setState({spaceType: spaceType })}
                            changeSpaceWorkType={(spaceWorkType)=> this.setState({spaceWorkType: spaceWorkType })}
                            changeHasCatering={(hasCatering)=> this.setState({hasCatering: hasCatering})}
                            changeHasEquipment={(hasEquipment)=> this.setState({hasEquipment: hasEquipment})}
                        />
                        <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                            <Grid item md={6} sx={{pt:0}} >
                                <Box className="table-panel-header" component="div" sx={{ ml: '0',textOverflow: 'ellipsis',whiteSpace:'nowrap',overflow:'hidden',width:"100%" }}> {this.labels.HubLabelGridScheduleView}</Box>
                            </Grid>
                            <Grid item md={12} sx={{pt:0}} >
                                <Typography sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme)=> theme.palette.text.secondary}}>
                                    {this.labels.HubMessageScheduleView}
                                </Typography>
                            </Grid>
                            <Grid item md={6} sx={{pt:0}} >
                                
                            </Grid>
                            <Grid item md={12} sx={{pt:1.5}} >
                                <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                                        <DesktopDatePicker
                                            value={this.state.startTime}
                                            onChange={(event) => this.changeDate(event as DateTime)}
                                            renderInput={(params) => 
                                                {
                                                    const { sx, ...paramsMinusSx } = params;
                                                    return <TextField
                                                                size="small"
                                                                {...paramsMinusSx}
                                                                sx={{
                                                                    width: "auto",
                                                                    "& legend": { display: "none" },
                                                                    "& fieldset": { top: 0 },
                                                                }}
                                                                error={false}
                                                            />
                                                }
                                            }
                                        />
                                    </LocalizationProvider>
                                    <IbssButton 
                                        variant={'contained'}
                                        onClick={()=> this.setState({openFilterModal: !this.state.openFilterModal})} 
                                    >
                                        {this.labels.HubButtonSpaceTypeFilter}
                                    </IbssButton> 
                                </Box>
                            </Grid>
                            <Grid item md={12} sx={{pt:1, pb: 1}} >
                                <Typography display={'inline'} variant="h6" sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                                    {`${this.labels.HubLabelFiltersApplied}: `}
                                </Typography>
                                <Typography display={'inline'} sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                                    {this.getFilterText()}
                                </Typography>
                            </Grid>
                        </Grid>
                        {this.state.spaces.length > 0 &&
                            <>
                                <ScheduleComponent
                                    currentView="Day"
                                    height='62vh' 
                                    width='100%'
                                    ref={this.scheduleRef}
                                    // show time line indicator of current time only if browser timezone is equal to building timezone.
                                    showTimeIndicator={Intl.DateTimeFormat().resolvedOptions().timeZone === DateHelper.getZoneByNode(this.state.buildingId)}
                                    eventSettings=
                                    {{
                                        dataSource: this.transformBookings(),
                                        fields: 
                                        {
                                            id: { title: 'id', name: 'id'},
                                            subject: { title: 'subject', name: 'subject'},
                                            startTime: { title: 'start time', name: 'startTime' },
                                            endTime:  { title: 'end time', name: 'endTime' },
                                        }
                                    }}
                                    group={this.groupData}
                                    showHeaderBar={false}
                                    startHour={`${this.state.buildingStartHrs.split(':')[0]}:00`} //ensure the start hour is a whole hour rather than 07:15.
                                    endHour={this.state.buildingEndHrs}
                                    workHours={{highlight: true, start: this.state.buildingStartHrs, end: this.state.buildingEndHrs}}
                                    eventRendered={this.onEventRendered}
                                    selectedDate={this.state.startTime.toJSDate()}
                                    popupOpen={this.onPopupOpen.bind(this)}
                                    select={this.onSelect.bind(this)}     
                                >
                                    <ResourcesDirective>
                                        <ResourceDirective
                                            field='spaceId' // has to match to a field of the data passed to ScheduleComponent eventSettings DataSource.  
                                            title='Space Id' // string displayed when one clicks on the booking
                                            name='Spaces' // match to a string in the arrya of groupData.resources
                                            allowMultiple={true}
                                            idField='Id'  
                                            textField='Name'
                                            colorField='Color'
                                            dataSource={this.state.spaces.slice(this.state.spacePageIndex*10, (this.state.spacePageIndex+1)*10).map(room => ({Id: room.spaceId, Name: room.spaceName}))}
                                        />
                                    </ResourcesDirective> 
                                    <Inject services={[Day]}/>
                                    <ViewsDirective>
                                        <ViewDirective option='Day'/>
                                    </ViewsDirective>
                                </ScheduleComponent>
                                <TablePagination
                                    component="div"
                                    count={this.state.spaces.length}
                                    page={this.state.spacePageIndex}
                                    onPageChange={(event, page) => this.handleChangePage(event, page)}
                                    rowsPerPage={10}
                                    rowsPerPageOptions={[10]}
                                    sx={theme => ({borderStyle: 'solid', borderWidth: '0px 1px 1px 1px', borderColor: theme.palette.grey[400] })}
                                />
                            </>
                        }
                        {
                            this.state.spaces.length === 0 && this.state.isLoading === false &&
                            <Box sx={{height: '62vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <NoSpaceScheduleViewIcon />
                                <Typography variant="h5" sx={{mt: 4, mb: 2, fontFamily: "Source Sans Pro" , fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                                    {this.labels.funcScheduleNoSpaces_L}
                                    </Typography>
                                <Typography sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold', color: (theme)=> theme.palette.text.primary}}>
                                    {this.labels.funcScheduleNoSpaces_D}
                                </Typography>
                                <IbssButton 
                                    variant="contained"
                                    size='large'
                                    sx={{fontFamily: "Source Sans Pro", mb: 3, mt: 3}}
                                    onClick={()=> this.setState({openFilterModal: true})}
                                >
                                    {this.labels.funcScheduleReturnToFilters_S}
                                </IbssButton>
                                <IbssButton 
                                    size='large'
                                    sx={{fontFamily: "Source Sans Pro", fontWeight: 'bold'}}
                                    onClick={()=> this.resetFilters()}
                                >
                                    {this.labels.funcScheduleResetFilters_S}
                                </IbssButton>
                            </Box>
                        }
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default ScheduleView;

export interface IState
{
    bookings: BookingView[],
    buildingId: number,
    floor: string,
    floorName: string,
    spaceType: (string),
    spaceWorkType: (string),
    capacity: string,
    zone: (string | null),
    startTime: DateTime,
    spaces: SpaceView[],
    buildingStartHrs: string,
    buildingEndHrs: string,
    openFilterModal: boolean,
    spacePageIndex: number,
    
    hasCatering: boolean,
    hasEquipment: boolean,

    showButton: boolean,
    selectedCell: Element | null,

    showCreateBooking: boolean,
    selectedSlot: ISelectedSlot | null,

    selectedBooking: BookingView | undefined,
    isLoading: boolean,
    
    sortSpacesOrder: ISortSpaces,
    zoneOptions: Array<IListOption<string>>,
}

export interface ISelectedSlot
{
    spaceId: string,
    startTime: Date,
    endTime: Date,
}
export interface IScheduleDate
{
    id: string,
    subject: string,
    startTime: Date,
    endTime: Date,
    bookingIsActive: number,
    bookingIsApproved: number,
    bookingStatus: string,
    spaceId: string, // singular, delimited spaceId, e.g.  spaceId: 1CC_03-NE-R005;1CC_03-SE-R006
    categoryColor: string,
    cancelledBookingsInSameSpaceTimeSlot: number, // total number of cancelled bookings in the same space and time
}

export class BookingView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceLayout = ""
    public bookingId = "";
    public bookingName = "";
    public bookingStart = "";
    public bookingEarlyCheckin = "";
    public bookingEnd = "";
    public bookingStatus = "";
    public bookingIsActive = 0;
    public bookingIsApproved = 0;
    public bookingOwnerEmail = "";
    public bookingOwnerName = "";
    public createdAt = "";
    public createdBy = "";

    public static fromBooking(booking: Booking): BookingView
    {
        return {
            nodeId: booking.Node_Id,
            spaceId: booking.Space_Id,
            spaceName: booking.Space_Name,
            spaceLayout: booking.Space_Layout,
            bookingId: booking.Booking_Id,
            bookingName: booking.Booking_Name,
            bookingStart: booking.Booking_Start.toISO(),
            bookingEarlyCheckin: booking.Booking_Early_Checkin.toISO(),
            bookingEnd: booking.Booking_End.toISO(),
            bookingStatus: booking.Booking_Status,
            bookingIsActive: booking.Booking_IsActive,
            bookingIsApproved: booking.Booking_IsApproved,
            bookingOwnerEmail: booking.Booking_Owner_Email,
            bookingOwnerName: booking.Booking_Owner_Name,
            createdAt: booking._CreatedAt.toISO(),
            createdBy: booking._CreatedBy,
        };
    }
}

export interface IQueryParams
{
    buildingid: string;
}

export class SpaceView
{
    public nodeId = 0;
    public spaceId = "";
    public spaceName = "";
    public spaceCapacity = 0;
    public spaceClass = "";
    public spaceType = "";
    public spaceTypeLabel = "";
    public spaceLayout = "";
    public spaceSetup = 0;
    public spaceStatus = "";
    public imageURI = "";
    public bookingPolicyId = "";
    public metaBookable = 0;
    public metaOccType = 0;
    public metaLocZone = "";
    public metaServReqsCatering = 0;
    public metaServReqsAV = 0;
    public metaServReqsHearing = 0;
    public metaServReqsPresentation = 0;
    public spaceWorkType = "";
    public meetingLinkAvailable = 0;

    public static fromSpace(space: Space): SpaceView
    {
        return {
            nodeId: space.Node_Id,
            spaceId: space.Space_Id,
            spaceName: space.Space_Name,
            spaceCapacity: space.Space_Capacity,
            spaceClass: space.Space_Class,
            spaceType: space.Space_Type,
            spaceTypeLabel: space.Space_Type_Label,
            spaceLayout: space.Space_Layout,
            spaceSetup: space.Space_Setup,
            spaceStatus: space.Space_Status,
            imageURI: space.ImageURI,
            bookingPolicyId: space.Booking_Policy_Id,
            metaBookable: space.Meta_Bookable,
            metaOccType: space.Meta_Occ_Type,
            metaLocZone: space.Meta_Loc_Zone,
            metaServReqsCatering: space.Meta_Serv_Reqs_Presentation,
            metaServReqsAV: space.Meta_Serv_Reqs_AV,
            metaServReqsHearing: space.Meta_Serv_Reqs_Hearing,
            metaServReqsPresentation: space.Meta_Serv_Reqs_Presentation,
            spaceWorkType: space.Space_Work_Type,
            meetingLinkAvailable: space.Meta_Ext_Booking_System
        };
    }
}

enum ISortSpaces
{
    Ascending,
    Descending
}