import { Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { getAllBuildingsData } from "../../../Common/Helper";
import { connect } from "react-redux";
import { appContext } from "../../../AppContext";
import { Box, TextField } from '@mui/material';
import { ONELENS_SPACEANALYTICS_OVERVIEW } from "../../../app/constants";
import IbssDatePicker from "../../../Components/uicomponents/Datepicker/IbssDatePicker";
import { withRouter } from "react-router-dom";
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import { DateTime } from "luxon";

type OptionNumType = {
    label: string,
    value: number
}

type OptionStrType = {
    label: string,
    value: string
}

type SearchCriteriaState = {
    buildingOptions: Array<OptionNumType>,
    workSpaceTypeOptions: Array<OptionStrType>,
    SpaceTypeOptions: Array<OptionStrType>,
    floorTypeOptions: Array<OptionNumType>,
    zoneOptions: Array<OptionStrType>,
    selectedBuildingOption: string | number, // mistyped in Class State as string | number[]? observed a number in React Components.
    selectedWorkspaceTypes: string,
    selectedSpaceTypes: string;
    selectedFloor: string | number;
    selectedZone: string,
    fmsfc_start_date_for_filter_modal: Date | null, // todo: this should be either a date or a string, not both
    End_Date_For_filter_modal: Date | null, // todo: this should be either a date or a string, not both
    periodTypeOption: Array<OptionNumType>,
    selectedperiodType: number,
}

class SpaceAnalyticsMainSearch extends Component<any, SearchCriteriaState>
{
    private alert = appContext().alert;
    private session = appContext().sessionStorageProvider;
    private local = appContext().localStorageProvider;

    private buildingid = this.session.getBuildingId();
    private labels = appContext().labels;
    private startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    private defaultMonthStartDate = DateTime.fromJSDate(this.startDate).toJSDate();
    private defaultEndDate = DateTime.fromJSDate(this.startDate).endOf('month').toJSDate();

    constructor(props: any) {
        super(props);
        this.state = {
            buildingOptions: [],
            workSpaceTypeOptions: [],
            SpaceTypeOptions: [],
            floorTypeOptions: [],
            zoneOptions: [],
            selectedBuildingOption: "",
            selectedWorkspaceTypes: "",
            selectedSpaceTypes: "",
            selectedFloor: "",
            selectedZone: "",
            fmsfc_start_date_for_filter_modal: null,
            End_Date_For_filter_modal: null,
            periodTypeOption: [],
            selectedperiodType: 1
        }
    }

    public async componentDidMount() {
        const periodType = [
            { label: `${this.labels.HublabelDay}`, value: 3 },
            { label: `${this.labels.HublabelWeek}`, value: 0 },
            { label: `${this.labels.HublabelMonth}`, value: 1 },
            { label: `${this.labels.HublabelYear}`, value: 2 },
        ];
        const bldgOpts = this.makeBuildingOptions(getAllBuildingsData());
        this.setState({
            buildingOptions: bldgOpts,
            periodTypeOption: periodType,
            fmsfc_start_date_for_filter_modal: this.defaultMonthStartDate,
            End_Date_For_filter_modal: this.defaultEndDate
        });
        const userPreferences = this.local.getUserPreferences();
        await this.setState({ selectedperiodType: this.props.onelensSpaceAnalyticsOverview.periodType });
        if (userPreferences.SearchPrefs.DefaultBuilding != null && userPreferences.SearchPrefs.DefaultBuilding != undefined && Object.keys(this.props.flexMySearchFilterCriteria).length === 0) {
            await this.setState({ selectedBuildingOption: this.props.onelensSpaceAnalyticsOverview.buildingNodeId });


            if (userPreferences.WorkingHoursPrefs.UserStartTime != null) {
                this.setState({ fmsfc_start_date_for_filter_modal: this.props.onelensSpaceAnalyticsOverview.fmsfc_start_date_for_filter_modal });
            }
            if (userPreferences.WorkingHoursPrefs.UserEndTime != null) {
                this.setState({ End_Date_For_filter_modal: this.props.onelensSpaceAnalyticsOverview.End_Date_For_filter_modal });
            }
        }

    }

    // Building option call
    public makeBuildingOptions(data: any) {
        let options: any = [{ label: "All", value: 1 }];
        data.map((obj: any) => {
            options.push({ label: obj['Name'], value: obj['Node_Id'] })
        });
        return options;
    }

    // Space type option call
    public makeSpaceTypeOptions(data: any) {
        let options: any = [];
        const spacesSet = new Set();
        data.map((obj: any) => {
            if (!spacesSet.has(obj['Name'])) {
                options.push({ label: obj['Label'], value: obj['Name'] })
                spacesSet.add(obj['Name'])
            }
        });
        options.unshift({ label: "Any", value: "Any" })
        this.setState({
            SpaceTypeOptions: options,
            selectedSpaceTypes: options[1].value,
        });
    }

    /*Filter calendar according to type day, month, year*/
    public getCalendarView(selectedPeriodType: number) {
        let view: CalendarPickerView = 'month';
        if (this.state.selectedperiodType === 1) {
            view = view;
        } else if (this.state.selectedperiodType === 2) {
            view = "year";
        } else if (this.state.selectedperiodType === 3) {
            view = "day"
        } else {
            view = "day"
        }
        return view;
    };

    public async handleOnBldngChange(event: SelectChangeEvent) {
        await this.setState({
            selectedBuildingOption: event.target.value
        });
        this.updateBlndgDependentParams();

    };

    public handleOnWorkspaceTypeChange(event: SelectChangeEvent) {
        this.setState({
            selectedWorkspaceTypes: event.target.value,
            selectedSpaceTypes: "Any"
        });
    };

    /* Get last week monday */
    public getMondayOfLastWeek() 
    {
        const today = DateTime.now();
        const first = today.minus({ weeks: 1 }).startOf('week');
        const last = today.endOf('week').minus({ days:1 });
        const monday = first.toJSDate();
        const lastday = last.toJSDate();
        this.setState({ fmsfc_start_date_for_filter_modal: monday });
        this.setState({ End_Date_For_filter_modal: lastday })
    }

    public handlePeriodTypeChange(event: SelectChangeEvent) {
        this.setState({
            selectedperiodType: parseInt(event.target.value)
        })
        if (Number(event.target.value) === 2) {
            this.setState({ fmsfc_start_date_for_filter_modal: new Date(new Date().getFullYear(), 0, 1) });
            this.setState({ End_Date_For_filter_modal: new Date(new Date().getFullYear(), 11, 31) })
        } else if (Number(event.target.value) === 3) {
            this.setState({ fmsfc_start_date_for_filter_modal: new Date(new Date().setDate(new Date().getDate() - 1)) });
            this.setState({ End_Date_For_filter_modal: DateTime.local().endOf('day').toJSDate()});
        } else if (Number(event.target.value) === 0) {
            this.getMondayOfLastWeek();
        } else {
            this.setState({ fmsfc_start_date_for_filter_modal: new Date(new Date().getFullYear(), new Date().getMonth(), 1) });
            this.setState({ End_Date_For_filter_modal: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) })
        }
    }

    public clear(): void {

        this.setState({
            selectedBuildingOption: this.buildingid,
            selectedWorkspaceTypes: 'Any',
            selectedSpaceTypes: 'Any',
            selectedFloor: 'Any',
            selectedZone: 'Any',
            fmsfc_start_date_for_filter_modal: this.defaultMonthStartDate,
            End_Date_For_filter_modal: this.defaultEndDate,
            selectedperiodType: 1
        });
        this.props.dispatch({
            type: ONELENS_SPACEANALYTICS_OVERVIEW, payload: {
                buildingOption: this.session.getBuildingName(),
                floor: "Any",
                classType: "Work",
                WorkSpace: "Any",
                periodTypeValue: "Month",
                periodType: 1,
                buildingNodeId: this.session.getBuildingId(),
                fmsfc_start_date_for_filter_modal: this.defaultMonthStartDate,
                End_Date_For_filter_modal: this.defaultEndDate
            }
        });
        const filterData = { buildingNodeId: this.buildingid, End_Date_For_filter_modal: this.defaultEndDate, buildingOption: this.session.getBuildingName(), fmsfc_start_date_for_filter_modal: this.defaultMonthStartDate, periodType: 1, periodTypeValue: "Month" };
        this.props.updateSearchResults(this.buildingid, filterData)
    }

    public updateBlndgDependentParams() {
        if (this.state.selectedBuildingOption != undefined && this.state.selectedBuildingOption != null && this.state.selectedBuildingOption != '') {
            this.setState({
                fmsfc_start_date_for_filter_modal: this.defaultMonthStartDate,
                End_Date_For_filter_modal: this.defaultEndDate,
            });
        }
    }

    public selectedMondays = (day: Date | DateTime) => {
        if (this.state.selectedperiodType === 0 && (day instanceof DateTime))
        {
            return day.weekday !== 1;
        }
        return false;
    };

    /* Start_Date & End_Date change as per selection */
    public handleStartDate = (e: Date) => {
        const startDay = e;
        if (this.state.selectedperiodType === 2) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: new Date(new Date(e).getFullYear(), 11, 31),
            });
        } else if (this.state.selectedperiodType === 1) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: new Date(new Date(e).getFullYear(), new Date(e).getMonth() + 1, 0),
            });
        } else if (this.state.selectedperiodType === 0) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: new Date(new Date(e).setDate(new Date(e).getDate() - new Date(e).getDay() + 1 + 6)),
            });
        } else if (this.state.selectedperiodType === 3) {
            this.setState({
                fmsfc_start_date_for_filter_modal: startDay,
                End_Date_For_filter_modal: new Date(new Date(e).setDate(new Date(e).getDate())),
            });
        }
    };

    public async upDateSearchResults() {
        let buildingId = null;
        let filterData: any = {};

        if (this.state.selectedBuildingOption != undefined && this.state.selectedBuildingOption != null && this.state.selectedBuildingOption != '') {
            buildingId = parseInt(this.state.selectedBuildingOption as string)
            const buildingName = this.state.buildingOptions.filter((val: any) => val.value === this.state.selectedBuildingOption);
            filterData = { ...filterData, buildingOption: buildingName[0].label, buildingNodeId: this.state.selectedBuildingOption }
        }
        else {
            //set the default value from user prefs
            let buildingId = this.session.getBuildingId();
            filterData = { ...filterData, buildingOption: buildingId }
        }

        if (this.state.selectedperiodType || this.state.selectedperiodType === 0) {
            filterData = {
                ...filterData, periodTypeValue: this.state.selectedperiodType === 1 ? "Month" : this.state.selectedperiodType === 2 ? "Year" : this.state.selectedperiodType === 3 ? "Day" : this.state.selectedperiodType === 0 ? "Week" : "",
                periodType: this.state.selectedperiodType
            }
        }
        if (this.state.fmsfc_start_date_for_filter_modal && this.state.End_Date_For_filter_modal) {
            filterData = { ...filterData, fmsfc_start_date_for_filter_modal: this.state.fmsfc_start_date_for_filter_modal, End_Date_For_filter_modal: this.state.End_Date_For_filter_modal }

        }
        await this.props.dispatch({ type: ONELENS_SPACEANALYTICS_OVERVIEW, payload: filterData });
        await this.props.updateSearchResults(buildingId, filterData)
        this.props.toggleDrawer();
    }

    public render() {
        const { open, toggleDrawer }: any = this.props;
        return (

            <>
                <Drawer open={open} onClose={() => toggleDrawer()} direction='right' className='flex-search-filter-criteria' style={{ backgroundColor: "var(--ui-background-alternate)" }}>
                    <div className="flexMySearch-filter-criteria">
                        <div className="flexMySearch-filter-criteria-header">
                            <span className="flexMySearch-filter-criteria-icon">
                                <img className="flexMySearch-filter-criteria-img " src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Filter-2.svg`} alt="icon" />
                            </span>
                            <span className="flexMySearch-filter-criteria-title">{this.labels.HubLabelflexFilterSearchCritera} </span>
                            <span className="flexMySearch-filter-criteria-close" onClick={() => toggleDrawer()}>&times;</span>
                        </div>


                        <div className="flexMySearch-filter-criteria-content">
                            {/* Building select */}
                            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                <div className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HubLabelBuilding}</div>
                                <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                    <FormControl fullWidth size="small">
                                        <InputLabel shrink={false} className="fms-fc-placeholder">
                                            {this.state.selectedBuildingOption == '' && '***Select an Option***'}
                                        </InputLabel>

                                        <Select
                                            disabled
                                            displayEmpty
                                            value={this.state.selectedBuildingOption}

                                            onChange={(e: any) => this.handleOnBldngChange(e)}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>

                                            {this.state.buildingOptions.map((eachVal: any) => {
                                                return (
                                                    <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                </div>
                            </div>
                            <div className="flexMySearch-filter-criteria-border" />

                            {/* date and period */}
                            <div className="flexMySearch-filter-criteria-firstLabel">
                                {this.labels.HubLabelDateandTime}
                            </div>
                            {/* Period Type */}
                            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                <div className="col-5 card pt-0 ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HublabelPeriodType}</div>
                                <div className="col-7 card pt-0 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={this.state.selectedperiodType}
                                            onChange={(e: any) => this.handlePeriodTypeChange(e)}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                            {this.state.periodTypeOption.map((eachVal: any) => {
                                                return (
                                                    <MenuItem value={eachVal.value}>{eachVal.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* Date Selection */}
                            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
                                <div className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">{this.labels.HublabelPeriodStartDate}</div>
                                <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                                    <FormControl fullWidth size="small">
                                        <IbssDatePicker
                                            value={this.state.fmsfc_start_date_for_filter_modal}
                                            onChange={(event) => this.handleStartDate(event as Date)}
                                            renderInput={(props) => <TextField {...props} size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                                            shouldDisableDate={this.selectedMondays}
                                            views={[this.getCalendarView(this.state.selectedperiodType)]}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className="flexMySearch-filter-criteria-border" />
                            {/* buttons */}
                            <div className="right-space-box-cont">
                                <div className="d-flex justify-content-center">
                                    <a type="button" className="clear-attendees my-2" onClick={() => this.clear()}>{this.labels.HubLabelClearSelections}</a>
                                    <span className="ml-2">
                                        <IbssButtonRedo
                                            variant='contained'
                                            onClick={() => this.upDateSearchResults()}
                                        >
                                            {this.labels.HubButtonUpdate}
                                        </IbssButtonRedo>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
        onelensSpaceAnalyticsOverview: state.onelensSpaceAnalyticsOverview
    };
};

export default withRouter(connect(mapStateToProps)(SpaceAnalyticsMainSearch));