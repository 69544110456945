import "./FloorPlan.scss";
import React, { Component, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { appContext } from "../../AppContext";
import { IApiCache } from "../../Providers.Api/ApiCache";
import SpaceCard, { Props as SpaceCardProps } from "../SpaceCard/SpaceCard";
import apis from "../../Providers.Api/apis";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import IbssDialog from "../uicomponents/IbssDialog";
import { Box } from "@mui/material";
import IbssButtonRedo from "../uicomponents/IbssButton";
import { LightWeightSpace } from "../../Providers.Api/Spaces/SpaceRepository";
import LoadingOverlay from "../LoadingOverlay";
import { IBooking } from "../../Providers.Api/Bookings/GetBookingsEndpoint";
import { getBuildingNodeIdUsingFloorNodeId, getBuildingTimeZoneByNodeId } from "../../Common/Helper";
import { IEnvironmentalZoneData } from "../../Providers.Api/EnvironmentalZoneData/GetByZoneIdsEndpoint";
import { SpacesFilter } from "../../Providers.Api/Spaces/SpaceRepository";

export default class FloorPlan extends Component<IProps, State>
{
    private labels = appContext().labels;
    private apiCache = appContext().apiCache;
    private apiClient = appContext().apiClient;
    private local = appContext().localStorageProvider;
    private panAmount = 200;
    private transformRef: React.RefObject<ReactZoomPanPinchRef>;
    private svgContainerRef: React.RefObject<HTMLDivElement>;
    private canSearchPeople: boolean;
    private spaces = new Map<string, LightWeightSpace>();

    constructor(props: IProps)
    {
        super(props);
        this.state = new State();
        this.transformRef = React.createRef();
        this.svgContainerRef = React.createRef();
        this.canSearchPeople = this.local.hasRight('FLEX.Search.People');
    }

    public componentDidUpdate(oldProps: IProps): void
    {
        if (oldProps.url !== this.props.url || oldProps.highlightedSpaces !== this.props.highlightedSpaces)
        {
            this.loadSvg();
        }
    }

    public async componentDidMount(): Promise<void>
    {
        this.loadSvg();
    }

    private async loadSvg(): Promise<void>
    {
        try
        {
            this.setState({ loading: true });

            if (!this.props.floorId)
            {
                this.spaces = new Map<string, LightWeightSpace>();
                this.setState({ svg: "" });
                this.props.mapFailedToLoad();
                return;
            }

            const spaces = await this.apiClient.spaces.getV1Spaces(LightWeightSpace, this.props.floorId, 10000, new SpacesFilter({ isEnabled: true }));
            this.spaces = new Map(spaces.map(i => [ i.Space_Id, i ]));
            let svgString: string;

            try
            {
                let truncatedUrl = this.props.url.replace(/^.*?\/images\//, "/");
                svgString = (truncatedUrl ? await this.apiCache.getFile(truncatedUrl) : "");
            }
            catch
            {
                this.setState({ svg: "" });
                this.props.mapFailedToLoad();
                return;
            }

            let svgContainer = document.createElement("div");
            svgContainer.innerHTML = svgString;
            let svgNode = Array.from(svgContainer.childNodes).filter(i => i.nodeName == "svg")[0] as SVGElement;
        

            if (svgNode == null)
            {
                this.setState({ svg: "" });
                return;
            }
        
            await this.showZoneData(svgContainer);
            this.highlightSpaces(svgNode);
            this.colourMap(svgNode);
            this.removeLargelabels(svgNode);
            this.flagAllSpaces(svgNode);
            this.showOccupacy(svgContainer);
            this.setState({ svg: svgNode.outerHTML });
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private getIaqStatus(iaq: number): string 
    {
        switch (iaq)
        {
            case 1:
                return this.labels.HubLabelGood;
            case 2:
                return this.labels.HubLabelOk;
            case 3:
                return this.labels.HubLabelPoor;
            default:
                return "";
        }
    }

    private getSoundLevelRange(soundRange: number): string 
    {
        switch (soundRange) 
        {
            case -1:
                return this.labels.HubLabelSilent;
            case 0:
                return this.labels.HubLabelQuiet;
            case 1:
                return this.labels.HubLabelNormal;
            default:
                return "";
        }
    }

    private async showZoneData(svgContainer: SvgElement): Promise<void>
    {
        if (!this.props.floorId)
        {
            return;
        }

        const zoneIds = Array.from(svgContainer.querySelectorAll('g[id="Environmental"]'))
            .filter(i => i instanceof SVGGElement)
            .map(i => i as SVGGElement)
            .flatMap(i => Array.from(i.querySelectorAll("g")))
            .map(i => i.id)
            .filter((str) => /^\d+$/.test(str));

        let zoneDatas: IEnvironmentalZoneData[];
        try
        {
            zoneDatas = await this.apiClient.environemntalZoneData.getByZoneIds(this.props.floorId, zoneIds);
        }
        catch
        {
            return;
        }

        for (const zoneData of zoneDatas)
        {
            const { Env_Zone_Id: zoneId, Env_Temperature: temperature, IAQ_Status: iaqStatus, Env_Sound_Level_Range: soundLevelRange } = zoneData;
            const gElement = svgContainer.querySelector(`g[data-env="${zoneId}"]`);

            if (!gElement)
            {
                continue;
            }

            const textElements = gElement.querySelectorAll('text');
            for (const textElement of Array.from(textElements))
            {
                const tspanElement = textElement.querySelector('tspan');
                if (!tspanElement)
                {
                    continue;
                }

                const dataText = textElement.getAttribute('data-text');
                switch (dataText)
                {
                    case 'Env_Temperature_Text':
                        tspanElement.textContent = `${temperature}°C`;
                        break;
                    case 'IAQ_Status_Text':
                        tspanElement.textContent = this.getIaqStatus(iaqStatus);
                        break;
                    case 'Env_Sound_Level_Range_Text':
                        tspanElement.textContent = this.getSoundLevelRange(soundLevelRange);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    private highlightSpaces(svgNode: SVGElement): void
    {
        let self = this;
        for (let i = 0; i < this.props.highlightedSpaces.length; i++)
        {
            let space = this.props.highlightedSpaces[i];
            let spaceNode = document.evaluate(`.//*[@id='${space.id}']`, svgNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

            if (spaceNode instanceof SVGElement)
            {
                let colouredSpaceNodes = document.evaluate(`../*[@id = '${space.id}' and ( @data-fillcolour = 'ui-secondary' or @data-strokecolour = 'ui-secondary' )] | .//*[@data-fillcolour = 'ui-secondary' or @data-strokecolour = 'ui-secondary']`, spaceNode, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
                for (let j = 0; j < colouredSpaceNodes.snapshotLength; j++)
                {
                    let colouredSpaceNode = colouredSpaceNodes.snapshotItem(j);
                    if (colouredSpaceNode instanceof SVGElement)
                    {
                        if (space.getColourFromData)
                        {
                            if (colouredSpaceNode.hasAttribute("data-fillcolour"))
                            {
                                colouredSpaceNode.setAttribute("data-fillcolour", "ui-success");
                            }
                            if (colouredSpaceNode.hasAttribute("data-strokecolour"))
                            {
                                colouredSpaceNode.setAttribute("data-strokecolour", "ui-success");
                            }
                        }
                        else
                        {
                            colouredSpaceNode.removeAttribute("data-fillcolour");
                            colouredSpaceNode.removeAttribute("data-strokecolour");
                            colouredSpaceNode.setAttribute("fill", space.colour);
                            let titleElement = document.createElement('title');
                            titleElement.textContent = `${this.labels.HubLabelUtilisation}: ${space ? Math.round(space.periodCurrentSpaceValue).toString() : 'null'}%`;
                            colouredSpaceNode.appendChild(titleElement);                            
                        }
                    }
                }
            }
        }
    }

    private colourMap(svgNode: SVGElement): void
    {
        let colouredNodes = document.evaluate(`.//*[@data-fillcolour|@data-strokecolour]`, svgNode, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
        for (let i = 0; i < colouredNodes.snapshotLength; i++)
        {
            let colouredNode = colouredNodes.snapshotItem(i);
            if (colouredNode instanceof SVGElement)
            {
                let fillColour = colouredNode.getAttribute("data-fillcolour");
                let isBackground = (fillColour == "ui-background" && colouredNode.parentNode == svgNode || colouredNode.parentNode?.parentNode == svgNode);

                if (isBackground)
                {
                    colouredNode.setAttribute("fill", `var(--ui-background-alternate)`);
                }
                else if (fillColour)
                {
                    colouredNode.setAttribute("fill", `var(--${fillColour})`);
                }
                else
                {
                    colouredNode.removeAttribute("fill");
                    let space = this.props.highlightedSpaces[i];
                    let titleElement = document.createElement('title');
                    titleElement.textContent = (space ? space.periodCurrentSpaceValue.toString() : "null");
                    colouredNode.appendChild(titleElement);
                }

                let strokeColour = colouredNode.getAttribute("data-strokecolour");
                if (strokeColour)
                {
                    colouredNode.setAttribute("stroke", `var(--${strokeColour})`);
                }
                else
                {
                    colouredNode.removeAttribute("stroke");
                }
            }
        }
    }

    private removeLargelabels(svgNode: SVGElement): void
    {
        let largeLabelsNode = document.evaluate(`.//*[@id="Labels_Large" and @data-layer="Labels"]`, svgNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
        if (largeLabelsNode instanceof SVGElement)
        {
            largeLabelsNode.remove();
        }
    }

    private flagAllSpaces(svgNode: SVGElement): void
    {
        const nodes = document.evaluate(`.//*[@id]`, svgNode, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
        for (let i = 0; i < nodes.snapshotLength; i++)
        {
            const node = nodes.snapshotItem(i);
            if (node instanceof SVGElement)
            {
                const space = this.spaces.get(node.id);
                if (space != null && space.Meta_Bookable != 0 && space.Meta_Bookable != 2)
                {
                    node.setAttribute("data-isspace", "true");
                }
            }
        }
    }

    private showOccupacy(svgContainer: SvgElement): void
    {
        const spaceElements = Array.from(svgContainer.querySelectorAll('g[id="Complex"]'))
            .filter(i => i instanceof SVGGElement)
            .map(i => i as SVGGElement)
            .flatMap(i => Array.from(i.querySelectorAll("g")));

        for (const spaceElement of spaceElements)
        {
            const space = this.spaces.get(spaceElement.id);
            if (space == null)
            {
                continue;
            }

            const { Space_Capacity, Space_Occupancy } = space;

            const numOfFreeSpacesElement = spaceElement.querySelector('#ItemValue tspan');
            if (numOfFreeSpacesElement)
            {
                const numOfFreeSpaces = (Space_Capacity - Space_Occupancy);
                numOfFreeSpacesElement.textContent = numOfFreeSpaces.toString();
            }

            const occupancyElement = spaceElement.querySelector('#Progress');
            if (occupancyElement)
            {
                const maxOccupancyElement = spaceElement.querySelector('#ProgressBG');
                const widthOfMaxOccupancy = Number(maxOccupancyElement?.getAttribute('width'));
                const widthOfOccupancy = (Space_Occupancy / Space_Capacity) * widthOfMaxOccupancy;
                occupancyElement.setAttribute('width', widthOfOccupancy.toString());

                const percentOccupancy = (Space_Occupancy / Space_Capacity * 100);
                const fillColour = (percentOccupancy < 50 ? "var(--ui-success)" : (percentOccupancy <= 75 ? "var(--ui-warn)" : "var(--ui-error)"));
                occupancyElement.setAttribute('fill', fillColour);
            }
        }
    } 

    private pan(setTransform: (newPositionX: number, newPositionY: number, newScale: number) => void, deltaX: number, deltaY: number): void
    {
        let transformState = this.transformRef.current?.state;
        if (transformState == null)
        {
            return;
        }
        setTransform(transformState.positionX + deltaX, transformState.positionY + deltaY, transformState.scale);
    }

    private async svgClicked(event: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void>
    {
        const url = window.location.href;
        const path = new URL(url).pathname;
        let spaceElement = this.getSpaceElement(event.target as HTMLElement);
        if (spaceElement == null)
        {
            return;
        }
        if(path === "/flex-find-a-space" || path === "/flex-recurring-booking"){
            let spaceId = spaceElement.id;
            await this.spaceClicked(spaceId);   
        }
    }

    private getSpaceElement(element: HTMLElement): (HTMLElement | null)
    {
        if (element == this.svgContainerRef.current || element.parentElement == null)
        {
            return null;
        }
        else if (element.getAttribute("data-isspace") == "true")
        {
            return element;
        }
        else
        {
            return this.getSpaceElement(element.parentElement);
        }
    }

    private async spaceClicked(spaceId: string): Promise<void>
    {
        this.setState({ bookings: null });
        if (!this.props.floorId)
        {
            return;
        }

        const isSpaceAvailable = (this.props.highlightedSpaces.find(i => i.id == spaceId) != null);
        if (!isSpaceAvailable && !this.canSearchPeople)
        {
            return;
        }

        const space = await appContext().apiCache.getSpace(this.props.floorId, spaceId);
        if (space == null)
        {
            return;
        }

        let spaceCardProps = SpaceCardProps.fromSpace(space);
        if (!isSpaceAvailable)
        {
            const startOfDay = (this.props.startTime ?? DateTime.now()).date().setZoneByNode(this.props.floorId, true);
            const endOfDay = startOfDay.plus({ days: 1 });
            const bookings = await this.apiClient.bookings.getBookings(this.props.floorId, 10, startOfDay, endOfDay, { spaceId: space.Space_Id, statusNot: ['Cancelled', 'Auto Cancelled'] });
            this.setState({ bookings: bookings });
        }
        this.setState({ spaceCard: spaceCardProps, showBookButton: isSpaceAvailable });
    }

    private spaceModalClicked(): void
    {
        if (this.props.spaceModalClicked == null)
        {
            return;
        }
        this.props.spaceModalClicked(this.state.spaceCard?.spaceId ?? "");
        this.setState({ spaceCard: null });
    }

    private hideSpaceModalClicked(): void
    {
        this.setState({ spaceCard: null });
    }

    public render(): JSX.Element
    {
        return (
            <TransformWrapper ref={this.transformRef}>
                {({ zoomIn, zoomOut, setTransform, resetTransform, ...rest }) => (
                    <>
                        <div className="floorplan">
                            <div className="floorplan-buttons">
                                <div className="floorplan-pan"></div>

                                <i className="floorplan-north fa fa-caret-up" onClick={() => this.pan(setTransform, 0, this.panAmount)} />
                                <i className="floorplan-north-east" onClick={() => this.pan(setTransform, -this.panAmount, this.panAmount)} />
                                <i className="floorplan-east fa fa-caret-right" onClick={() => this.pan(setTransform, -this.panAmount, 0)} />
                                <i className="floorplan-south-east" onClick={() => this.pan(setTransform, -this.panAmount, -this.panAmount)} />
                                <i className="floorplan-south fa fa-caret-down" onClick={() => this.pan(setTransform, 0, -this.panAmount)} />
                                <i className="floorplan-south-west" onClick={() => this.pan(setTransform, this.panAmount, this.panAmount)} />
                                <i className="floorplan-west fa fa-caret-left" onClick={() => this.pan(setTransform, this.panAmount, 0)} />
                                <i className="floorplan-north-west" onClick={() => this.pan(setTransform, this.panAmount, -this.panAmount)} />

                                <i className="floorplan-centre fa fa-bullseye" onClick={() => resetTransform()} />
                                <i className="floorplan-zoom-in fa fa-plus" onClick={() => zoomIn()} />
                                <i className="floorplan-zoom-out fa fa-minus" onClick={() => zoomOut()} />
                            </div>

                            {window.location.href.includes('space-analytics-heatmaps') ? 
                            (
                                <div style={{ color: `var(--ui-text)`, position: 'absolute', left: 20, top: 100, zIndex: 9, }}>
                                    <div>100%</div>
                                    <img src="/images/Rectangle 939.svg" alt="Gradient color" />
                                    <div>0%</div>
                                </div>
                            ) : ('')
                            }

                            <TransformComponent>
                                {this.state.loading && <LoadingOverlay />}
                                <div className="floorplan-container" ref={this.svgContainerRef} onClick={e => this.svgClicked(e)} dangerouslySetInnerHTML={{ __html: this.state.svg }} />
                            </TransformComponent>

                            {
                                this.state.spaceCard &&
                                <IbssDialog
                                    id="map-modal"
                                    open={this.state.spaceCard !== null}
                                    content=
                                    {
                                        <>
                                            <SpaceCard {...this.state.spaceCard} closeIcon={true}  pointer={false} handleClose={() => this.hideSpaceModalClicked()} bookings={this.state.bookings} />
                                            {this.state.showBookButton ?
                                            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                                                {this.state.showBookButton && (<IbssButtonRedo color="primary" variant="contained" onClick={() => this.spaceModalClicked()}>{this.labels.HubButtonBookSpace}</IbssButtonRedo>)}
                                            </Box>: " "}
                                        </>
                                    }
                                    onClose={() => this.hideSpaceModalClicked()}
                                    className={`floorplan-modal ${this.state.spaceCard.showNoBookingMessage ? 'floorplan-noBookingData' : 'floorplan-hasBookingData'}`}
                                />
                            }

                        </div>
                    </>
                )}
            </TransformWrapper>
        );
    }
}

export interface IProps
{
    url: string;
    highlightedSpaces: IHighlightedSpace[];
    spaceModalClicked?: (spaceId: string) => void;
    mapFailedToLoad: () => void;
    floorId: (number | null),
    startTime?: DateTime,
    endTime?: DateTime
}

export interface SvgElement extends Element {
    id: string;
    querySelectorAll(selector: string): NodeList;
  }

export interface IHighlightedSpace
{
    id: string;
    colour: string;
    getColourFromData: boolean;
    periodCurrentSpaceValue: number;
}

export class State
{
    public svg = "";
    public spaceCard: (SpaceCardProps | null) = null;
    public showBookButton = false;
    public loading = false;
    public bookings: IBooking[] | null = [];
}
