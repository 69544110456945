import { appContext } from "../AppContext";
import { Space, SpacesFilter } from "../Providers.Api/Spaces/SpaceRepository";

export class SpaceService implements ISpaceService
{
    private get api() { return appContext().apiClient; }
    private get hasV2Rights() { return appContext().localStorageProvider.hasRight("API.Spaces.V2"); }

    public async getSpaces<TSpace>(type: new() => TSpace, nodeId: number, top: number, filter?: SpacesFilter): Promise<TSpace[]>
    {
        if(this.hasV2Rights)
        {
            return this.api.spaces.getV2Spaces(type, nodeId, top, filter);
        }
        else
        {
            return this.api.spaces.getV1Spaces(type, nodeId, top, filter);
        }
    }
}

export interface ISpaceService
{
    getSpaces<TSpace>(type: new() => TSpace, nodeId: number, top: number, filter?: SpacesFilter): Promise<TSpace[]>;
}
