import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import Guid from "../../Common/Guid";
import { IbssRequestConfig } from "../ApiClient";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CreateEndpoint implements ICreateEndpoint
{
    public async execute(cateringMenu: ICateringMenu): Promise<string>
    {
        try
        {
            const payload =
            {
                ...cateringMenu,
                Menu_Id: (new Guid()).toString(),
                Description: cateringMenu.Short_Description,
                Available_From: cateringMenu.Available_From.toUTC().toISO(),
                Available_Until: cateringMenu.Available_Until.toUTC().toISO(),
                Status: "StatusActive",
            }
            const response = await axios.post(`${apis.allApiv2}${cateringMenu.Node_Id}/catering-menu`, payload);
            return payload.Menu_Id;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateEndpoint
{
    execute(cateringMenu: ICateringMenu): Promise<string>;
}

export interface ICateringMenu
{
    Node_Id: number;
    Name: string;
    Short_Description: string;
    Available_From: DateTime;
    Available_Until: DateTime;
    ImageURI: string;
}
