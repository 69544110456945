import { ApiHelper } from "../Providers.Api/ApiHelper";
import { IFilter } from "./ODataFilter";

export class ODataQuery<TSelect = void>
{
    public select: ((new() => TSelect) | null) = null;
    public filter: (IFilter | null) = null;
    public nodeId = 1;
    public top = 0;
    public skipToken = "";
    public orderBy = "";

    public static get empty(): ODataQuery
    {
        return new ODataQuery({});
    }

    constructor(value: Partial<ODataQuery<TSelect>>)
    {
        Object.assign(this, value);
    }

    public toODataString(filterSeparator: string, customFilter: string): string
    {
        let filterParts: string[] = [];
        filterParts.push(this.filter == null ? "" : this.filter.toODataString());
        filterParts.push(customFilter);
        filterParts = filterParts.filter(i => i != "");
        const filterString = (filterParts.length == 0 ? "" : "$filter=" + filterParts.join(filterSeparator));

        let query: string[] = [];
        query.push(this.select == null ? "" : `$select=${ApiHelper.toODataSelect(this.select)}`);
        query.push(filterString);
        query.push(this.top == 0 ? "" : `$top=${this.top}`);
        query.push(this.skipToken == "" ? "" : `$skipToken=${this.skipToken}`);
        query.push(this.orderBy == "" ? "" : `$orderby=${this.orderBy}`);
        query = query.filter(i => i != "");
        const queryString = query.join("&");

        return queryString;
    }

    public fixTypes(primitiveResponse: TSelect): void
    {
        if (this.select == null)
        {
            return;
        }

        const select = new this.select() as any;
        if (select.fixTypes == null)
        {
            return;
        }

        select.fixTypes(primitiveResponse);
    }
}
