import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { ApiError } from "../ApiError";
import { ApiHelper } from "../ApiHelper";
import apis from "../apis";
import { SpacesFilter } from "./SpaceRepository";

export class SearchSpacesEndpoint implements ISearchSpacesEndpoint
{
    public async execute(nodeId: number, payload: ISpaceSearch): Promise<IAvailableSpace[]>
    {
        try
        {
            const primitivePayload =
            {
                Booking_Dates: payload.Booking_Dates.map(i =>
                ({
                    Start_Time: i.Start_Time.toUTC().toISO(),
                    End_Time: i.End_Time.toUTC().toISO(),
                })),
                Space_Capacity: payload.Space_Capacity,
                Space_Type: payload.Space_Type,
                Meta_Serv_Reqs_AV: payload.Meta_Serv_Reqs_AV,
                Meta_Serv_Reqs_Catering: payload.Meta_Serv_Reqs_Catering,
                Meta_Serv_Reqs_Hearing: payload.Meta_Serv_Reqs_Hearing,
                Meta_Serv_Reqs_Presentation: payload.Meta_Serv_Reqs_Presentation,
                Floor_Id: payload.Floor_Id,
                Meta_Loc_Zone: payload.Meta_Loc_Zone,
                Space_Setup: payload.Space_Setup,
            };

            const response: AxiosResponse<IAvailableSpace[]> = await axios.post(`${apis.allApiv2}${nodeId}/spaces/search`, primitivePayload);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ISearchSpacesEndpoint
{
    execute(nodeId: number, payload: ISpaceSearch): Promise<IAvailableSpace[]>;
}

export interface IAvailableSpace
{
    Space_Id: string;
    Requested: number;
    Found: number;
    FoundDates: string[];
}

export interface ISpaceSearch
{
    Booking_Dates: IBookingDates[];
    Space_Capacity?: number;
    Space_Type?: string;
    Meta_Serv_Reqs_AV?: number;
    Meta_Serv_Reqs_Catering?: number;
    Meta_Serv_Reqs_Hearing?: number;
    Meta_Serv_Reqs_Presentation?: number;
    Floor_Id?: number;
    Meta_Loc_Zone?: string;
    Space_Setup?: number;
}

export interface IBookingDates
{
    Start_Time: DateTime;
    End_Time: DateTime;
}
