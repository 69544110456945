import { Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import { InputOption } from "../../../Components/uicomponents/InputSelectBox";
import IbssInputDropDown from "../../../Components/uicomponents/IbssInputDropDown";
import { IUserPreferences } from "../../../Providers.Api/UserPreferenceRepository";

export default class OnBoardingFloorPreferences extends Component<IOnBoardingFloorPreferences>
{

    private labels = appContext().labels;
    private userPreferences = appContext().userPreferencesService;
    private incrementActiveStepProp;
    private local = appContext().localStorageProvider;

    constructor(props: IOnBoardingFloorPreferences) {
        super(props);
        this.incrementActiveStepProp = props.incrementActiveStep;
    }

    submitFloor = async () => {
        const payload: IUserPreferences =
        {
            ...this.local.getUserPreferences(),
            Nodes:
            [
                {
                    NodeId: this.props.selectedBuilding,
                    DefaultFloor: this.props.floors.value,
                    DefaultZone: null
                }
            ]
        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.incrementActiveStepProp();
            }
        }
        catch { }
    }

    public render(): JSX.Element {
        const { floors } = this.props;
        return (<>
            <div className="px-50">
                <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelYourfloorpreference}</h2>
                <p className="ibss-modal-text-md"> {this.labels.HubLabelfloorpreferenceParagraph} </p>
                <div className="ibss-label-bold-md">{this.labels.HubLabelFloortext}</div>
                <div className="my-16">
                    <IbssInputDropDown
                        name= "DefaultFloor"
                        id= "DefaultFloor"
                        inputLabel={this.labels.dataUserPrefWorkplaceDefFloor_L}
                        options = {floors.options.sort((a,b) => Number(a.value) - Number(b.value))}
                        value={floors.value}
                        onChange={floors.onChange}
                    />
                </div>
                <IbssButton variant="contained"
                    color="primary"
                    onClick={() => {
                        this.submitFloor();
                    }}
                > {this.labels.HubButtonNext} </IbssButton>
            </div>
        </>);

    }
}

export interface IOnBoardingFloorPreferences {
    incrementActiveStep?: any;
    selectedBuilding: number | null;
    userPrefrences: IUserPreferences;
    floors: {
        name: string;
        id: string;
        inputLabel: string;
        options: InputOption[];
        value: number | null;
        onChange: (value: string) => void;
    };
}
class state {
    private get labels() { return appContext().labels; }
    public floors =
        {
            name: "DefaultFloor",
            id: "DefaultFloor",
            inputLabel: this.labels.dataUserPrefWorkplaceDefFloor_L,
            options: new Array<InputOption>(),
            value: "",
            onChange: (value: string) => { }
        };
}


