import React, { useState, useEffect, Component, ReactNode } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, RouteComponentProps, useHistory } from "react-router-dom";
import LoadingOverlay from "./Components/LoadingOverlay";
import Dashboard from "./Pages/Public/Dashboard";
import Blank from "./Pages/Obsolete/Blank";
import Unauthorised from "./Pages/Public/Unauthorised";
import AccessDenied from "./Pages/Obsolete/AccessDenied";
import IRoles from "./Pages/Admin/IdentityProvidersRoles";
import IUsers from "./Pages/Admin/IdentityProvidersUsers";
import IdentityProviders from "./Pages/Admin/IdentityProviders";
import Details from "./Pages/Admin/Details";
import RolesDetails from "./Pages/Admin/RolesDetails";
import Organisations from "./Pages/Admin/Organisations";
import Region from "./Pages/Admin/Region";
import Room from "./Pages/Admin/Room";
import Zone from "./Pages/Admin/Zone";
import Floor from "./Pages/Admin/Floor";
import Building from "./Pages/Admin/Building";
import SpacesEdit from "./Pages/Admin/SpacesEdit";
import Taskcategoriesedit from "./Pages/Admin/TaskCategoriesEdit";
import Tasktypesedit from "./Pages/Admin/TaskTypesEdit";
import Taskstatesedit from "./Pages/Admin/TaskStatesEdit";
import SpaceStatesedit from "./Pages/Admin/SpaceStatesEdit";
import Login from "./Pages/Public/Login/Login";
import { oktaAuthConfig } from "./configOkta";
import { LoginCallback, Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import UserDetails from "./Pages/Admin/UserDetails";
import DeviceDetails from "./Pages/Admin/DeviceDetails";
import ParameterDetails from "./Pages/Admin/ParameterDetails";
import BuildingKPIDetails from "./Pages/Admin/BuildingKpiDetails";
import SignageDetails from "./Pages/Admin/SignagesDetails";
import VisitorsAwaitingApproval from "./Pages/Obsolete/VisitorsAwaitingApproval";
import VisitorsToday from "./Pages/Obsolete/VisitorsToday";
import VisitorsNwd from "./Pages/Obsolete/VisitorsNwd";
import VisitorsOnSite from "./Pages/Obsolete/VisitorsOnSite";
import VisitorsApproved from "./Pages/Obsolete/VisitorsApproved";
import VisitorsInactive from "./Pages/Obsolete/VisitorsInactive";
import FacilityBuilding from "./Pages/Shared/FacilityBuilding";
import VisitorTrends from "./Pages/OneLens/Visits/Trends";
import FileListingPage from "./Pages/Admin/FileListingPage";
import FacilitySpaces from "./Pages/OneLens/Spaces/FacilitySpaces";
import Task from "./Pages/Shared/Tasks/Task";
import FacilityVisitDetails from "./Pages/Shared/FacilityVisitDetails";
import TaskDetails from "./Pages/Shared/Tasks/TaskDetails";
import Bookings from "./Pages/Shared/Bookings/Bookings";
import NotificationsSetup from "./Pages/Admin/NotificationsSetup";
import NotificationsDetails from "./Pages/Admin/NotificationsDetails";
import NoRights from "./Pages/Public/NoRights";
import WorkProgress from "./Pages/OneLens/WorkProgress";
import CateringOrders from "./Pages/OneLens/CateringOrders/CateringOrders";
import FacilityDirectedCleaning from "./Pages/OneLens/FacilityDirectedCleaning";
import EditCateringItem from "./Pages/OneLens/CateringItems/EditCateringItem";
import FacilityNotificationsDetails from "./Pages/OneLens/FacilityNotificationsDetails";
import FacilityNotifications from "./Pages/OneLens/FacilityNotifications";
import ListVisits from "./Pages/Shared/Visits/ListVisits/ListVisits";
import apis from "./Providers.Api/apis";
import FacilityZones from "./Pages/OneLens/Zones/FacilityZones";
import FacilityZoneDetails from "./Pages/OneLens/Zones/FacilityZoneDetails";
import FacilitySensors from "./Pages/OneLens/Sensors/FacilitySensors";
import FacilitySensorDetails from "./Pages/OneLens/Sensors/FacilitySensorDetails";
import FacilityAnalyticsOverview from "./Pages/OneLens/FacilityAnalyticsOverview";
import SpaceAnalyticsOverview from "./Pages/OneLens/SpaceAnalyticsOverview";
import FullZoneData from "./Pages/OneLens/Zones/FullZoneData";
import HistoricalZoneData from "./Pages/OneLens/Zones/HistoricalZoneData";
import FullSensorsData from "./Pages/OneLens/Sensors/FullSensorsData";
import FloorsData from "./Pages/OneLens/Floors/FloorsData";
import SearchSpaces from "./Pages/Shared/Spaces/Search/Search";
import WorkPlace from "./Pages/Flex/Shared/Workplace/WorkPlace";
import Notifications from "./Pages/Flex/Shared/Notifications/Notifications";
import AccountSettings from "./Pages/Flex/Shared/Account/AccountSettings";
import EditBooking from "./Pages/Shared/Bookings/EditBooking/EditBooking";
import Layout from "./Components/Layout";
import Test from "./Pages/Public/Test";
import FlexMyNotications from "./Pages/Flex/Notifications/FlexMyNotications";
import SearchEquipment from "./Pages/Flex/Equipment/SearchEquipment";
import SpaceAnalyticsMain from "./Pages/OneLens/SpaceAnalyticsMain";
import SpaceAnalyticsHeatMaps from "./Pages/OneLens/Space Analytics/SpaceAnalyticsHeatMaps";
import SpaceAnalyticsDetails from "./Pages/OneLens/SpaceAnalyticsDetails";
import TestRunner from "./Pages/Public/TestRunner";
import { appContext } from "./AppContext";
import { LogOutReason } from "./Providers.Api/Authentication";
import { MenuItemKey } from "./Components/Sidebar/MenuItemKey";
import { ConnectedComponent } from "react-redux";
import { StaticContext } from "react-router";
import EditBookingPolicy from "./Pages/Admin/BookingPolicies/EditBookingPolicy";
import BookingPolicies from "./Pages/Admin/BookingPolicies/BookingPolicies";
import NotFound from "./Pages/Public/NotFound";
import AssignPolicy from "./Pages/Admin/BookingPolicies/AssignPolicy";
import Tasks from "./Pages/Shared/Tasks/Tasks";
import Home from "./Pages/Shared/Home";
import EditCateringOrder from "./Pages/OneLens/CateringOrders/EditCateringOrder";
import CateringItems from "./Pages/OneLens/CateringItems/CateringItems";
import RecurringBooking from "./Pages/Flex/Bookings/RecurringBookings/RecurringBooking";
import CateringOrder from "./Pages/OneLens/CateringOrders/CateringOrder";
import CateringOrderPolicies from "./Pages/OneLens/CateringOrderPolicies/CateringOrderPolicies";
import CostCodes from "./Pages/OneLens/CostCodes/CostCodes";
import CateringMenus from "./Pages/OneLens/CateringMenus/CateringMenus";
import EditCateringSupplier from "./Pages/OneLens/CateringSuppliers/EditCateringSupplier";
import ListCateringSuppliers from "./Pages/OneLens/CateringSuppliers/ListCateringSuppliers";
import ScheduleView from "./Pages/OneLens/Bookings/ScheduleView/ScheduleView";
import VisitorDashboard from "./Pages/OneLens/Visits/Dashboard/VisitorDashboard";
import EditCateringOrderPolicy from "./Pages/OneLens/CateringOrderPolicies/EditCateringOrderPolicy";
import EditCateringMenu from "./Pages/OneLens/CateringMenus/EditCateringMenu";
import BookingTrends from "./Pages/OneLens/Bookings/Trends";
import ListDietaryTerms from "./Pages/OneLens/CateringDietaryTerms/ListDietaryTerms";
import ListDeletedUsers from "./Pages/OneLens/Users/ListDeletedUsers";
import OutOfService from "./Pages/Shared/OutOfService";
import OnelensHome from "./Pages/OneLens/Shared/Home/OnelensHome";
import FlexHome from "./Pages/Flex/Shared/Home/FlexHome";

interface IRoute
{
    exact: boolean;
    protected: boolean;
    requiredRights?: any[];
    path: string;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined; // from RouterProps interface
    menuItemId?: MenuItemKey;
}

export const routeList: IRoute[] =
    [
        // private routes
        { exact: true, protected: true, requiredRights: [], path: "/subscription", component: Blank },
        { exact: true, protected: true, requiredRights: [], path: "/datapublisher", component: Blank },
        { exact: true, protected: true, requiredRights: [], path: "/access-denied", component: AccessDenied },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["IdentityProviders"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-identity-providers", component: IdentityProviders, menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["IdentityProviders"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-identity-providers/details", component: Details, menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["IdentityProviders"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-identity-providers/details/:id", component: Details, menuItemId: MenuItemKey.Admin_Setup_Security_IdentityProviders },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Users"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-users/userdetails", component: UserDetails, menuItemId: MenuItemKey.Admin_Manage_Users },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Roles"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-roles/rolesdetails", component: RolesDetails, menuItemId: MenuItemKey.Admin_Setup_Security_Roles },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Users"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-users/userdetails/:id", component: UserDetails, menuItemId: MenuItemKey.Admin_Manage_Users },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Roles"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-roles/rolesdetails/:id", component: RolesDetails, menuItemId: MenuItemKey.Admin_Setup_Security_Roles },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Roles"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-roles", component: IRoles, menuItemId: MenuItemKey.Admin_Setup_Security_Roles },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Security": ["Users"] }, { "ADMINPORTAL.Security": ["*"] }], path: "/security-users", component: IUsers, menuItemId: MenuItemKey.Admin_Manage_Users },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL": ["*"] }, { "API.CostCodes": ["V2"] }], path: "/cost-codes/", component: CostCodes, menuItemId: MenuItemKey.Admin_Manage_CostCodes },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Root", component: Organisations, menuItemId: MenuItemKey.Admin_Organisation },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Region", component: Region, menuItemId: MenuItemKey.Admin_Organisation_Regions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Building", component: Building, menuItemId: MenuItemKey.Admin_Organisation_Buildings },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Floor", component: Floor, menuItemId: MenuItemKey.NotSet },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Zone", component: Zone, menuItemId: MenuItemKey.NotSet },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Space", component: Room, menuItemId: MenuItemKey.NotSet },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Taskcategories/:id", component: Taskcategoriesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskCategories"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-categories/filter=Taskcategories/:id", component: Taskcategoriesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskTypes"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskTypes/:id", component: Tasktypesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskTypes/:id", component: Tasktypesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskStates"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskStates/:id", component: Taskstatesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskStates/:id", component: Taskstatesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Region)", component: SpacesEdit, menuItemId: MenuItemKey.Admin_Organisation_Regions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Region)/:tab", component: SpacesEdit, menuItemId: MenuItemKey.Admin_Organisation_Regions },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Building)", component: SpacesEdit, menuItemId: MenuItemKey.Admin_Organisation_Buildings },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/edit/:id/:filter(Building)/:tab", component: SpacesEdit, menuItemId: MenuItemKey.Admin_Organisation_Buildings },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskStates"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskStates/nodeid=:nodeid/id=:id", component: Taskstatesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskStates/nodeid=:nodeid/id=:id", component: Taskstatesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskTypes"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=TaskTypes/nodeid=:nodeid/id=:id", component: Tasktypesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=Building/TaskTypes/nodeid=:nodeid/id=:id", component: Tasktypesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["SpaceStates"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-types/filter=SpaceStates/nodeid=:nodeid/id=:id", component: SpaceStatesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=SpaceStates/nodeid=:nodeid/id=:id", component: SpaceStatesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["TaskCategories"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/spaces-task-categories/filter=TaskCategories/nodeid=:nodeid/id=:id", component: Taskcategoriesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=TaskCategories/nodeid=:nodeid/id=:id", component: Taskcategoriesedit },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/Device/nodeid=:nodeid/id=:id", component: DeviceDetails },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/Parameter/nodeid=:nodeid/id=:id", component: ParameterDetails },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/BuildingKPI/nodeid=:nodeid/id=:id", component: BuildingKPIDetails },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Spaces"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/portfolio-setup-spaces/filter=:filter/Signage/nodeid=:nodeid/id=:id", component: SignageDetails },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/:buildingid", component: BookingPolicies, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/assign/:bookingPolicyId", component: AssignPolicy, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/:buildingId/create", component: EditBookingPolicy, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [{ "DATAMODEL.BookingPolicies": ["Read"] }], path: "/booking-policies/edit/:bookingId", component: EditBookingPolicy, menuItemId: MenuItemKey.Admin_Manage_BookingPolicies },
        { exact: true, protected: true, requiredRights: [], path: "/visitors/type=AwaitingApproval", component: VisitorsAwaitingApproval },
        { exact: true, protected: true, requiredRights: [], path: "/visitors/type=Today", component: VisitorsToday },
        { exact: true, protected: true, requiredRights: [], path: "/visitors/type=Tomorrow", component: VisitorsNwd },
        { exact: true, protected: true, requiredRights: [], path: "/visitors/type=OnSite", component: VisitorsOnSite },
        { exact: true, protected: true, requiredRights: [], path: "/visitors/type=Approved", component: VisitorsApproved },
        { exact: true, protected: true, requiredRights: [], path: "/visitors/type=Inactive", component: VisitorsInactive },
        { exact: true, protected: true, requiredRights: [], path: "/operational-services-buildings", component: FacilityBuilding },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["DirectedCleaning"] }, { "ONELENS360.OperationalServices": ["Notifications"] }, { "ONELENS360.OperationalServices": ["OccupancyMonitor"] }, { "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["Tasks"] }, { "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-buildings/:buildingid", component: FacilityBuilding, menuItemId: MenuItemKey.OneLens },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Tasks"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-tasks/:buildingid", component: Tasks, menuItemId: MenuItemKey.OneLens_Operations_Tasks },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid", component: ListVisits, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid/:tab", component: ListVisits, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid/:tab/:filter", component: ListVisits, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors/:buildingid/visit/:visit_Id/:tab", component: FacilityVisitDetails, menuItemId: MenuItemKey.OneLens_Operations_Visitors_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors-dashboard/:buildingid", component: VisitorDashboard, menuItemId: MenuItemKey.OneLens_Operations_Visitors_Dashboard },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Visitors"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-visitors-trends/:buildingid", component: VisitorTrends, menuItemId: MenuItemKey.OneLens_Operations_Visitors_Trends },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Tasks"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-tasks/:buildingid/task/:task_Id/:tab", component: TaskDetails, menuItemId: MenuItemKey.OneLens_Operations_Tasks },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-home/", component: OnelensHome, menuItemId: MenuItemKey.OneLens_Home },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings-list/:buildingid", component: Bookings, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings/:buildingid/:tab", component: Bookings, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings/:buildingid/:tab/:filter", component: Bookings, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-bookings/:buildingid/bookings/:bookingid/:spaceId", component: EditBooking, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/bookings/trends", component: BookingTrends, menuItemId: MenuItemKey.OneLens_Operations_Bookings_Trends },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Bookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/bookings/schedule-view", component: ScheduleView, menuItemId: MenuItemKey.OneLens_Operations_Bookings_ScheduleView },
        { exact: true, protected: true, requiredRights: [], path: "/operational-services-home/", component: OnelensHome, menuItemId: MenuItemKey.OneLens_Home },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Notifications"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/notifications", component: NotificationsSetup, menuItemId: MenuItemKey.Admin_Organisation_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.PortfolioSetup": ["Notifications"] }, { "ADMINPORTAL.PortfolioSetup": ["*"] }], path: "/notifications/:notificationid", component: NotificationsDetails, menuItemId: MenuItemKey.Admin_Organisation_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Roamer"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/roamer", component: FileListingPage, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Roamer },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Unity"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/unity", component: FileListingPage, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Unity },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Inspire"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/inspire", component: FileListingPage, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Inspire },
        { exact: true, protected: true, requiredRights: [{ "ADMINPORTAL.Files": ["Common"] }, { "ADMINPORTAL.Files": ["*"] }], path: "/filemgmt/common", component: FileListingPage, menuItemId: MenuItemKey.Admin_Manage_FileManagement_Common },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.Notifications": ["*"] }], path: "/onelen", component: WorkProgress },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/catering-orders/:buildingid", component: CateringOrders, menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/catering-items/:buildingid", component: CateringItems, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid", component: CateringOrders, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/catering-order-policies", component: CateringOrderPolicies, menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/catering-order-policies/new", component: EditCateringOrderPolicy, menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingid/catering-order-policies/:policyId/edit", component: EditCateringOrderPolicy, menuItemId: MenuItemKey.Onelens_Operations_Catering_OrderPolicy },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid/catering/:spacecateringId", component: EditCateringItem, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringItems },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid/catering/:spacecateringId/edit", component: EditCateringOrder, menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering/:buildingid/catering/:spacecateringId/view", component: CateringOrder, menuItemId: MenuItemKey.OneLens_Operations_Catering_CateringOrders },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }], path: "/buildings/:buildingId/catering-menus", component: CateringMenus, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }], path: "/buildings/:buildingId/catering-menus/new", component: EditCateringMenu, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }], path: "/buildings/:buildingId/catering-menus/:cateringMenuId/edit", component: EditCateringMenu, menuItemId: MenuItemKey.Onelens_Operations_Catering_CateringMenus },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/catering-dietary-terms/:buildingId", component: ListDietaryTerms, menuItemId: MenuItemKey.Onelens_Operations_Catering_DietaryTerms },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/catering-suppliers", component: ListCateringSuppliers, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/catering-suppliers/new", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/buildings/:buildingId/catering-suppliers/:supplierId/edit", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering-suppliers/create/:buildingId", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Catering"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-catering-suppliers/edit/:buildingId/:supplierId", component: EditCateringSupplier, menuItemId: MenuItemKey.Onelens_Operations_Catering_Suppliers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["DirectedCleaning"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-directed-cleaning/:buildingid", component: FacilityDirectedCleaning, menuItemId: MenuItemKey.OneLens_Operations_DirectedCleaning },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["OrphanedBookings"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/users/deleted", component: ListDeletedUsers, menuItemId: MenuItemKey.OneLens_Operations_DeletedUsers },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-notifications/:buildingid", component: FacilityNotifications, menuItemId: MenuItemKey.OneLens_Operations_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Notifications"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-notifications/:buildingid/notifications/:notificationId", component: FacilityNotificationsDetails, menuItemId: MenuItemKey.OneLens_Operations_Notifications },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid", component: FacilityZones, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid/:zoneId", component: FullZoneData, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["CostCodes"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-cost-codes/:buildingid", component: CostCodes, menuItemId: MenuItemKey.OneLens_Operations_CostCodes },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid/zones/:zoneId", component: FacilityZoneDetails, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Zones"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-zones/:buildingid/:zoneId/historical", component: HistoricalZoneData, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Zones },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-sensors/:buildingid", component: FacilitySensors, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-sensors/:buildingid/:sensorId", component: FullSensorsData, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-sensors/:buildingid/sensors/:sensorId", component: FacilitySensorDetails, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Sensors },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Overview"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-overview/:buildingid", component: FacilityAnalyticsOverview, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Overview },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SustainabilityAnalytics": ["Sensors"] }, { "ONELENS360.SustainabilityAnalytics": ["*"] }], path: "/sustainability-analytics-floors/:buildingid", component: FloorsData, menuItemId: MenuItemKey.OneLens_Analytics_Sustainability_Floors },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings", component: Bookings, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings/:buildingid/mybooking/:bookingid/:spaceId", component: EditBooking, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookingsForOthers"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings-for-others", component: Bookings, menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookingsForOthers"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings-for-others/:buildingid/mybookingother/:bookingid/:spaceId", component: EditBooking, menuItemId: MenuItemKey.Flex_MySchedule_MyBookingsForOthers },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-bookings/:buildingid/mybooking/:bookingid/catering/:spacecateringId/edit", component: EditCateringOrder, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyBookings"] }, { "FLEX.MySchedule": ["*"] }], path: "/create-catering-order/:buildingid/:bookingid/:spaceId", component: EditCateringOrder, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyVisitors"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-visitor", component: ListVisits, menuItemId: MenuItemKey.Flex_MySchedule_MyVisitors },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MySchedule": ["MyVisitors"] }, { "FLEX.MySchedule": ["*"] }], path: "/flex-my-visitor/:buildingid/myvisit/:visit_Id", component: FacilityVisitDetails, menuItemId: MenuItemKey.Flex_MySchedule_MyVisitors },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["Workplace"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex-user-pref-workplace", component: WorkPlace, menuItemId: MenuItemKey.Flex_Settings_Workplace },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["AccountSettings"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex-user-pref-notifications", component: Notifications, menuItemId: MenuItemKey.Flex_Settings_Notifications },
        { exact: true, protected: true, requiredRights: [{ "FLEX.UserPreferences": ["Notifications"] }, { "FLEX.UserPreferences": ["*"] }], path: "/flex-user-pref-account", component: AccountSettings, menuItemId: MenuItemKey.Flex_Settings_Account },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MyTasks": ["MyTasks"] }, { "FLEX.MyTasks": ["*"] }], path: "/flex-my-tasks", component: Tasks, menuItemId: MenuItemKey.Flex_MyTask_TasksRaised },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MyTasks": ["MyTasks"] }, { "FLEX.MyTasks": ["*"] }], path: "/flex-my-tasks/:tab", component: Tasks, menuItemId: MenuItemKey.Flex_MyTask_TasksRaised },
        { exact: true, protected: true, requiredRights: [{ "FLEX.MyTasks": ["MyTasks"] }, { "FLEX.MyTasks": ["*"] }], path: "/flex-my-tasks/:buildingid/mytask/:task_Id", component: TaskDetails, menuItemId: MenuItemKey.Flex_MyTask_TasksRaised },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-spaces/:buildingid", component: FacilitySpaces, menuItemId: MenuItemKey.OneLens_Operations_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-spaces/:buildingid/:tab", component: FacilitySpaces, menuItemId: MenuItemKey.OneLens_Operations_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services-spaces/:buildingid/:tab/:filter", component: FacilitySpaces, menuItemId: MenuItemKey.OneLens_Operations_Spaces },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.OperationalServices": ["Spaces"] }, { "ONELENS360.OperationalServices": ["*"] }], path: "/operational-services/buildings/:buildingid/spaces/search", component: SearchSpaces, menuItemId: MenuItemKey.OneLens_Operations_Bookings_List },
        { exact: true, protected: true, requiredRights: [{ "FLEX.Search": ["Spaces"] }, { "FLEX.Search": ["*"] }], path: "/flex-find-a-space", component: SearchSpaces, menuItemId: MenuItemKey.Flex_FindASpace_Single },
        { exact: true, protected: true, requiredRights: [{ "FLEX.Search": ["Spaces"] }, { "FLEX.Search": ["*"] }], path: "/flex-find-a-space/:buildingid/:searchaspace/:spaceId", component: EditBooking, menuItemId: MenuItemKey.Flex_FindASpace_Single },
        { exact: true, protected: true, requiredRights: [{ "FLEX.Search": ["Spaces"] }, { "FLEX.Search": ["*"] }], path: "/flex-my-bookings/:buildingid/catering/:spacecateringId/edit", component: EditCateringOrder, menuItemId: MenuItemKey.Flex_MySchedule_MyBookings },
        { exact: true, protected: true, requiredRights: [{ "FLEX.FindASpace": ["RecurringBookings"] }], path: "/flex-recurring-booking", component: RecurringBooking, menuItemId: MenuItemKey.Flex_FindASpace_Recurring },
        { exact: true, protected: true, requiredRights: [], path: "/flex-home/", component: FlexHome, menuItemId: MenuItemKey.Flex_Home },
        { exact: true, protected: false, requiredRights: [], path: "/flex-my-notification", component: FlexMyNotications, menuItemId: MenuItemKey.Flex_MyNotification },
        { exact: true, protected: false, requiredRights: [], path: "/flex-Equipment/:buildingid", component: SearchEquipment, menuItemId: MenuItemKey.Flex_FindASpace },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["Overview"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-overview/:buildingid", component: SpaceAnalyticsMain, menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["Analytics"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-analytics", component: SpaceAnalyticsOverview, menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["Heatmaps"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-heatmaps/:buildingid", component: SpaceAnalyticsHeatMaps, menuItemId: MenuItemKey.OneLens_Analytics_Space_Heatmaps },
        { exact: true, protected: true, requiredRights: [{ "ONELENS360.SpaceAnalytics": ["SpaceDetails"] }, { "ONELENS360.SpaceAnalytics": ["*"] }], path: "/space-analytics-detail", component: SpaceAnalyticsDetails, menuItemId: MenuItemKey.OneLens_Analytics_Space_Overview },

        // public routes
        {
            exact: true,
            protected: false,
            requiredRights: [],
            path: "/norights",
            component: NoRights,
        },
        {
            exact: true,
            protected: false,
            requiredRights: [],
            path: "/out-of-service",
            component: OutOfService,
        },
        {
            exact: true,
            protected: false,
            path: "/unauthorised",
            component: Unauthorised,
        },
        { exact: true, protected: false, path: "/login", component: Login },
        {
            exact: true,
            protected: false,
            path: "/implicit/callback",
            component: LoginCallback,
        },
        { exact: true, protected: false, path: "/auth", component: Dashboard },
        { exact: true, protected: false, path: "/", component: Home },
        { exact: true, protected: false, path: "/tests", component: TestRunner },
    ];

export default class Routes extends Component<any, IState>
{
    private get auth() { return appContext().authentication; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }

    private restoreOriginalUri(_oktaAuth: any, originalUri: any): void
    {
        const history = this.props.history;
        if (history)
        {
            history.replace(toRelativeUrl(originalUri, window.location.origin + "/auth"));
        }
        else
        {
            window.location.href = window.location.origin + "/auth";
        }
    }

    private resetPageTitle(): void
    {
        this.appState.set({ pageTitle: "" });
    }

    private isAuthenticated(): boolean
    {
        let isAuthenticated = true;
        const token = this.local.getToken()
        if (!token)
        {
            isAuthenticated = false;
        }
        return isAuthenticated;
    }

    private isAuthorised(requiredRights: any): boolean
    {
        let isRightAvailable = false;
        const rights = this.local.getUserDetails().userRights;

        if(requiredRights == null || requiredRights.length === 0)
        {
            // if requiredRights is null, undefined or an empty array, then no rights are required and route is authorised. 
            return true;
        }

        requiredRights.forEach((item: any) =>
        {
            Object.entries(item).map(([objectKey, objectValue]) =>
            {
                try
                {
                    if (rights)
                    {
                        const newArray = objectKey.split(".");
                        let userRightsFromApi = { ...(rights as any) };

                        newArray.forEach((element) =>
                        {
                            userRightsFromApi = userRightsFromApi[element];
                        });

                        if (userRightsFromApi)
                        {
                            if (userRightsFromApi.includes("*"))
                            {
                                isRightAvailable = true;
                            }
                            else
                            {
                                (objectValue as any).forEach((element: any) =>
                                {
                                    const isRightAvailableForItem = userRightsFromApi.includes(element);
                                    if (isRightAvailableForItem === true)
                                    {
                                        isRightAvailable = true;
                                    }
                                });
                            }
                        }
                    }
                }
                catch (error)
                {
                    console.log(error);
                }
            });
        });
        return isRightAvailable;
    }

    // common function to get the public route object
    public getPublicRoute = (routeObj: IRoute): IRoute =>
    {
        const _routeobj =
        {
            key: routeObj.path,
            exact: routeObj.exact,
            path: routeObj.path,
            component: routeObj.component,
            protected: false,
        };
        return _routeobj;
    }

    public render(): JSX.Element
    {
        const oktaAuth = oktaAuthConfig;
        return (
            <Security oktaAuth={oktaAuth} restoreOriginalUri={(_oktaAuth, originalUri) => this.restoreOriginalUri(_oktaAuth, originalUri)}>
                <Router>
                    <Switch>
                        {routeList.map((routeObj) =>
                        {
                            if (routeObj.protected)
                            {
                                const privateRouteProps = {
                                    key: routeObj.path,
                                    exact: routeObj.exact,
                                    requiredRights: routeObj.requiredRights,
                                    path: routeObj.path,
                                    component: routeObj.component,
                                };

                                const { component: Component, ...rest } = privateRouteProps;
                                return (
                                    <Route
                                        {...rest}
                                        render={(props) =>
                                        {
                                            const isAuthenticated = this.isAuthenticated();
                                            const isAuthorised = this.isAuthorised(
                                                routeObj.requiredRights
                                            );

                                            if (!isAuthenticated)
                                            {
                                                this.auth.logOut(LogOutReason.SessionExpired);
                                                return <></>;
                                            }
                                            else if (!isAuthorised)
                                            {
                                                return <Redirect to={{ pathname: "/norights" }} />;
                                            }
                                            else if (!Component)
                                            {
                                                /*in the RouteProps interface, the component is defined as optional, hence it might be undefined. the 
                                                                    component in else block will throw an error. */
                                                return null;
                                            }
                                            else
                                            {
                                                return (
                                                    <Layout {...props} isPrivate={true}>
                                                        <Component {...props} />
                                                    </Layout>
                                                );
                                            }
                                        }}
                                    />
                                );
                            }
                            else
                            {
                                const publicRoute = this.getPublicRoute(routeObj);

                                const { component: Component, ...rest } = publicRoute;
                                if (!Component)
                                {
                                    return null;
                                }
                                return (
                                    <Route
                                        {...rest}
                                        render={(props) =>
                                        {
                                            return (
                                                <Layout {...props} isPrivate={false}>
                                                    <Component {...props} />
                                                </Layout>
                                            );
                                        }}
                                    />
                                );
                            }
                        })}
                        <Route exact path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Router>
            </Security>
        );
    }
}

export interface IState
{
}
