import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { RouteComponentProps, RouterProps, generatePath } from 'react-router';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { appContext } from '../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../Components/uicomponents/IbssActionButton';
import IbssDataGrid from '../../../Components/uicomponents/IbssDataGrid';
import IbssSvgIcon from '../../../Components/uicomponents/IbssSvgIcon';
import { IDispatch, IPropsFromState } from '../../../redux/Interfaces';
import IbssDialog from '../../../Components/uicomponents/IbssDialog';
import IbssInputDropDown from '../../../Components/uicomponents/IbssInputDropDown';
import IbssButtonRedo from '../../../Components/uicomponents/IbssButton';
import IbssFilter from '../../../Components/uicomponents/IbssFilter';
import Spinner from '../../../Components/Spinner';
import { ISpaceCateringMenuItem } from '../../../Providers.Api/CateringItems/GetManyEndpoint';
import { CateringItemsFilter, Classification, Status } from '../../../Providers.Api/CateringItems/CateringItemRepository';
import { TypeHelper } from '../../../Common/TypeHelper';
import Helper, { getAllBuildingsData } from '../../../Common/Helper';
import { IUpdateSpaceCateringMenuItemPayload } from '../../../Providers.Api/CateringItems/UpdateEndpoint';
import { ISupplierView } from '../../../Providers.Api/CateringSuppliers/CateringSupplierRepository';
import IbssChip from "../../../Components/uicomponents/IbssChip";
import CreateIcon from '@mui/icons-material/Create';
import IbssAutocomplete from '../../../Components/uicomponents/IbssAutocomplete';
import { TextField } from "@mui/material";
import { INode } from '../../../Providers.Api/Models';

export default class CateringItems extends Component<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            loading: false,
            taskList: [],
            isCreateRight: false,
            isCancelRight: false,
            isUpdateRight: false,
            buildingId: this.appState.buildingId,
            message: "",
            selectedTaskIds: [],
            actionType: "",
            searchTerm: "",
            openFilterModal: false,
            statusValue: "Any",
            classificationValue: "Any",
            supplierId: "",
            disableBtn: true,
            enableBtn: true,
            filterStatusOptions: [],
            filterClassificationOptions: [],
            suppliers: [],
            currency: ''
        }
    }

    public async componentDidMount(): Promise<void> 
    {
        const { history } = this.props;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        const buildingId = parseInt(this.props.match.params["buildingid"]);

        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: buildingId });
            if (buildingId !== 0)
            {
                history.push("/catering-items/" + this.state.buildingId);
            }
            else 
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }
        }

        this.setRights();
        this.loadTasks();
        this.getSuppliers();
        this.setSelectedBuildingCurrency();
    }
    
    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            const { history } = this.props;
            const buildingID = this.state.buildingId;
            const path = generatePath(this.props.match.path, { buildingid: buildingID });
            history.replace(path);
            await this.loadTasks();
            this.setSelectedBuildingCurrency();
        }
    }

    private setSelectedBuildingCurrency(): void
    {
        const buildings = getAllBuildingsData();
        let selectedBuilding: INode | null = null;
        for (let i = 0; i < buildings.length; i++)
        {
            if (buildings[i].Node_Id == this.state.buildingId)
            {
                selectedBuilding = buildings[i];
            }
        }
        if (selectedBuilding == null)
        {
            return;
        }
        this.setState({ currency: selectedBuilding.Cat_Crncy });
    }

    private setRights(): void 
    {
        this.setState({
            isCreateRight: this.local.hasRight("DATAMODEL.CateringMenuItems.Create"),
            isCancelRight: this.local.hasRight("DATAMODEL.CateringMenuItems.Delete"),
            isUpdateRight: this.local.hasRight("DATAMODEL.CateringMenuItems.Update"),
        });
    }

    private async loadTasks(): Promise<void> 
    {
        try
        {
            this.setState({ loading: true });
            const tasksFilter = new CateringItemsFilter(
            {
                classification: (this.state.classificationValue === "Any" ? null : this.state.classificationValue),
                status: (this.state.statusValue === "Any" ? null : this.state.statusValue),
                supplierId: (this.state.supplierId === "" ? null : this.state.supplierId),
            });
            const response = await this.apiClient.cateringItems.getMany(this.state.buildingId, tasksFilter);

            if (!response) 
            {
                return;
            }
            let tasks = response.map(item => new MenuItemView(item));
            this.setState({ taskList: tasks, loading: false });
            this.tasksSelected(this.state.selectedTaskIds)
        }
        finally 
        {
            this.setState({ loading: false });
        }
    }

    private async getSuppliers(): Promise<void> 
    {
        try 
        {
            const data = await this.apiClient.cateringSuppliers.getCateringSupplierByNodeId(this.state.buildingId);
            this.setState({
                suppliers: data.Value
            })
        } 
        catch (error) 
        {    
        }
    }

    private filterTasks(searchTerm: string): MenuItemView[] 
    {
        const filteredTasks = this.state.taskList.filter(task =>
        {
            let key: keyof MenuItemView;
            for (key in task)
            {
                if (task[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async taskStatusUpdate(status: Status): Promise<void> {
        try 
        {
            const filtered = this.filterTasks(this.state.searchTerm).filter((item) => this.state.selectedTaskIds.includes(item.id));
            for (const item of filtered) 
            {
                await this.apiClient.cateringItems.update(this.state.buildingId, item.id, 
                {
                    Status: status,
                    ConcurrencyStamp: item.concurrencyStamp,
                });
            }
            this.loadTasks();
        } 
        catch (error) 
        {
        }
    }
    

    private enabledClicked(): void 
    {
        this.taskStatusUpdate("StatusActive");
    }

    private disabledClicked(): void 
    {
        this.taskStatusUpdate("StatusInactive");
    }

    private async deleteClicked(): Promise<void> {
        try {
            for (const id of this.state.selectedTaskIds) {
                await this.apiClient.cateringItems.delete(this.state.buildingId, String(id));
            }
            this.loadTasks();
        } 
        catch (error) 
        {
        }
    }

    private filterModalCancelled(): void
    {
        this.setState({ openFilterModal: !this.state.openFilterModal });
        this.setState({ loading: false });
    }

    private async filterModalSubmitted(): Promise<void>
    {
        this.setState({ loading: true });
        this.setState({ openFilterModal: false });
        await this.loadTasks();
    }

    private async filterButtonClicked(): Promise<void>
    {
        const status = [
            {
                label: this.labels.HubLabelAny, 
                value: "Any"
            },
            {
                label: this.labels.HubLabelAvailable,
                value: "StatusActive"
            },
            {
                label: this.labels.HubLabelUnavailable,
                value: "StatusInactive"   
            }
        ]

        const classifications = [
            {
                label: this.labels.HubLabelAny, 
                value: "Any"
            },
            {
                label: this.labels.HubLabelBeverage,
                value: "MenuItemClassificationBeverage"
            },
            {
                label: this.labels.HubLabelSnack,
                value: "MenuItemClassificationSnack"
            },
            {
                label: this.labels.HubLabelFood,
                value: "MenuItemClassificationFood"
            },

        ]
        this.setState({
            openFilterModal: true,
            filterStatusOptions: status,
            filterClassificationOptions: classifications
        })

    }

    private tasksSelected(selection: GridRowSelectionModel): void
    {
        this.setState({ selectedTaskIds: selection });
        const selectedMenuItems = this.state.taskList.filter(menuItem => selection.contains(menuItem.id));
        const enableBtnState = (selectedMenuItems.length ? selectedMenuItems.some(e => e.status === "StatusActive") : true);
        const disableBtnState = (selectedMenuItems.length ? selectedMenuItems.some(e => e.status === "StatusInactive") : true);

        if (!enableBtnState)
        {
            this.setState({ enableBtn: false });
        }
        else
        {
            this.setState({ enableBtn: true });
        }

        if (!disableBtnState)
        {
            this.setState({ disableBtn: false });
        }
        else
        {
            this.setState({ disableBtn: true });
        }
    }

    private renderCateringStatus(status: string): React.ReactNode
    {
        if (status === "StatusActive")
        {
            return <IbssChip label={this.labels.HubLabelAvailable} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <IbssChip label={this.labels.HubLabelUnavailable} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
    }

    private redirectActionButton(cateringId: string): React.ReactNode
    {
        const { history } = this.props;
            return (
                <>  
                    {
                    this.state.isUpdateRight ? 
                        <IbssSvgIcon className="pointer" onClick={() => history.push(`/operational-services-catering/${this.state.buildingId}/catering/${cateringId}`)}>
                            <CreateIcon />
                        </IbssSvgIcon>    
                        : <p>-</p>
                    }
                </>
            )
    }

    private supplierName(suppliers: MenuItemView): React.ReactNode 
    {
        const supplierId = suppliers.supplierId;
        const data = this.state.suppliers.filter((item) => item.Supplier_Id === supplierId)
        return <p>{data[0]?.Name}</p>
    }

    private exportClicked(): void
    {
        const tasksFilter = new CateringItemsFilter(
        {
            classification: (this.state.classificationValue === "Any" ? null : this.state.classificationValue),
            status: (this.state.statusValue === "Any" ? null : this.state.statusValue),
            supplierId: (this.state.supplierId === "" ? null : this.state.supplierId),
        });

        this.apiClient.cateringItems.getManyAsBlob(this.state.buildingId,tasksFilter).then(blob => Helper.downloadFile(blob, 'CateringItems'));
    }

    private getClassification(classification: MenuItemView): React.ReactNode 
    {
        const classificationName = classification.classification;

        switch (classificationName)
        {
            case "MenuItemClassificationBeverage":
                return <p>{this.labels.HubLabelBeverage}</p>;

            case "MenuItemClassificationSnack":
                return <p>{this.labels.HubLabelSnack}</p>;

            case "MenuItemClassificationFood":
                return <p>{this.labels.HubLabelFood}</p>;

            default:
                return <p>-</p>;
        }
     }

    public render(): JSX.Element
    {
        const { history } = this.props;

        const dataGridHeader: GridColDef<MenuItemView>[] =
            [
                {
                    headerName: this.labels.HubLabelItemCode,
                    field: Helper.nameOf<MenuItemView>("name"),
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelDescription,
                    field: Helper.nameOf<MenuItemView>("description"),
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelCost,
                    field: Helper.nameOf<MenuItemView>("unitPrice"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => `${this.state.currency}${params.row.unitPrice}`
                },
                {
                    headerName: this.labels.HubLabelSupplier,
                    field: "",
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => this.supplierName(params.row)
                },
                {
                    headerName: this.labels.dataNotificationClassification_S,
                    field: Helper.nameOf<MenuItemView>("classification"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => this.getClassification(params.row)
                },
                {
                    headerName: this.labels.HubLabelStatus,
                    field: Helper.nameOf<MenuItemView>("status"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => this.renderCateringStatus(params.row.status)
                },
                {
                    headerName: this.labels.HubLabelAction,
                    field: Helper.nameOf<MenuItemView>("concurrencyStamp"),
                    flex: 1,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => <>{this.redirectActionButton(params.row.id)}</>
                }
            ];
            
        
        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.deleteClicked(),
                    disabled: this.state.selectedTaskIds.length > 0 && this.state.isCancelRight ? false : true
                },
                {
                    label: this.labels.HubLabelDisable,
                    icon: (
                        <IbssSvgIcon>
                            <CancelOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.disabledClicked(),
                    disabled: !this.state.disableBtn && this.state.isUpdateRight ? false : true
                },
                {
                    label: this.labels.HubLabelEnable,
                    icon: (
                        <IbssSvgIcon>
                            <CheckCircleOutlineTwoToneIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.enabledClicked(),
                    disabled: !this.state.enableBtn && this.state.isUpdateRight ? false : true
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => { history.push(`/operational-services-catering/${this.state.buildingId}/catering/0`) },
                    disabled: !this.state.isCreateRight
                }
            ];

        const filteredTasks = this.filterTasks(this.state.searchTerm);

        return (
            <>
                <div>
                    {/* Filter Modal */}
                    <IbssDialog
                        open={this.state.openFilterModal}
                        onClose={() => this.filterModalCancelled()}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        content=
                        {
                            <>
                                <Typography className="mb-3">{this.labels.HubCateringFilterSubTitle}</Typography>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        options={this.state.filterStatusOptions}
                                        id="statusSelection"
                                        value={this.state.statusValue}
                                        inputLabel={this.labels.HubLabelStatus}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<Status>) => { this.setState({ statusValue: event.target.value  as Status}) }}
                                    />
                                </div>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        options={this.state.filterClassificationOptions}
                                        id="statusSelection"
                                        value={this.state.classificationValue}
                                        inputLabel={this.labels.dataNotificationClassification_S}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<Classification>) => { this.setState({ classificationValue: event.target.value  as Classification }) }}
                                    />
                                </div>
                                <div className="row my-3">
                                    <IbssAutocomplete
                                        fullWidth
                                        id="combo-box-demo"
                                        size="small"
                                        options={this.state.suppliers}
                                        getOptionLabel={(option) => option.Name}    
                                        value={this.state.suppliers.find(supplier => supplier.Supplier_Id === this.state.supplierId)}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.Name}
                                            </Box>
                                        )}
                                        onChange={(event, value) => {
                                            this.setState({
                                                supplierId: value ? value.Supplier_Id : null,
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} label={this.labels.HubLabelSupplier} />}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalCancelled()}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.filterModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <div>
                        {this.state.loading && <Spinner />}
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                            <Grid container rowSpacing={1} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                            <Grid item md={12} >
                                <Box className="table-panel-header" sx={{ml:0}}>{this.labels.HubMenuCateringItems}</Box>
                            </Grid>
                            <Grid item md={5} >
                                <IbssFilter
                                    searchTerm={this.state.searchTerm}
                                    searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                    filterButtonClicked={() => this.filterButtonClicked()}
                                />
                            </Grid>
                            <Grid item md={7} >
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                    <IbssButtonRedo variant="contained" color="secondary" onClick={() => this.exportClicked()}>{this.labels.HubButtonExport}</IbssButtonRedo>
                                </Box>
                                <IbssActionButton buttons={actionButtons} />
                            </Grid>
                            </Grid>
                                <Box sx={{mt:1}}>
                                <IbssDataGrid
                                    checkboxSelection
                                    columns={dataGridHeader}
                                    disableRowSelectionOnClick
                                    rows={filteredTasks}
                                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.tasksSelected(rowSelectionModel)}
                                    initialState={{
                                        pagination: { paginationModel: {pageSize: 25} },
                                    }}
                                    pageSizeOptions={[25,50,100]}
                                />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
}

interface IState
{
    loading: boolean;
    taskList: MenuItemView[];
    isCreateRight: boolean;
    isCancelRight: boolean;
    isUpdateRight: boolean;
    buildingId: number;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    actionType: string;
    searchTerm: string;
    openFilterModal: boolean;
    statusValue: Status | "Any";
    classificationValue: Classification | "Any";
    supplierId: string;
    disableBtn: boolean;
    enableBtn: boolean;
    filterStatusOptions: IFilterOption[];
    filterClassificationOptions: IFilterOption[];
    suppliers: ISupplierView[];
    currency: string;
}

interface IFilterOption
{
    label: string;
    value: string;
}

class MenuItemView
{
    public id: string;
    public description: string;
    public unitPrice: number;
    public name: string;
    public supplierId: string;
    public status: Status;
    public classification: Classification;
    public concurrencyStamp: string;

    constructor(value: ISpaceCateringMenuItem)
    {   
        this.id = value.MenuItem_Id;
        this.name = value.Name;
        this.description = value.Description;
        this.unitPrice = value.UnitPrice;
        this.supplierId = value.Supplier_Id;
        this.status = value.Status;
        this.classification = value.Classification;
        this.concurrencyStamp = value.ConcurrencyStamp;
    }
}