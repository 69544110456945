import { Component } from "react";
import "../../../styles/css/dashboard.scss";
import "../../../styles/css/header.scss";
import "../../../App.css";
import { RouteComponentProps, RouterProps, generatePath } from 'react-router';
import ConfirmModal from "../../../Components/uicomponents/ConfirmModal";
import LoadingOverlay from "../../../Components/LoadingOverlay"
import FormPanelInfo from "../../../Components/FormPanelInfo"
import { appContext } from "../../../AppContext";
import { Autocomplete, Box, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import IbssTextField from "../../../Components/uicomponents/IbssTextField";
import IbssSwitchLabel from "../../../Components/uicomponents/IbssSwitchLabel";
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import { IDispatch, IPropsFromState } from "../../../redux/Interfaces";
import { ICreateSpaceCateringMenuItemPayload } from "../../../Providers.Api/CateringItems/CreateEndpoint";
import { IUpdateSpaceCateringMenuItemPayload } from "../../../Providers.Api/CateringItems/UpdateEndpoint";
import MediaSelector from "../../../Components/MediaSelector";
import { IFile } from "../../../Providers.Api/Files/FilesRepository";
import Helper from "../../../Common/Helper";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ICateringOrderPolicy } from "../../../Providers.Api/CateringOrderPolicies/CateringOrderPoliciesRepository";
import { ICateringRestriction } from "../../../Providers.Api/CateringRestrictions/CateringRestrictionsRepository";
import { ISupplierView } from "../../../Providers.Api/CateringSuppliers/CateringSupplierRepository";
import { CateringItemsFilter, Classification } from '../../../Providers.Api/CateringItems/CateringItemRepository';
import { DateTime } from "luxon";
import IbssInputDropDown, { optionObject } from "../../../Components/uicomponents/IbssInputDropDown";
import IbssFormControl from "../../../Components/uicomponents/IbssFormControl";
import IbssDialog from "../../../Components/uicomponents/IbssDialog";
import IbssCheckBox from "../../../Components/uicomponents/IbssCheckBox";

export default class EditCateringItem extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }
    private get local() { return appContext().localStorageProvider; }


    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoadingToSave: false,
            buildingid: this.appState.buildingId,
            loading: false,
            imageUrl: "",
            status: false,
            show: false,
            isDelete: false,
            images: [],
            orderPolicy: [],
            selectedPolicy: "",
            dietries: [],
            allergen:[],
            dietaryRestrictions: [],
            allergenRestrictions: [],
            selectedClassification: "",
            suppliers: [],
            selectedSuppliers: "",
            title:"",
            description:"",
            neutritionalInformation:"",
            unitPrice: 0,
            retailPrice: 0,
            concurrencyStamp: "",
            isRestriction: true,
            showRestrictionsModal: false,
            showCheckBoxModal: false,
            selectedRestriction: "",
            classifications: [
                {
                    label:this.labels.HubLabelBeverage,
                    value:"MenuItemClassificationBeverage"
                },
                {
                    label:this.labels.HubLabelFood,
                    value:"MenuItemClassificationFood"
                },
                {
                    label:this.labels.HubLabelSnack,
                    value: "MenuItemClassificationSnack"
                }
            ],
            nameError: false,
            classificationError: false,
            orderPolicyError: false,
            supplierError: false,
            allRestrictions:[]
        };
    }


    public componentDidMount(): void
    {
        const { match } = this.props;
        if (match.params.buildingid !== "0" && match.params.spacecateringId !== "0")
        {
            this.retrieveSpecificCateringData();
        }
        this.getImages();
        this.getOrderpolicy();
        this.getDietary();
        this.getSuppliers();
        
    }

    private async getOrderpolicy(): Promise<void>
    {
        if(this.state.buildingid) 
        {
            const response = await this.apiClient.cateringOrderPolicies.getMany(this.state.buildingid);
            this.setState({
                orderPolicy: response,
            })
        }
    }

    private async getDietary(): Promise<void>
    {
        if(this.state.buildingid) 
        {
            const response = await this.apiClient.cateringRestrictions.getCateringRestrictions(this.state.buildingid)
            const dietary = response.Value.filter((item) => item.Section === "Dietary");
            const allergen = response.Value.filter((item) => item.Section === "Allergen");
            this.setState({
                dietries: dietary,
                allergen: allergen
            })
        }
    }

    private async getSuppliers(): Promise<void>
    {
        const data = await this.apiClient.cateringSuppliers.getCateringSupplierByNodeId(this.state.buildingid);
        this.setState({
            suppliers: data.Value
        })
    }

    public orderPolicyChanged(event: SelectChangeEvent): void
    {
        this.setState({
            selectedPolicy: event.target.value,
            orderPolicyError: false
          })
    }

    public supplierChanged(event: SelectChangeEvent): void
    {
        this.setState({
            selectedSuppliers: event.target.value,
            supplierError: false
        })
    }


    private async getImages(): Promise<void>
    {
        const responseData = await this.apiClient.files.getFiles('r/Catering');
        const newResponseData = responseData.map((item: IFile) => {
            let newItem = { ...item }
            newItem.path = `https://storage.ibss.${Helper.environment}/images/r/Catering/${item.name}`
            return newItem
        })
        this.setState({ images: newResponseData });
    }

    private async uploadImage(filename: string, file: FormData, processAsMap: boolean): Promise<void>
    {
        await this.apiClient.files.uploadFile(file, 'r/Catering/' + filename, processAsMap);
        this.getImages();   
    }

    private async retrieveSpecificCateringData(): Promise<void>
    {
        try
        {
            const { match }: IProps = this.props;
            const response = await this.apiClient.cateringItems.getById(this.state.buildingid,match.params.spacecateringId);
            const restrictions = await this.apiClient.cateringItemRestriction.getRestrictions(this.state.buildingid,match.params.spacecateringId);
            const allergenRestrictions = restrictions.Value.filter((item: any) => item.Section === "Allergen");
            const dietaryRestrictions = restrictions.Value.filter((item: any) => item.Section === "Dietary");

            this.setState({
                selectedClassification: response.Classification,
                neutritionalInformation: response.NutritionalInformation,
                description: response.Description,
                selectedPolicy: response.OrderPolicy_Id,
                selectedSuppliers: response.Supplier_Id,
                imageUrl:  response.ImageURI,
                unitPrice: response.UnitPrice,
                retailPrice: response.RetailPrice,
                title: response.Name,
                status: response.Status === "StatusActive" ? true : false,
                concurrencyStamp: response.ConcurrencyStamp,
                allergenRestrictions: allergenRestrictions,
                dietaryRestrictions: dietaryRestrictions,
                allRestrictions: restrictions.Value
            })
        }
        catch
        {
            this.setState({ isLoadingToSave: false });
        }
    }

    private cancel(): void
    {
        const { history }: IProps = this.props;
        history.push(`/catering-items/` + this.session.getBuildingId());
    }

    private delete(): void
    {
        const { history, match }: IProps = this.props;
        if (this.state.isDelete)
        {
            this.apiClient.cateringItems.delete(Number(match.params.buildingid), match.params.spacecateringId).then((res) =>
            {
                history.push(`/catering-items/` + this.session.getBuildingId());
            })
            .catch(() =>
            {
                this.setState({ isLoadingToSave: false, });
            });
        }
    }

    private cancelDeleteModal(): void
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        });
    }

    public isDeleteModal(): void
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        });
        this.delete();
    }

    private async submit(): Promise<void>
    {
        const { match }: IProps = this.props;
        let isValid = true;
        if(!this.state.title)
        {
            this.setState({
                nameError: true
            })
            isValid = false;
        }
        if(!this.state.selectedPolicy)
        {
            this.setState({
                orderPolicyError: true
            }) 
            isValid = false;
        }
        if(!this.state.selectedClassification)
        {
            this.setState({
                classificationError: true
            }) 
            isValid = false;
        }
        if(!this.state.selectedSuppliers)
        {
            this.setState({
                supplierError: true
            }) 
            isValid = false;
        }
        if (match.params.spacecateringId === "0" && isValid)
        {
            this.setState({ isLoadingToSave: true });
            await this.createCatering();
        }
    }
    
    private async update(): Promise<void>
    {
        await this.updateCatering();
    }

    private async addRestriction(type: string): Promise<void>
    {
        this.setState({
            showCheckBoxModal: true,
            selectedRestriction : type
        })
    }

    private async createCatering(): Promise<void>
    {
        const { history } = this.props;
        const payload: ICreateSpaceCateringMenuItemPayload = 
        {
            Classification: this.state.selectedClassification,
            Supplier_Id: this.state.selectedSuppliers,
            OrderPolicy_Id: this.state.selectedPolicy,
            Name: this.state.title,
            UnitPrice: this.state.unitPrice, 
            RetailPrice: this.state.retailPrice,
            NutritionalInformation: this.state.neutritionalInformation,
            Description: this.state.description,
            ImageURI: this.state.imageUrl,
            Node_Id: this.state.buildingid,
            Status: this.state.status ? "StatusActive" : "StatusInactive",
        }
        try
        {
            const newCateringItem = await this.apiClient.cateringItems.create(this.state.buildingid, payload);
            const path = generatePath(this.props.match.path, { buildingid: this.state.buildingid, spacecateringId: newCateringItem.MenuItem_Id });
            this.setState({ concurrencyStamp: newCateringItem.ConcurrencyStamp });

            history.replace(path);
            this.setState({
                isLoadingToSave: false,
                isRestriction: false,
                showRestrictionsModal: true
            })
        } 
        catch(error)
        {
            this.setState({
                isLoadingToSave: false,
            })
        }
    }

    private async updateCatering(): Promise<void>
    {
        const payload: IUpdateSpaceCateringMenuItemPayload =
        {
            Classification: this.state.selectedClassification,
            Supplier_Id: this.state.selectedSuppliers,
            OrderPolicy_Id: this.state.selectedPolicy,
            Name: this.state.title,
            UnitPrice: this.state.unitPrice, 
            RetailPrice: this.state.retailPrice,
            NutritionalInformation: this.state.neutritionalInformation,
            Description: this.state.description,
            ImageURI: this.state.imageUrl,
            Node_Id: this.state.buildingid,
            Status: this.state.status ? "StatusActive" : "StatusInactive",
            ConcurrencyStamp: this.state.concurrencyStamp
        };

        const { match,history } = this.props;
        try
        {
            await this.apiClient.cateringItems.update(this.state.buildingid,match.params.spacecateringId,payload)
            this.setState({
                isRestriction: false,
                isLoadingToSave: false
            })
            this.setState({ isLoadingToSave: false });
            history.push(`/catering-items/` + this.state.buildingid);

        }
        catch(error)
        {
        }
    }

    private get orderPoliciyOptions(): optionObject[]
    {
        return this.state.orderPolicy.map(i =>
        ({
            label: i.Name,
            value: i.OrderPolicy_Id
        }))
    }

    private nutritionalInformationChanged(event: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({
            neutritionalInformation: event.target.value 
        })
    }

    private descriptionChanged(event: React.ChangeEvent<HTMLInputElement>): void 
    {
        this.setState({
            description: event.target.value 
        })
    }

    private classificationChanged(e: React.ChangeEvent<HTMLInputElement>): void
    {
    const value = e.target.value;
     this.setState({
            selectedClassification: value,
            classificationError: false
        })
    }

    private restrictionsModalSubmitted(): void
    {
        const {history} = this.props;
        this.restrictionsModalClosed();
        history.push(`/catering-items/${this.state.buildingid}`);
    }

    private restrictionsModalClosed(): void
    {
        this.setState({
            showRestrictionsModal: false,
        });
    }

    private checkboxModalClosed(): void
    {
        this.setState({
            showCheckBoxModal: false,
        });
    }

    private async checkBoxModalSubmitted(): Promise<void>
    {   
        const { match } = this.props;
        const selectedRestrictionType = this.state.selectedRestriction === this.labels.HubLabelAllergens
        ? this.state.allergenRestrictions
        : this.state.dietaryRestrictions;
      
        const addIdList = selectedRestrictionType
        .filter(item => item.Restriction_Id !== undefined && item.Restriction_Id !== null)
        .map(item => item.Restriction_Id) as string[];

        const removeIdList: string[] = [];
        
        const section = this.state.selectedRestriction === this.labels.HubLabelAllergens ? "Allergen" : "Dietary";
        const restrictions = this.state.allRestrictions.filter(item => item.Section === section);
        
        restrictions.forEach(item => 
            {
                const itemInAddList = item.Restriction_Id && addIdList.includes(item.Restriction_Id);
                if (itemInAddList && item.Restriction_Id) 
                {
                    addIdList.splice(addIdList.indexOf(item.Restriction_Id), 1);
                } 
                else if (!itemInAddList && item.Restriction_Id) 
                {
                    removeIdList.push(item.Restriction_Id);
                }
        });

        const payload = 
        {
            Add_Ids: addIdList || [],
            Remove_Ids: removeIdList || []
        };

        if (payload.Add_Ids.length == 0 && payload.Remove_Ids.length == 0)
        {
            this.checkboxModalClosed();
            return;
        }

        await this.apiClient.cateringItemRestriction.update(this.state.buildingid, match.params.spacecateringId, payload);

        const itemRestrictions = await this.apiClient.cateringItemRestriction.getRestrictions(this.state.buildingid,match.params.spacecateringId);
        this.setState({ allRestrictions: itemRestrictions.Value });
        this.checkboxModalClosed();
    }

    private checkBoxStatus(value: ICateringRestriction): boolean
    {
        const restrictions = (this.state.selectedRestriction === this.labels.HubLabelAllergens ? this.state.allergenRestrictions : this.state.dietaryRestrictions);
        const hasValue = restrictions.find((item)=> item.Restriction_Id === value.Restriction_Id) != null;
        return hasValue;
    }

    private async updateList(value: ICateringRestriction, checked:boolean): Promise<void>
    {
        let updatedList = this.state.selectedRestriction === this.labels.HubLabelAllergens ? this.state.allergenRestrictions : this.state.dietaryRestrictions;
        if (checked)
        {
            updatedList.push(value);
        }
        else
        {
            updatedList = updatedList.filter((item)=> item.Restriction_Id !== value.Restriction_Id);
        }
        if (this.state.selectedRestriction === this.labels.HubLabelAllergens)
        {
            this.setState({
                allergenRestrictions: updatedList
            });
        }
        else
        {
            this.setState({
                dietaryRestrictions: updatedList
            });
        }
    }

    public render(): JSX.Element
    {

        const { match }: IProps = this.props;

        const confModalData = this.state.isDelete &&
        {
            show: this.state.show,
            handleModal: () => this.cancelDeleteModal(),
            okButton: () => this.delete(),
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        };

        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <div className="page-height-exct-header">
                <div className="rightPanel-main-content ">
                    {this.state.show ? (
                        <ConfirmModal {...confModalData} />
                    ) : ""}
                        {this.state.loading || (
                            <Grid container sx={{ml:0}}>
                                <Grid item md={12}>
                                <Grid container spacing={1} rowSpacing={1} mt={.5} ml={.5} sx={{ backgroundColor: "white",  borderRadius: '10px', padding: '16px' }}>
                                <Grid item md={12} sx={{mt:1}}>
                                    <FormPanelInfo
                                        formHeading={this.labels.HubLabelCateringMenu}
                                        iconSrc="/images/Sidebar_Icons/Light_theme/Drink.svg"
                                    />
                                </Grid>
                                <Grid item md={12} sx={{mt:1}}>
                                    <IbssFormControl fullWidth size="small" >
                                    <IbssInputDropDown
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.classificationChanged(e)}
                                        value={this.state.selectedClassification}
                                        inputLabel={this.labels.dataNotificationClassification_S}
                                        required
                                        id='classification'
                                        options = {this.state.classifications}
                                        error={this.state.classificationError ? true : false} 
                                        helperText={this.state.classificationError ? this.labels.HubLabelclassificationError_D : ""}
                                    />
                                    </IbssFormControl>
                                </Grid>
                                <Grid item md={12} sx={{mt:1}}>
                                    <IbssTextField id="standard-basic" color="secondary" error={this.state.nameError ? true : false} helperText={this.state.nameError ? this.labels.HubLabelNameError_D : ""} label={this.labels.funcCateringMenuEditName_S} required variant="outlined" size="small" fullWidth value={this.state.title}
                                            onChange={(event) =>
                                                this.setState({ title: event.target.value,nameError: false })
                                            }
                                        />
                                </Grid>
                                <Grid item md={6} sx={{mt:1}}>
                                    <IbssTextField
                                        id="outlined-multiline-static"
                                        label={this.labels.HubLabelDescription}
                                        multiline
                                        color="secondary"
                                        fullWidth
                                        rows={4}
                                        value={this.state.description}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.descriptionChanged(e)}

                                    />
                                </Grid>
                                <Grid item md={6} sx={{mt:1}}>
                                    <IbssTextField
                                        id="outlined-multiline-static"
                                        label={this.labels.HubLabelNutritionalInformation}
                                        multiline
                                        color="secondary"
                                        fullWidth
                                        rows={4}
                                        value={this.state.neutritionalInformation}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.nutritionalInformationChanged(e)}
                                    />
                                </Grid>
                                <Grid item md={7} sx={{mt:1}}>
                                    <Box className="mb-3">
                                    <IbssFormControl fullWidth size="small">
                                                <IbssInputDropDown
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.orderPolicyChanged(e)}
                                                    value={this.state.selectedPolicy}
                                                    inputLabel={this.labels.HubLabelMenuOrderPolicy}
                                                    required
                                                    id='orderPolicy'
                                                    options={this.orderPoliciyOptions}
                                                    error={this.state.orderPolicyError ? true : false} 
                                                    helperText={this.state.orderPolicyError ? this.labels.HubLabelorderPolicyError_D : ""}
                                                />
                                            </IbssFormControl>
                                    </Box>
                                    <Box className="mb-3">
                                    <IbssFormControl fullWidth size="small">
                                        <IbssInputDropDown
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.supplierChanged(e)}
                                            inputLabel={this.labels.HubLabelSupplier}
                                            required
                                            id='spaceType'
                                            error={this.state.supplierError}
                                            value={this.state.selectedSuppliers}
                                            options= {this.state.suppliers.map((item) => {
                                                return (
                                                    {
                                                        label: item.Name,
                                                        value: item.Supplier_Id
                                                    }
                                                )
                                            })}
                                        />
                                    </IbssFormControl>
                                    </Box>
                                    <Box className="mb-2" sx={{ml:2}}>
                                        <FormControlLabel control={
                                            <IbssSwitchLabel checked={this.state.status}
                                                onChange={(event) => this.setState({ status: event.target.checked })}
                                            />
                                        } label="Enabled" />
                                    </Box>
                                </Grid>
                                <Grid item md={5} sx={{mt:1}}>
                                    <Box className="mb-3" sx={{ml:1}}>
                                        <IbssTextField id="standard-basic" color="secondary" type="number" label={this.labels.HubLabelCost} variant="standard" fullWidth value={this.state.unitPrice}
                                            inputProps={{
                                                min: 0,
                                            }}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                if (!inputValue.startsWith('-')) {
                                                    this.setState({ unitPrice: parseFloat(inputValue) });
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box className="mb-3" sx={{ml:1}}>
                                        <IbssTextField id="standard-basic" color="secondary" type="number" label={this.labels.HubLabelRetailPrice} variant="standard" fullWidth value={this.state.retailPrice}
                                            inputProps={{
                                                min: 0,
                                            }}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                if (!inputValue.startsWith('-')) {
                                                    this.setState({ retailPrice: parseFloat(inputValue) });
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ml:1}}>
                                        <MediaSelector
                                            options={this.state.images.map(x => {return{name: x.name, url:x.path}})}
                                            imageSelected={(file: { name: string, url: string }) => this.setState({ imageUrl: file.url })}
                                            uploadFile={(filename: string, file: FormData, processAsMap: boolean) => this.uploadImage(filename, file, processAsMap)}
                                            defaultSelectedImage={this.state.imageUrl}
                                        />
                                    </Box>
                                </Grid>

                               {match.params.spacecateringId !== "0" &&
                                <>
                                <Grid item sm={1} sx={{mt:1}}>
                                    <Typography variant="body1" sx={{ pr: 2, whiteSpace: "nowrap" }} gutterBottom>{this.labels.HubLabelAllergens}</Typography>
                                </Grid>
                                <Grid item sm={11} className="d-flex align-items-center" sx={{mt:1}}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        className="chips-btns"
                                        id="tags-outlined" 
                                        options={this.state.allergen}
                                        getOptionLabel={(option) => option.Name}
                                        filterSelectedOptions
                                        forcePopupIcon={false}
                                        ChipProps={{ deleteIcon: <></> }}
                                        value={this.state.allergenRestrictions}
                                        onChange={(event, newValue) => {
                                            this.setState({ allergenRestrictions: newValue });
                                        }}
                                        disabled
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            size="small"
                                            />
                                        )}
                                    />
                                    <IbssButtonRedo size="small" color="primary"  variant="contained" className="mr-2 height-40px" onClick={() => this.addRestriction(this.labels.HubLabelAllergens)}>+</IbssButtonRedo>
                                </Grid>
                                                        
                                <Grid item md={1} sx={{mt:1}}>
                                    <Typography variant="body1" sx={{ pr: 2, whiteSpace: "nowrap" }} gutterBottom>{this.labels.HubLabelDietaryType}</Typography>
                                </Grid>
                                <Grid item sm={11} className="d-flex align-items-center" sx={{mt:1}}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        className="chips-btns"
                                        id="tags-outlined"
                                        options={this.state.dietries}
                                        getOptionLabel={(option) => option.Name}
                                        disabled
                                        forcePopupIcon={false}
                                        ChipProps={{ deleteIcon: <></> }}
                                        filterSelectedOptions
                                        value={this.state.dietaryRestrictions}
                                        onChange={(event, newValue) => {
                                            this.setState({ dietaryRestrictions: newValue });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            size="small"
                                            />
                                        )}
                                    />
                                    <IbssButtonRedo size="small" color="primary" variant="contained" className="mr-2 height-40px" onClick={() => this.addRestriction(this.labels.HubLabelDietaryType)}>+</IbssButtonRedo>
                                </Grid>
                                </>
                                }

                                </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <div className="rightPanel-main-button-group">
                            <Box>
                                <IbssButtonRedo color="error" variant="contained" className="mr-2" disabled={match.params.spacecateringId == "0" && this.local.hasRight("DATAMODEL.CateringMenuItems.Delete") ? true : false} onClick={() => this.isDeleteModal()}>{this.labels.HubButtonDelete}</IbssButtonRedo>
                            </Box>
                            <Box>
                                <IbssButtonRedo color="secondary" variant="contained" className="mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</IbssButtonRedo>
                                <IbssButtonRedo color="primary" variant="contained" className="ml-2" disabled={!this.local.hasRight("DATAMODEL.CateringMenuItems.Create")} onClick={match.params.spacecateringId === "0" ? () => this.submit() : () => this.update()}>{this.labels.HubButtonSave}</IbssButtonRedo>
                            </Box>
                        </div>

                        <IbssDialog
                        aria-modal="true"
                        aria-label="restrictions modal"
                        onClose={() => this.restrictionsModalClosed()}
                        open={this.state.showRestrictionsModal}
                        header={this.labels.HubLabelAddRestrictions}
                        fullWidth
                        content={
                                <>
                                  <div className="my-3">
                                    {this.labels.HubLabelAddRestrictionsDescription}
                                  </div>
                                  <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" sx={{ pt: 2, whiteSpace: "nowrap" }} gutterBottom>{this.labels.HubLabelAllergens}</Typography>
                                        </Grid>
                                        <Grid item xs={9} className="d-flex align-items-center">
                                            <Autocomplete
                                            fullWidth
                                            multiple
                                            className="chips-btns"
                                            id="tags-outlined"
                                            options={this.state.allergen}
                                            getOptionLabel={(option) => option.Name}
                                            filterSelectedOptions
                                            disabled
                                            ChipProps={{ deleteIcon: <></> }}
                                            value={this.state.allergenRestrictions}
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                size="small"
                                                />
                                            )}
                                            />
                                            <IbssButtonRedo size="large" color="primary" disabled={this.state.isRestriction} variant="contained" className="mr-2 height-40px" onClick={() => this.addRestriction(this.labels.HubLabelAllergens)}>+</IbssButtonRedo>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Typography variant="body1" sx={{ pt: 2, whiteSpace: "nowrap" }} gutterBottom>{this.labels.HubLabelDietaryType}</Typography>
                                        </Grid>
                                            <Grid item sm={9} className="d-flex align-items-center">
                                            <Autocomplete
                                                fullWidth
                                                multiple
                                                className="chips-btns"
                                                id="tags-outlined"
                                                options={this.state.dietries}
                                                getOptionLabel={(option) => option.Name}
                                                ChipProps={{ deleteIcon: <></> }}
                                                filterSelectedOptions
                                                disabled
                                                value={this.state.dietaryRestrictions}
                                                renderInput={(params) => (
                                                    <TextField
                                                    {...params}
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                                                    size="small"
                                                    />
                                                )}
                                            />
                                                <IbssButtonRedo size="small" color="primary" disabled={this.state.isRestriction} variant="contained" className="mr-2 height-40px" onClick={() => this.addRestriction(this.labels.HubLabelDietaryType)}>+</IbssButtonRedo>
                                            </Grid>
                                    </Grid>
                                </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className="ml-2"
                                    disabled={!(this.state.allergenRestrictions?.length || this.state.dietaryRestrictions?.length)}
                                    onClick={() => this.restrictionsModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                        
                    />
                      <IbssDialog
                        aria-modal="true"
                        aria-label="restrictions checklist modal"
                        onClose={() => this.checkboxModalClosed()}
                        open={this.state.showCheckBoxModal}
                        header={this.state.selectedRestriction}
                        fullWidth
                        content={
                            <div className="minHeight-30">
                            {this.state.selectedRestriction === "Allergens" ? 
                                <>
                                <div>
                                    {this.labels.HubLabelAllergensDescription }
                                </div>
                                <Grid container spacing={2}>
                                {this.state.allergen?.map((allergenValue: ICateringRestriction)=>
                                    (
                                        <Grid item xs={6}>
                                        <IbssCheckBox label={allergenValue.Name} checked={this.checkBoxStatus(allergenValue)} onClicked={(e: { target: { checked: boolean; }; }) =>  {this.updateList(allergenValue, e.target.checked)}} />
                                        </Grid>
                                    )
                                )}
                                </Grid>
                                </>
                                :   
                                <>
                                <div>
                                    {this.labels.HubLabelDietaryDescription}
                                </div>
                                <Grid container spacing={2}>
                                {this.state.dietries?.map((dietriesValue: ICateringRestriction)=>
                                        (
                                            <Grid item xs={6}>
                                                <IbssCheckBox label={dietriesValue.Name} checked={this.checkBoxStatus(dietriesValue)} onClicked={(e: { target: { checked: boolean; }; }) =>  {this.updateList(dietriesValue, e.target.checked)}} />
                                                </Grid>
                                        )
                                    )}
                                </Grid>
                                </> 
                            }
                            </div>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className="ml-2"
                                    onClick={() => this.checkBoxModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />

                </div>
                </div>
            </>
        );
    }
}

interface IProps extends RouterProps, RouteComponentProps<IQueryParams>, IPropsFromState, IDispatch
{
}

interface IQueryParams 
{
    buildingid: string;
    spacecateringId: string;
}

interface IClassification 
{
    label: string;
    value: string;
}

interface IState
{
    isLoadingToSave: boolean;
    buildingid: number;
    loading: boolean;
    imageUrl: string;
    status: boolean;
    show: boolean;
    isDelete: boolean;
    images: IFile[];
    orderPolicy: ICateringOrderPolicy[];
    selectedPolicy: string;
    dietries: ICateringRestriction[];
    allergen: ICateringRestriction[];
    dietaryRestrictions: ICateringRestriction[];
    allergenRestrictions: ICateringRestriction[];
    classifications: IClassification[];
    selectedClassification: Classification | string;
    suppliers: ISupplierView[];
    selectedSuppliers: string;
    title: string;
    description: string;
    neutritionalInformation: string;
    unitPrice: number;
    retailPrice: number;
    concurrencyStamp: string;
    isRestriction: boolean;
    nameError: boolean;
    supplierError: boolean;
    classificationError: boolean,
    orderPolicyError: boolean,
    showRestrictionsModal:boolean,
    showCheckBoxModal:boolean,
    selectedRestriction: string,
    allRestrictions:ICateringRestriction[]
}
