import { Component } from "react";
import { Card } from "react-bootstrap";
import { appContext } from "../../AppContext";
import { Constants } from "../../Common/Constants";
import "./RecurringSpaceCard.scss";
import { Icons } from '../../Common/AllsvgIcons';
import IbssSvgIcon from "../uicomponents/IbssSvgIcon";

export default class SpaceCard extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private appState = appContext().state;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            lightModeTheme: false,
        };
    };

    public componentWillMount(): void
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme }));
    }

    public render(): JSX.Element
    {
        let props = this.props;

        return (
            <div className={`mb-1 recurring-space-card ${props.selected ? 'card-selected' : ''}`} onClick={() => { }}>
                <Card className={`${props.capacity == '1' ? 'content-card-desk' : 'content-card'}`}>
                    <div style={{ position: 'relative' }}>
                        {
                            props.bestFit && props.recurringSpace &&
                            <div className="best-fit-label">{this.labels.HubLabelBestFit}</div>
                        }
                        <Card.Img className="card-image" style={{ borderRadius: props.selected ? '0px' : '5px 5px 0px 0px' }} src={props.imageUrl ? props.imageUrl : Constants.imagePaths.get(props.spaceType)} />
                        <div className="mr-2 ml-2">
                            <div className="d-flex">
                                <h3 className="space-label" style={{ width: '85%', margin: '10px 8px' }}>{props.spaceName ? props.spaceName : props.spaceType}</h3>
                                <div style={{ width: '15%' }} className="align-icons-text">
                                    <span className="recurring-icon"><img src="/images/Sidebar_Icons/Active_icons/Attendee Icon.svg" /></span>
                                    <div style={{ width: '20%', alignSelf: 'center' }}>{props.capacity}</div>
                                </div>
                            </div>
                            <div className="space-info">
                                <div className="d-flex m-2 mb-3">
                                    <div style={{ width: '50%', fontSize: '11px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.FloorIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginTop: '3px' }}>{props.floor}</div>
                                    </div>
                                    <div style={{ width: '50%', fontSize: '11px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.ZoneIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginTop: '3px' }}>{props.zone}</div>
                                    </div>
                                </div>
                                <div className="d-flex m-2">
                                    <div style={{ width: '50%', fontSize: '15px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.calenderIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginLeft: '-5px' }}>{props.dateRange}</div>
                                    </div>
                                    <div style={{ width: '50%', fontSize: '17px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2" sx={{ marginTop: '2px' }}>
                                            {Icons.TimeIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginLeft: '-5px' }}>{props.timeRange}</div>
                                    </div>
                                </div>
                                <div className="row pb-1" >
                                    <div className="col-12 mt-8 d-flex" style={{ padding: 0, paddingBottom: '5px' }}>
                                        {props.cateringAvailable === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.requiresAV === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/ITAV (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.hearingAidAvailable === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.presentationAidAvailable === 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.spaceSetup > 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} alt="icon" /></div>
                                            </div>
                                        )}

                                        {props.spaceSetup >= 1 && (
                                            <div className="align-icons-text ml-8">
                                                <div className="space-card__icon back-round"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} alt="icon" /></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                {
                    props.recurringSpace &&
                    <div className={`d-flex ${props.capacity == '1' ? '' : 'additional-info'}`} style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginRight: '-13px', paddingTop: '11px' }}>
                            <IbssSvgIcon className="mr-2">
                                {Icons.RepeatIcon}
                            </IbssSvgIcon>
                        </div>
                        <div className="mr-3 availability-text">
                            {this.labels.HubLabelRecurrenceAvailability}
                        </div>
                        <div className="ml-3 occurences">{props.availableOccurrences}/{props.requestedOccurrence} {this.labels.HubLabelOccurrences}</div>
                    </div>
                }
            </div>
        );
    }
}

export interface IProps
{
    imageUrl: string;
    spaceName: string;
    spaceType: string;
    capacity: string;
    floor: string;
    zone: string;
    spaceLayout: string;
    dateRange: string;
    timeRange: string;
    requestedOccurrence: string | null;
    availableOccurrences: string | null;
    selected: boolean;
    bestFit: boolean;
    cateringAvailable: number;
    presentationAidAvailable: number;
    hearingAidAvailable: number;
    requiresAV: number;
    spaceSetup: number;
    recurringSpace: boolean;
}

export interface IState
{
    lightModeTheme: boolean
}
