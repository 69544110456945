import { IApiCache, ApiCache } from "./Providers.Api/ApiCache";
import { ApiClient, IApiClient } from "./Providers.Api/ApiClient";
import Authentication from "./Providers.Api/Authentication";
import { ICache, Cache } from "./Common/Cache";
import { AlertModalController } from "./Components/AlertModal";
import { IMsalProvider } from "./Providers.Msal/IMsalProvider";
import { MsalProvider } from "./Providers.Msal/MsalProvider";
import { RouteComponentProps } from "react-router-dom";
import { ConfigHelper } from "./Common/ConfigHelper";
import { ISessionStorageProvider, SessionStorageProvider } from "./Providers.SessionStorage/SessionStorageProvider";
import { ILocalStorageProvider, LocalStorageProvider } from "./Providers.LocalStorage/LocalStorageProvider";
import { IUserPreferencesService, UserPreferencesService } from "./Services/UserPreferencesService";
import { Event } from "./Common/Event";
import { Component } from "react";
import { BookingService, IBookingService } from "./Services/BookingService";
import { ISpaceService, SpaceService } from "./Services/SpaceService";
import { GetV2BookingEndpoint } from "./Providers.Api/Bookings/GetV2BookingEndpoint";
import { BookingPolicyRepository } from "./Providers.Api/BookingPolicies/BookingPolicyRepository";
import { SpaceRepository } from "./Providers.Api/Spaces/SpaceRepository";
import { BookingRepository } from "./Providers.Api/Bookings/BookingRepository";
import { UserPreferenceRepository } from "./Providers.Api/UserPreferenceRepository";
import { SpacesDailySummaryRepository } from "./Providers.Api/SpacesDailySummaryRepository";
import { RoleRepository } from "./Providers.Api/Roles/RoleRepository";
import { TaskRepository } from "./Providers.Api/Tasks/TaskRepository";
import { CostCodeRepository } from "./Providers.Api/CostCodes/CostCodeRepository";
import { ParameterRepository } from "./Providers.Api/Parameters/ParameterRepository";
import { BookingPartyRepository } from "./Providers.Api/BookingParties/BookingPartyRepository";
import { GetV1BookingEndpoint } from "./Providers.Api/Bookings/GetV1BookingEndpoint";
import { GetBookingsEndpoint } from "./Providers.Api/Bookings/GetBookingsEndpoint";
import { GetV2BookingsEndpoint } from "./Providers.Api/Bookings/GetV2BookingsEndpoint";
import { GetV1BookingsByEmailEndpoint } from "./Providers.Api/Bookings/GetV1BookingsByEmailEndpoint";
import { GetMyV2BookingsEndpoint } from "./Providers.Api/Bookings/GetMyV2BookingsEndpoint";
import { GetMyBookingsForOthersEndpoint } from "./Providers.Api/Bookings/GetMyBookingsForOthersEndpoint";
import { GetMyV2BookingsForOthersEndpoint  } from "./Providers.Api/Bookings/GetMyV2BookingsForOthersEndpoint";
import { ApproveBookingEndpoint } from "./Providers.Api/Bookings/ApproveBookingEndpoint";
import { RejectBookingEndpoint } from "./Providers.Api/Bookings/RejectBookingEndpoint";
import { CreateV1BookingEndpoint } from "./Providers.Api/Bookings/CreateV1BookingEndpoint";
import { CreateV2BookingEndpoint } from "./Providers.Api/Bookings/CreateV2BookingEndpoint";
import { DeleteV1BookingEndpoint } from "./Providers.Api/Bookings/DeleteV1BookingEndpoint";
import { DeleteV2BookingEndpoint } from "./Providers.Api/Bookings/DeleteV2BookingEndpoint";
import { UpdateBookingCostCodesEndpoint } from "./Providers.Api/Bookings/UpdateBookingCostCodesEndpoint";
import { UpdateV1BookingEndpoint } from "./Providers.Api/Bookings/UpdateV1BookingEndpoint";
import { UpdateV2BookingEndpoint } from "./Providers.Api/Bookings/UpdateV2BookingEndpoint";
import { PatchV2BookingEndpoint } from "./Providers.Api/Bookings/PatchV2BookingEndpoint";
import { DownloadV1BookingEndpoint } from "./Providers.Api/Bookings/DownloadV1BookingEndpoint";
import { DownloadV2BookingEndpoint } from "./Providers.Api/Bookings/DownloadV2BookingEndpoint";
import { CateringItemRepository } from "./Providers.Api/CateringItems/CateringItemRepository";
import { GetManyEndpoint as GetManySpaceCateringMenuItemsEndpoint } from "./Providers.Api/CateringItems/GetManyEndpoint";
import { UpdateEndpoint as UpdateSpaceCateringMenuItemEndpoint } from "./Providers.Api/CateringItems/UpdateEndpoint";
import { DeleteEndpoint as DeleteSpaceCateringMenuItemEndpoint } from "./Providers.Api/CateringItems/DeleteEndpoint";
import { CreateEndpoint as CreateSpaceCateringMenuItemEndpoint } from "./Providers.Api/CateringItems/CreateEndpoint";
import { GetByIdEndpoint as GetSpaceCateringMenuItemByIdEndpoint } from "./Providers.Api/CateringItems/GetByIdEndpoint";
import { GetNewCateringOrdersEndpoint } from "./Providers.Api/CateringOrders/GetNewCateringOrdersEndpoint";
import { GetCateringOrdersEditEndpoint } from "./Providers.Api/CateringOrders/GetCateringOrdersEditEndpoint";
import { GetCateringOrdersEndpoint } from "./Providers.Api/CateringOrders/GetCateringOrdersEndpoint";
import { UpdateCateringOrdersEndpoint } from "./Providers.Api/CateringOrders/UpdateCateringOrdersEndpoint";
import { CreateTaskEndpoint } from "./Providers.Api/Tasks/CreateTaskEndpoint";
import { ExportTasksEndpoint } from "./Providers.Api/Tasks/ExportTasksEndpoint";
import { GetTaskEndpoint } from "./Providers.Api/Tasks/GetTaskEndpoint";
import { GetTasksByBookingIdEndpoint } from "./Providers.Api/Tasks/GetTasksByBookingIdEndpoint";
import { GetTasksEndpoint } from "./Providers.Api/Tasks/GetTasksEndpoint";
import { SetStatusToInProgressEndpoint } from "./Providers.Api/Tasks/SetStatusToInProgressEndpoint";
import { UpdateTaskEndpoint } from "./Providers.Api/Tasks/UpdateTaskEndpoint";
import { CateringOrderRepository } from "./Providers.Api/CateringOrders/CateringOrderRepository";
import { NotificationRepository } from "./Providers.Api/Notifications/NotificationRepository";
import { GetActiveNotificationEndpoint } from "./Providers.Api/Notifications/GetActiveNotificationEndpoint";
import { ExternalIdentityProviderRepository } from "./Providers.Api/ExternalIdentityProviders/ExternalIdentityProviderRepository";
import { GetExternalIdentityProvidersEndpoint } from "./Providers.Api/ExternalIdentityProviders/GetExternalIdentityProvidersEndpoint";
import { GetIdentityProvidersEndpoint } from "./Providers.Api/ExternalIdentityProviders/GetIdentityProvidersEndpoint";
import { GetCostCodesEndpoint } from "./Providers.Api/CostCodes/GetCostCodesEndpoint";
import { GetV2CostCodesEndpoint } from "./Providers.Api/CostCodes/GetV2CostCodesEndpoint";
import { GetCostCodeByIdEndpoint } from "./Providers.Api/CostCodes/GetCostCodeByIdEndpoint";
import { GetBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/GetBookingPolicyEndpoint";
import { GetBookingPoliciesEndpoint } from "./Providers.Api/BookingPolicies/GetBookingPoliciesEndpoint";
import { CreateBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/CreateBookingPolicyEndpoint";
import { UpdateBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/UpdateBookingPolicyEndpoint";
import { DeleteBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/DeleteBookingPolicyEndpoint";
import { AssignBookingPolicyToSpacesEndpoint } from "./Providers.Api/BookingPolicies/AssignBookingPolicyToSpacesEndpoint";
import { GetParameterByNameEndpoint } from "./Providers.Api/Parameters/GetParameterByNameEndpoint";
import { GetUsersEndpoint } from "./Providers.Api/Users/GetUsersEndpoint";
import { UsersRepository } from "./Providers.Api/Users/UsersRepository";
import { SpaceUtilisationSummaryRepository } from "./Providers.Api/SpaceUtilisationSummaries/SpaceUtilisationSummaryRepository";
import { GetManyEndpoint as GetManySpaceUtilisationSummariesEndpoint } from "./Providers.Api/SpaceUtilisationSummaries/GetManyEndpoint";
import { ISpaceUtilisationSummaryService, SpaceUtilisationSummaryService } from "./Services/SpaceUtilisationSummaryService";
import { CreateVisitEndpoint } from "./Providers.Api/Visits/CreateVisitEndpoint";
import { VisitsRepository } from "./Providers.Api/Visits/VisitsRepository";
import { VisitActionEndpoint } from "./Providers.Api/Visits/VisitActionEndpoint";
import { SetStatusToCancelled } from "./Providers.Api/Tasks/SetStateToCancelled";
import { GetManyEndpoint } from "./Providers.Api/Visits/GetManyEndpoint";
import { CateringMenuRepository } from "./Providers.Api/CateringMenus/CateringMenuRepository";
import { GetManyEndpoint as GetManyCateringMenusEndpoint } from "./Providers.Api/CateringMenus/GetManyEndpoint";
import { CreateEndpoint as CreateCateringMenuEndpoint } from "./Providers.Api/CateringMenus/CreateEndpoint";
import { UpdateEndpoint as UpdateCateringMenuEndpoint } from "./Providers.Api/CateringMenus/UpdateEndpoint";
import { DeleteEndpoint as DeleteCateringMenuEndpoint } from "./Providers.Api/CateringMenus/DeleteEndpoint";
import { EnableEndpoint as EnableCateringMenuEndpoint } from "./Providers.Api/CateringMenus/EnableEndpoint";
import { DisableEndpoint as DisableCateringMenuEndpoint } from "./Providers.Api/CateringMenus/DisableEndpoint";
import { GetManyAsBlobEndpoint as GetManyCateringMenuAsBlobEndpoint } from "./Providers.Api/CateringItems/GetManyAsBlobEndpoint";
import { CateringSupplierRepository } from "./Providers.Api/CateringSuppliers/CateringSupplierRepository";
import { CreateCateringSupplierEndpoint } from "./Providers.Api/CateringSuppliers/CreateCateringSupplierEndpoint";
import { GetCateringSupplierByIdEndpoint } from "./Providers.Api/CateringSuppliers/GetCateringSupplierByIdEndpoint";
import { UpdateCateringSupplierEndpoint } from "./Providers.Api/CateringSuppliers/UpdateCateringSupplier";
import { FilesRepository } from "./Providers.Api/Files/FilesRepository";
import { GetFilesEndpoint } from "./Providers.Api/Files/GetFilesEndpoint";
import { UploadFileEndpoint } from "./Providers.Api/Files/UploadFileEndpoint";
import { GetCateringSupplierByNodeIdEndpoint } from "./Providers.Api/CateringSuppliers/GetCateringSupplierByNodeIdEndpoint";
import { CateringOrderPolicyRepository } from "./Providers.Api/CateringOrderPolicies/CateringOrderPoliciesRepository";
import { GetV1SpaceByIdEndpoint } from "./Providers.Api/Spaces/GetV1SpaceByIdEndpoint";
import { GetV1SpacesEndpoint } from "./Providers.Api/Spaces/GetV1SpacesEndpoint";
import { GetV2SpacesEndpoint } from "./Providers.Api/Spaces/GetV2SpacesEndpoint";
import { SearchV1SpaceByIdEndpoint } from "./Providers.Api/Spaces/SearchV1SpaceByIdEndpoint";
import { EnvironmentalZoneDataRepository } from "./Providers.Api/EnvironmentalZoneData/EnvironmentalZoneDataRepository";
import { GetByZoneIdsEndpoint } from "./Providers.Api/EnvironmentalZoneData/GetByZoneIdsEndpoint";
import { GetManyAsBlobEndpoint as GetManyCateringSuppliersAsBlobEndpoint } from "./Providers.Api/CateringSuppliers/GetManyAsBlobEndpoint";
import { DeleteCateringSupplierEndpoint } from "./Providers.Api/CateringSuppliers/DeleteEndpoint";
import { GetV2BookingPoliciesEndpoint } from "./Providers.Api/BookingPolicies/GetV2BookingPoliciesEndpoint";
import { BookingPolicyService, IBookingPolicyService } from "./Services/BookingPolicyService";
import { GetByIdEndpoint as GetCateringMenuByIdEndpoint } from "./Providers.Api/CateringMenus/GetByIdEndpoint";
import { CateringMenuItemRepository } from "./Providers.Api/CateringMenuItems/CateringMenuItemRepository";
import { GetManyEndpoint as GetManyCateringMenuItemsEndpoint } from "./Providers.Api/CateringMenuItems/GetManyEndpoint";
import { GetAllEndpoint as GetAllRolesEndpoint } from "./Providers.Api/Roles/GetAllEndpoint";
import { GetManyEndpoint as GetManyCateringOrderPoliciesEndpoint } from "./Providers.Api/CateringOrderPolicies/GetManyEndpoint";
import { DeleteEndpoint as DeleteCateringOrderPolicyEndpoint } from "./Providers.Api/CateringOrderPolicies/DeleteEndpoint";
import { CreateEndpoint as CreateCateringOrderPolicyEndpoint } from "./Providers.Api/CateringOrderPolicies/CreateEndpoint";
import { GetOrderPolicyById as GetCateringOrderPolicyByIdEndpoint } from "./Providers.Api/CateringOrderPolicies/GetOrderPolicyById";
import { UpdateEndpoint as UpdateCateringOrderPolicy } from "./Providers.Api/CateringOrderPolicies/UpdateEndpoint";
import { CateringRestrictionsRepository } from "./Providers.Api/CateringRestrictions/CateringRestrictionsRepository";
import { GetCateringRestrictionsEndpoint } from "./Providers.Api/CateringRestrictions/GetCateringRestrictionsEndpoint";
import { DeleteCateringRestrictionEndpoint } from "./Providers.Api/CateringRestrictions/DeleteCateringRestrictionEndpoint";
import { GetManyByPeriodEndpoint as GetManySpaceUtilisationSummariesByPeriodEndpoint } from "./Providers.Api/SpaceUtilisationSummaries/GetManyByPeriodEndpoint";
import { GetDeletedEndpoint as GetDeletedUsersEndpoint } from "./Providers.Api/Users/GetDeletedEndpoint";
import { CreateEndpoint as CreateCateringRestrictionEndpoint } from "./Providers.Api/CateringRestrictions/CreateEndpoint";
import { UpdateEndpoint as EditCateringRestrictionEndpoint } from "./Providers.Api/CateringRestrictions/UpdateEndpoint";
import { CateringItemRestrictionRepository } from "./Providers.Api/CateringItemRestrictions/CateringItemRestrictionRepository";
import { CreateEndpoint as CreateCateringItemRestrictionsEndpoint } from "./Providers.Api/CateringItemRestrictions/CreateEndpoint";
import { UpdateEndpoint as UpdateCateringItemRestrictionsEndpoint } from "./Providers.Api/CateringItemRestrictions/UpdateEndpoint";
import { CheckInEndpoint as CheckIntoSpaceEndpoint } from "./Providers.Api/Spaces/CheckInEndpoint";
import { CheckOutEndpoint as CheckOutOfSpaceEndpoint } from "./Providers.Api/Spaces/CheckOutEndpoint";
import { CreateDeleteEndpoint as CreateDeleteCateringMenuItemsEndpoint } from "./Providers.Api/CateringMenuItems/CreateDeleteEndpoint";
import { GetManyByMenuIdEndpoint as GetManyCateringMenuItemsByMenuIdEndpoint } from "./Providers.Api/CateringMenuItems/GetManyByMenuIdEndpoint";
import { IServices, Services } from "./Services/Services";
import { Service as CateringMenuItemService } from "./Services/CateringMenuItems/Service";
import { SetSelected as SetCateringMenuItems } from "./Services/CateringMenuItems/SetSelected";
import { GetManyByMenuIdEndpoint as GetManyRolesByMenuIdEndpoint } from "./Providers.Api/Roles/GetManyByMenuIdEndpoint";
import { CreateDeleteEndpoint as CreateDeleteRolesEndpoint } from "./Providers.Api/Roles/CreateDeleteEndpoint";
import { Service as RoleService } from "./Services/Roles/Service";
import { SetSelected as SetSelectedRoles } from "./Services/Roles/SetSelected";
import { CreateCateringOrderEndpoint } from "./Providers.Api/CateringOrders/CreateCateringOrderEndpoint";
import { GetCateringOrderEndpoint } from "./Providers.Api/CateringOrders/GetCateringOrderEndpoint";
import { EditCateringOrderEndpoint } from "./Providers.Api/CateringOrders/EditCateringOrderEndpoint";
import { GetManyEndpoint as GetManyCateringOrderEndpoint } from "./Providers.Api/CateringOrders/GetManyEndPoint";
import { UpdateEndpoint as UpdateCateringOrderEndpoint } from "./Providers.Api/CateringOrders/UpdateEndPoint";
import { DeleteEndpoint as DeleteCateringOrderEndpoint } from "./Providers.Api/CateringOrders/DeleteEndPoint";
import { BookingDailySummaryRepository } from "./Providers.Api/BookingDailySummaries/BookingDailySummaryRepository"; 
import { GetManyEndpoint as GetManyBookingSummaryEndpoint } from "./Providers.Api/BookingDailySummaries/GetManyEndpoint";
import { SpaceZonesRepository } from "./Providers.Api/SpaceZones/SpaceZonesRepository";
import { GetSpaceZonesEndpoint } from "./Providers.Api/SpaceZones/GetSpaceZones";
import { GetSpaceBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/GetSpaceBookingPolicy";
import { SearchSpacesEndpoint } from "./Providers.Api/Spaces/SearchSpacesEndpoint";
import { GetRestrictionsEndpoint } from "./Providers.Api/CateringItemRestrictions/GetRestrictionsEndpoint";
import { DeleteBookingPartyEndPoint } from "./Providers.Api/BookingParties/DeleteBookingPartyEndPoint";
import { GetBookingPartyEndPoint } from "./Providers.Api/BookingParties/GetBookingPartyEndPoint";
import { GetManyEndpoint as GetManyBookingPartiesEndpoint } from "./Providers.Api/BookingParties/GetManyEndpoint";
import { PoEditorParser } from "./Providers.Text/PoEditorParser";
import { Labels } from "./Providers.Text/Labels";
import { ILabels } from "./Providers.Text/ILabels";
import { IApiMessages } from "./Providers.Text/IApiMessages";
import ApiMessages from "./Providers.Text/ApiMessages";
import { PatchCateringOrderEndpoint } from "./Providers.Api/CateringOrders/PatchCateringOrderEndpoint";
import { GetByIdEndpoint as GetTaskByIdEndpoint } from "./Providers.Api/Tasks/GetByIdEndponts";
import { ResolveTask } from "./Providers.Api/Tasks/ResolveTask";
import { PrivancyPolicyRepository } from './Providers.Api/PrivacyPolicies/PrivancyPolicyRepository';
import { GetPrivacyPolicyEndpoint } from "./Providers.Api/PrivacyPolicies/GetPrivacyPolicyEndpoint";
import { GetManyAsBlobEndpoint as GetManyCateringOrdersAsBlobEndpoint } from "./Providers.Api/CateringOrders/GetManyAsBlobEndpoint";
import { GetRoleNamesByCurrentUserEndpoint } from "./Providers.Api/Roles/GetRoleNamesByCurrentUserEndpoint";
import { GetRolesByCurrentUserEndpoint } from "./Providers.Api/Roles/GetRolesByCurrentUserEndpoint";
import { GetManyV2Endpoint } from "./Providers.Api/Visits/GetV2ManyVisits";
import { IVisitsService, VisitsService } from "./Services/VisitsService";
import { CreateV2VisitEndpoint } from "./Providers.Api/Visits/CreateV2VisitEndpoint";
import { DenyVisitsV2Endpoint } from "./Providers.Api/Visits/DenyVisitsV2Endpoint";
import { CheckinVisitsV2Endpoint } from "./Providers.Api/Visits/CheckinVisitsV2Endpoint";
import { CheckoutVisitsV2Endpoint } from "./Providers.Api/Visits/CheckoutVisitsV2Endpoint";
import { CancelVisitsV2Endpoint } from "./Providers.Api/Visits/CancelVisitsV2Endpoint";
import { ApproveVisitsV2Endpoint } from "./Providers.Api/Visits/ApproveVisitsV2Endpoint";
import { GetByIdEndpoint } from "./Providers.Api/Visits/GetByIdEndpoint";
import { GetByIdV2Endpoint } from "./Providers.Api/Visits/GetByIdV2Endpoint";
import { EditVisitEndpoint } from "./Providers.Api/Visits/EditVisitEndpoint";
import { EditV2VisitEndpoint } from "./Providers.Api/Visits/EditV2VisitEndpoint";

let instance: (ApplicationContext | null) = null;
export function appContext()
{
    if (!instance)
    {
        instance = new ApplicationContext();
        instance.initialise();
    }
    return instance;
}

export class ApplicationContext
{
    public cache: ICache = {} as ICache;

    // providers
    public apiCache = {} as IApiCache;
    public apiClient = {} as IApiClient;
    public msalProvider = {} as IMsalProvider;
    public labels = {} as ILabels;
    public apiMessages = {} as IApiMessages;
    public sessionStorageProvider = {} as ISessionStorageProvider;
    public localStorageProvider = {} as ILocalStorageProvider;

    // services
    public services: IServices = {} as IServices;
    public userPreferencesService: IUserPreferencesService = {} as IUserPreferencesService;
    public bookingService: IBookingService = {} as IBookingService;
    public visitsService: IVisitsService = {} as IVisitsService;
    public bookingPolicyService: IBookingPolicyService = {} as IBookingPolicyService;
    public spaceService: ISpaceService = {} as ISpaceService;
    public spaceUtilisationSummaryService: ISpaceUtilisationSummaryService = {} as ISpaceUtilisationSummaryService;

    // helpers
    public alert = {} as AlertModalController;
    public router = {} as RouteComponentProps;
    public state = {} as Readonly<AppState>;
    public config = {} as ConfigHelper;
    public authentication = {} as Authentication;

    public initialise(): void
    {
        this.alert = new AlertModalController();
        this.state = new AppState();
        this.config = new ConfigHelper();
        this.authentication = new Authentication();
    }

    public useDefaultCache(): void
    {
        this.cache = new Cache();
    }

    public useDefaultApiCache(): void
    {
        if (!this.cache)
        {
            throw "You must set the cache before using the API cache.";
        }
        this.apiCache = new ApiCache(this.cache);
    }

    public useDefaultApiClient(): void
    {
        this.apiClient = new ApiClient(
            this.authentication,
            new BookingPolicyRepository(
                new GetBookingPolicyEndpoint(),
                new GetBookingPoliciesEndpoint(),
                new GetV2BookingPoliciesEndpoint(),
                new CreateBookingPolicyEndpoint(),
                new UpdateBookingPolicyEndpoint(),
                new DeleteBookingPolicyEndpoint(),
                new AssignBookingPolicyToSpacesEndpoint(),
                new GetSpaceBookingPolicyEndpoint(),
            ),
            new BookingPartyRepository(
                new DeleteBookingPartyEndPoint(),
                new GetBookingPartyEndPoint(),
                new GetManyBookingPartiesEndpoint(),
            ),
            new SpaceRepository(
                new GetV1SpacesEndpoint(),
                new GetV2SpacesEndpoint(),
                new GetV1SpaceByIdEndpoint(),
                new SearchV1SpaceByIdEndpoint(),
                new CheckIntoSpaceEndpoint(),
                new CheckOutOfSpaceEndpoint(),
                new SearchSpacesEndpoint(),
            ),
            new BookingRepository(
                new GetV2BookingEndpoint(),
                new GetV1BookingEndpoint(),
                new GetBookingsEndpoint(),
                new GetV2BookingsEndpoint(),
                new GetV1BookingsByEmailEndpoint(),
                new GetMyV2BookingsEndpoint(),
                new GetMyBookingsForOthersEndpoint(),
                new GetMyV2BookingsForOthersEndpoint(),
                new ApproveBookingEndpoint(),
                new RejectBookingEndpoint(),
                new CreateV1BookingEndpoint(),
                new CreateV2BookingEndpoint(),
                new DeleteV1BookingEndpoint(),
                new DeleteV2BookingEndpoint(),
                new UpdateBookingCostCodesEndpoint(),
                new UpdateV1BookingEndpoint(),
                new UpdateV2BookingEndpoint(),
                new PatchV2BookingEndpoint(),
                new DownloadV1BookingEndpoint(),
                new DownloadV2BookingEndpoint()
            ),
            new UserPreferenceRepository(),
            new SpacesDailySummaryRepository(),
            new RoleRepository(
                new GetAllRolesEndpoint(),
                new GetManyRolesByMenuIdEndpoint(),
                new CreateDeleteRolesEndpoint(),
                new GetRoleNamesByCurrentUserEndpoint(),
                new GetRolesByCurrentUserEndpoint(),
            ),
            new TaskRepository(
                new CreateTaskEndpoint(),
                new ExportTasksEndpoint(),
                new GetTaskEndpoint(),
                new GetTasksByBookingIdEndpoint(),
                new GetTasksEndpoint(),
                new SetStatusToInProgressEndpoint(),
                new UpdateTaskEndpoint(),
                new SetStatusToCancelled(),
                new GetTaskByIdEndpoint(),
                new ResolveTask(),
                ),
            new CostCodeRepository(
                new GetCostCodesEndpoint(),
                new GetV2CostCodesEndpoint(),
                new GetCostCodeByIdEndpoint(),
            ),
            new ParameterRepository(
                new GetParameterByNameEndpoint(),
            ),
            new CateringMenuRepository(
                new GetCateringMenuByIdEndpoint(),
                new GetManyCateringMenusEndpoint(),
                new CreateCateringMenuEndpoint(),
                new UpdateCateringMenuEndpoint(),
                new DeleteCateringMenuEndpoint(),
                new EnableCateringMenuEndpoint(),
                new DisableCateringMenuEndpoint(),
            ),
            new CateringMenuItemRepository(
                new GetManyCateringMenuItemsEndpoint(),
                new GetManyCateringMenuItemsByMenuIdEndpoint(),
                new CreateDeleteCateringMenuItemsEndpoint(),
            ),
            new CateringItemRepository(
                new GetManySpaceCateringMenuItemsEndpoint(),
                new CreateSpaceCateringMenuItemEndpoint(),
                new UpdateSpaceCateringMenuItemEndpoint(),
                new DeleteSpaceCateringMenuItemEndpoint(),
                new GetManyCateringMenuAsBlobEndpoint(),
                new GetSpaceCateringMenuItemByIdEndpoint(),
            ),
            new CateringOrderPolicyRepository(
                new GetManyCateringOrderPoliciesEndpoint(),
                new DeleteCateringOrderPolicyEndpoint(),
                new CreateCateringOrderPolicyEndpoint(),
                new GetCateringOrderPolicyByIdEndpoint(),
                new UpdateCateringOrderPolicy()
            ),
            new CateringOrderRepository(
                new GetNewCateringOrdersEndpoint(),
                new GetCateringOrdersEditEndpoint(),
                new GetCateringOrdersEndpoint(),
                new UpdateCateringOrdersEndpoint(),
                new CreateCateringOrderEndpoint(),
                new GetCateringOrderEndpoint(),
                new EditCateringOrderEndpoint(),
                new PatchCateringOrderEndpoint(),
                new GetManyCateringOrderEndpoint(),
                new UpdateCateringOrderEndpoint(),
                new DeleteCateringOrderEndpoint(),
                new GetManyCateringOrdersAsBlobEndpoint()
            ),
            new NotificationRepository(
                new GetActiveNotificationEndpoint()
            ),
            new ExternalIdentityProviderRepository(
                new GetExternalIdentityProvidersEndpoint(),
                new GetIdentityProvidersEndpoint()
            ),
            new UsersRepository(
                new GetUsersEndpoint(),
                new GetDeletedUsersEndpoint()
            ),
            new VisitsRepository(
                new GetByIdEndpoint(),
                new GetByIdV2Endpoint(),
                new CreateVisitEndpoint(),
                new CreateV2VisitEndpoint(),
                new VisitActionEndpoint(),
                new GetManyEndpoint(),
                new GetManyV2Endpoint(),
                new DenyVisitsV2Endpoint(),
                new CancelVisitsV2Endpoint(),
                new CheckinVisitsV2Endpoint(),
                new CheckoutVisitsV2Endpoint(),
                new ApproveVisitsV2Endpoint(),
                new EditVisitEndpoint(),
                new EditV2VisitEndpoint()
            ),
            new SpaceUtilisationSummaryRepository(
                new GetManySpaceUtilisationSummariesEndpoint(),
                new GetManySpaceUtilisationSummariesByPeriodEndpoint(),
            ),
            new CateringSupplierRepository(
                new CreateCateringSupplierEndpoint(),
                new GetCateringSupplierByIdEndpoint(),
                new GetCateringSupplierByNodeIdEndpoint(),
                new GetManyCateringSuppliersAsBlobEndpoint(),
                new UpdateCateringSupplierEndpoint(),
                new DeleteCateringSupplierEndpoint()
            ),
            new FilesRepository(
                new GetFilesEndpoint(),
                new UploadFileEndpoint(),
            ),
            new EnvironmentalZoneDataRepository(
                new GetByZoneIdsEndpoint(),
            ),
            new CateringRestrictionsRepository(
                new GetCateringRestrictionsEndpoint(),
                new DeleteCateringRestrictionEndpoint(),
                new CreateCateringRestrictionEndpoint(),
                new EditCateringRestrictionEndpoint(),
                ),
            new CateringItemRestrictionRepository(
                new CreateCateringItemRestrictionsEndpoint(),
                new UpdateCateringItemRestrictionsEndpoint(),
                new GetRestrictionsEndpoint(),
            ),
            new SpaceZonesRepository(
                new GetSpaceZonesEndpoint(),
            ),
            new BookingDailySummaryRepository(
                new GetManyBookingSummaryEndpoint(),
            ),
            new PrivancyPolicyRepository(
                new GetPrivacyPolicyEndpoint()
            ),
        );

        this.apiClient.initialise();
    }

    public useDefaultMsalProvider(): void
    {
        this.msalProvider = new MsalProvider();
    }

    public async useDefaultLabels(): Promise<void>
    {
        this.labels = await Labels.create();
    }

    public async useDefaultApiMessages(): Promise<void>
    {
        this.apiMessages = await ApiMessages.create();
    }

    public useDefaultLocalStorageProvider(): void
    {
        this.localStorageProvider = new LocalStorageProvider();
    }

    public useDefaultSessionStorageProvider(): void
    {
        this.sessionStorageProvider = new SessionStorageProvider();
    }

    public useDefaultServices(): void
    {
        this.services = new Services(
            new BookingPolicyService(),
            new BookingService(),
            new VisitsService(),
            new SpaceService(),
            new SpaceUtilisationSummaryService(),
            new UserPreferencesService(),
            new CateringMenuItemService(
                new SetCateringMenuItems(),
            ),
            new RoleService(
                new SetSelectedRoles(),
            ),
        );

        // todo: for backwards compatibility
        this.bookingPolicyService = this.services.bookingPolicies;
        this.bookingService = this.services.bookings;
        this.visitsService = this.services.visits;
        this.spaceService = this.services.spaces;
        this.spaceUtilisationSummaryService = this.services.spaceUtilisationSummaries;
        this.userPreferencesService = this.services.userPreferences;
    }
}

export interface IAppState
{
    pageTitle: string;
    lightModeTheme: boolean;
    buildingId: number;
    buildingName: string;
}

export class AppState implements IAppState
{
    public pageTitle = "";
    public lightModeTheme = true;

    private get session()
    {
        return appContext().sessionStorageProvider;
    }

    public get buildingId(): number
    {
        return this.session.getBuildingId();
    }

    public set buildingId(value: number)
    {
        this.session.setBuildingId(value);
    }

    public get buildingName(): string
    {
        return this.session.getBuildingName();
    }

    public set buildingName(value: string)
    {
        this.session.setBuildingName(value);
    }
    
    public changed = new Event<IPartialAppState>();

    public async set(state: IPartialAppState, sender?: Component): Promise<void>
    {
        for (const property in state)
        {
            (<any>this)[property] = (<any>state)[property];
        }
        await this.changed.notify(state, sender);
    }

    public subscribe(component: Component, handler: (state: IPartialAppState, sender?: Component) => Promise<void>, callHandlerImmediately: boolean = true): string
    {
        let ref = "";
        try
        {
            ref = this.changed.add((state, sender) => handler(state, sender as Component));
            if (callHandlerImmediately)
            {
                handler(this, component);
            }

            let originalComponentWillUnmount = component.componentWillUnmount;
            component.componentWillUnmount = () =>
            {
                this.unsubscribe(ref);
                if (originalComponentWillUnmount != null)
                {
                    originalComponentWillUnmount.bind(component)();
                }
            };
        }
        catch
        {
            this.unsubscribe(ref);
        }
        return ref;
    }

    public unsubscribe(ref: string): void
    {
        this.changed.remove(ref);
    }

    public autoMap<TComponentState>(component: Component<unknown, TComponentState>, map: (appState: IPartialAppState) => Partial<TComponentState>): string
    {
        // todo: would be better to insert this handler first so that the component's state is updated before other handlers are invoked
        return this.subscribe(component, async (appState, sender) =>
        {
            const componentState = map(appState);
            const componentStateWithoutUndefined: Partial<TComponentState> = {};

            for (const componentProperty in componentState)
            {
                if (componentState[componentProperty] != undefined)
                {
                    componentStateWithoutUndefined[componentProperty] = componentState[componentProperty];
                }
            }
            if (Object.keys(componentStateWithoutUndefined).length > 0)
            {
                component.setState(componentStateWithoutUndefined as Pick<TComponentState, keyof TComponentState>);
            }
        });
    }
}

export type IPartialAppState = Partial<IAppState>;
