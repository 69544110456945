import { SxProps, TextField, Theme } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";
import { IbssComponent } from "../IbssComponent";
import IbssDateTimePicker from "./Datepicker/IbssDateTimePicker";

export class IbssLuxonDateTimePicker extends IbssComponent<IProps, IState>
{
    private get defaultSx(): SxProps<Theme>
    {
        return (this.props.label ? {} : { '& legend': { display: 'none' }, '& fieldset': { top: 0 } });
    }

    private valueChanged(value: (DateTime | null), keyboardInputValue?: string)
    {
        this.props.onChange(value ?? DateHelper.null(), keyboardInputValue);
    }

    public render(): JSX.Element
    {
        // note: the order of props is important because it allows this component to set default values but also allows the consuming page to override them
        return (
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().getLocale()}>
                <DateTimePicker
                    renderInput={props =>
                        <TextField
                            {...props}
                            sx={this.defaultSx}
                            fullWidth
                            error={false}
                            {...this.props.input}
                        />}
                    {...this.props}
                    onChange={(value, keyboardInputValue) => this.valueChanged(value, keyboardInputValue)}
                />
            </LocalizationProvider>
        );
    }
}

export interface IProps extends Omit<DateTimePickerProps<DateTime, DateTime>, "renderInput" | "onChange">
{
    renderInput?: (props: TextFieldProps) => React.ReactElement;
    onChange: (value: DateTime, keyboardInputValue?: string) => void;
    input?: TextFieldProps;
}

export interface IState
{
}
