import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Paper, Typography, TextField, MenuItem, InputAdornment, Divider, IconButton  } from "@mui/material";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import { DataGrid, GridColDef, GridPaginationModel, GridCallbackDetails } from '@mui/x-data-grid';
import { appContext } from "../../../AppContext";
import { DateTime } from "luxon";
import { CostCodeSource, CostCodeType, ICostCode } from "../../../Providers.Api/CostCodes/CostCodeRepository";
import { ComponentHelper } from "../../../Common/ComponentHelper";
import CostCodesModal from "./CostCodesModal";
import ClearIcon from '@mui/icons-material/Clear';
import IbssDataGrid from "../../../Components/uicomponents/IbssDataGrid";

class CostCodes extends React.Component<RouteComponentProps<IQueryParams>, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private costCodes: ICostCode[] = [];
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);

    private columns: GridColDef[] =
    [
        {
            field: 'typeName',
            headerName: this.labels.HubLabelType,
            width: 215,
            flex: 1
        },
        {
            field: 'code',
            headerName: this.labels.HubLabelCode,
            width: 215,
            flex: 1
        },
        {
            field: 'name',
            headerName: this.labels.HubLabelName,
            width: 215,
            flex: 1
        },
        {
            field: 'lastModified',
            headerName: this.labels.HubLabelLastModified,
            width: 215,
            flex: 1
        },
    ]

    constructor(props: RouteComponentProps<IQueryParams>)
    {
        super(props);
        this.state =
        {
            costCodes: [],
            isLoading: false,
            buildingId: this.appState.buildingId,
            openFilterModal: false,
            costCodeType: CostCodeType.Any,
            costCodeSource: CostCodeSource.Any,
            searchTerm: '',
            skipToken: '',
            page: 0,
            pageSize: 25,
        }
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        await this.loadCostCodes();
    }

    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps<IQueryParams>>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId !== this.state.buildingId)
        {
            const { history, match } = this.props;
            if(match.params !== null)
            {
                history.push(match.path.replace(":buildingid", this.state.buildingId.toString()));
            }
        }
    }

    private async loadCostCodes(): Promise<void>
    {
        this.setState({ isLoading: true });
        const response = await this.apiClient.costCodes.getV2CostCodes(1000, this.state.skipToken);
        this.costCodes = response.value;

        this.setState((prevState) => {
            return {
                ...prevState,
                isLoading: false,
                costCodes: [...prevState.costCodes, ...this.mapCostCodes()],
                skipToken: response.skipToken,
            }
        })
    }

    private async filterCostCodes(costCodeSource: CostCodeSource, costCodeType: CostCodeType, searchTerm: string, skipToken?: string): Promise<void>
    {
        this.setState({ isLoading: true });
        const response = await this.apiClient.costCodes.getV2CostCodes(1000, skipToken, searchTerm, costCodeSource, costCodeType);
        this.costCodes = response.value;

        this.setState((prevState) => {
            return {
                ...prevState,
                page: 0, // reset page to 0
                isLoading: false,
                costCodes: this.mapCostCodes(), 
                skipToken: response.skipToken,
            }
        });
    }

    private async searchTermChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): Promise<void>
    {
        await this.setStateAsync({ searchTerm: e.target.value.trim() });
    }

    private async clearSearch(): Promise<void>
    {
        await this.setStateAsync({ searchTerm: ''});
        await this.filterCostCodes(this.state.costCodeSource, this.state.costCodeType, this.state.searchTerm);
    }

    private mapCostCodes(): ICostCodeView[]
    {
        const mappedCostCodes = this
            .costCodes
            .map(costCode => (
            {
                id: parseInt(costCode.Cost_Code_Id),
                type: costCode.Cost_Code_Type,
                typeName: this.getCostCodeType(costCode.Cost_Code_Type),
                code: costCode.Cost_Code,
                name: costCode.Cost_Code_Description,
                lastModified: DateTime.fromISO(costCode.EventTime).toLocaleString(DateTime.DATETIME_SHORT),
                source: costCode.Cost_Code_Source,
            } as ICostCodeView));

        return mappedCostCodes;
    }

    private getCostCodeType(costCode: number | null): string
    {
            if(costCode != null && costCode < 100)
            {
                return CostCodeType.StandardCostCode;
            }
            if(costCode != null && costCode === 100) 
            {
                return CostCodeType.ClientCode;
            }
            if(costCode != null && costCode === 101)
            {
                return CostCodeType.DepartmentCode;
            }
            return '';
    }

    private onLastPage(page: number): boolean
    {
        // detect if user is on the last page of the data in client side.
        return page===this.state.costCodes.length/this.state.pageSize - 1;
    }

    private changePage(page: number): void
    {
        this.setState({page: page});
        if(this.onLastPage(page))
        {
            this.loadCostCodes();
        }
    }

    private changePageSize(pageSize: number): void
    {
        this.setState({pageSize: pageSize});
    }

    public render(): JSX.Element
    {
        return (
            <>
                {/* Filter Modal */}
                <CostCodesModal
                    costCodeSource = {this.state.costCodeSource}
                    costCodeType = {this.state.costCodeType}
                    changeCostCodeSource={(source)=> this.setState({costCodeSource: source})}
                    changeCostCodeType={(type)=> this.setState({costCodeType: type})}
                    openFilterModal={this.state.openFilterModal}
                    handleFilterModal={(status: boolean)=> this.setState({openFilterModal: status})}
                    handleFilterCostCodes={(source: CostCodeSource, type: CostCodeType)=> this.filterCostCodes(source, type, this.state.searchTerm)} // clicking on filtering also filters by searchTerm because the filtering is progressive.
                />
                <Paper sx={{ margin: '22px 25px 20px 25px' }} elevation={0}>
                    <div className="m-3">
                        <Typography variant="h5" gutterBottom>{this.labels.HubLabelCostCodes}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: '20px 0 20px 0' }}>
                            <TextField
                                id="cost-codes-search"
                                InputProps={{
                                    endAdornment: 
                                        <InputAdornment position={'end'}>
                                            <IconButton aria-label="clear" size="small" disabled={this.state.searchTerm.length===0} onClick={()=> this.clearSearch()}>
                                                <ClearIcon fontSize={'small'}/>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                size="small"
                                label={this.labels.HubLabelSearch}
                                value={this.state.searchTerm}
                                onChange={e => this.searchTermChanged(e)}
                            />
                            <IbssButton
                                disabled={this.state.searchTerm.length < 5}
                                sx={{ml:2, mr:2}}
                                variant="contained"
                                onClick={() => this.filterCostCodes(this.state.costCodeSource, this.state.costCodeType, this.state.searchTerm)} // clicking on search button also filters by costCodeType and costCodeSource because the filtering is progressive.
                            >
                                {this.labels.HubLabelSearch}
                            </IbssButton>
                            <Divider orientation="vertical" flexItem/>
                            <IbssButton
                                sx={{ml:2, mr:2}}
                                variant="contained"
                                onClick={() => this.setState({ openFilterModal: true })}
                            >
                                {this.labels.HubLabelFilter}
                            </IbssButton>
                        </Box>
                        <IbssDataGrid
                            aria-label="cost codes table"
                            rows={this.state.costCodes}
                            columns={this.columns}
                            loading={this.state.isLoading}
                            hideFooterSelectedRowCount={true}
                            paginationModel=
                            {{
                                page: this.state.page,
                                pageSize: this.state.pageSize,
                            }}
                            onPaginationModelChange={(model: GridPaginationModel, details: GridCallbackDetails)=> 
                                {
                                    this.changePage(model.page);
                                    this.changePageSize(model.pageSize);
                                }
                            }

                            pageSizeOptions={[25, 50, 100]}
                            sx={{
                                '&>.MuiDataGrid-main':
                                {
                                    '&>.MuiDataGrid-columnHeaders': {
                                        borderBottom: 'none'
                                    },

                                    '& div div div div >.MuiDataGrid-cell': {
                                        borderBottom: 'none'
                                    }
                                }
                            }}
                        />
                    </div>
                </Paper>
            </>
        )
    }
}

export default withRouter(CostCodes);

export interface IState
{
    costCodes: ICostCodeView[];
    isLoading: boolean;
    buildingId: number;
    openFilterModal: boolean;
    costCodeType: CostCodeType;
    costCodeSource: CostCodeSource;
    searchTerm: string;
    skipToken: string;
    page: number;
    pageSize: number;
}

export interface ICostCodeView
{
    id: number;
    type: number | null;
    typeName: string;
    code: string;
    name: string | null;
    lastModified: string;
    source: string;
}

export interface IQueryParams
{
    buildingid: string;
}