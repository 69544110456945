import { IbssComponent } from "../../IbssComponent";
import IbssAutocomplete from "../../uicomponents/IbssAutocomplete";
import { appContext } from "../../../AppContext";
import IbssTextField from "../../uicomponents/IbssTextField";

export default class UserPicker extends IbssComponent<IProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private searchUsersTimer: NodeJS.Timeout | null = null;

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            searchText: props.searchText ?? "",
            users: [],
            selectedUser: null,
            error: "",
        };
    }

    private userChanged(e: React.SyntheticEvent<Element, Event>, user: IUser | null): void
    {
        this.setState({ selectedUser: user, searchText: user?.label ?? "" });
        this.props.onChange?.(user);
    }

    private async searchTextChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        clearTimeout(this.searchUsersTimer ?? undefined);
        const searchText = e.target.value;
        await this.setStateAsync({ searchText: searchText });

        if (searchText.length < 3)
        {
            this.setState({ users: [] });
            this.props.onSearchTextChange?.(searchText);
            return;
        }

        this.searchUsersTimer = setTimeout(() => this.searchUsers(), 500);
        this.props.onSearchTextChange?.(searchText);
    }

    private async searchUsers(): Promise<void>
    {
        const userData = await this.apiClient.users.getUsers(this.state.searchText);
        const users = userData.map(i =>
        ({
            label: i.displayName || i.email,
            email: i.email,
            userName: i.UserName,
            firstName: i.firstName,
            lastName: i.lastName,
            displayName: i.displayName,
            company: i.companyName,
        }));

        this.setState({ users: users });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <IbssAutocomplete
                    fullWidth={!this.props.alternateStyle}
                    freeSolo
                    options={this.state.users}
                    value={this.state.searchText}
                    className={"mt-8" + (this.props.alternateStyle ? " form-input-box-autocomplete auto-text" : "")}
                    onChange={(e, user: IUser | null) => this.userChanged(e, user)}
                    renderInput={params =>
                        <IbssTextField
                            {...params}
                            label={this.props.label ?? this.labels.HubLabelNameOrEmailLabel}
                            placeholder={this.props.placeholder ?? this.labels.HubLabelNameOrEmailLabel}
                            variant="outlined"
                            value={this.state.searchText}
                            className={this.props.alternateStyle ? "input-box model-textbox select-box-border" : ""}
                            onChange={e => this.searchTextChanged(e)}
                        />}
                />
                <div className="text-danger">{this.state.error}</div>
            </>
        );
    }
}

export interface IProps
{
    searchText?: string;
    label?: string;
    placeholder?: string;
    alternateStyle?: boolean;
    onChange?: (user: IUser | null) => Promise<void>;
    onSearchTextChange?: (text: string) => Promise<void>;
}

export interface IState
{
    searchText: string;
    users: IUser[];
    selectedUser: IUser | null;
    error: string;
}

export interface IUser
{
    label: string;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    displayName: string;
    company: string;
}
