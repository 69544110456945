import { Component, SyntheticEvent } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import IbssDialog from '../../../../Components/uicomponents/IbssDialog';
import IbssButton from '../../../../Components/uicomponents/IbssButton';
import { appContext } from '../../../../AppContext';
import IbssDateTimePicker from '../../../../Components/uicomponents/Datepicker/IbssDateTimePicker';
import IbssTextField from '../../../../Components/uicomponents/IbssTextField';
import IbssAutocomplete from '../../../../Components/uicomponents/IbssAutocomplete';
import IbssFormControl from '../../../../Components/uicomponents/IbssFormControl';
import LoadingOverlay from '../../../../Components/LoadingOverlay';
import { DateTime } from 'luxon';
import { IUser } from '../../../../Providers.Api/Users/UsersRepository';
import { Constants } from '../../../../Common/Constants';
import { IbssLuxonDateTimePicker } from '../../../../Components/uicomponents/IbssLuxonDateTimePicker';
import { IbssComponent } from '../../../../Components/IbssComponent';
import IbssInputDropDown from '../../../../Components/uicomponents/IbssInputDropDown';
import Helper from '../../../../Common/Helper';
import UserPicker, * as UserPickerModule from '../../../../Components/Inputs/UserPicker/UserPicker';

class NewVisitorModal extends IbssComponent<IProps, IState>
{
    private get visitsService() { return appContext().visitsService; }
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private get local() {return appContext().localStorageProvider;}
    
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoading: false,
            fullname: '',
            company: '',
            email: '',
            invalidVisitorEmail: false,
            hostName: '',
            hostEmail: '',
            pacs: '',
            arrival: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.appState.buildingId),
            departure: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.appState.buildingId),
            buildingId: this.appState.buildingId,
            creatingVisitor: false,
            arrivalError: "",
            departureError: "",
            buildingOption: [],
            isFlex: false
        };
    }

    public async componentDidMount(): Promise<void>
    {
        const url = window.location.href;
        const path = new URL(url).pathname;
        if (path.includes('flex'))
        {
            this.setState({ isFlex: true });
        }

        const buildings = Helper.getAllBuildingsData().sort((a, b) => a.Name.localeCompare(b.Name));
        const options = buildings.map(i => ({ value: i.Node_Id, label: i.Name }) as IBuildingOption);
        this.setState({ buildingOption: options});
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState): void
    {
        if(prevState.buildingId !== this.state.buildingId)
        {
            this.setState({
                arrival: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.state.buildingId),
                departure: DateTime.now().plus({ minutes: 5 }).offsetTimeByNode(this.state.buildingId),
            })
        }
    }
    
    private async titleChanged(text: string): Promise<void>
    {
    }

    private async selectedUserChanged(user: UserPickerModule.IUser | null): Promise<void>
    {
        if (user)
        {
            this.setState({ hostEmail: user.email, hostName: user.displayName })
        }
        else
        {
            this.setState({ hostEmail: '', hostName: '' })
        }
    }

    private async arrivalChanged(value: DateTime): Promise<void>
    {
        await this.setStateAsync({ arrival: value });
        await this.validateArrival();
    }

    private async departureChanged(value: DateTime): Promise<void>
    {
        await this.setStateAsync({ departure: value });
        await this.validateDeparture();
    }

    private async validateArrival(): Promise<boolean>
    {
        const fromGreaterThanTo = (this.state.arrival.isValid && this.state.departure.isValid && this.state.arrival >= this.state.departure);

        if (this.state.arrival.isNull())
        {
            await this.setStateAsync({ arrivalError: this.labels.funcCateringMenuEditRequired_S });
        }
        else if (!this.state.arrival.isValid)
        {
            await this.setStateAsync({ arrivalError: this.labels.funcCateringMenuEditMustBeAValidDateAndTime_S });
        }
        else if (fromGreaterThanTo)
        {
            await this.setStateAsync({ arrivalError: this.labels.funcAddVisitorEditMustBeBeforeDeparture_S });
        }
        else
        {
            await this.setStateAsync({ arrivalError: "" });
        }

        if (this.state.departure.isValid)
        {
            if (fromGreaterThanTo)
            {
                await this.setStateAsync({ departureError: this.labels.funcAddVisitorMustBeAfterArrival_S });
            }
            else
            {
                await this.setStateAsync({ departureError: "" });
            }
        }

        return (this.state.arrivalError == "");
    }

    private async validateDeparture(): Promise<boolean>
    {
        const fromGreaterThanTo = (this.state.arrival.isValid && this.state.departure.isValid && this.state.arrival >= this.state.departure);

        if (this.state.departure.isNull())
        {
            await this.setStateAsync({ departureError: this.labels.funcCateringMenuEditRequired_S });
        }
        else if (!this.state.departure.isValid)
        {
            await this.setStateAsync({ departureError: this.labels.funcCateringMenuEditMustBeAValidDateAndTime_S });
        }
        else if (fromGreaterThanTo)
        {
            await this.setStateAsync({ departureError: this.labels.funcAddVisitorMustBeAfterArrival_S });
        }
        else
        {
            await this.setStateAsync({ departureError: "" });
        }

        if (this.state.arrival.isValid)
        {
            if (fromGreaterThanTo)
            {
                await this.setStateAsync({ arrivalError: this.labels.funcAddVisitorEditMustBeBeforeDeparture_S });
            }
            else
            {
                await this.setStateAsync({ arrivalError: "" });
            }
        }

        return (this.state.departureError == "");
    }

    private async createVisitorRecord(): Promise<void>
    {
        this.setState({ creatingVisitor: true });
        const visitorFullName = this.state.fullname.split(' ');
        const payload = {
            Space_Id: "",
            Space_Name: "",
            Visitor_First_Name: visitorFullName[0],
            Visitor_Last_Name: visitorFullName.length > 1 ? visitorFullName[visitorFullName.length - 1] : '',
            Visitor_Email: this.state.email,
            Visitor_Company: this.state.company,
            Visit_Host_Name: this.state.hostName,
            Visit_Host_Email: this.state.hostEmail,
            Visit_Start_Date: this.state.arrival.setZoneByNode(this.state.buildingId).toUTC().toISO(),
            Visit_End_Date: this.state.departure.setZoneByNode(this.state.buildingId).toUTC().toISO(),
            Visit_Approval_Comments: "",
            DisableExtUpdate: false,
            Visit_Pacs_Id: this.state.pacs,
            Booking_Id:"",
            Booking_Start:"",
            Booking_End:"",
            Visitor_Dietary_Pref: 0,
            Visit_Save_Info: 0,
            Disable_Ext_Update: false
        }

        try
        {
            const visitResponse = await this.visitsService.create(this.state.buildingId, payload);
            if (this.local.hasRight('API.Visits.Approve') && visitResponse.Visit_IsApproved == 0)
            {
                await this.visitsService.approve(this.state.buildingId, [visitResponse.Visit_Id], '');
            }
            const visitArrivalWindow = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(x => x.Node_Id == this.state.buildingId)?.Vis_Arvl_Wndw_Mins
            if (this.local.hasRight('API.Visits.CheckIn') && !this.state.isFlex && this.state.arrival.minus({ minutes: visitArrivalWindow }) <= DateTime.now().offsetTimeByNode(this.state.buildingId))
            {
                await this.visitsService.checkin(this.state.buildingId, [visitResponse.Visit_Id]);
            }
        } catch (error)
        {
            this.setState({ creatingVisitor: false })
        }
        this.setState({ creatingVisitor: false, fullname: '', company: '', email: '', hostName: '', hostEmail: '', pacs: '' })
        this.props.closeClicked()
    }

    private visitorEmailChanged(value: string): void
    {
        this.setState({ invalidVisitorEmail: !Constants.emailExpression?.test(value), email: value });
    }

    private buildingChanged(buildingId: string): void
    {
        this.setState({ buildingId: parseInt(buildingId) });
    }

    public render(): JSX.Element
    {
        const allFieldsValid = this.state.fullname.length > 0 && this.state.company.length > 0 && this.state.email.length > 0 && this.state.hostName.length > 0 && !this.state.invalidVisitorEmail

        return (
                <IbssDialog
                            open={this.props.showAddVisitorModal}
                            onClose={() => this.props.closeClicked()}
                            content=
                            {
                                <>
                                    {this.state.creatingVisitor && <LoadingOverlay />}
                                    <div style={{ fontSize: '14px' }} className='mb-3'>{this.labels.HubLabelAddAdhocVisitorText}</div>
                                    <IbssFormControl fullWidth className='mb-3'>
                                        <IbssTextField
                                            value={this.state.fullname}
                                            fullWidth
                                            variant='outlined'
                                            onChange={e => this.setState({ fullname: e.target.value })}
                                            label={this.labels.HubLabelFullName}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl fullWidth className='mb-3'>
                                        <IbssTextField
                                            value={this.state.company}
                                            fullWidth
                                            variant='outlined'
                                            onChange={e => this.setState({ company: e.target.value })}
                                            label={this.labels.HubLabelCompany}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl fullWidth className='mb-3'>
                                        <IbssTextField
                                            value={this.state.email}
                                            error={this.state.invalidVisitorEmail}
                                            helperText={this.state.invalidVisitorEmail ? this.labels.HubLabelInvalidEmailAddress : ''}
                                            fullWidth
                                            variant='outlined'
                                            onChange={e => this.visitorEmailChanged(e.target.value)}
                                            label={this.labels.HubLabelEmail}
                                        />
                                    </IbssFormControl>
                                    { this.state.isFlex &&
                                        <IbssFormControl fullWidth className='mb-3'>
                                            <IbssInputDropDown
                                            inputLabel={this.labels.HubLabelBuilding}
                                            options={this.state.buildingOption}
                                            value={this.state.buildingId}
                                            id={"buildingSelection"}
                                            onChange={(e: SelectChangeEvent<string>) => this.buildingChanged(e.target.value)}
                                            fullWidth
                                            />
                                        </IbssFormControl>
                                    }
                                    <IbssFormControl fullWidth className='mb-4'>
                                        <UserPicker
                                            searchText={this.state.hostName}
                                            placeholder={this.labels.HubLabelHostName}
                                            onChange={user => this.selectedUserChanged(user)}
                                            onSearchTextChange={text => this.titleChanged(text)}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl fullWidth className='mb-3'>
                                        <IbssTextField
                                            disabled
                                            value={this.state.hostEmail}
                                            fullWidth
                                            variant='outlined'
                                            onChange={e => this.setState({ hostEmail: e.target.value })}
                                            label={this.labels.HubLabelHostNamePlaceholder}
                                        />
                                    </IbssFormControl>
                                    <IbssFormControl fullWidth className='mb-3'>
                                        <IbssTextField
                                            value={this.state.pacs}
                                            fullWidth
                                            variant='outlined'
                                            onChange={e => this.setState({ pacs: e.target.value })}
                                            label={this.labels.HubLabelPacs}
                                        />
                                    </IbssFormControl>
                                    <div style={{ display: 'flex' }}>
                                        <div className='mb-3' style={{ width: '49%', flexWrap: 'wrap', display: 'flex', marginRight: '2%' }}>
                                            <IbssLuxonDateTimePicker
                                                        label={this.labels.HubLabelArrival}
                                                        value={this.state.arrival}
                                                        onChange={e => this.arrivalChanged(e)}
                                                        input=
                                                        {{
                                                            error: this.state.arrivalError != "",
                                                            helperText: this.state.arrivalError
                                                        }}
                                            />
                                        </div>
                                        <div style={{ width: '49%' }}>
                                            <IbssLuxonDateTimePicker
                                                        label={this.labels.HubLabelDeparture}
                                                        value={this.state.departure}
                                                        onChange={e => this.departureChanged(e)}
                                                        input=
                                                        {{
                                                            error: this.state.departureError != "",
                                                            helperText: this.state.departureError
                                                        }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            header={this.labels.HubLabelAddVisitor}
                            footer=
                            {
                                <>
                                    <IbssButton disabled={!allFieldsValid || this.state.creatingVisitor} onClick={() => this.createVisitorRecord()} variant="contained" sx={{ width: '100px' }}>
                                        {this.labels.HubLabelOk}
                                    </IbssButton>
                                </>
                            }
                            fullWidth
                        /> 
        )
    }
}

export default NewVisitorModal;

export interface IProps
{
    closeClicked: () => void,
    showAddVisitorModal: boolean, 
}

export interface IState
{
    isLoading: boolean,
    fullname: string;
    company: string;
    email: string;
    invalidVisitorEmail: boolean;
    hostName: string;
    hostEmail: string;
    pacs: string;
    arrival: DateTime;
    departure: DateTime;
    buildingId: number;
    creatingVisitor: boolean;
    arrivalError: string;
    departureError: string;
    buildingOption: IBuildingOption[];
    isFlex: boolean
}

interface IBuildingOption
{
    label: string,
    value: number,
}
