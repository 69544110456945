import * as React from 'react';
import { DataGrid, DataGridProps, deDE, zhCN, esES, jaJP, enUS, GridLocaleText, GridPaginationModel, GridFilterModel, GridSortModel, GridColDef, GridValidRowModel, GridActionsColDef, GridPagination } from "@mui/x-data-grid";
import { ComponentHelper } from '../../Common/ComponentHelper';
import { ODataFilter, ODataFilterItem } from '../../Providers.Api/ODataFilter';

export default class IbssDataGrid extends React.Component<IProps, IState>
{
    private get isServerPaging() { return this.props.paginationMode == "server" }
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);
    private defaultPageSizeOptions = [ 25, 50, 100 ];
    private filterChangedTimeout: (NodeJS.Timeout | null) = null;

    constructor(props: IProps)
    {
        super(props);
        const pageSizeOptions = this.props.pageSizeOptions ?? this.defaultPageSizeOptions;

        this.state =
        {
            pageSizeOptions: pageSizeOptions,
            pageSize: pageSizeOptions[0],
            pageIndex: 0,
            pages: [],
            skipToken: "",
            odataFilter: ODataFilter.empty,
            rows: [],
            rowCount: pageSizeOptions[0] + 1,
        };
    }

    private get fromRow(): number
    {
        return (this.state.pageIndex * this.state.pageSize + 1);
    }

    private get toRow(): number
    {
        const numOfRows = this.state.pages[this.state.pageIndex]?.rows?.length ?? 0;
        return (this.state.pageIndex * this.state.pageSize + numOfRows);
    }

    private timeout: (NodeJS.Timeout | null) = null;
    public componentDidMount(): void
    {
        // todo:
        // - for some reason the component mounts twice
        // - the first time, it unmounts shortly after mounting
        // - this causes the  API to be hit twice
        // - the timeout allows the component to unmount before calling the API
        // - this is hacky so we need to find a better solution
        this.timeout = setTimeout(() => this.loadData(""), 100);
    }

    public componentWillUnmount(): void
    {
        clearTimeout(this.timeout ?? undefined);
    }

    private async paginationModelChanged(model: GridPaginationModel): Promise<void>
    {
        if (model.pageSize != this.state.pageSize)
        {
            return this.pageSizeChanged(model.pageSize);
        }
        else if (model.page != this.state.pageIndex && model.page < this.state.pages.length)
        {
            return this.pageChangedToCachedPage(model.page);
        }
        else if (model.page != this.state.pageIndex && this.state.skipToken != "")
        {
            return this.pageChangedToNewPage(model.page);
        }
        else
        {
            return;
        }
    }

    private async pageChangedToCachedPage(pageIndex: number): Promise<void>
    {
        const page = this.state.pages[pageIndex];
        await this.setStateAsync({ pageIndex: pageIndex, rows: page.rows });
    }

    private async pageChangedToNewPage(pageIndex: number): Promise<void>
    {
        await this.setStateAsync({ pageIndex: pageIndex });
        return this.loadData(this.state.skipToken);
    }

    private async pageSizeChanged(pageSize: number): Promise<void>
    {
        await this.setStateAsync({ pageSize: pageSize });
        return this.loadData("");
    }

    private filterModelChanged(model: GridFilterModel): Promise<void>
    {
        return Promise.resolve();
    //    clearTimeout(this.filterChangedTimeout ?? undefined);
    //    const odataFilter = new ODataFilter();
        
    //    for (const gridFilterItem of model.items)
    //    {
    //        const column = this.props.columns.find(column => column.field == gridFilterItem.field);
    //        if (column == null || column.serverField == null)
    //        {
    //            continue;
    //        }
    //        const serverField = column.serverField;
    //        const value = gridFilterItem.value;

    //        // todo: handle unsupported operators
    //        switch (gridFilterItem.operator)
    //        {
    //            case "equals":
    //                odataFilter.filterItems.push(new ODataFilterItem(serverField, "eq", value));
    //                break;
    //            case "starts with":
    //                odataFilter.filterItems.push(new ODataFilterItem(serverField, "ge", value));
    //                odataFilter.filterItems.push(new ODataFilterItem(serverField, "le", value + "`"));
    //                break;
    //            case "is empty":
    //                odataFilter.filterItems.push(new ODataFilterItem(serverField, "eq", ""));
    //                break;
    //            case "is not empty":
    //                odataFilter.filterItems.push(new ODataFilterItem(serverField, "ne", ""));
    //                break;
    //            case "is any of":
    //                break;
    //        }
    //    }

    //    const currentFilter = this.state.odataFilter.toODataString();
    //    const newFilter = odataFilter.toODataString();

    //    if (currentFilter == newFilter)
    //    {
    //        return;
    //    }

    //    await this.setStateAsync({ odataFilter: odataFilter });

    //    // - onFilterModelChange is very chatty
    //    // - therefore we cannot hit the API everytime this event fires
    //    // - ideally we would hit the API when the filter modal has closed
    //    // - but there isn't a reliable way to detect this
    //    // - best we can do is delay hitting the API for 2 seconds after no further filter changes
    //    this.filterChangedTimeout = setTimeout(() => this.loadData(""), 2000);
    }

    private sortModelChanged(model: GridSortModel): Promise<void>
    {
        return Promise.resolve();
        //return this.loadData(""); // todo:
    }

    public refresh(): Promise<void>
    {
        return this.loadData("");
    }

    public refreshPage(): Promise<void>
    {
        return this.loadData(""); // todo:
    }

    private async loadData(skipToken: string): Promise<void>
    {
        if (!this.isServerPaging || this.props.onDataQueryChange == null)
        {
            return;
        }
        
        const query: IDataQuery =
        {
            pageSize: this.state.pageSize,
            skipToken: skipToken,
            odataFilter: this.state.odataFilter,
        };
        
        const result = await this.props.onDataQueryChange(query);
        
        const pages = (skipToken == "" ? [] : this.state.pages);
        pages.push({ skipToken: skipToken, rows: result.rows });
        
        const rowCount = (result.skipToken == "" ? pages.flatMap(i => i.rows).length : 10000);
        await this.setStateAsync({ skipToken: result.skipToken, rows: result.rows, rowCount: rowCount, pages: pages, pageIndex: pages.length - 1 });
    }

    public getLocaleText(): Partial<GridLocaleText>
    {
        const languages = navigator.languages;
        let preferredLanguage = enUS.components.MuiDataGrid.defaultProps.localeText;

        for (var i = 0; i < languages.length; i++)
        {
            if (languages[i] == 'en')
            {
                preferredLanguage = enUS.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'de')
            {
                preferredLanguage = deDE.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'es')
            {
                preferredLanguage = esES.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'ja')
            {
                preferredLanguage = jaJP.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
            if (languages[i] == 'zh')
            {
                preferredLanguage = zhCN.components.MuiDataGrid.defaultProps.localeText;
                break;
            }
        }
        return preferredLanguage;
    }

    private get paginationModel(): (GridPaginationModel | undefined)
    {
        if (!this.isServerPaging)
        {
            return this.props.paginationModel;
        }

        let paginationModel = this.props.paginationModel ?? { page: 0, pageSize: 0 };
        paginationModel.page = this.state.pageIndex;
        paginationModel.pageSize = this.state.pageSize;
        return paginationModel;
    }

    public render(): JSX.Element
    {
        if (this.isServerPaging)
        {
            this.props.columns.forEach(i => { i.sortable = false });
        }

        return (
            <div className='grid-view-height' style={{ height: this.props.height ? this.props.height : "calc(100vh - 370px)", width: "100%" }}>
                <DataGrid
                    localeText={this.getLocaleText()}
                    onPaginationModelChange={i => this.paginationModelChanged(i)}
                    onFilterModelChange={i => this.filterModelChanged(i)}
                    onSortModelChange={i => this.sortModelChanged(i)}
                    components={{ Pagination: () => <CustomPagination {...this.props} fromRow={this.fromRow} toRow={this.toRow} /> }}
                    {...this.props} // props override previous attributes
                    rows={this.isServerPaging ? this.state.rows : this.props.rows}
                    rowCount={this.isServerPaging ? this.state.rowCount : this.props.rowCount}
                    disableColumnFilter={this.isServerPaging}
                    paginationModel={this.paginationModel}
                    pageSizeOptions={this.state.pageSizeOptions}
                />
            </div>
        );
    }
}
                    

export class CustomPagination extends React.Component<IPaginationProps>
{
    private get isServerPaging() { return this.props.paginationMode == "server" }

    constructor(props: IPaginationProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        if (!this.isServerPaging)
        {
            return (<GridPagination {...this.props} />);
        }
        return (<GridPagination {...this.props} labelDisplayedRows={() => (<>{this.props.fromRow} to {this.props.toRow}</>)} />);
    }
}

export interface IPaginationProps<R extends GridValidRowModel = any> extends DataGridProps<R>
{
    fromRow: number;
    toRow: number;
}

export interface IProps<R extends GridValidRowModel = any> extends DataGridProps<R>
{
    height?: string;
    onDataQueryChange?: (query: IDataQuery) => Promise<DataGridQueryResult>;
    columns: IIbssGridColDef[];
}

export type IIbssGridColDef<R extends GridValidRowModel = any, V = any, F = V> = GridColDef<R, V, F> & IIbssGridBaseColDef;

export interface IIbssGridBaseColDef
{
    serverField?: string;
}

export interface IDataQuery
{
    pageSize: number;
    skipToken: string;
    odataFilter: ODataFilter;
}

export interface IState
{
    pageSizeOptions: number[],
    pageSize: number;
    pageIndex: number;
    pages: IPage[];
    skipToken: string;
    odataFilter: ODataFilter;
    rows: any[];
    rowCount: number;
}

export interface IPage
{
    skipToken: string;
    rows: any[];
}

export class DataGridQueryResult
{
    public rows: any[];
    public skipToken: string;

    constructor(rows: any[], skipToken: string)
    {
        this.rows = rows;
        this.skipToken = skipToken;
    }
}