import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";

export class UpdateV2BookingEndpoint implements IUpdateV2BookingEndpoint
{
    public async execute(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateV2BookingResponse>
    {
        try
        {
            const response = await axios.put<IUpdateV2BookingResponse>(apis.allApiv2 + nodeId + `/Bookings/` + bookingId, booking);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateV2BookingEndpoint
{
    execute(nodeId: number, bookingId: string, booking: IUpdateV2BookingRequest): Promise<IUpdateV2BookingResponse>;
}

export interface IUpdateV2BookingRequest
{
    SpaceId: string;
    Space_Layout?: string;
    Space_Name?: string;
    StartTime: string;
    EndTime: string;
    PartyCount: number;
    BookingName: string;
    BookingDescription: string;
    Private: number;
    Organisation: string;
    Booking_Parties: IUpdateV2Booking_BookingParty[];
    Cost_Code_Allocation: IUpdateV2Booking_CostCodeAllocation[];
    DisableExtUpdate: boolean;
    OnBehalfOf?: string;
    AddOnlineMeetingLink: number;
}

export interface IUpdateV2Booking_BookingParty
{
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Resource_Id: string;
    Booking_Visitor: boolean;
    Booking_Participant_Type: number;
}

export interface IUpdateV2Booking_CostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}

export interface IUpdateV2BookingResponse
{
    Message: string;
}
