import { SxProps, Theme } from "@mui/material";
import React, { Component } from "react";
import { appContext } from "../AppContext";
import IbssChip from "./uicomponents/IbssChip";

export default class BookingStatus extends Component<IProps>
{
    private get labels() { return appContext().labels; }

    private statusMap: Record<Status, IStatusConfig> = {
        "New": {
            label: this.labels.HubLabelNew,
            sx: {
                backgroundColor: 'var(--ui-success-pastel)',
                color: 'var(--ui-text)',
            },
        },
        "Active": {
            label: this.labels.HubLabelActive,
            sx: {
                backgroundColor: 'var(--ui-success)',
                color: 'var(--ui-text)',
            },
        },
        "Approved": {
            label: this.labels.HubLabelApproved,
            sx: {
                backgroundColor: 'var(--ui-warn-pastel)',
                color: 'var(--ui-text)',
            },
        },
        'Early Check In': {
            label: this.labels.HublabelEarlyCheckInText,
            sx: {
                backgroundColor: 'var(--ui-success-pastel)',
                color: 'var(--ui-text)',
            },
        },
        'Awaiting Approval': {
            label: this.labels.HubTabAwaitingApproval,
            sx: {
                backgroundColor: 'var(--ui-warn-pastel)',
                color: 'var(--ui-text)',
            },
        },
        'In Progress': {
            label: this.labels.HubLabelInProgress,
            sx: {
                backgroundColor: 'var(--ui-mid-tone)',
                color: 'var(--ui-text)',
            },
        },
        'Checked Out': {
            label: this.labels.HubLabelCheckedOut,
            sx: {
                backgroundColor: 'var(--ui-mid-tone)',
                color: 'var(--ui-text)',
            },
        },
        'Checked In': {
            label: this.labels.HubLabelCheckedIn,
            sx: {
                backgroundColor: 'var(--ui-mid-tone)',
                color: 'var(--ui-text)',
            },
        },
        "Completed": {
            label: this.labels.HubLabelCompleted,
            sx: {
                backgroundColor: 'var(--ui-success)',
                color: 'var(--ui-text)',
            },
        },
        "Cancelled": {
            label: this.labels.HubLabelCancelled,
            sx: {
                backgroundColor: 'var(--ui-error)',
                color: 'var(--ui-light-text)',
            },
        },
        'No Show': {
            label: this.labels.HubLabelNoShow,
            sx: {
                backgroundColor: 'var(--ui-mid-tone)',
                color: 'var(--ui-text)',
            },
        },
        "Resolved": {
            label: this.labels.HubLabelResolved,
            sx: {
                backgroundColor: 'var(--ui-mid-tone)',
                color: 'var(--ui-text)',
            },
        },
        "Assigned": {
            label: this.labels.HubTabAssigned,
            sx: {
                backgroundColor: 'var(--ui-success-pastel)',
                color: 'var(--ui-text)',
            },
        },
        "Awaiting Re-allocation":{
            label: this.labels.HubLabelReAllocation,
            sx: {
                backgroundColor: 'var(--ui-warn-pastel)',
                color: 'var(--ui-text)',
            },
        },
        "Denied":{
            label: this.labels.HubLabelDeniedLabel,
            sx: {
                backgroundColor: 'var(--ui-error)',
                color: 'var(--ui-text)',
            },
        },
    };

    public render(): JSX.Element 
    {
        const bookingStatus = this.props.status;
        const statusConfig = this.statusMap[bookingStatus];
        
        if (statusConfig) 
        {
            const { label, sx } = statusConfig;
            return <IbssChip label={label} sx={sx} />;
        }
        else 
        {
            return <p>-</p>;
        }
    }
}

type Status = ("New" | "Approved" | "Early Check In" | "Awaiting Approval" | "In Progress" | "Checked Out" | "Checked In" | "Completed" | "Cancelled" | "No Show" | "Active" | "Resolved" | "Assigned" | "Awaiting Re-allocation" | "Denied");

interface IProps 
{
    status: Status;
}

interface IStatusConfig
{
    label: string;
    sx: ISxProps;
}

interface ISxProps
{
    backgroundColor: string;
    color: string;
}
