import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";

export class GetV1BookingEndpoint implements IGetV1BookingEndpoint
{
    public async execute(nodeId: number, bookingId: string): Promise<IGetV1BookingResponse>
    {
        try
        {
            const response = await axios.get<IResponse>(`${apis.allApi}${nodeId}/Bookings/${bookingId}`);
            const data = response.data;

            const booking: IGetV1BookingResponse =
            {
                _CreatedAt: data._CreatedAt,
                _CreatedBy: data._CreatedBy,
                Booking_AutoCheckIn: TypeHelper.ToBoolean(data.Booking_AutoCheckIn) ?? false,
                Booking_Cancelled_Time:  data.Booking_Cancelled_Time,
                Booking_Checkin_Time: data.Booking_Checkin_Time,
                Booking_Early_Checkin: data.Booking_Early_Checkin,
                Booking_Completed_Time: data.Booking_Completed_Time,
                Booking_Description: data.Booking_Description,
                Booking_End: data.Booking_End,
                Booking_Host_Email: data.Booking_Host_Email,
                Booking_Host_Name: data.Booking_Host_Name,
                Booking_Id: data.Booking_Id,
                Booking_IsActive: TypeHelper.ToBoolean(data.Booking_IsActive) ?? false,
                Booking_IsApproved: data.Booking_IsApproved,
                Booking_IsCancelled: TypeHelper.ToBoolean(data.Booking_IsCancelled) ?? false,
                Booking_IsCheckedIn: TypeHelper.ToBoolean(data.Booking_IsCheckedIn) ?? false,
                Booking_IsCompleted: TypeHelper.ToBoolean(data.Booking_IsCompleted) ?? false,
                Booking_IsEarly_Checkin: TypeHelper.ToBoolean(data.Booking_IsEarly_Checkin) ?? false,
                Booking_IsLate_Checkin: TypeHelper.ToBoolean(data.Booking_IsLate_Checkin) ?? false,
                Booking_IsPrivate: TypeHelper.ToBoolean(data.Booking_IsPrivate) ?? false,
                Booking_Name: data.Booking_Name,
                Booking_Owner_Email: data.Booking_Owner_Email,
                Booking_Owner_Id: data.Booking_Owner_Id,
                Booking_Owner_Name: data.Booking_Owner_Name,
                Booking_Share_Loc: data.Booking_Share_Loc,
                Booking_Start: data.Booking_Start,
                Booking_Status: data.Booking_Status,
                Meta_Ext_Booking_Id: data.Meta_Ext_Booking_Id,
                Meta_Organisation: data.Meta_Organisation,
                Node_Id: data.Node_Id,
                Online_Meeting: data.Online_Meeting,
                Space_Id: data.Space_Id,
                Space_Layout: data.Space_Layout,
                Booking_Parties: JSON.parse(data.Booking_Parties) as IGetV1BookingResponse_BookingParty[],
                Booking_Parties_Visitors: JSON.parse(data.Booking_Parties_Visitors) as IGetV1BookingResponse_BookingParty[],
                Cost_Code_Allocation: data.Cost_Code_Allocation,
            };
            return booking;
        }
        catch(response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV1BookingEndpoint
{
    execute(nodeId: number, bookingId: string): Promise<IGetV1BookingResponse>;
}

interface IResponse
{
    _CreatedAt: string;
    _CreatedBy: string;
    Booking_AutoCheckIn: number;
    Booking_Cancelled_Time: string;
    Booking_Early_Checkin:string;
    Booking_Checkin_Time: string;
    Booking_Completed_Time: string;
    Booking_Description: string;
    Booking_End: string;
    Booking_Host_Email: string;
    Booking_Host_Name: string;
    Booking_Id: string;
    Booking_IsActive: number;
    Booking_IsApproved: number;
    Booking_IsCancelled: number;
    Booking_IsCheckedIn: number;
    Booking_IsCompleted: number;
    Booking_IsEarly_Checkin: number;
    Booking_IsLate_Checkin: number;
    Booking_IsPrivate: number;
    Booking_Name: string;
    Booking_Owner_Email: string;
    Booking_Owner_Id: string;
    Booking_Owner_Name: string;
    Booking_Share_Loc: number;
    Booking_Start: string;
    Booking_Status: string;
    Meta_Ext_Booking_Id: string;
    Meta_Organisation: string;
    Node_Id: number;
    Online_Meeting: string;
    Space_Id: string;
    Space_Layout: string;
    Booking_Parties: string;
    Booking_Parties_Visitors: string;
    Cost_Code_Allocation: Array<ICostCodeAllocation> | null;
}

export interface IGetV1BookingResponse
{
    _CreatedAt: string;
    _CreatedBy: string;
    Booking_AutoCheckIn: boolean;
    Booking_Cancelled_Time: string;
    Booking_Checkin_Time: string;
    Booking_Completed_Time: string;
    Booking_Early_Checkin:string;
    Booking_Description: string;
    Booking_End: string;
    Booking_Host_Email: string;
    Booking_Host_Name: string;
    Booking_Id: string;
    Booking_IsActive: boolean;
    Booking_IsApproved: number;
    Booking_IsCancelled: boolean;
    Booking_IsCheckedIn: boolean;
    Booking_IsCompleted: boolean;
    Booking_IsEarly_Checkin: boolean;
    Booking_IsLate_Checkin: boolean;
    Booking_IsPrivate: boolean;
    Booking_Name: string;
    Booking_Owner_Email: string;
    Booking_Owner_Id: string;
    Booking_Owner_Name: string;
    Booking_Parties: IGetV1BookingResponse_BookingParty[];
    Booking_Parties_Visitors: IGetV1BookingResponse_BookingParty[];
    Booking_Share_Loc: number;
    Booking_Start: string;
    Booking_Status: string;
    Cost_Code_Allocation: Array<ICostCodeAllocation> | null;
    Meta_Ext_Booking_Id: string;
    Meta_Organisation: string;
    Node_Id: number;
    Online_Meeting: string;
    Space_Id: string;
    Space_Layout: string;
}

export interface IGetV1BookingResponse_BookingParty
{
    Email: string;
    First_Name: string;
    Last_Name: string;
    Company: string;
}

export interface ICostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}
