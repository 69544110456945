import React, { Component } from "react";
import { appContext } from "../AppContext";
import { IVisit } from "../Providers.Api/Models";
import apis from "../Providers.Api/apis";
import { DateTime } from "luxon";
import Spinner from "./Spinner";
import { AxiosResponse } from "axios";

export default class PrintPass extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }


    constructor(props: IProps)
    {
        super(props);

        this.state = {
            isLoading: false,
            firstName: "",
            lastName: "",
            startDate: DateTime.now(),
            endDate: DateTime.now(),
            hostName: "",
            spaceName: "",
            pacsId: "",
            isEBRD: false,
            badgeImageUrl: "",
        };
    }

    public async componentDidMount(): Promise<void> 
    {
        this.setState({ isLoading: true });
        const visitorImageResponse = await this.apiClient.parameters.getParameterByName(this.appState.buildingId, "Vis_Pass_Image");

        this.setState({
            badgeImageUrl: visitorImageResponse[0]?.Parameter_Value,
            isEBRD: (window.location.origin.toLowerCase().indexOf('ebrd') !== -1),
        });

        const visitorResponse: AxiosResponse<IVisit> = await apis.getDetailsByVisitId(this.appState.buildingId, this.props.visitId);

        if (visitorResponse.data)
        {
            this.setState({
                firstName: visitorResponse.data.Visitor_First_Name,
                lastName: visitorResponse.data.Visitor_Last_Name,
                startDate: DateTime.fromISO(visitorResponse.data.Visit_Start_Date),
                endDate: DateTime.fromISO(visitorResponse.data.Visit_End_Date),
                hostName: visitorResponse.data.Visit_Host_Name,
                spaceName: visitorResponse.data.Space_Name,
                pacsId: visitorResponse.data.Visit_Pacs_Id,
                isLoading: false,
            });
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                {this.state.isLoading && <Spinner />}
                <>
                    <div className="visitor-badge">
                        <div className="align-items-center row">
                            <div className="col-4">
                                <div className="ibssheader align-items-center row">
                                    <img
                                        src={
                                            this.state.isEBRD
                                                ? "/images/ebrdLogo.svg"
                                                : this.state.badgeImageUrl
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <h1>{this.labels.HubLabelvisitor}</h1>
                            </div>
                        </div>
                        <div className="row name">
                            <div className="col-12">
                                <span>
                                    {this.state.firstName +
                                        " " +
                                        this.state.lastName}{" "}
                                </span>
                            </div>
                        </div>
                        <div className="row time">
                            <div className="col-3">
                                <span>
                                    {this.state.startDate.toLocaleString(DateTime.DATE_SHORT)}
                                </span>
                            </div>
                            <div className="col-1 ml-2">
                                <span>
                                    {this.state.startDate.toLocaleString(DateTime.TIME_SIMPLE)}
                                </span>
                            </div>
                            <div className="col-1">
                                <span>
                                    {this.state.endDate.toLocaleString(DateTime.TIME_SIMPLE)}
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span className="l-h-24">
                                    HOST:{" "}
                                    <span className="ml-1">
                                        {this.state.hostName}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span className="l-h-24">
                                    VISITOR DESTINATION:{" "}
                                    <span className="ml-1">
                                        {this.state.spaceName}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span className="l-h-24">
                                    Access Card:{" "}
                                    <span className="ml-1">
                                        {this.state.pacsId}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="visitorBadgeContent">
                        <div className="visitor-badge">
                            <div className="visitordetailsalign">
                                <div className="row namepdf">
                                    <div className="col-12">
                                        <span>
                                            {this.state.firstName +
                                                " " +
                                                this.state.lastName}{" "}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="l-h-24">
                                            Host:{" "}
                                            <span className="ml-1">
                                                <b>{this.state.hostName}</b>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="row date">
                                    <div className="col-12">
                                        <span className="l-h-24">
                                            Date:
                                            <span className="ml-1">
                                                <b>
                                                    {this.state.startDate.toLocaleString(DateTime.DATE_SHORT)}
                                                </b>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="row time1">
                                    <div className="col-12">
                                        <span className="l-h-24">
                                            Time:
                                            <span className="ml-1">
                                                <b>
                                                    {this.state.startDate.toLocaleString(DateTime.TIME_SIMPLE)}
                                                </b>
                                            </span>{" "}
                                            <b>-</b>{" "}
                                            <span>
                                                <b>
                                                    {this.state.endDate.toLocaleString(DateTime.TIME_SIMPLE)}
                                                </b>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="l-h-24">
                                            Visitor Destination:{" "}
                                            <span className="ml-1">
                                                <b>{this.state.spaceName}</b>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="l-h-24">
                                            Access Card:{" "}
                                            <span className="ml-1">
                                                {this.state.pacsId}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </>
        );
    }
}

interface IProps
{
    visitId: string;
}

interface IState
{
    isLoading: boolean;
    isEBRD: boolean;
    firstName: string;
    lastName: string;
    startDate: DateTime;
    endDate: DateTime;
    hostName: string;
    spaceName: string;
    pacsId: string;
    badgeImageUrl: string;
}
