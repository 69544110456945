import { Component } from "react";
import { withRouter } from "react-router-dom";
import "./sidebar.scss";
import { appContext, IPartialAppState } from "../../AppContext";
import { IMenuItem, MenuItems } from "./SidebarData";
import { MenuItemKey } from "./MenuItemKey";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { routeList } from "../../routes";
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as RightArrowIcon } from './icons/RightArrow.svg';

class Sidebar extends Component<any, IState>
{
    private labels = appContext().labels;
    private appState = appContext().state;

    constructor(props: any)
    {
        super(props);
        const menuItems = new MenuItems();

        this.state =
        {
            lightModeTheme: this.appState.lightModeTheme,
            menu: menuItems.menuItems,
            activeAppMenu: "flex",
            showAppMenu: false,
            menuExpansionIds: [],
            collapseIds: [],
        };
    }

    public componentDidMount(): void
    {
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
        const activeMenuItemkey = this.getActiveMenuItemKey();
        const activeMenuIds = this.getActiveMenu(activeMenuItemkey);
        this.setState({menuExpansionIds: activeMenuIds});

        const activeAppMenuId = (activeMenuIds)[0];
        const activeAppMenu = this.state.menu.find(i => i.id === activeAppMenuId);
        if(activeAppMenu?.appMenu)
        {
            this.setState({activeAppMenu: activeAppMenu.appMenu});
        } 
        else
        {
            //in case no corresponding appMenu can be matched to url,  norights in particular.
            this.setState({activeAppMenu: 'flex'});
        }
    }
    
    private getActiveMenuItemKey(): MenuItemKey
    {
        //given a path that React Router matches to current url, find the corresponding RoutesList item, and return its menuItem Id
        const menuItem = routeList.find(i => i.path === this.props.match.path);
        if(menuItem && menuItem.menuItemId)
        {
            return menuItem.menuItemId;
        }

        // else returns menuItem key to Flex menu. 
        return MenuItemKey.NotSet;
    }

    private getActiveMenu(pathMenuItemKey: number): MenuItemKey[]
    {
        let activeMenu: MenuItemKey[]=[];
        this.state.menu.forEach(section => 
        {
            const activeItems = this.getActiveMenuItems(section, pathMenuItemKey);
            if(activeItems)
            {
                activeMenu = activeItems;
            }
        })
        return activeMenu;
    }

    private getActiveMenuItems(menuItem: IMenuItem, pathMenuItemKey: number): MenuItemKey[] | null 
    {
        // given a menuItem id, find all the parents of that menuItem. Return an array including node's link.
        
        // if menu's link match current url, return array of id.
        if(menuItem.id === pathMenuItemKey) 
        {
            return [menuItem.id];
        }

        for(const child of menuItem.children)
        {
            const childResult = this.getActiveMenuItems(child,pathMenuItemKey);
            // if child's link matches url, 
            if(childResult)
            {
                return [menuItem.id].concat(childResult);
            }
        }

        // else if no menu links matches location url, return null
        return null;
    }

    private getLink(menuItem: IMenuItem): string 
    {
        /* return link of the first descendant for a menu Item that has both link and for which the user has permissions */

        // if no permissions, return blank string
        if(!menuItem.permissions) return '#';

        // recursively apply getLink function to each child and their descendant, return the first descendant that has link and permissions.
        for(const child of menuItem.children.filter(child => child.permissions))
        {
            const link = this.getLink(child);

            if(link !=="#")
            {
                return link;
            }
        }

        // if no children returned with valid link from recursion, return menu item's link
        const link = menuItem.link();
        if (link)
        {
            return link;
        }
        // if menu item has no link, return empty url.
        return '#';
    }

    private sectionClicked(section: IMenuItem): void
    {
        /*
        * handle clicking on a menu item with a collapsible section, when you are already on the page the menu item links to. 
        * keep in mind menuExpansionIds.includes(section.id) === true for the logic in this function.
        * opening of menus with links to other pages are handled driven by the url changes.
        */
       if(this.state.collapseIds.includes(section.id))
       {
            // if collapseIds includes section.id, remove that id from collapseIds list.
            this.setState((prevState) => {
                return {collapseIds: prevState.collapseIds.filter(id => id !==section.id )}
            });
       }
       else
       {
            // if collapseIds does not includes section.id, add that id to the collapseIds list.
            this.setState((prevState) => {
                return {collapseIds: prevState.collapseIds.concat([section.id])}
            });
       }
    }

    public render(): JSX.Element
    {
        const visibleTopLevelMenu=this.state.menu.filter( i => i.permissions && i.appMenu === this.state.activeAppMenu)
        const bannerImage = visibleTopLevelMenu.length > 0 ?  visibleTopLevelMenu : this.state.menu.filter(i => i.id === MenuItemKey.Flex) // show flex hero image if no permissions.
        return(
            <Paper elevation={0} square>
                <nav>
                <div className="side-navigation">
                    {/* hero image banners for each of the three apps. onClick, shows the menu containing all apps (that user is permitted to see).*/}
                    <div className="side-navigation-logo jc-ai-center" tabIndex={0}>
                        {bannerImage.map(section =>  
                                <img
                                    key={section.id}
                                    alt={section.label}
                                    style={{width: '100%'}}
                                    onClick={() =>  this.setState({activeAppMenu: section?.appMenu ?? "", showAppMenu: !this.state.showAppMenu})} 
                                    src={section?.titleImageUrl ?? ""} 
                                />
                            )
                        }
                    </div>
                    
                    {/* menu containing all apps (that user is permitted to see) */}
                    {this.state.showAppMenu && <List disablePadding className="side-navigation-menu">
                        {[...this.state.menu].reverse().filter(i => i.permissions)
                        .map(module => 
                            <ListItem key={module.id} divider sx={{padding: 0, lineHeight: '65px'}}>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={()=> 
                                        {
                                            this.setState({showAppMenu: false});

                                            const adminPortalBookingPolicies = module.id === MenuItemKey.Admin && module.children.find(i => {
                                                return i.id === MenuItemKey.Admin_Manage && i.permissions && i.children.find(
                                                    j => j.id=== MenuItemKey.Admin_Manage_BookingPolicies && j.permissions
                                                )
                                            });
                                            
                                            if(adminPortalBookingPolicies)
                                            {
                                                const bookingPoliciesItem = adminPortalBookingPolicies?.children.find(i => i.id === MenuItemKey.Admin_Manage_BookingPolicies)
                                                // adminPortalBookingPolicies is the menuItem with id Admin_Manage_BookingPolicies so this is equivalent to this.props.history.push('/booking-policies');
                                                this.props.history.push(this.getLink(bookingPoliciesItem ?? adminPortalBookingPolicies));
                                            }
                                            else
                                            {
                                                this.props.history.push(this.getLink(module));
                                            }
                                        }}>
                                    {module.icon && <SvgIcon component={module.icon.component} inheritViewBox sx={{height: '34px', width: '34px', marginLeft: '18px'}}/>}
                                    <span className="app-selector-menu">{module.label}</span>
                                </ListItemButton>
                            </ListItem>)}
                    </List>
                    }
                    {!this.state.showAppMenu && <Box className="side-navigation-menu">
                        {this.state.menu.filter(i => 
                        {
                            // filter by permissions and the menu that should be active based on url. one of flex, oneLens and adminPortal.
                            return i.permissions && i.appMenu === this.state.activeAppMenu
                        }).map(module => <List disablePadding key={module.id}>
                                {/* menu layer 1 */}
                                {module.children.filter(i => i.permissions).map(section => 
                                {
                                    return section.component == null ?
                                    <Box key={section.id}>
                                        <ListItem divider sx={{padding: 0}}>
                                            <ListItemButton 
                                                id={(section.id).toString()} 
                                                sx={{height: '65px', paddingRight: '28px'}}
                                                selected={this.state.menuExpansionIds.at(-1)===section.id}
                                                onClick={()=> {
                                                    if(this.state.menuExpansionIds.includes(section.id)){ //this.state.menuExpansionIds.includes(section.id)
                                                        // if section is in the list of nodes that are active, clicking on menu button collapses the dropdown menu
                                                        this.sectionClicked(section);
                                                    }else{
                                                        // else clicking on menu button routes user to another page.
                                                        const bookingPoliciesItem = section?.children.find(i => i.id === MenuItemKey.Admin_Manage_BookingPolicies && i.permissions )
                                                        this.props.history.push(this.getLink(bookingPoliciesItem ?? section));        
                                                    }
                                                }}
                                                >
                                                    {section.icon && <SvgIcon component={section.icon.component} inheritViewBox sx={{marginRight: '32px', color: (theme)=> theme.palette.text.primary}} />}
                                                    <ListItemText primary={
                                                        <Typography sx={{ fontFamily:'Source Sans Pro', fontSize: '16px', fontStyle: 'normal', fontWeight: '900', textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow:'hidden'}}>
                                                            {section.label}
                                                        </Typography>
                                                    }/>                                          
                                                    {/* right chevron */}
                                                    {(!this.state.menuExpansionIds.includes(section.id) || this.state.collapseIds.includes(section.id)) && <SvgIcon component={RightArrowIcon} inheritViewBox sx={{ color: (theme)=> theme.palette.text.primary}} />}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={this.state.menuExpansionIds.includes(section.id) && !(this.state.collapseIds.includes(section.id))}>
                                            <List key={`collapse layer 1 list - ${section.id}`} disablePadding>
                                                {/* menu layer 2 */}
                                                {section.children.filter(i => i.permissions).map(menuItem =>
                                                    {
                                                        if(menuItem.component)
                                                        {
                                                            const BuildingSelector = menuItem.component
                                                            return <BuildingSelector key={menuItem.id}/>
                                                        }
                                                        else
                                                        {
                                                            return <Box key={menuItem.id} sx={{padding: 0}}>
                                                            {/* menuItem.id === MenuItemKey.Admin_Manage_BookingPolicies && <BuldingSelector /> */ }
                                                            <ListItem divider sx={{padding: 0}}>
                                                                <ListItemButton 
                                                                    id={(menuItem.id).toString()} 
                                                                    sx={{height: '62px',  paddingRight: '28px'}}
                                                                    selected={this.state.menuExpansionIds.at(-1)===menuItem.id}
                                                                    onClick={()=> {
                                                                        if(this.state.menuExpansionIds.includes(menuItem.id)){ //this.state.menuExpansionIds.includes(section.id)
                                                                            // if section is in the list of nodes that are active, clicking on menu button collapses the dropdown menu
                                                                            this.sectionClicked(menuItem);
                                                                        }else{
                                                                            // else clicking on menu button routes user to another page.
                                                                            this.props.history.push(this.getLink(menuItem));        
                                                                        }
                                                                    }}
                                                                    >
                                                                    <Box sx={{display:'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                                                                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                                                        <ListItemText primary={
                                                                                <Typography sx={{ fontFamily:'Source Sans Pro', fontSize: '14px', fontStyle: 'normal', fontWeight: '900', textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow:'hidden'}}>
                                                                                    {menuItem.label}
                                                                                </Typography>
                                                                        }/>
                                                                        {/* sub heading*/}
                                                                        {
                                                                            menuItem.subLabel && <ListItemText 
                                                                                sx={{marginTop: "0px"}} 
                                                                                secondary={
                                                                                    <Typography sx={{ fontFamily:'Source Sans Pro', color: (theme)=> theme.palette.text.secondary ,fontSize: '14px', fontStyle: 'normal', fontWeight: '900', textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow:'hidden'}}>
                                                                                        {menuItem.subLabel}
                                                                                    </Typography>
                                                                                }/>
                                                                        }
                                                                        </Box>                                                                    
                                                                        {menuItem.icon && <SvgIcon component={menuItem.icon.component} inheritViewBox sx={{color: (theme)=> theme.palette.text.primary}} />}
                                                                    </Box>
                                                                </ListItemButton>
                                                            </ListItem>
                                                            <Collapse in={this.state.menuExpansionIds.includes(menuItem.id) && !(this.state.collapseIds.includes(menuItem.id))}>
                                                                <List key={`collapse layer 2 list - ${menuItem.id}`} disablePadding>
                                                                    {/* menu layer 3 */}
                                                                    {menuItem.children.filter(i => i.permissions).map(subMenuItem =>
                                                                    <Box key={subMenuItem.id} sx={{padding: 0}}>
                                                                        <ListItem divider sx={{padding: 0}}>
                                                                            <ListItemButton
                                                                                id={(subMenuItem.id).toString()} 
                                                                                sx={{height: '36px',  paddingRight: '28px'}}
                                                                                selected={this.state.menuExpansionIds.at(-1)===subMenuItem.id}
                                                                                onClick={()=> {
                                                                                    if(this.state.menuExpansionIds.includes(subMenuItem.id)){ //this.state.menuExpansionIds.includes(section.id)
                                                                                        // if section is in the list of nodes that are active, clicking on menu button collapses the dropdown menu
                                                                                        this.sectionClicked(subMenuItem);
                                                                                    }else{
                                                                                        // else clicking on menu button routes user to another page.
                                                                                        this.props.history.push(this.getLink(subMenuItem));        
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <ListItemText primary={
                                                                                    <Typography sx={{ fontFamily:'Source Sans Pro', fontSize: '14px', fontStyle: 'normal', fontWeight: '900', textOverflow: 'ellipsis', whiteSpace: 'nowrap',overflow:'hidden'}}>
                                                                                        {subMenuItem.label}
                                                                                    </Typography>
                                                                                }/>
                                                                            </ListItemButton>
                                                                        </ListItem> 
                                                                    </Box>    
                                                                    )}
                                                                </List>
                                                            </Collapse>
                                                        </Box>
                                                        }
                                                    }    
                                                )}
                                            </List>
                                        </Collapse>
                                    </Box>
                                    :
                                    <section.component key={section.id}/>
                                })}
                            </List>
                        )}
                    </Box>
                    }
                    <div className="jc-ai-center side-navigation-bottom-logo">
                        <img
                            alt=""
                            src={this.state.lightModeTheme ? `/images/ibss-logo.png` : `/images/Ibss_Logo_Dark.svg`}
                            style={{ width: "55px", height: "17px" }}
                        />
                    </div>
                </div>
                </nav>
            </Paper>
        );
    }
}

export default withRouter(Sidebar);

interface IState
{
    lightModeTheme: boolean;
    menu: Array<IMenuItem>;
    activeAppMenu: string;
    showAppMenu: boolean;
    menuExpansionIds: MenuItemKey[];
    collapseIds: MenuItemKey[];
}