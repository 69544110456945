import React, { useEffect, useState } from 'react';
import '../../../styles/css/searchspace.scss';
import '../../../App.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Helper, { periodTypeCall, getAllBuildingsData } from '../../../Common/Helper';
import apis from '../../../Providers.Api/apis';
import IbssDatePicker from '../../../Components/uicomponents/Datepicker/IbssDatePicker';
import TextField from '@mui/material/TextField';
import { appContext } from '../../../AppContext';
import { ONELENS_SPACEANALYTICS_FILTER, ONE_LENSE_DATE_BULDING } from '../../../app/constants';
import { getCalendarView } from '../../../Common/Helper';
import { constTotal, constAny, constSpaceTypeLabel, constBuilding, constSpace, constFloor, constWork, periodTypeMonth } from "../../../app/constants";
import IbssButtonRedo from '../../../Components/uicomponents/IbssButton';
import { DateTime } from 'luxon';

interface toggleProps {
  object: any;
  open: boolean;
  toggleDrawer: () => void;
  lightModeTheme: boolean;
  searchCriteriaSubmit: (object: any) => void;
  clearFormClicked: () => void;
}

interface workSpaceTypeInterface {
  Space_Type_Label: string
}

function SearchCriteria(props: toggleProps) {
  const { open, toggleDrawer, lightModeTheme } = props;
  const labels = appContext().labels;
  const session = appContext().sessionStorageProvider;
  const local = appContext().localStorageProvider;

  const buildingid = session.getBuildingId()
  const dispatch = useDispatch();
  var date = new Date();

  const {
    buildingNodeId,
    onelensSpaceAnalyticsFilterCriteria,
    onelensDateBuilding,
    onelensmetaBookable,
  } = useSelector((state: RootStateOrAny) => ({
    onelensSpaceAnalyticsFilterCriteria:
      state.onelensSpaceAnalyticsFilterCriteria,
    onelensDateBuilding: state.onelensDateBuilding,
    onelensmetaBookable: state.onelensDateBuilding.metaBookable,
    buildingNodeId: state.onelensDateBuilding.buildingNodeId,
  }));

  const [selectedBuildingOption, setselectedBuildingOption] = useState<any>(
    onelensDateBuilding.buildingNodeId
  );
  const [buildingOptions, setbuildingOptions] = useState<any>([]);
  const [selectedClassType, setselectedClassType] = useState(onelensSpaceAnalyticsFilterCriteria.buildingClassType);
  const [selectedPeriodType, setselectedPeriodType] = useState<any>(onelensSpaceAnalyticsFilterCriteria.buildingPeriodType === "Day" ? 3 : onelensSpaceAnalyticsFilterCriteria.buildingPeriodType === "Week" ? 0 : onelensSpaceAnalyticsFilterCriteria.buildingPeriodType === "Year" ? 2 : 1);
  const [floorTypeOptions, setfloorTypeOptions] = useState<any>([]);
  const [selectedFloor, setselectedFloor] = useState(onelensDateBuilding.floor === "" ? constAny : onelensDateBuilding.floor);
  const [workSpaceTypeOption, setWorkSpaceTypeOption] = useState<workSpaceTypeInterface[]>([]);
  const [selectedAggregation, setSelectedAggregation] = useState(onelensDateBuilding.nodeLevelName);
  const [periodStartDate, setPeriodStartDate] = useState<Date>(new Date(onelensDateBuilding.periodstartDate));
  const [periodEndDate, setPeriodEndDate] = useState<Date>(new Date(onelensDateBuilding.periodEndDate));
  const [selectedWorkSpaceType, setselectedWorkSpaceType] = useState<workSpaceTypeInterface["Space_Type_Label"]>(onelensSpaceAnalyticsFilterCriteria.buildingWorkSpaceType);
  const [selectedBookable, setSelectedBookable] = useState<any>(onelensmetaBookable);
  const defaultBuilding = buildingOptions.filter((val: any) => val.value === buildingid);
  const periodType = [
    { Name: `${labels.HublabelDay}`, Value: 3 },
    { Name: `${labels.HublabelWeek}`, Value: 0 },
    { Name: `${labels.HublabelMonth}`, Value: 1 },
    { Name: `${labels.HublabelYear}`, Value: 2 },
  ];
  const classType = [
    { Name: `${labels.HubLabelworkspaces}`, Value: "Work" },
    { Name: `${labels.HublabelSupportSpaces}`, Value: "Support" },
    { Name: `${labels.HublabelAmenities}`, Value: "Amenity" },
  ];
  const AggregationValueOne = [
    { Name: `${labels.HublabelFloor}`, Value: "Floor" },
    { Name: `${labels.HublabelWorkspaceType}`, Value: `Building` },
    { Name: `${labels.HubLabelSpaceName}`, Value: "Space" },
  ];
  const AggregationValueTwo = [
    { Name: `${labels.HubLabelBuilding}`, Value: "Building" },
  ];
  const bookableOptions = [
    { Name: `${labels.HubLabelAllSpaces}`, Value: 0 },
    { Name: `${labels.HubLabelAllBookableSpaces}`, Value: 1345 },
    { Name: `${labels.HubLabelAllReservableSpaces}`, Value: 2 },
    { Name: `${labels.HubLabelAllBookandReservableSpaces}`, Value: 12345 },
  ];

  /* First render API & state call */
  useEffect(() => {
    const bldgOpts = makeBuildingOptions(getAllBuildingsData());
    const defaultMonthStartDate = DateTime.fromISO(periodStartDate.toString()).minus({ months: 1 }).startOf('month');
    const defaultMonthEndDate = DateTime.fromISO(periodEndDate.toString()).minus({ months: 1 }).startOf('month');
    const monthFirstStartDate = DateTime.local().day !== 1 ? periodStartDate : defaultMonthStartDate.toISO();
    const monthFirstEndDate = DateTime.local().day !== 1 ? periodEndDate : defaultMonthEndDate.toISO();
    const spaceAnalyticsFilter = session.getSpaceAnalyticsCriteria();
    if (spaceAnalyticsFilter)
    {
      setselectedPeriodType(spaceAnalyticsFilter.periodType);
    }
    apis
      .getWorkSpaceType(
        selectedBuildingOption,
        constSpaceTypeLabel,
        constSpace,
        selectedClassType,
        selectedPeriodType,
        selectedFloor,
        monthFirstStartDate,
        monthFirstEndDate
      )
      .then((res) => {
        const uniq = new Set(
          res.data.map((e: workSpaceTypeInterface) => JSON.stringify(e))
        );
        const response = Array.from(uniq).map((e: any) => JSON.parse(e));
        setWorkSpaceTypeOption(
          response.filter((value) => value.Space_Type_Label !== constTotal)
        );
      });
    setbuildingOptions(bldgOpts);
    const userPreferences = local.getUserPreferences()

    if (
      userPreferences?.SearchPrefs?.DefaultBuilding != null &&
      userPreferences?.SearchPrefs?.DefaultBuilding != undefined
    ) {
      setselectedBuildingOption(onelensDateBuilding.buildingNodeId);
    }
  }, []);

  useEffect(() => {
    apis
      .getWorkSpaceType(
        selectedBuildingOption,
        constSpaceTypeLabel,
        constSpace,
        selectedClassType,
        1,
        selectedFloor,
        periodStartDate,
        periodEndDate
      )
      .then((res) => {
        const uniq = new Set(
          res.data.map((e: workSpaceTypeInterface) => JSON.stringify(e))
        );

        const response = Array.from(uniq).map((e: any) => JSON.parse(e));
        const resWorkspaceValue = response.filter((val) => val.Space_Type_Label === selectedWorkSpaceType);
        setselectedWorkSpaceType(resWorkspaceValue.length ? resWorkspaceValue[0].Space_Type_Label : constTotal);
        setWorkSpaceTypeOption(
          response.filter((value) => value.Space_Type_Label !== constTotal)
        );
      });
  }, [selectedBuildingOption])

  /* Get Floor as selected building*/
  useEffect(() => {
    const options = Helper.getFloorsByBuildingId(selectedBuildingOption);
    makeFloorsOptions(options);

    if (
      selectedBuildingOption === 1 &&
      selectedFloor === constAny &&
      selectedWorkSpaceType === constTotal
    ) {
      setSelectedAggregation(constBuilding);
      setSelectedBookable(0);
    }
  }, [selectedBuildingOption, selectedFloor, selectedWorkSpaceType]);

  /* Floor option array */
  const makeFloorsOptions = (data: any[]) => {
    let options: any = [{ label: constAny, value: constAny }];
    data.map((obj: any) => {
      options.push({ label: obj.Node_Name, value: obj.Node_Id });
    });
    setfloorTypeOptions(options);
  };

  /* Building option array */
  const makeBuildingOptions = (data: any) => {
    let options: any = [{ label: "All", value: 1 }];
    data.map((obj: any) => {
      options.push({ label: obj["Name"], value: obj["Node_Id"] });
    });
    return options;
  };

  /* Building change, changes different selections */
  const handleOnBldngChange = (e: SelectChangeEvent) => {
    setselectedBuildingOption(e.target.value);
    if (e.target.value) {
      setselectedFloor(constAny);
    }

    if (parseInt(e.target.value) === 1) {
      setselectedFloor(constAny);
      setselectedWorkSpaceType(constTotal);
      setSelectedAggregation(constBuilding);
      setSelectedBookable(0);
    } else if (
      parseInt(e.target.value) !== 1 &&
      selectedFloor === constAny &&
      selectedWorkSpaceType === constTotal
    ) {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    } else if (
      parseInt(e.target.value) !== 1 &&
      selectedFloor === constAny &&
      selectedWorkSpaceType === constAny
    ) {
      setSelectedAggregation(constBuilding);
      setSelectedBookable(0);
    }
  };

  /* Class selection changes work space type */
  const handleOnClassTypeChange = (e: SelectChangeEvent) => {
    setselectedClassType(e.target.value);
    apis
      .getWorkSpaceType(
        selectedBuildingOption,
        constSpaceTypeLabel,
        constSpace,
        e.target.value,
        selectedPeriodType,
        selectedFloor,
        periodStartDate,
        periodEndDate
      )
      .then((res) => {
        const uniq = new Set(
          res.data.map((e: workSpaceTypeInterface) => JSON.stringify(e))
        );
        const response = Array.from(uniq).map((e: any) => JSON.parse(e));
        setWorkSpaceTypeOption(
          response.filter((value) => value.Space_Type_Label !== constTotal)
        );
        const classTypeChange = response.filter(
          (val) => val.Space_Type_Label === selectedWorkSpaceType
        );
        if (classTypeChange.length > 0) {
          setselectedWorkSpaceType(classTypeChange[0].Space_Type_Label);
        } else {
          setselectedWorkSpaceType(constTotal);
          if (selectedFloor === constAny) {
            setSelectedAggregation(constFloor);
            setSelectedBookable(0);
          }
        }
      });
  };

  /* Period type selection changes Period Start/End Date*/
  const handleOnPeriodTypeChange = (e: SelectChangeEvent) => {
    if (parseInt(e.target.value) === 1) {
      setPeriodEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      setPeriodStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    } else if (parseInt(e.target.value) === 3) {
      setPeriodStartDate(new Date(new Date().setDate(new Date().getDate() - 1)));
      setPeriodEndDate(new Date());
    } else if (parseInt(e.target.value) === 0) {
      getMondayOfCurrentWeek();
    } else {
      setPeriodEndDate(new Date(new Date().getFullYear(), 11, 31));
      setPeriodStartDate(new Date(new Date().getFullYear(), 0, 1));
    }
    setselectedPeriodType(parseInt(e.target.value));
  };

  /* Floor change, changes different selections */
  const handleOnFloorChange = (e: SelectChangeEvent) => {
    setselectedFloor(e.target.value);
    if (selectedWorkSpaceType === constTotal && e.target.value === constAny) {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    } else if (
      selectedWorkSpaceType === constAny &&
      e.target.value === constAny
    ) {
      setSelectedAggregation(constBuilding);
      setSelectedBookable(0);
    } else if (
      selectedWorkSpaceType !== constAny ||
      (constTotal && e.target.value === constAny)
    ) {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    }

    if (e.target.name === constAny) {
      setselectedWorkSpaceType(constTotal);
    } else {
      setselectedWorkSpaceType(constTotal);
    }

    if (e.target.name === constAny && selectedAggregation !== constSpace) {
      selectedBookable(0);
    }

    apis
      .getWorkSpaceType(
        selectedBuildingOption,
        constSpaceTypeLabel,
        constSpace,
        selectedClassType,
        selectedPeriodType,
        e.target.value,
        periodStartDate,
        periodEndDate
      )
      .then((res) => {
        const uniq = new Set(
          res.data.map((e: workSpaceTypeInterface) => JSON.stringify(e))
        );
        const response = Array.from(uniq).map((e: any) => JSON.parse(e));

        setWorkSpaceTypeOption(
          response.filter((value) => value.Space_Type_Label !== constTotal)
        );
      });
  };

  /* Group By handle Change */
  const handleOnAggregationChange = (e: SelectChangeEvent) => {
    if (e.target.value !== constSpace) {
      setSelectedBookable(0);
    }
    setSelectedAggregation(e.target.value);
  };

  /* Work_Space_Type Change, changes aggregation */
  const handleOnWorkspaceTypeChange = (e: SelectChangeEvent<string>) => {
    let value: string = e.target.value;
    if (
      (selectedFloor === constAny || selectedFloor === "") &&
      e.target.value === constTotal
    ) {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    } else if (
      (selectedFloor === constAny || selectedFloor === "") &&
      (e.target.value === constAny || e.target.value !== constTotal)
    ) {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    } else if (selectedFloor !== constAny && e.target.value !== constTotal) {
      setSelectedAggregation(constSpace);
    } else if (
      ((selectedFloor === constAny || selectedFloor === "") &&
        e.target.value !== constAny) ||
      e.target.value !== constTotal
    ) {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    } else {
      setSelectedAggregation(constFloor);
      setSelectedBookable(0);
    }
    setselectedWorkSpaceType(value);
  };

  /* Start_Date & End_Date change as per selection */
  const handleStartDate = (e: Date) => {
    const startDay = e;
    if (selectedPeriodType === 2) {
      setPeriodStartDate(startDay);
      setPeriodEndDate(new Date(new Date(e).getFullYear(), 11, 31));
    } else if (selectedPeriodType === 1) {
      setPeriodStartDate(startDay);
      setPeriodEndDate(
        new Date(new Date(e).getFullYear(), new Date(e).getMonth() + 1, 0)
      );
    } else if (selectedPeriodType === 0) {
      setPeriodStartDate(startDay);
      setPeriodEndDate(new Date(new Date(e).setDate(new Date(e).getDate() - new Date(e).getDay() + 1 + 6)));
    } else if (selectedPeriodType === 3) {
      setPeriodEndDate(
        new Date(new Date(e).setDate(new Date(e).getDate() + 1))
      );
      setPeriodStartDate(startDay);
    }
  };

  /* Get Bookable Value */
  const handleBookableChange = (e: SelectChangeEvent) => {
    setSelectedBookable(e.target.value);
  };
  /* Get current week monday */
  function getMondayOfCurrentWeek() {
    const today = DateTime.local();
    const monday = today.startOf('week');
    const lastday = monday.plus({ days: 6 });
    setPeriodStartDate(monday.toJSDate());
    setPeriodEndDate(lastday.toJSDate());
  }

  /* Filtered Building as per selection */
  const filteredBuildingName = buildingOptions.filter((val: any) => selectedBuildingOption === val.value);
  /* Filtered Floor as per selection */
  const fitleredFloor = floorTypeOptions.filter((val: any) => selectedFloor === val.value);
  /* Filtered Period Type as per selection */
  const filteredPeriodType = periodType.filter((val) => selectedPeriodType === val.Value);

  /* Update Search Result */
  const upDateSearchResults = async () => {
    let filterData: any = {};
    if (filteredBuildingName) {
      filterData = { ...filterData, buildingOptions: filteredBuildingName[0].label };
    }
    if (fitleredFloor) {
      filterData = { ...filterData, buildingFloor: fitleredFloor[0]?.label ? fitleredFloor[0]?.label : constAny };
    }
    if (selectedWorkSpaceType) {
      filterData = { ...filterData, buildingWorkSpaceType: selectedWorkSpaceType ? selectedWorkSpaceType : constTotal };
    }
    if (selectedClassType) {
      filterData = { ...filterData, buildingClassType: selectedClassType };
    }
    if (filteredPeriodType) {
      filterData = { ...filterData, buildingPeriodType: filteredPeriodType[0].Name };
    }

    const filter = {
      startDate: DateTime.fromJSDate(periodStartDate), 
      endDate: DateTime.fromJSDate(periodEndDate),
      spaceId:"",
      periodType: periodTypeCall(filteredPeriodType[0].Name)
    }
    session.setSpaceAnalyticsCriteria(filter);

    /* Update redux filter state */
    await dispatch({
      type: ONELENS_SPACEANALYTICS_FILTER,
      payload: filterData,
    });

    toggleDrawer();

    /* Update redux date building */
    await dispatch({
      type: ONE_LENSE_DATE_BULDING,
      payload: {
        floor: selectedFloor,
        periodstartDate: DateTime.fromJSDate(new Date(periodStartDate)).toISO(),
        periodEndDate: DateTime.fromJSDate(new Date(periodEndDate)).toISO(),
        nodeLevelName: selectedBuildingOption === 1 ? constBuilding : selectedAggregation ? selectedAggregation : constFloor,
        buildingNodeId: selectedBuildingOption,
        metaBookable: selectedBookable,
        periodtype_: selectedPeriodType
      },
    });

    const object = {
      filterData,
      floor: selectedFloor,
      periodstartDate: DateTime.fromJSDate(new Date(periodStartDate)).toISO(),
      periodEndDate: DateTime.fromJSDate(new Date(periodEndDate)).toISO(),
      nodeLevelName: selectedBuildingOption === 1 ? constBuilding : selectedAggregation ? selectedAggregation : constFloor,
      buildingNodeId: selectedBuildingOption,
      metaBookable: selectedBookable,
    };
    props.searchCriteriaSubmit(object);
  };

  /* Set default form value */
  const clearForm = () => {
    setselectedBuildingOption(buildingid);
    setselectedFloor(constAny);
    setselectedClassType(constWork);
    setselectedWorkSpaceType(constTotal);
    setselectedPeriodType(1);
    setPeriodStartDate(new Date(date.getFullYear(), date.getMonth(), 1));
    setSelectedAggregation(constFloor);
    setSelectedBookable(0);

    dispatch({
      type: ONELENS_SPACEANALYTICS_FILTER,
      payload: {
        buildingOptions: defaultBuilding[0].label,
        buildingFloor: constAny,
        buildingWorkSpaceType: constTotal,
        buildingClassType: constWork,
        buildingPeriodType: periodTypeMonth,
      },
    });

    dispatch({
      type: ONE_LENSE_DATE_BULDING,
      payload: {
        floor: constAny,
        periodstartDate: new Date(date.getFullYear(), date.getMonth(), 1),
        periodEndDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        nodeLevelName: constFloor,
        buildingNodeId: selectedBuildingOption,
        metaBookable: 0,
        periodtype_: 1
      },
    });
    props.clearFormClicked();
  };

  /*Disabled all dates except Monday dates*/
  const selectedMondays = (day: Date | DateTime) => {
    if (selectedPeriodType === 0 && (day instanceof DateTime)) 
    {
      return day.weekday !== 1;
    }
    return false;
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={() => toggleDrawer()}
        direction="right"
        className="flex-search-filter-criteria"
        style={{ backgroundColor: "var(--ui-background-alternate)" }}
      >
        <div className="flexMySearch-filter-criteria">
          <div className="flexMySearch-filter-criteria-header">
            <span className="flexMySearch-filter-criteria-icon">
              <img
                className="flexMySearch-filter-criteria-img "
                src={`/images/Sidebar_Icons/${lightModeTheme ? "Light_theme" : "Dark_Theme"
                  }/Filter-2.svg`}
                alt="icon"
              />
            </span>

            {/* Filter your search criteria */}
            <span className="flexMySearch-filter-criteria-title">
              {labels.HubLabelflexFilterSearchCritera}
            </span>
            <span
              className="flexMySearch-filter-criteria-close"
              onClick={toggleDrawer}
            >
              &times;
            </span>
          </div>

          <div className="flexMySearch-filter-criteria-content">
            {/* Select a Building */}
            <div className="flexMySearch-filter-criteria-firstLabel pt-0">
              {labels.HubLabelBuildings}
            </div>
            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              <div className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">
                {labels.HubLabelSelectBuilding}
              </div>
              <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  <Select
                    className="filter-criteria-inputbox"
                    displayEmpty
                    value={selectedBuildingOption}
                    onChange={(e: SelectChangeEvent) => handleOnBldngChange(e)}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {buildingOptions.map((eachVal: any) => {
                      return (
                        <MenuItem value={eachVal.value}>
                          {eachVal.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flexMySearch-filter-criteria-border" />
            <div className="flexMySearch-filter-criteria-firstLabel pt-0">
              {labels.HubLabelLocation}
            </div>

            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              {/* floor */}
              <div className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">
                {labels.HubLabelFloors}
              </div>
              <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  {selectedBuildingOption === 1 ? (
                    <Select
                      className="filter-criteria-inputbox"
                      disabled
                      displayEmpty
                      value={selectedFloor}
                      onChange={(e) => handleOnFloorChange(e)}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {floorTypeOptions.map((eachVal: any) => {
                        return (
                          <MenuItem value={eachVal.value}>
                            {eachVal.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      className="filter-criteria-inputbox"
                      value={selectedFloor === "" ? constAny : selectedFloor}
                      onChange={(e) => handleOnFloorChange(e)}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {floorTypeOptions.map((eachVal: any) => {
                        return (
                          <MenuItem value={eachVal.value}>
                            {eachVal.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="flexMySearch-filter-criteria-border" />

            {/* Class Type */}
            <div className="flexMySearch-filter-criteria-firstLabel">
              {labels.HubLabelworkspaces}
            </div>
            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              <div className="col-5 card   ml-0 flexMySearch-filter-criteria-select-label">
                {labels.HublabelClassType}
              </div>
              <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  <InputLabel
                    shrink={false}
                    className="fms-fc-placeholder"
                  ></InputLabel>
                  <Select
                    className="filter-criteria-inputbox"
                    displayEmpty
                    value={selectedClassType}
                    onChange={(e) => handleOnClassTypeChange(e)}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {classType.map((eachVal: any) => {
                      return (
                        <MenuItem value={eachVal.Value}>
                          {eachVal.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              {/* Work Space Type */}
              <div className="col-5 card   ml-0 flexMySearch-filter-criteria-select-label">
                {labels.HublabelWorkSpaceLabel}
              </div>
              <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  <InputLabel
                    shrink={false}
                    className="fms-fc-placeholder"
                  ></InputLabel>
                  {selectedBuildingOption === 1 ? (
                    <Select
                      disabled
                      className="filter-criteria-inputbox"
                      labelId="fms_fc_ws-label"
                      id="fms_fc_bldng"
                      value={selectedWorkSpaceType}
                      onChange={(e: SelectChangeEvent<string>) =>
                        handleOnWorkspaceTypeChange(e)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      <MenuItem value="Total">
                        {selectedWorkSpaceType === constTotal ? constAny : constAny}
                      </MenuItem>
                      {workSpaceTypeOption?.map((eachVal: any, index) => {
                        return (
                          <MenuItem value={eachVal.Space_Type_Label}>
                            {eachVal.Space_Type_Label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      className="filter-criteria-inputbox"
                      labelId="fms_fc_ws-label"
                      id="fms_fc_bldng"
                      value={selectedWorkSpaceType}
                      onChange={(e: SelectChangeEvent<string>) =>
                        handleOnWorkspaceTypeChange(e)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      <MenuItem value="Total">
                        {selectedWorkSpaceType === constTotal ? constAny : constAny}
                      </MenuItem>
                      {workSpaceTypeOption?.map((eachVal: any, index) => {
                        return (
                          <MenuItem value={eachVal.Space_Type_Label}>
                            {eachVal.Space_Type_Label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              {/* Bookable */}
              <div className="col-5 card   ml-0 flexMySearch-filter-criteria-select-label">
                {labels.HublabelBookableLabel}
              </div>
              <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  <InputLabel
                    shrink={false}
                    className="fms-fc-placeholder"
                  ></InputLabel>
                  {selectedAggregation === constSpace ? (
                    <Select
                      className="filter-criteria-inputbox"
                      labelId="fms_fc_ws-label"
                      id="fms_fc_bldng"
                      value={selectedBookable}
                      onChange={(e: SelectChangeEvent) =>
                        handleBookableChange(e)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {bookableOptions?.map((eachVal: any, index) => {
                        return (
                          <MenuItem value={eachVal.Value}>
                            {eachVal.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      className="filter-criteria-inputbox"
                      labelId="fms_fc_ws-label"
                      id="fms_fc_bldng"
                      disabled
                      value={selectedBookable}
                      onChange={(e: SelectChangeEvent) =>
                        handleBookableChange(e)
                      }
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {bookableOptions?.map((eachVal: any, index) => {
                        return (
                          <MenuItem value={eachVal.Value}>
                            {eachVal.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="flexMySearch-filter-criteria-border" />
            {/* date and time */}
            <div className="flexMySearch-filter-criteria-firstLabel">
              {labels.HubLabelDateandTime}
            </div>
            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              <div className="col-5 card ml-0 flexMySearch-filter-criteria-select-label">
                {labels.HublabelPeriodType}
              </div>
              <div className="col-7 d-flex justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  <Select
                    className="filter-criteria-inputbox"
                    displayEmpty
                    value={selectedPeriodType}
                    onChange={(e: SelectChangeEvent) =>
                      handleOnPeriodTypeChange(e)
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  >
                    {periodType.map((eachVal: any) => {
                      return (
                        <MenuItem value={eachVal.Value}>
                          {eachVal.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flexMySearch-filter-criteria-border" />

            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              <div className="col-5 card ml-0 pt-0 flexMySearch-filter-criteria-select-label">
                {labels.HublabelPeriodStartDate}
              </div>
              <div className="col-7 d-flex pt-0 card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small" className="w-300">
                  <IbssDatePicker
                    value={periodStartDate}
                    onChange={(e) => handleStartDate(e as Date)}
                    renderInput={(props) => <TextField {...props} size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                    shouldDisableDate={selectedMondays}
                    views={[getCalendarView(selectedPeriodType)]}
                  />
                </FormControl>
              </div>
            </div>
            <div className="flexMySearch-filter-criteria-border" />
            {/* Aggregation type */}
            <div className="flexMySearch-filter-criteria-firstLabel">
              {labels.HublabelGroupType}
            </div>
            <div className="row flexMySearch-filter-criteria-selectBldng-filter">
              <div className="col-5 card ml-0 pt-0 flexMySearch-filter-criteria-select-label">
                {labels.HublabelGroupBy}
              </div>
              <div className="col-7 d-flex pt-0 justify-content-end card mr-0 flexMySearch-filter-criteria-select-selectBox">
                <FormControl fullWidth size="small">
                  {selectedBuildingOption === 1 ||
                    ((selectedFloor === constAny || selectedFloor === "") &&
                      selectedWorkSpaceType === constAny) ? (
                    <Select
                      className="filter-criteria-inputbox"
                      displayEmpty
                      value={selectedAggregation}
                      onChange={(e) => handleOnAggregationChange(e)}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {AggregationValueTwo.map((eachVal: any) => {
                        return (
                          <MenuItem value={eachVal.Value}>
                            {eachVal.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      className="filter-criteria-inputbox"
                      displayEmpty
                      value={selectedAggregation}
                      onChange={(e) => handleOnAggregationChange(e)}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    >
                      {AggregationValueOne.map((eachVal: any) => {
                        return (
                          <MenuItem value={eachVal.Value}>
                            {eachVal.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="flexMySearch-filter-criteria-border" />
            <div className="right-space-box-cont">
              <div className="d-flex justify-content-center">
                <a
                  type="button"
                  className="clear-attendees my-2"
                  onClick={() => clearForm()}
                >
                  {labels.HubLabelClearSelections}
                </a>
                <span className="ml-2">
                  <IbssButtonRedo
                    variant="contained"
                    onClick={() => upDateSearchResults()}
                  >
                    {labels.HubButtonUpdate}
                  </IbssButtonRedo>
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SearchCriteria;
