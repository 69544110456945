import { PagedResponse } from "../Models";
import * as GetById from "./GetByIdEndpoint";
import * as GetMany from "./GetManyEndpoint";
import * as Create from "./CreateEndpoint";
import * as Update from "./UpdateEndpoint";
import * as Delete from "./DeleteEndpoint";
import * as Enable from "./EnableEndpoint";
import * as Disable from "./DisableEndpoint";
import Guid from "../../Common/Guid";
import { DateTime } from "luxon";
import { Classification, Status } from "../CateringItems/CateringItemRepository";

export class CateringMenuRepository implements ICateringMenuRepository
{
    private getByIdEndpoint: GetById.IGetByIdEndpoint;
    private getManyEndpoint: GetMany.IGetManyEndpoint;
    private createEndpoint: Create.ICreateEndpoint;
    private updateEndpoint: Update.IUpdateEndpoint;
    private deleteEndpoint: Delete.IDeleteEndpoint;
    private enableEndpoint: Enable.IEnableEndpoint;
    private disableEndpoint: Disable.IDisableEndpoint;

    constructor(
        getByIdEndpoint: GetById.IGetByIdEndpoint,
        getManyEndpoint: GetMany.IGetManyEndpoint,
        createEndpoint: Create.ICreateEndpoint,
        updateEndpoint: Update.IUpdateEndpoint,
        deleteEndpoint: Delete.IDeleteEndpoint,
        enableEndpoint: Enable.IEnableEndpoint,
        disableEndpoint: Disable.IDisableEndpoint,
    )
    {
        this.getByIdEndpoint = getByIdEndpoint;
        this.getManyEndpoint = getManyEndpoint;
        this.createEndpoint = createEndpoint;
        this.updateEndpoint = updateEndpoint;
        this.deleteEndpoint = deleteEndpoint;
        this.enableEndpoint = enableEndpoint;
        this.disableEndpoint = disableEndpoint;
    }

    public getById(nodeId: number, menuId: string, expandMenuItems: boolean, expandMenuItemRestrictions: boolean): Promise<GetById.ICateringMenu>
    {
        return this.getByIdEndpoint.execute(nodeId, menuId, expandMenuItems, expandMenuItemRestrictions);
    }

    public getMany(nodeId: number, expandMenuItems: boolean, expandMenuItemRestrictions: boolean, top: number, filter: Filter): Promise<PagedResponse<GetMany.ICateringMenu[]>>
    {
        return this.getManyEndpoint.execute(nodeId, expandMenuItems, expandMenuItemRestrictions, top, filter);
    }

    public create(cateringMenu: Create.ICateringMenu): Promise<string>
    {
        return this.createEndpoint.execute(cateringMenu);
    }

    public update(cateringMenu: Update.ICateringMenu): Promise<void>
    {
        return this.updateEndpoint.execute(cateringMenu);
    }

    public delete(nodeId: number, cateringMenuId: string, suppressError: boolean): Promise<void>
    {
        return this.deleteEndpoint.execute(nodeId, cateringMenuId, suppressError);
    }

    public enable(nodeId: number, cateringMenuId: string, concurrencyStamp: string, suppressError: boolean): Promise<void>
    {
        return this.enableEndpoint.execute(nodeId, cateringMenuId, concurrencyStamp, suppressError);
    }

    public disable(nodeId: number, cateringMenuId: string, concurrencyStamp: string, suppressError: boolean): Promise<void>
    {
        return this.disableEndpoint.execute(nodeId, cateringMenuId, concurrencyStamp, suppressError);
    }
}

export interface ICateringMenuRepository
{
    getById(nodeId: number, menuId: string, expandMenuItems: boolean, expandMenuItemRestrictions: boolean): Promise<GetById.ICateringMenu>;
    getMany(nodeId: number, expandMenuItems: boolean, expandMenuItemRestrictions: boolean, top: number, filter: Filter): Promise<PagedResponse<GetMany.ICateringMenu[]>>;
    create(cateringMenu: Create.ICateringMenu): Promise<string>;
    update(cateringMenu: Update.ICateringMenu): Promise<void>;
    delete(nodeId: number, cateringMenuId: string, suppressError: boolean): Promise<void>;
    enable(nodeId: number, cateringMenuId: string, concurrencyStamp: string, suppressError: boolean): Promise<void>;
    disable(nodeId: number, cateringMenuId: string, concurrencyStamp: string, suppressError: boolean): Promise<void>;
}

export class Filter
{
    public status?: CateringMenuStatus;
    public availableFrom?: DateTime;
    public availableTo?: DateTime;
    public roleIds?: string[];

    constructor(value: Partial<Filter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        const filterParts: string[] = [];
        filterParts.push(this.status != null ? `Status eq '${this.status}'` : "");
        filterParts.push(this.availableFrom ? `Available_From le ${this.availableFrom.toUTC().toISO()}` : "");
        filterParts.push(this.availableTo ? `Available_Until ge ${this.availableTo.toUTC().toISO()}` : "");
        filterParts.push(this.roleIds && this.roleIds.length > 0 ? `Menu_Roles/any(c: ${this.roleIds.map(roleId => `c/Role_Id eq '${roleId}'`).join(' or ')})` : "");
        return filterParts.filter(i => i != "").join(" and ");
    }
}

export class CateringMenuStatus
{
    public static active = "StatusActive";
    public static inactive = "StatusInactive";
}

export interface IMenuItems
{
    MenuItem_Id: string;
    Menu_Id: string;
    MenuItem: IMenuItem;
}

export interface IMenuItem
{
    Description: string;
    Status: Status;
    UnitPrice: number;
    Classification: Classification;
    RetailPrice: number;
    Name: string;
    Supplier_Id: string;
    MenuItem_Id: string;
    ConcurrencyStamp: string;
    NutritionalInformation: string;
    OrderPolicy_Id: string;
    ImageURI: string;
    CreatedAt: string;
    MenuItem_Restrictions: ICateringRestriction[];
}

export interface ICateringRestriction
{
    Restriction_Id: string;
    Restriction: ICateringRestrictionDetails;
}

export interface ICateringRestrictionDetails
{
    Restriction_Id: string;
    Section: string;
    ImageURI: string;
    Name: string;
    ConcurrencyStamp?: string;
}
