import { IGetFilesEndpoint } from "./GetFilesEndpoint";
import { IUploadFileEndpoint } from "./UploadFileEndpoint";


export class FilesRepository implements IFilesRepository
{
    private getFilesEndpoint: IGetFilesEndpoint;
    private uploadFileEndpoint: IUploadFileEndpoint;

    constructor(
        getFilesEndpoint: IGetFilesEndpoint,
        uploadFileEndpoint: IUploadFileEndpoint
    )
    {
        this.getFilesEndpoint = getFilesEndpoint;
        this.uploadFileEndpoint = uploadFileEndpoint;
    }

    public getFiles(path: string): Promise<IFile[]>
    {
        return this.getFilesEndpoint.execute(path);
    }

    public uploadFile(fileData: FormData, pathValue: string, processAsMap: boolean): Promise<void>
    {
        return this.uploadFileEndpoint.execute(fileData, pathValue, processAsMap);
    }

}
export interface IFilesRepository 
{
    getFiles(name: string): Promise<IFile[]>;
    uploadFile(fileData: FormData, pathValue: string, processAsMap: boolean ): Promise<void>;
}

export interface IFile
{
    name: string;
    path: string;
    lastModified: string;
    createdAt: string;
    size: string;
}
