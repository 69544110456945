import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { appContext } from "../AppContext";
import Helper, { dateConverter, getBuildingTimeZoneByNodeId } from "../Common/Helper";
import { RouteHelper } from '../Common/RouteHelper'
import { ApiError } from "./ApiError";
import ApiMessageBuilder from "./ApiMessageBuilder";
import apis from "./apis";
import Authentication, { LogOutReason } from "./Authentication";
import { IBookingPartyRepository } from "./BookingParties/BookingPartyRepository";
import { IBookingPolicyRepository } from "./BookingPolicies/BookingPolicyRepository";
import { IBookingRepository } from "./Bookings/BookingRepository";
import { IRoleRepository } from "./Roles/RoleRepository";
import { ISpaceRepository } from "./Spaces/SpaceRepository";
import { ISpacesDailySummaryRepository } from "./SpacesDailySummaryRepository";
import { ITaskRepository } from "./Tasks/TaskRepository";
import { IUserPreferenceRepository } from "./UserPreferenceRepository";
import { ICostCodeRepository } from './CostCodes/CostCodeRepository'
import { ICateringItemRepository } from "./CateringItems/CateringItemRepository";
import { ICateringOrderRepository } from "./CateringOrders/CateringOrderRepository";
import { INotificationRepository } from "./Notifications/NotificationRepository";
import { IExternalIdentityProviderRepository } from "./ExternalIdentityProviders/ExternalIdentityProviderRepository";
import { IParameterRepository } from "./Parameters/ParameterRepository";
import { IUsersRepository } from "./Users/UsersRepository";
import { ISpaceUtilisationSummaryRepository } from "./SpaceUtilisationSummaries/SpaceUtilisationSummaryRepository";
import { IVisitsRepository } from "./Visits/VisitsRepository";
import { ICateringMenuRepository } from "./CateringMenus/CateringMenuRepository";
import { ICateringSupplierRepository } from "./CateringSuppliers/CateringSupplierRepository";
import { IFilesRepository } from "./Files/FilesRepository";
import { IEnvironmentalZoneDataRepository } from "./EnvironmentalZoneData/EnvironmentalZoneDataRepository";
import { ICateringMenuItemRepository } from "./CateringMenuItems/CateringMenuItemRepository";
import { ICateringOrderPolicyRepository } from "./CateringOrderPolicies/CateringOrderPoliciesRepository";
import { ICateringRestrictionsRepository } from "./CateringRestrictions/CateringRestrictionsRepository";
import { ICateringItemRestrictionRepository } from "./CateringItemRestrictions/CateringItemRestrictionRepository";
import { IBookingDailySummaryRepository } from "./BookingDailySummaries/BookingDailySummaryRepository";
import { ISpaceZonesRepository } from "./SpaceZones/SpaceZonesRepository";
import { createBrowserHistory } from 'history';
import { IPrivancyPolicyRepository } from "./PrivacyPolicies/PrivancyPolicyRepository";

export class ApiClient implements IApiClient
{
    private readonly authentication: Authentication;

    private get alert() { return appContext().alert; }
    private messageBuilder = new ApiMessageBuilder();
    private get local() { return appContext().localStorageProvider; }
    private get session() { return appContext().sessionStorageProvider; }

    // repositories
    public bookingPolicies: IBookingPolicyRepository;
    public bookingParties: IBookingPartyRepository;
    public spaces: ISpaceRepository;
    public bookings: IBookingRepository;
    public userPreferences: IUserPreferenceRepository;
    public spacesDailySummaries: ISpacesDailySummaryRepository;
    public roles: IRoleRepository;
    public tasks: ITaskRepository;
    public costCodes: ICostCodeRepository;
    public cateringMenus: ICateringMenuRepository;
    public cateringMenuItems: ICateringMenuItemRepository;
    public cateringItems: ICateringItemRepository;
    public cateringOrderPolicies: ICateringOrderPolicyRepository;
    public cateringOrders: ICateringOrderRepository;
    public cateringRestrictions: ICateringRestrictionsRepository;
    public notifications: INotificationRepository;
    public users: IUsersRepository;
    public visits: IVisitsRepository;
    public spaceUtilisationSummaries: ISpaceUtilisationSummaryRepository;
    public cateringSuppliers: ICateringSupplierRepository;
    public files: IFilesRepository;
    public environemntalZoneData: IEnvironmentalZoneDataRepository;
    public parameters: IParameterRepository;
    public externalIdentityProviders: IExternalIdentityProviderRepository;
    public cateringItemRestriction :ICateringItemRestrictionRepository;
    //public cateringOrder :ICateringOrdersRepository;
    public spaceZones: ISpaceZonesRepository;
    public bookingDailySummary: IBookingDailySummaryRepository;
    public privacyPolicies: IPrivancyPolicyRepository;

    constructor(
        authentication: Authentication,
        bookingPolicies: IBookingPolicyRepository,
        bookingParties: IBookingPartyRepository,
        spaces: ISpaceRepository,
        bookings: IBookingRepository,
        userPreferences: IUserPreferenceRepository,
        spacesDailySummaries: ISpacesDailySummaryRepository,
        roles: IRoleRepository,
        tasks: ITaskRepository,
        costCodes: ICostCodeRepository,
        parameters:IParameterRepository,
        cateringMenus: ICateringMenuRepository,
        cateringMenuItems: ICateringMenuItemRepository,
        cateringItems: ICateringItemRepository,
        cateringOrderPolicies: ICateringOrderPolicyRepository,
        cateringOrders: ICateringOrderRepository,
        notifications: INotificationRepository,
        externalIdentityProviders: IExternalIdentityProviderRepository,
        users: IUsersRepository,
        visits: IVisitsRepository,
        spaceUtilisationSummaries: ISpaceUtilisationSummaryRepository,
        cateringSuppliers: ICateringSupplierRepository,
        files: IFilesRepository,
        environemntalZoneData: IEnvironmentalZoneDataRepository,
        cateringRestrictions: ICateringRestrictionsRepository,
        cateringItemRestriction: ICateringItemRestrictionRepository,
        //cateringOrder: ICateringOrdersRepository,
        spaceZones: ISpaceZonesRepository,
        bookingDailySummary: IBookingDailySummaryRepository,
        privacyPolicies: IPrivancyPolicyRepository,
    )
    {
        this.authentication = authentication;
        this.bookingPolicies = bookingPolicies;
        this.bookingParties = bookingParties;
        this.spaces = spaces;
        this.bookings = bookings;
        this.userPreferences = userPreferences;
        this.spacesDailySummaries = spacesDailySummaries;
        this.roles = roles;
        this.tasks = tasks;
        this.costCodes = costCodes;
        this.parameters = parameters;
        this.cateringMenus = cateringMenus;
        this.cateringMenuItems = cateringMenuItems;
        this.cateringItems = cateringItems;
        this.cateringOrderPolicies =  cateringOrderPolicies;
        this.cateringOrders = cateringOrders;
        this.notifications = notifications;
        this.externalIdentityProviders = externalIdentityProviders;
        this.users = users;
        this.visits = visits;
        this.spaceUtilisationSummaries = spaceUtilisationSummaries;
        this.cateringSuppliers = cateringSuppliers;
        this.files = files;
        this.environemntalZoneData = environemntalZoneData;
        this.cateringRestrictions = cateringRestrictions;
        this.cateringItemRestriction = cateringItemRestriction;
        //this.cateringOrder = cateringOrder;
        this.bookingDailySummary = bookingDailySummary;
        this.spaceZones = spaceZones;
        this.privacyPolicies = privacyPolicies;
    }

    public initialise(): void
    {
        const productName = "IBSS Hub";
        const version = process.env.REACT_APP_VERSION;
        
        axios.interceptors.request.use(request =>
        {
            request.headers["App-Version"] = `${productName}/${version}`;
            const authToken = this.local.getToken();

            if (authToken != null && authToken != "")
            {
                request.headers.common = { Authorization: `bearer ${authToken}` };
            }
            return request;
        });

        axios.interceptors.response.use(
            response =>
            {
                return response;
            },
            (error: AxiosError) =>
            {
                let config: IbssRequestConfig = error.config;
                let statusCode = error?.response?.status;
                let route = RouteHelper.getCurrentRoute();
                let isProtected = (route?.protected ?? false);
                let isAuthenticationRoute = route?.path == "/auth";
                const nodeId = parseInt(error.config.url?.match(/\/(v\d+)\/(\d+)\//i)?.[2] ?? "1");
                if (statusCode === 401 && isProtected)
                    {
                        // log out if we get another 401 after refreshing the access token (to guard against an infinite loop)
                        if (config.tokenRefreshed === true)
                        {
                            this.authentication.logOut(LogOutReason.SessionExpired);
                            return Promise.reject(error);
                        }

                        return this.authentication
                            .refreshAccessToken()
                            .then(token =>
                            {
                                // try again with new token
                                config.tokenRefreshed = true;
                                error.config.headers["Authorization"] = `bearer ${token}`;
                                return axios(error.config);
                            })
                            .catch(() =>
                            {
                                this.authentication.logOut(LogOutReason.SessionExpired);
                                return Promise.reject(error);
                            });
                    }
                    else if (statusCode === 403 && isProtected)
                    {
                        if(config.suppressErrorPopup == null || !config.suppressErrorPopup)
                        {
                            let titleAndMessage = this.messageBuilder.getTitleAndMessage(error, nodeId);
                            this.alert.show(titleAndMessage.title, titleAndMessage.message);
                        }
                        return Promise.reject(error);
                    }
                    else if (statusCode === 403 && isAuthenticationRoute)
                    {
                        this.authentication.logOut(LogOutReason.AccountIncomplete);
                        return Promise.reject(error);
                    }
                    else
                    {
                        if (config.suppressErrorPopup == null || !config.suppressErrorPopup)
                        {
                            let titleAndMessage = this.messageBuilder.getTitleAndMessage(error, nodeId);
                            this.alert.show(titleAndMessage.title, titleAndMessage.message);
                        }
                        return Promise.reject(error);
                    }
            });
    }
}

export interface IApiClient
{
    initialise(): void;
    bookingPolicies: IBookingPolicyRepository;
    bookingParties: IBookingPartyRepository;
    spaces: ISpaceRepository;
    bookings: IBookingRepository;
    userPreferences: IUserPreferenceRepository;
    spacesDailySummaries: ISpacesDailySummaryRepository;
    roles: IRoleRepository;
    tasks: ITaskRepository;
    costCodes: ICostCodeRepository;
    parameters: IParameterRepository;
    cateringMenus: ICateringMenuRepository;
    cateringMenuItems: ICateringMenuItemRepository;
    cateringItems: ICateringItemRepository;
    cateringOrderPolicies: ICateringOrderPolicyRepository;
    cateringOrders: ICateringOrderRepository;
    notifications: INotificationRepository;
    externalIdentityProviders: IExternalIdentityProviderRepository;
    users: IUsersRepository;
    visits: IVisitsRepository;
    spaceUtilisationSummaries: ISpaceUtilisationSummaryRepository;
    cateringSuppliers: ICateringSupplierRepository;
    files: IFilesRepository;
    environemntalZoneData: IEnvironmentalZoneDataRepository;
    cateringRestrictions: ICateringRestrictionsRepository;
    cateringItemRestriction: ICateringItemRestrictionRepository,
    //cateringOrder: ICateringOrdersRepository;
    bookingDailySummary: IBookingDailySummaryRepository;
    spaceZones: ISpaceZonesRepository;
    privacyPolicies : IPrivancyPolicyRepository;
}

export interface IbssRequestConfig extends AxiosRequestConfig
{
    tokenRefreshed?: boolean;
    suppressErrorPopup?: boolean;
}
