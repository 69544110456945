import React, { Component } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import "../styles/css/table.scss";
import "../App.css";
class PieChartCard extends Component<IProps, IState> {

  private dataPieEmpty = [{ name: "No Data", value: 100, count: 0 }];
  private COLORSEMPTY = ['#393D44'];
  
  constructor(props: IProps)
  {
      super(props);
      this.state = 
      {
        list: [],
        color:[]
      }
  }
  

  public componentDidMount(): void 
  {
    const { dataPie, dataPieValue, colorArray } = this.props;
    this.setState({ 
        list: dataPie.length && dataPieValue ? dataPie : this.dataPieEmpty, 
        color: dataPie.length && dataPieValue ? colorArray : this.COLORSEMPTY 
    });
  }
  
  public componentDidUpdate(prevProps: IProps): void
  {
    if (prevProps.dataPie !== this.props.dataPie) 
    {
        this.setState({ 
            list: this.props.dataPie, 
            color: this.props.colorArray 
        });
    }
  }

  public render(): JSX.Element
  {
    const { dataPieValue, label, selectedDate } = this.props;
    
    const pieChartList = this.state.list && dataPieValue ? this.state.list : this.dataPieEmpty;
    const pieChartColor = this.state.list && dataPieValue ? this.state.color : this.COLORSEMPTY;

    return (
      <div className="donutchart">
        <h3 className="donutcharttitle">{label}{selectedDate}</h3>
        <div className="div-flex">
          <div className="col-6">
            <PieChart width={250} height={200}>
              <Pie
                stroke="none"
                data={pieChartList}
                cx={80}
                cy={70}
                innerRadius="40"
                outerRadius="65"
                paddingAngle={0}
                dataKey="value"
                startAngle={180}
                endAngle={-360}
              >
                {pieChartList?.map((entry: IPie, index: number) => (
                  <Cell key={`cell-${index}`} fill={pieChartColor[index % pieChartColor.length]} />
                ))}
                <Label
                  stroke="var(--ui-text)"
                  value={dataPieValue}
                  position="center"
                  fontSize="35"
                  fontWeight="bolder"
                />
              </Pie>
            </PieChart>
          </div>
          <div className="donutchartlegend">
            {this.state.list.length !== 0 && (
              <table className="col-12 charttable">
                <tbody>
                  {this.state.list?.map((item: IPie, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="statusbox" style={{ backgroundColor: this.state.color[index] }}></div>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.count}</td>
                      <td>{item.value ? item.value : 0}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export interface IPie 
{
  name: string;
  count: number;
  value: number;
}

interface IProps 
{
  dataPie: IPie[];
  dataPieValue: number;
  colorArray: string[];
  label: string;
  selectedDate: string;
}

interface IState 
{
  list: IPie[];
  color: string[]
}

export default PieChartCard;
